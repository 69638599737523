<div class="form-body">

  <!-- Language -->
  <div class="one-card">
    <h2>{{ 'SETTINGS.LANG' | atrans }}</h2>
    <div class="one-field">
      <label class="input outlined" [title]="'SETTINGS.LANG' | atrans">
        <select [(ngModel)]="lang" (ngModelChange)="changedLanguage()">
          <option *ngFor="let l of languages" [ngValue]="l.locale">
            {{ l.icon }} {{ l.name }}
          </option>
        </select>
      </label>
    </div>
  </div> <!-- Language -->

</div>
