import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalBase, language } from 'curvy';
import { Task } from '@task-utils/types';
import { orders_translations } from '../orders.trans';

@Component({
    selector: 'task-selected-rows-info',
    template: `
		<div class="flex-column" style="width: 100%; gap: 1em;" >
				<!-- general info -->	
            <ng-container>
               
                
                <span class="flex-row wrappable" style="gap: .8em; align-items: flex-start; flex-direction: column;">
                    <div style="display: flex; align-items: center">
                        <strong>{{'ORDER.SCHEDULED.SELECTED.ORDERS' | trans }}: </strong>
                    </div>
                    
                    <div class="flex-column" style="max-height: 260px; flex: 1 0 auto; flex-direction: column; box-sizing: border-box; overflow-x: hidden; overflow-y: auto;">
                        <div *ngFor="let row of selected" style="white-space: nowrap; display: flex; align-items: center">
                            <div>
                                <i *ngIf="row.status_id === 1" icon="radio_button_unchecked" style="color: #49f"></i>
                                <i *ngIf="row.status_id === 5" icon="motion_photos_off" style="color: #f66"></i>
                            </div>
                            <div style="padding-left: 10px;">
                                <p>{{ row.title }}, </p> 
                            </div>
                            <div style="padding-left: 5px;">
                                <p>{{row.supplier_name}}</p>
                            </div>
                        </div>
                    </div>
                </span>
                
               
            </ng-container>
		</div>
	`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../order.component.scss']
})

export class SelectedRowsInfoComponent extends ModalBase<Task.ScheduledEvent[], any> implements OnInit {
	selected: Task.ScheduledEvent[];


	ngOnInit(): void {
        this.selected = [...this.injectedData]
        language.load_translations(orders_translations);
	}

}
