import { Component, OnInit, Input, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { FileType, files } from '@task-utils/utils';
import { color, ConfirmDialogComponent, language, modal, notification } from "curvy";
import { components_trans } from './components.trans';
import { Task } from '@task-utils/types';


const CUSTOM_CVA = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiFileSelectNew),
    multi: true
};


@Component({
  selector: 'task-multi-files',
  template: `
    <div class="thumbs-grid" [class.thumbs-flex]="!in_grid">
        <div *ngFor="let file of files; let i = index;"
            [style.cursor]="file.file ? 'auto' : 'pointer'">
            <task-file-new [file]="file" [download_file]="download_file"></task-file-new>

            <button class="round filled delete-file"
                color="warn"
                [title]="'DELETE' | trans"
                (click)="remove_file(i, file)"
                *ngIf="should_delete(file)"
            >
                <i [attr.icon]="file.file ? 'clear' : 'delete'"></i>
            </button>
        </div>
    </div>

    <button *ngIf="!disabled && !in_grid" (click)="get_files_for_upload()" color="primary" class="pill" style="margin: .7em auto;">
        <i icon="add_circle"></i>
    <span>{{ "ADD_FILE" | trans }}</span>
    </button>
   
  `,
    styleUrls: ['./components.scss'],
    providers: [ CUSTOM_CVA ],
    encapsulation: ViewEncapsulation.None

})
export class MultiFileSelectNew implements OnInit, ControlValueAccessor {
    msgs = {
        msg: language.translate("FILE_UPLOAD.SUCCESS_MSG"),
        showMsg: false,
        msg_color: 'secondary-dark'
    };
    

    files: FileType [] = [];
    
    @Input ()
    download_file:(file: FileType)=>Promise<Blob> | null;
    
    @Input()
    disabled = false;

    @Input('allow-deleting-uploaded')
    allow_deleting_uploaded = true;

    @Input('type-of-files')
    type_of_files: string = null;

    @Input()
    in_grid = false;

    private on_changed;
    private on_touched;

    ngOnInit() {
		language.load_translations(components_trans);
    }

    async get_files_for_upload() {
        let options: files.GetFilesOptions = {
            multiple: true,
            max_file_size: 10000000,
            on_files_too_large: (files) => {
                let names = files.map(f => f.name).join(", ");
                console.log('names: ', names);
                notification.show({
                    icon: "error",
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: language.translate('DOCUMENT.UPLOADED_TOO_BIG_TEXT', names)
                })
            }
        }
        if (this.type_of_files) options["accept"] = this.type_of_files;

        let out_files = await files.get_files(options)

        this.files.push(...out_files);
        
        this.on_changed(this.files);

        console.log('files:', this.files)
    }

    add_file(file: FileType, do_events=true) {
        this.files.push(file);
        console.log('files', file)
        if (do_events && this.on_changed) {
            this.on_changed(this.files);
        }
    }

    name(file: FileType) {
        if ("file" in file) {
            console.log('name', file)
            return file.file.name;
        } else {
       
          return file.dms_document_file_name;
        }
      }

    remove_file(index, file) {
        if (files.is_dms_file(file)) {
            modal.open(ConfirmDialogComponent, async (succ)=>{
                if (succ) {
                    this.files.splice(index, 1);
                    this.on_changed(this.files);
                }
            }, {
                title: language.translate("DELETE"),
                message: language.translate("DELETE.FILE.TEXT", `${this.name(file)}`),
                icon: "delete", 
                iconColor: color.variable(color.Variable.warn)
            })

        } else {
            this.files.splice(index, 1);
            this.on_changed(this.files);
        }
    }

    should_delete(file: FileType) {
        if (this.disabled) return false;

        if ("dms_document_id" in file) {
            return this.allow_deleting_uploaded;
        } else {
            return true;
        }
    }

    writeValue(val: FileType[]) {
        val = val || [];
        this.files = [];
        for (let file of val) {
            this.add_file(file, false);
        }
    }

    registerOnChange(fn) {
        this.on_changed = fn;
    }

    registerOnTouched(fn) {
        this.on_touched = fn;
    }

    setDisabledState(state: boolean) {
        this.disabled = state;
    }
}
