import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

type WorkDescriptionType = Task.WorkDescription & {automatically_update_arrival_details? : boolean};

@Component({
    selector: 'task-create-edit-work-description-dialog',
    template: `
        <task-create-edit-work-description [workgroup]="wd" (result)="handle_result($event)">
        </task-create-edit-work-description>
    `
})
export class CreateEditWorkDescriptionDialog extends ModalBase<Task.WorkDescription | null, any> implements OnInit {
    preventSoftClose = true;
    wd: Task.WorkDescription;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => {
                this.wd = res || {
                    work_description: "",
		            work_description_id: null
                }
            });
        } else {
            this.wd = this.injectedData || {
                work_description: "",
		        work_description_id: null
            };
        }
    }

    handle_result(wd: WorkDescriptionType) {
        if (wd == null) {
            this.submit(false);
            return;
        }

        this.wd = wd;

        let promise: Promise<any>;
        if (this.injectedData) {
            promise = TaskRoutes.work_descriptions.api_work_description_modify(wd.work_description_id, wd);
        } else {
            promise = TaskRoutes.work_descriptions.api_add_work_description(wd)
        }

        promise.then(() => {
            this.submit(true);
        });
    }
}
