import { Component, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { Frame, language, notification, color } from 'curvy';
import { locations_translations } from '../locations/locations.trans';
import { location_groups_translations } from './location-groups.trans';

@Component({
    selector: 'task-create-edit-location-group',
    templateUrl: './create-edit-location-group.component.html',
    styleUrls: ['./create-edit-location-group.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class CreateEditLocationGroupComponent {
    @Input()
    location_group: Partial<Task.LocationGroup> = {
        location_group_id: null,
		location_group_users: [],
        locations: [],
        name: '', 
        nfc: false
    };

    is_edit_form = false;

    @Output()
    result = new EventEmitter<Task.LocationGroup>();

    constructor() {
        language.load_translations(locations_translations);
        language.load_translations(location_groups_translations);
    }

    async search_locations(search:string) {
        let res = await TaskRoutes.locations.api_location_search_responsible(
            { search: [search], attributes: ["cin+ +name"] },
            { page_no: 1, page_size: 10 }
        )
        return res.data;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
    }

    ngOnInit() {
        this.is_edit_form = this.location_group.location_group_id ? true : false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["location_group"]) {
            this.location_group = {...changes["location_group"].currentValue};
        }
        this.is_edit_form = !!this.location_group.location_group_id;

    }

    done() {
        this.result.emit(this.location_group as Task.LocationGroup);
    }

    cancel() {
        this.result.emit(null);
    }
}
