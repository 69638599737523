<div class="flex-column fullheight-noscroll">
	<h2 class="modal-title">
		{{ "ORDER.CHANGE_DIALOG.TITLE" | trans : order.title }}
	</h2>

	<div style="overflow: auto">
		<div class="field-grid" form-collector #orderForm="collector">
			<label class="input outlined" title="{{ 'ORDER.TITLE' | trans }}">
				<i icon="ballot"></i>
				<input [(ngModel)]="order.title" placeholder="{{ 'ORDER.TITLE' | trans }}"
				conditions="nonempty" />
			</label>

			<task-whier-picker required
					[(ngModel)]="order.whier"
					[requiredLevel]="3"
					[maxLevel]="3">
			</task-whier-picker>

			<label class="input outlined" title="{{ 'ORDER.INVESTMENT_TYPE' | trans }}">
				<select [(ngModel)]="order.investment_type" required>
					<option *ngFor="let type of investment_types | keyvalue" [ngValue]="+type.key">
							{{ ("ORDER.INVESTMENT_TYPE." + type.value) | trans }}
					</option>
				</select>
			</label>

			<div class="flex-row align-center wrappable" style="margin-top: 1em;">
				<label class="checkbox-input">
					<input type="checkbox"
							[(ngModel)]="order.insurance" />
					{{"ORDER.INSURANCE" | trans }}
				</label>
				<label class="checkbox-input">
					<input type="checkbox"
							[(ngModel)]="order.lease" />
						{{"ORDER.LEASE" | trans }}
				</label>
				<label class="checkbox-input">
					<input type="checkbox"
							[(ngModel)]="order.preinvoice" />
						{{"ORDER.PREINVOICE" | trans }}
				</label>

				<label class="checkbox-input">
					<input type="checkbox"
							[(ngModel)]="order.complaint" />
						{{"ORDER.RECLAMATION" | trans }}
				</label>

				<label class="checkbox-input">
					<input type="checkbox"
							[(ngModel)]="order.inspection" />
					{{"ORDER.INSPECTION" | trans }}
				</label>
			</div>

		</div>

	</div>

	<div class="flex-row modal-footer">

		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" (click)="done()" [disabled]="orderForm.containsErrors()">
			<i icon="check"></i>
			{{ 'SAVE' | trans }}
		</button>

	</div>
</div>
