import { language } from 'curvy';

export const english: language.TranslationDefinition = {
	locale: 'en-us',
	translations: {
		"ARRIVAL.ORDER": "Order",
		"ARRIVAL.ORDER.DETAILS": "Order details",
		"ARRIVAL.RELATED.TICKETS": "Related tickets",
		"ARRIVAL.RELATED.ORDER": "Related order",

		"ARRIVAL.TITLE": "Arrival",
		"ARRIVAL.WORKERS": "Workers",
		"ARRIVAL.TIME": "Time",
		"ARRIVAL.PLAN.TITLE": "Plan arrival",
		"ARRIVAL.PLAN.DATE": "Arrival date",
		"ARRIVAL.PLAN.DATE2": "Arrival date",
		"ARRIVAL.PLAN.END_DATE2": "Departure date",
		"ARRIVAL.PLAN.END_DATE": "Departure",
		"ARRIVAL.PLAN.TIME": "Arrival time",
		"ARRIVAL.PLAN.END_TIME": "Departure time",
		"ARRIVAL.PLAN.WORKERS": "Number of workers",
		"ARRIVAL.PLAN.WORKER.NEW": "Add worker",
		"ARRIVAL.COMPANY": 'Company',


		"ARRIVAL.PLAN.WORKER_FIRST_NAME": "First name",
		"ARRIVAL.PLAN.WORKER_LAST_NAME": "Last name",
		"ARRIVAL.PLAN.WORKER_ID": "Identification number",
		"ARRIVAL.PLAN.WORKER_NO": (no) => `${no}. Worker`,
		"ARRIVAL.PLAN.WORKER_SEARCH": "Search for a worker",

		"ARRIVAL.QR.CONFIRM": "Arrival confirmation",
		"ARRIVAL.QR.SAVE": "Save and\ngenerate QR code",
		"ARRIVAL.START": "Start work",
		"ARRIVAL.END": "Completion of work",
		"ARRIVAL.COMPLETE": "Complete arrival",
		"ARRIVAL.COMPLETION": "Complete arrival manually",
		"ARRIVAL.CREATED_BY": "Arrival created by",
		"ARRIVAL.ENDED_BY": "Arrival closed by",
		"ARRIVAL.DETAILS": "Arrival details",
		"ARRIVAL.DETAILS.CREATED_BY": "Financial item created by",
		"ARRIVAL.DETAILS.QUANTITY": "Quantity",
		"ARRIVAL.DETAILS.PRICE": "Price",
		"ARRIVAL.DETAILS.LUMP": "Lump sum",
		"ARRIVAL.DETAILS.REASON": "Reason",
		"ARRIVAL.DETAIL.WORK_DESCRIPTION": "Work description",
		"ARRIVAL.DETAIL.WORK_COMMENTARY": "Comment",
		"ARRIVAL.DETAIL.WORK_ELEMENT": "Price list item",
		"ARRIVAL.DETAIL.WORK_UNFINISHED_REASON": "Unfinished reason",
		"ARRIVAL.DETAIL.DOCUMENT": "Files",
		"ARRIVAL.DETAIL.ELEMENT_PRICE": "Unit price",
		"ARRIVAL.DETAIL.ELEMENT_TOTAL": "Price",
		"ARRIVAL.DETAIL.ELEMENT_QUANTITY": "Quantity",
		"ARRIVAL.DETAIL.DELETE": "Delete",
		"ARRIVAL.DETAIL.UNDELETE": "Undo",
		"ARRIVAL.DETAIL.TYPE": "Detail type",
		"ARRIVAL.DETAIL.SELECT_TYPE": "Select detail type...",
		"ARRIVAL.DETAIL.ADD": "Add detail",
		"ARRIVAL.DETAIL.NONPRICELIST_ELEMENT": "Nonpricelist element",
		"ARRIVAL.DETAIL.PRICELIST_ELEMENT": "Pricelist element",
		"ARRIVAL.DETAIL.ELEMENT_NAME": "Element name",
		"ARRIVAL.DETAIL.NO_FIN_DETAILS" : "There are no financial details",


		"ARRIVAL.DETAIL.UOM": "Unit of measure",
		"ARRIVAL.DELETE.TITLE": "Delete arrival",
		"ARRIVAL.DELETE.MSG": (arrival_id) => `Are you sure you want to delete arrival #${arrival_id}?`,
		"ARRIVAL.DELETE.ENTER_REASON": "Enter the reason for deleting the arrival",
		"ARRIVAL.DELETE.REASON": "Reason",

		"ARRIVAL.DETAIL.TITLE": "Title",
		"ARRIVAL.DETAIL.DETAIL": "Detail",
		"ARRIVAL.DETAIL.GROUP" : "Group",

		"ARRIVAL.DETAILS.HISTORY_TITLE": (name) => `History of changes for ${name}`,
		"ARRIVAL.DETAILS.HISTORY": "History of changes",
		"ARRIVAL.DETAILS.HISTORY_NO_CHANGES": "Item has no changes",
		"ARRIVAL.DETAILS.EMERGENCY_TITLE": "Emergency intervention",
		"ARRIVAL.DETAILS.EMERGENCY_TEXT": "Dozvoljeno je korištenje isključivo za slučaj već izvršene izvanredne ili hitne intervencije.",
		"ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE": "Manually closed",


		"ARRIVAL.DETAILS.CONFIRM.MSG": "I confirm the presence and personal information of the service technician",
		"ARRIVAL.DETAIL.REASON" : "REASON IS MANDATORY",
		"ARRIVAL.INVENTORY": "Inventory",
		"ARRIVAL.IMAGES": "Images",
		'ARRIVAL.DOCUMENTS': "Documents",
		'ARRIVAL.COST.SHEET': 'Cost sheet'

    }
};

export const croatian: language.TranslationDefinition = {
	locale: 'hr-hr',
	translations: {
		"ARRIVAL.ORDER": "Nalog",
		"ARRIVAL.ORDER.DETAILS": "Detalji naloga",
		"ARRIVAL.RELATED.TICKETS": "Povezane prijave",
		"ARRIVAL.RELATED.ORDER": "Povezani nalog",

		"ARRIVAL.TITLE": "Dolazak",
		"ARRIVAL.WORKERS": "Radnici",
		"ARRIVAL.TIME": "Vrijeme",
		"ARRIVAL.PLAN.TITLE": "Plan dolaska",
		"ARRIVAL.PLAN.DATE": "Dolazak",
		"ARRIVAL.PLAN.END_DATE": "Odlazak",
		"ARRIVAL.PLAN.DATE2": "Datum dolaska",
		"ARRIVAL.PLAN.END_DATE2": "Datum odlaska",
		"ARRIVAL.PLAN.TIME": "Vrijeme dolaska",
		"ARRIVAL.PLAN.END_TIME": "Vrijeme odlaska",
		"ARRIVAL.PLAN.WORKERS": "Broj radnika",
		"ARRIVAL.COMPANY": 'Tvrtka',


		"ARRIVAL.PLAN.WORKER.NEW": "Dodaj novog radnika",
		"ARRIVAL.PLAN.WORKER_FIRST_NAME": "Ime",
		"ARRIVAL.PLAN.WORKER_LAST_NAME": "Prezime",
		"ARRIVAL.PLAN.WORKER_ID": "OIB",
		"ARRIVAL.PLAN.WORKER_NO": (no) => `${no}. Radnik`,
		"ARRIVAL.PLAN.WORKER_SEARCH": "Pretraga radnika",

		"ARRIVAL.QR.CONFIRM": "Potvrda dolaska",
		"ARRIVAL.QR.SAVE": "Spremi i\ngeneriraj QR kod",
		"ARRIVAL.START": "Početak radova",
		"ARRIVAL.END": "Završetak radova",
		"ARRIVAL.COMPLETE": "Završi dolazak",
		"ARRIVAL.COMPLETION": "Završi dolazak ručno",
		"ARRIVAL.CREATED_BY": "Dolazak kreirao",
		"ARRIVAL.ENDED_BY": "Dolazak ručno zatvorio",
		"ARRIVAL.DETAILS": "Detalji dolaska",
		"ARRIVAL.DETAILS.CREATED_BY": "Financijski detalj kreirao",
		"ARRIVAL.DETAILS.QUANTITY": "Količina",
		"ARRIVAL.DETAILS.PRICE": "Cijena",
		"ARRIVAL.DETAILS.LUMP": "Paušalno",
		"ARRIVAL.DETAILS.REASON": "Razlog",
		"ARRIVAL.DETAIL.WORK_DESCRIPTION": "Opis rada",
		"ARRIVAL.DETAIL.WORK_COMMENTARY": "Komentar",
		"ARRIVAL.DETAIL.WORK_ELEMENT": "Dio cjenika",
		"ARRIVAL.DETAIL.WORK_UNFINISHED_REASON": "Razlog nedovršenog rada",
		"ARRIVAL.DETAIL.DOCUMENT": "Dokumenti",
		"ARRIVAL.DETAIL.ELEMENT_PRICE": "Bazna cijena",
		"ARRIVAL.DETAIL.ELEMENT_TOTAL": "Cijena",
		"ARRIVAL.DETAIL.ELEMENT_QUANTITY": "Količina",
		"ARRIVAL.DETAIL.DELETE": "Obriši",
		"ARRIVAL.DETAIL.UNDELETE": "Vrati",
		"ARRIVAL.DETAIL.TYPE": "Tip detalja",
		"ARRIVAL.DETAIL.SELECT_TYPE": "Odaberi tip detalja...",
		"ARRIVAL.DETAIL.ADD": "Dodaj detalj",
		"ARRIVAL.DETAIL.NONPRICELIST_ELEMENT": "Vantroškovnička stavka",
		"ARRIVAL.DETAIL.PRICELIST_ELEMENT": "Troškovnička stavka",
		"ARRIVAL.DETAIL.ELEMENT_NAME": "Naziv stavke",
		"ARRIVAL.DETAIL.NO_FIN_DETAILS" : "Nema financijskih stavki",


		"ARRIVAL.DELETE.TITLE": "Brisanje dolaska",
		"ARRIVAL.DELETE.MSG": (arrival_id) => `Da li ste sigurni da želite obrisati dolazak #${arrival_id}?`,
		"ARRIVAL.DELETE.ENTER_REASON": "Unesite razlog brisanja dolaska",
		"ARRIVAL.DELETE.REASON": "Razlog",

		"ARRIVAL.DETAIL.UOM": "Jedinica mjere",
		"ARRIVAL.DETAIL.GROUP" : "Grupa radova",

		"ARRIVAL.DETAIL.TITLE": "Naziv",
		"ARRIVAL.DETAIL.DETAIL": "Detalj",

		"ARRIVAL.DETAILS.HISTORY_TITLE": (name) => `Povijest izmjena za ${name}`,
		"ARRIVAL.DETAILS.HISTORY": "Povijest izmjena",
		"ARRIVAL.DETAILS.HISTORY_NO_CHANGES": "Stavka nema izmjena",
		"ARRIVAL.DETAILS.EMERGENCY_TITLE": "Izvanredna intervencija",
		"ARRIVAL.DETAILS.EMERGENCY_TEXT": "Dozvoljeno je korištenje isključivo za slučaj već izvršene izvanredne ili hitne intervencije.",
		"ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE": "Ručno zatvoren",

		"ARRIVAL.DETAILS.CONFIRM.MSG": "Potvrđujem prisutnost i osobne podatke servisera",
		"ARRIVAL.DETAIL.REASON" : "OBAVEZNO NAVESTI RAZLOG",
		"ARRIVAL.INVENTORY": "Inventar",
		"ARRIVAL.IMAGES": "Slike",
		'ARRIVAL.DOCUMENTS': "Dokumenti",
		'ARRIVAL.COST.SHEET': 'Troškovnik'

	}

};

export const arrivals_translations = [english, croatian];
