import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
    }
};

export const comments_translations = [english, croatian];
