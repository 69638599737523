import { Directive, ElementRef, Input } from '@angular/core';
import { modal, noOp } from 'curvy';
import { get_global } from '@task-utils/utils';

@Directive({
	selector: 'img[clickable]'
})
export class ImageDirective {
	@Input()
	imgs: object[];

	@Input()
	imgIndex: number;

	constructor(public ref: ElementRef<HTMLImageElement>) {
		let img = ref.nativeElement;
		const ImageDialog = get_global("ImageDialog");
		img.addEventListener("click", () => {
			if (img.complete && img.naturalHeight !== 0) {
				modal.open(ImageDialog, noOp, { imgs: this.imgs, index: this.imgIndex });
			}
		});
	}
}
