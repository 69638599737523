<h2 class="modal-title">{{ (is_edit_form ? 'SECTOR.EDIT' : 'SECTOR.NEW') | trans }}</h2>

<div class="field-grid" form-collector #sectorsForm="collector">

    <!-- SECTOR NAME -->
    <label class="input outlined" [title]="'SECTOR' | trans" style="grid-column: 1 / -1">
        <input [(ngModel)]="sector.name" conditions="nonempty" />
    </label>

    <!-- CATEGORY POOL -->
    <div *ngIf="whiers_pool.length > 0" class="whiers-container">
        <div *ngFor="let iw of whiers_pool; let i = index" class="whiers-item">
            <button (click)="removeWhier(i)" color="warn" class="round"><i icon="clear"></i></button>
            <div class="w-title"><span class="name">{{ whiers_pool[i].name }}</span> {{ whiers_pool[i].description }}
            </div>
        </div>
    </div>

    <!-- CATEGORY DROPDOWN -->
    <label class="input outlined" style="grid-column: 1 / -1" [title]="'CATEGORY' | trans">
        <i icon="account_tree"></i>
        <select [(ngModel)]="temp_selected_index" (change)="insertWhier()">
            <option value="-1">{{ 'CATEGORY' | trans }}</option>
            <ng-container *ngFor="let wh of levels[0]; let i = index">
                <option *ngIf="isVisibleOption(wh)" [value]="i">
                    {{ wh.name }} {{ wh.description }}
                </option>
            </ng-container>
        </select>
    </label>
    <!-- <label class="input outlined" style="grid-column: 1 / -1" [title]="'SUBCATEGORY' | trans">
      <select (change)="changeLevelValue(1)" [(ngModel)]="temp_selected_index[1]">
          <option value="-1"></option>
          <option *ngFor="let wh of levels[1]; let i = index" [value]="i">
              {{ wh.name }} {{ wh.description }}
          </option>
      </select>
    </label>
    <label class="input outlined" style="grid-column: 1 / -1" [title]="'GROUP' | trans">
      <select (change)="changeLevelValue(2)" [(ngModel)]="temp_selected_index[2]">
          <option value="-1"></option>
          <option *ngFor="let wh of levels[2]; let i = index" [value]="i">
              {{ wh.name }} {{ wh.description }}
          </option>
      </select>
    </label>

	<button (click)="insertWhier()" [disabled]="is_insert_whier">
		{{ 'SAVE' | trans }}
	</button> -->
</div>
<div class="flex-row modal-footer">

    <button color="primary" (click)="cancel()">
        <i icon="clear"></i>
        {{ 'CANCEL' | trans }}
    </button>

    <!-- <div class="flex-dynamic"></div> -->

    <button class="filled" color="secondary" (click)="done()" [disabled]="sectorsForm.containsErrors()">
        <i icon="check"></i>
        {{ 'SAVE' | trans }}
    </button>

</div>