import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { Task } from '@task-utils/types';
import { DataTableComponent, Frame, GenericFilter, GenericFilterComponent, language, TableConfig, template, modal, ConfirmDialogComponent, color, noOp, popover } from 'curvy';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { checklists_translations } from './checklists.trans';
import { orders_translations } from '../orders/orders.trans';
import { CreateEditQuestiontDialog } from "./create-edit-question.dialog";
import { ChecklistsQuestionInfo } from "./checklist-question-info.component";

@Component({
   selector: 'task-checklists',
   template: `
       <div class="temp-add-button">
            <button (click)="add_question()"
                class="filled pill"
                color="primary">
                <i icon="add_circle"></i>
                <span>{{ 'CHECKLISTS.ADD_NEW_Q' | trans }}</span>
            </button>
        </div>
      <knw-data-table #checkliststable [config]="tableConfig"></knw-data-table>
   `,
   encapsulation: ViewEncapsulation.None,
   styles: [`
      task-checklists {
         display: flex;
         flex-flow: column nowrap;
         overflow: hidden !important;
      }
   `]
})

export class ChecklistsComponent {
   @ViewChild('checkliststable') table: DataTableComponent<Task.CheckListQuestion, Record<string, any>, 'checklists', GenericFilterComponent>;

   annual_frequency = {...Task.Annual_Frequency};

   constructor() {
      Frame.set({
         title: "NAVROUTE.CHECKLISTS",
         visible: true,
         layout: "middle",
         size: "full"
      });

      language.load_translations(checklists_translations);
      language.load_translations(orders_translations);

   }

   add_question() {
      modal.open(CreateEditQuestiontDialog, (res)=>{
         if (res) {
            this.table.getData();
         }
      })
   }

   async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

   tableConfig: TableConfig<Task.CheckListQuestion, Record<string, any>, 'checklists', GenericFilterComponent> = {
      tableName: 'checklists',

      getData: async (filter, paginator, sort_header) => {
         let parsed_filter = { search: [], attributes: [], sort: null };

         for (let key in filter) {
            if (key === 'company' && filter.company_id !== null) {

               parsed_filter.attributes.push('#=:company_id');
               parsed_filter.search.push(filter[key].company_id);

            } else {
               parsed_filter.attributes.push(key);
               parsed_filter.search.push(filter[key]);
            }
         }


         if (sort_header) {

            parsed_filter.sort = sort_header;
         }

         return await TaskRoutes.checklists.api_checklist_questions_search(parsed_filter, paginator);
      },

      filterComponent: GenericFilterComponent,
      filterInput: async()=> {
         let filters: GenericFilterComponent["filterInput"] = [
            GenericFilter.text("CHECKLISTS.Q_NAME", "name+ +description"),
            GenericFilter.dropdown("ORDER.SHEDULED.YEARLY_PLAN", "#=:frequency", [
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.YEARLY", value: Task.ANNUAL_FREQUENCY.YEARLY },
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.HALF_YEARLY", value: Task.ANNUAL_FREQUENCY.HALF_YEARLY },
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.QUARTERLY", value: Task.ANNUAL_FREQUENCY.QUARTERLY },
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.MONTHLY", value: Task.ANNUAL_FREQUENCY.MONTHLY },
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.WEEKLY", value: Task.ANNUAL_FREQUENCY.WEEKLY },
               { label: "ORDER.SCHEDULED.YEARLY_PLAN.DAILY", value: Task.ANNUAL_FREQUENCY.DAILY },

            ]),
         ]

         if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
            filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));
        }

         return filters;
      },

      getHeader: (header) => {
         switch (header) {
            case 'name':
               return { icon: "", label: "CHECKLISTS.Q_NAME", sortable: true };

            case 'description':
               return { icon: "", label: "CHECKLISTS.Q_DESCRIPTION", sortable: false };

            case 'locations':
               return { icon: "place", label: "CHECKLISTS.Q_LOCATIONS_NUBMER", sortable: false };

            case 'location_groups':
               return { icon: "holiday_village", label: "CHECKLISTS.Q_LOCGROUP_NUMBER", sortable: false };

            case 'whier':
               return { icon: "account_tree", label: "NAVROUTE.WORKHIER", sortable: false };

            case 'frequency':
               return { icon: "", label: "CHECKLISTS.Q_FREQUENCY", sortable: false };

         }

         return { icon: "place", label: header, sortable: false}
      },

      unwrapData: (question) => {
         let data = {
            name: question.name,
            description: question.description,
            locations: question.locations.length,
            location_groups: question.location_groups.length,
            category: question.whier.category_description,
            subcategory: question.whier.subcategory_description,
            whier_description: question.whier.description,
            annual_plan: language.translate('ORDER.SCHEDULED.YEARLY_PLAN.' + this.annual_frequency[question.frequency])
         };

         let name_template = `
            <div>{{ name }}</div>
         `;

         let description_template = `
            <div class="truncate-text">{{ description }}</div>
         `;

         let locations_template = `
            <div>{{ locations }}</div>
         `;

         let location_groups_template = `
            <div>{{ location_groups }}</div>
         `;

         let whier_template = `
            <div> <i>{{ category }}</i></div>
         `;

         let frequency_template = `
            <div>{{ annual_plan }}</div>
         `;

         return {
            name: template.transform(name_template, data),
            description: template.transform(description_template, data),
            locations: template.transform(locations_template, data),
            location_groups: template.transform(location_groups_template, data),
            whier: template.transform(whier_template, data),
            frequency: template.transform(frequency_template, data)
         }

      },

      rowActions: [
          {
            icon: "edit",
            color: "primary",
            label: language.translate("EDIT"),
            onClick: (question)=>{
               modal.open(CreateEditQuestiontDialog, (res)=>{
                  if (res) {
                     this.table.getData();
                  }
               }, question);
            },
            priority: 1,
            isVisible: true
         }, {
            icon: "delete",
            color: "warn",
            label: language.translate("DELETE"),
            onClick: (question)=>{
               modal.open(ConfirmDialogComponent, async(succ)=>{
                  if (succ) {
                     await TaskRoutes.checklists.api_checklist_question_delete(question.checklist_question_id);
                     this.table.getData();
                  }
               }, {
                  title: language.translate("CHECKLISTS.DELETE.TITLE"),
                  message: language.translate("CHECKLISTS.DELETE.MESSAGE", question.name),
                  icon: "delete",
                  iconColor: color.variable(color.Variable.warn)

               });
            },
            priority: 2,
            isVisible: true
         },
         {
            label: "Info",
            icon: "info",
            onClick: async (question)=>{
               popover.open(ChecklistsQuestionInfo, noOp, question, noOp, "mouse", "auto", "info-popover-wrapper");
            },
            priority: 3
         }
      ]


   }
}
