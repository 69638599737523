import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { User } from '@task-modules/users';
import { Task } from '@task-utils/types';
import { Frame, language, date } from 'curvy';
import { arrivals_translations } from './arrivals.trans';

type Out_Type = Pick<Task.Arrival, 'order_id' | 'planned_start_time' | 'arrival_workers' | 'start_time' | 'end_time'>;

@Component({
    selector: 'task-plan-arrival',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './plan-arrival.component.html',
    styleUrls: ['./plan-arrival.component.scss']
})
export class PlanArrivalComponent implements OnChanges {
    constructor() {
        language.load_translations(arrivals_translations);
    }

    @Input()
    arrival: Partial<Task.Arrival>;

    workers: Partial<Task.ArrivalWorker>[] = [];
    start_time: Date = null;
    emergency_intervention = false;
    end_time: Date = null;
    today = new Date();

    @Input()
    start_time_picker = true;

    can_edit_time = true;
    can_add_emergency_arrival = false;
    is_confirmed = false; 

    @Output()
    result = new EventEmitter<Out_Type>();

    ngOnChanges(changes: SimpleChanges) {

        if (this.arrival && this.arrival.planned_start_time) {
            this.start_time = new Date(this.arrival.planned_start_time);
            
            this.start_time = date.snap(this.start_time, { minute: 1 });
        }

        if (this.arrival.start_time) {
            this.can_edit_time = false;
        }

        this.workers = (this.arrival.arrival_workers || []).map(w => ({ ...w }));
        this.update_values();
    }
    
    async update_values() {
        this.can_add_emergency_arrival = await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER) && !this.arrival.arrival_id 
    }

    is_disabled(planForm) {
        let filterd_workers = this.workers.filter(w=>w.first_name.trim().length > 0 && w.last_name.trim().length > 0);

        let formIncorrect = planForm.containsErrors() || filterd_workers.length === 0;
        if (this.start_time_picker) return formIncorrect;
        return formIncorrect || !this.is_confirmed;
    }

    handle_start_date() {
        if (!this.emergency_intervention) {
            this.start_time = null;
        } 
    }

    done() {
        if (this.start_time_picker) {

            this.arrival.planned_start_time = this.start_time.toISOString();

            if (this.emergency_intervention) {

                this.arrival.end_time = this.end_time.toISOString();
                this.arrival.start_time = this.arrival.planned_start_time;
                this.arrival.emergency = true;
            }
        }

        let filterd_workers = this.workers.filter(w=>w.first_name.trim().length > 0 && w.last_name.trim().length > 0);

        this.arrival.arrival_workers = filterd_workers as Task.ArrivalWorker[];
        this.result.emit(this.arrival as Out_Type);

    }

    cancel() {
        this.result.emit(null);
    }
}
