
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
	locale: 'en-us',
	translations: {
		"ORDER.NEW": "New Order",
		"ORDER.TITLE": "Order",
		"ORDER.COST_ESTIMATE": "Cost estimate",
		"ORDER.COST_ESTIMATE_EXTERNAL": "External cost estimate",
		"ORDER.INVESTMENT_TYPE": "Investment type",
		"ORDER.INVENTORY": "Inventory",
		"TICKET.RELATED": "Related tickets",
		"ARRIVALS.RELATED": "Related arrivals",
		"ORDER.ERROR.NODE": "Trying to pick a node that can't be picked",
		"ORDER.INVESTMENT_TYPE.EA": "EA - Maintenance",
		"ORDER.INVESTMENT_TYPE.DA": "DI - Investment",
		"ORDER.TOTAL": "Total",
		"ORDER.PLAN": "Plan arrival",
		"ORDER.INVOICE_NO": "Invoice number",
		"ORDER.INVOICE_CHECK": "Order has no invoice",
		"ORDER.ARRIVALS_COUNT": "Number of arrivals",
		"ORDER.COMPANY": 'Company',
		"ORDER.ORDER_ID": "Order id",

		"ORDER.REJECT": "Reject",
		"ORDER.REJECT.TITLE": "Order rejection",
		"ORDER.REJECT.LABEL": "Rejection reason",

		"ORDER.REASSIGN": "Reassign supplier",
		"ORDER.REASSIGN.TITLE": "Reassign supplier",
		"ORDER.REASSIGN.LABEL": "New supplier",

		"ORDER.DELETE": "Delete",
		"ORDER.DELETE.TITLE": (id)=>`Delete order #${id}`,
		"ORDER.DELETE.MSG": "Are you sure that you want to delete this order?",
		"ORDER.DELETE.IN_EXECUTION": "Are you sure that you want to delete order in execution?",
		"ORDER.DELETE.ENTER_REASON": "Enter the reason for deleting the order",
		"ORDER.DELETE.REASON": "Reason",
		"ORDER.DELETED.REASON": "Deletion reason",

		"ARRIVAL_DETAIL_PICK": "Pick an arrival detail",
		"ORDER.FINANCIAL_ITEMS_ASSING_TITLE": "Assinging financial items to inventory elements",
		"SEARCH.INVENTORY": "Search inventory",

		"ORDER.CHANGE_DIALOG.TITLE": (title)=>`Approval of order: ${title}`,
		"ORDER.CODE": "Code",
		"ORDER.PREINVOICE": "Preinvoice",
		"ORDER.LEASE": "Lease",
		"ORDER.INSURANCE": "Insurance",
		"ORDER.RECLAMATION": "Reclamation",
		"ORDER.INSPECTION": "Inspection",
		'ORDER.APPROVED_BY' : "Order approved by",
		'ORDER.APPROVED_DATE': "Date of approval",
		'ORDER.APPROVED_REJECTED_DATE': "Date of approval/rejection",

		'ORDER.SCHEDULED.EDIT': 'Edit scheduled order',
		'ORDER.SCHEDULED.NEW': 'New scheduled order plans',
		'ORDER.SCHEDULED.ADD_NEW': 'Add new yearly plan',
		'ORDER.SCHEDULED_TITLE': 'Title',
		'ORDER.SCHEDULED.PLANNED_DATE_START' : 'Start date',
		'ORDER.SCHEDULED.PLANNED_DATE_END' : 'End date',
		'ORDER.SCHEDULED.CUT_DATE' : 'Final date',
		'ORDER.SHEDULED.YEARLY_PLAN': 'Yealry plan',
		'ORDER.SCHEDULED.YEALRY_PLAN_SELECT': 'Select repetition frequency',
		'ORDER.SCHEDULED.YEARLY_PLAN.DAILY': 'Daily',
		'ORDER.SCHEDULED.YEARLY_PLAN.YEARLY': 'Once a year',
		'ORDER.SCHEDULED.YEARLY_PLAN.HALF_YEARLY': 'Twice a year',
		'ORDER.SCHEDULED.YEARLY_PLAN.QUARTERLY': 'Quarterly',
		'ORDER.SCHEDULED.YEARLY_PLAN.MONTHLY': 'Monthly',
		'ORDER.SCHEDULED.YEARLY_PLAN.WEEKLY': 'Weekly',
		'ORDER.SCHEDULED_DELETE_TITLE': 'Scheduled order shall be deleted',
		'ORDER.SCHEDULED_DELETE_MSG': 'Are you sure?',
		'ORDER.SCHEDULED.LEAVE_TITLE': 'You might loose unsaved data',
		'ORDER.SCHEDULED.LEAVE_MSG': 'Are you sure?',
		'ORDER.SCHEDULED.DATES_TO_CREATE': 'Final dates',
		'ORDER.SCHEDULED_REJECT_TITLE': 'Reject scheduled order',
		'ORDER.SCHEDULED_REJECT_REASON': 'Reason',
		'ORDER.SCHEDULED_APPROVE_TITLE': 'Scheduled order approval',
		'ORDER.SCHEDULED_APPROVE_MSG': 'Do you want to approve this scheduled order?',
		'ORDER.SCHEDULED_APPROVE_SERVISES': 'Do you want to approve this scheduled orders?',
		'ORDER.TIME': 'Time range',
		'ORDER.SCHEDULED.NUMBER_OF_LOC': 'No of locations',
		'ORDER.REJECTED_BY': 'Rejected by',
		'ORDER.SCEHDULED.APPROVED_BY': 'Approved by',
		'ORDER.SCHEDULED.CREATED_BY': 'Created by',
		'ORDER.SCHEDULED_DUPLICATE': 'Duplicate scheduled order plan',
		'ORDER.SCHEDULED_ORDER': 'Scheduled order',
		'ORDER.IS_SHC_ORDER': 'Scheduled orders',
		'ORDER.IS_SCH_ORDER_SINGLE': 'Scheduled order',
		'ORDER.IS_ORDER': 'Other orders',
		'ORDER.SCHEDULED.SELECTED.ROWS': 'Make sure you selected 1 or more rows',
		'ORDER.SCHEDULED.SELECTED.APPROVED': 'Selected rows in table are approved',
		'ORDER.SCHEDULED.APPROVED': 'Data is approved',
		'ORDER.SCHEDULED.SELECTED.REJECTED': 'Selected rows in table are rejected',
		'ORDER.SCHEDULED.REJECT': 'Data is rejected',
		'ORDER.SCHEDULED.DELETE': 'Data is deleted',
		'ORDER.SCHEDULED.SELECTED.DELETED': 'Selected rows in table are deleted',
		'ORDER.SCHEDULED.SELECTED.ORDERS': 'Selected orders',
		'ORDER.SCHEDULED.SELECTED_ROWS': 'Selected rows',

		'ORDER.ORDER_HISTORY': "History report",

		"ORDER.REVERT.ENTER_REASON": "Enter the reason for reverting the order",
		"ORDER.REVERT.REASON": "Revert reason",


		'ORDER.REVERT_REVIEW': "Revert to supplier review",
		'ORDER.REVERT_REVIEW.TITLE': (order_id) => `Revert order #${order_id}`,
		"ORDER.REVERT_REVIEW.MSG": "Are you sure that you want to revert this order to supplier review?",

		'ORDER.REVERT_EXECUTION': "Revert to execution",
		'ORDER.REVERT_EXECUTION.TITLE': (order_id) => `Revert order #${order_id}`,
		"ORDER.REVERT_EXECUTION.MSG": "Are you sure that you want to revert this order to execution?",

		'ORDER.REVERT_EXECUTED': "Remove invoice number",
		'ORDER.REVERT_EXECUTED.TITLE': (order_id) => `Remove invoice number from #${order_id}`,
		"ORDER.REVERT_EXECUTED.MSG": "Are you sure that you want to remove the invoice number from this order?",

		'ORDER.REVERT_ITEMS': "Revert financial items",
		'ORDER.REVERT_ITEMS.TITLE': (order_id) => `Revert financial items for order #${order_id}`,
		"ORDER.REVERT_ITEMS.MSG": "Are you sure that you want to revert these financial items? You will be able to change their quantity and price, as well as delete them.",
		'ORDER.ORDER.DETAILS': 'Order details',
		'ORDER.DESCRIPTION': 'Description',
		'ORDER.ADD.FILE': 'Add file',
		'ORDER.FINANCIAL.DOCUMENTATION': 'Add financial documentation',
		'ORDER.SERVICE.TRACKED.DOCUMENTATION': 'Add service and other documentation',

		'ORDER.ADMIN_DELETE': "Delete order",
		'ORDER.ADMIN_DELETE.TITLE': (order_id) => `Delete order #${order_id}`,
		"ORDER.ADMIN_DELETE.MSG": "Are you sure that you want to delete this order and all connected arrivals and scheduled orders?",

		"ORDER.INTERNAL_APPROVAL.TITLE": "Request order approval",
		"ORDER.INTERNAL_APPROVAL.MSG": "Are you sure you want to request order approval from your manager?",

		"SCHEDULED.ORDERS.DELETE.MSG": (order_id) => `Are you sure you want to delete scheduled order #${order_id}?`,

		"ORDER.ADDITIONAL_EMAIL": "Send order to additional e-mail address",
		"ORDER.CC_EMAIL": "E-mail",
		"ORDER.ADD_EMAIL": "Add e-mail",
		"ORDER.CONFIRM": "Confirm order",
		"ORDER.ARRIVALS_TOTAL_TIME": "Total time"

	}
};

export const croatian: language.TranslationDefinition = {
	locale: 'hr-hr',
	translations: {
		"ORDER.NEW": "Novi nalog",
		"ORDER.TITLE": "Nalog",
		"ORDER.COST_ESTIMATE": "Procjena troška",
		"ORDER.COST_ESTIMATE_EXTERNAL": "Procjena troška eksterne usluge",
		"ORDER.INVESTMENT_TYPE": "Tip ulaganja",
		"ORDER.INVENTORY": "Inventar",
		"TICKET.RELATED": "Povezane prijave",
		"ARRIVALS.RELATED": "Povezani dolasci",
		"ORDER.ERROR.NODE": "Odabirana je hierarhija koje se ne može odabrati",
		"ORDER.INVESTMENT_TYPE.EA": "EA - Troškovi održavanja",
		"ORDER.INVESTMENT_TYPE.DA": "DI - Investicija",
		"ORDER.TOTAL": "Ukupno",
		"ORDER.PLAN": "Planiranje dolaska",
		"ORDER.INVOICE_NO": "Broj računa",
		"ORDER.INVOICE_CHECK": "Po nalogu nije izdana faktura",
		"ORDER.ARRIVALS_COUNT": "Broj dolazaka",
		"ORDER.COMPANY": 'Tvrtka',
		"ORDER.ORDER_ID": "Broj naloga",

		"ORDER.REJECT": "Odbijanje",
		"ORDER.REJECT.TITLE": "Odbijanje naloga",
		"ORDER.REJECT.LABEL": "Razlog odbijanja",

		"ORDER.REASSIGN": "Promjena dobavljača",
		"ORDER.REASSIGN.TITLE": "Promjena dobavljača",
		"ORDER.REASSIGN.LABEL": "Novi dobavljač",

		"ORDER.DELETE": "Brisanje",
		"ORDER.DELETE.TITLE": (id)=>`Brisanje naloga #${id}`,
		"ORDER.DELETE.MSG": "Jeste li sigurni da želite obrisati ovaj nalog?",
		"ORDER.DELETE.IN_EXECUTION": "Jeste li sigurni da želite obrisati nalog u izvršavanju?",
		"ORDER.DELETE.ENTER_REASON": "Unesite razlog brisanja naloga",
		"ORDER.DELETE.REASON": "Razlog",
		"ORDER.DELETED.REASON": "Razlog brisanja",

		"ARRIVAL_DETAIL_PICK": "Odaberite troškovničku stavku",
		"ORDER.FINANCIAL_ITEMS_ASSING_TITLE": "Dodjeljivanje troškovničkih stavki elementima inventara",
		"SEARCH.INVENTORY": "Pretraži prema inventaru",

		"ORDER.CHANGE_DIALOG.TITLE": (title)=>`Povrda datalja naloga: ${title}`,

		"ORDER.CODE": "Šifre",
		"ORDER.PREINVOICE": "Prefakturiranje",
		"ORDER.LEASE": "Najam",
		"ORDER.INSURANCE": "Osiguranje",
		"ORDER.RECLAMATION": "Reklamacija",
		"ORDER.INSPECTION": "Inspekcija",
		'ORDER.APPROVED_BY' : "Nalog ovjerio",
		'ORDER.APPROVED_DATE': "Datum ovjere",
		'ORDER.APPROVED_REJECTED_DATE': "Datum ovjere/odbijanja",

		'ORDER.SCHEDULED.EDIT': 'Uređivanje godišnjeg servisa',
		'ORDER.SCHEDULED.NEW': 'Novi godišnji servisi',
		'ORDER.SCHEDULED.ADD_NEW': 'Dodaj novi servis',
		'ORDER.SCHEDULED_TITLE': 'Naslov',
		'ORDER.SCHEDULED.PLANNED_DATE_START' : 'Datum prvog servisa (od)',
		'ORDER.SCHEDULED.PLANNED_DATE_END' : 'Datum prvog servisa (do)',
		'ORDER.SCHEDULED.CUT_DATE' : 'Zaključni datum',
		'ORDER.SHEDULED.YEARLY_PLAN': 'Godišnji servisni plan',
		'ORDER.SCHEDULED.YEALRY_PLAN_SELECT': 'Odaberite frekvenciju ponavljanja',
		'ORDER.SCHEDULED.YEARLY_PLAN.DAILY': 'Dnevno',
		'ORDER.SCHEDULED.YEARLY_PLAN.YEARLY': 'Jednom godišnje',
		'ORDER.SCHEDULED.YEARLY_PLAN.HALF_YEARLY': 'Dvaput godišnje',
		'ORDER.SCHEDULED.YEARLY_PLAN.QUARTERLY': 'Kvartalno',
		'ORDER.SCHEDULED.YEARLY_PLAN.MONTHLY': 'Jednom mjesečno',
		'ORDER.SCHEDULED.YEARLY_PLAN.WEEKLY': 'Tjedno',
		'ORDER.SCHEDULED_DELETE_TITLE': 'Brisanje servisa',
		'ORDER.SCHEDULED_DELETE_MSG': 'Jeste li sigurni da želite obrisati ovaj plan servisa?',
		'ORDER.SCHEDULED.LEAVE_TITLE': 'Mogli biste izgubiti nespremljene podatke',
		'ORDER.SCHEDULED.LEAVE_MSG': 'Jeste li sigurni?',
		'ORDER.SCHEDULED.DATES_TO_CREATE': 'Datumi servisa',
		'ORDER.SCHEDULED_REJECT_TITLE': 'Odbijanje servisa',
		'ORDER.SCHEDULED_REJECT_REASON': 'Razlog',
		'ORDER.SCHEDULED_APPROVE_TITLE': 'Odobravanje plana servisa',
		'ORDER.SCHEDULED_APPROVE_MSG': 'Želite li odobriti sevis?',
		'ORDER.SCHEDULED_APPROVE_SERVISES': 'Želite li odobriti sevise?',
		'ORDER.TIME': 'Vrijeme planiranja',
		'ORDER.SCHEDULED.NUMBER_OF_LOC': 'Broj lokacija',
		'ORDER.REJECTED_BY': 'Odbio',
		'ORDER.SCEHDULED.APPROVED_BY': 'Odobrio',
		'ORDER.SCHEDULED.CREATED_BY': 'Kreirao',
		'ORDER.SCHEDULED_DUPLICATE': 'Dupliciraj plan servisa',
		'ORDER.SCHEDULED_ORDER': 'Servisi',
		'ORDER.IS_SHC_ORDER': 'Servisni nalozi',
		'ORDER.IS_ORDER': 'Ostali nalozi',
		'ORDER.IS_SCH_ORDER_SINGLE': 'Servisni nalog',
		'ORDER.SCHEDULED.SELECTED.ROWS': 'Odaberite 1 ili više redaka kako bise mogli nastaviti dalje',
		'ORDER.SCHEDULED.SELECTED.APPROVED': 'Odabrani redovi u tablici su odobreni',
		'ORDER.SCHEDULED.APPROVED': 'Podaci su odobreni',
		'ORDER.SCHEDULED.SELECTED.REJECTED': 'Odabrani redovi u tablici su odbijeni',
		'ORDER.SCHEDULED.REJECT': 'Podaci su odbijeni',
		'ORDER.SCHEDULED.DELETE': 'Podaci su obrisani',
		'ORDER.SCHEDULED.SELECTED.DELETED': 'Odabrani redovi u tablici su obrisani',
		'ORDER.SCHEDULED.SELECTED.ORDERS': 'Odabrani nalozi',
		'ORDER.SCHEDULED.SELECTED_ROWS': 'Selektirani redovi',

		'ORDER.ORDER_HISTORY': "Izvještaj događaja",

		"ORDER.REVERT.ENTER_REASON": "Unesite razlog vraćanja naloga",
		"ORDER.REVERT.REASON": "Razlog vraćanja",

		'ORDER.REVERT_REVIEW': "Vrati dobavljaču na prefakturiranje",
		'ORDER.REVERT_REVIEW.TITLE': (order_id) => `Vraćanje naloga #${order_id}`,
		"ORDER.REVERT_REVIEW.MSG": "Jeste li sigurni da želite vratiti ovaj nalog dobavljaču na prefakturiranje?",

		'ORDER.REVERT_EXECUTION': "Vrati u izvršavanje",
		'ORDER.REVERT_EXECUTION.TITLE': (order_id) => `Vraćanje naloga #${order_id}`,
		"ORDER.REVERT_EXECUTION.MSG": "Jeste li sigurni da želite vratiti ovaj nalog u izvršavanje?",

		'ORDER.REVERT_EXECUTED': "Ukloni broj računa",
		'ORDER.REVERT_EXECUTED.TITLE': (order_id) => `Ukloni broj računa za nalog #${order_id}`,
		"ORDER.REVERT_EXECUTED.MSG": "Jeste li sigurni da želite ukloniti broj računa na ovom nalogu?",

		'ORDER.REVERT_ITEMS': "Vrati financijske stavke",
		'ORDER.REVERT_ITEMS.TITLE': (order_id) => `Vraćanje financijskih stavki naloga #${order_id}`,
		"ORDER.REVERT_ITEMS.MSG": "Jeste li sigurni da želite vratiti ove financijske stavke? Nakon što ih vratite, biti će im moguće ponovno promijeniti količinu, cijenu, te ih ukloniti.",
		'ORDER.ORDER.DETAILS': 'Detalji naloga',
		'ORDER.DESCRIPTION': 'Opis',
		'ORDER.ADD.FILE': 'Dodaj dokument',
		'ORDER.FINANCIAL.DOCUMENTATION': 'Dodaj financijsku dokumentaciju',
		'ORDER.SERVICE.TRACKED.DOCUMENTATION': 'Dodaj servisnu i ostalu prateću dokumentaciju',

		'ORDER.ADMIN_DELETE': "Briši nalog",
		'ORDER.ADMIN_DELETE.TITLE': (order_id) => `Brisanje naloga #${order_id}`,
		"ORDER.ADMIN_DELETE.MSG": "Jeste li sigurni da želite obrisati ovaj nalog i sve vezane dolaske i servise?",

		"ORDER.INTERNAL_APPROVAL.TITLE": "Zahtjev za potvrdom naloga",
		"ORDER.INTERNAL_APPROVAL.MSG": "Jeste li sigurni da želite zatražiti potvrdu naloga od voditelja?",

		"SCHEDULED.ORDERS.DELETE.MSG": (order_id) => `Jeste li sigurni da želite obrisati servis #${order_id}?`,

		"ORDER.ADDITIONAL_EMAIL": "Pošalji kopiju obavijesti na dodatne e-mail adrese",
		"ORDER.CC_EMAIL": "E-mail",
		"ORDER.ADD_EMAIL": "Dodaj e-mail",
		"ORDER.CONFIRM": "Potvrdi nalog",
		"ORDER.ARRIVALS_TOTAL_TIME": "Ukupno vrijeme"

	}
};

export const orders_translations = [english, croatian];
