import { Pipe } from "@angular/core";
import { User } from "@task-modules/users";
import { Task } from "@task-utils/types";

@Pipe({
   name: "has_feature",
   pure: false
})
export class FeaturePipe {
   feature_flag_promise: Promise<boolean>|null = null;
   feature_flag_value: boolean|null = null;

   transform(feature_flag: keyof Task.User["feature_flags"]) {
      if (this.feature_flag_promise == null) {
         this.feature_flag_promise = User.is_feature_flag_enabled(feature_flag);
         this.feature_flag_promise.then(res => {
            this.feature_flag_value = res;
         });

         return false;
      }

      if (this.feature_flag_value != null) {
         return this.feature_flag_value;
      }

      return false;
   }
}
