import { Task, Writables } from '@task-utils/types';
import { files } from '@task-utils/utils';

type With_Key<T, X extends string> = T extends Partial<Record<X, any>> ? T : never;
type Event_Detail = Task.ScheduledEvent["event_details"][number];

type SOT_Detail_Base = Partial<With_Key<Event_Detail, "scheduled_orders_template_id">>;
type SO_Detail_Base = Partial<With_Key<Event_Detail, "scheduled_order_id">>;

interface SOT_Detail extends SOT_Detail_Base {
    order_detail_type_id: Task.ORDER_DETAIL_TYPE
};

interface SO_Detail extends SO_Detail_Base {
    order_detail_type_id: Task.ORDER_DETAIL_TYPE
};

export type OrderType = Pick<Task.Order, 'title' | 'supplier_id' | 'location_id' | 'preinvoice' | 'lease' | 'insurance'> & {
	cost_estimate: string;
	cc_emails: string[];
	investment_type: number;
	order_details: Writables<Partial<Task.OrderDetail>>[];
	ticket_ids: Pick<Task.Ticket, 'title' | 'ticket_id' | 'location_id' | 'location_name'>[];
	whier_id: number;
	iimages: files.IImage[];
	ifinancial_files: (files.IFile | Task.DMSFile)[];
	iservice_files: (files.IFile | Task.DMSFile)[];
	complaint: boolean;
	inspection: boolean;
	arrival_worker: Task.User;
};

export function toScheduledOrderTemplate(e: Task.ScheduledEvent) {
    if (e.type !== "TEMPLATE") { throw Error("Wrong ScheduledEvent type!"); }

    let event_id = e.event_id ? { scheduled_orders_template_id: e.event_id } : {};

    return {
        ...e, ...event_id,
        scheduled_orders_template_details: e.event_details as SOT_Detail[],
        whier_id: e.whier.whier_id,
        arrival_worker_id: e.arrival_worker?.user_id ?? null
    };
}

export type ScheduledOrderTemplateType = ReturnType<typeof toScheduledOrderTemplate>;

export function toScheduledOrder(e: Task.ScheduledEvent) {
    if (e.type !== "SCHEDULED_ORDER") { throw Error("Wrong ScheduledEvent type!"); }

    let event_id = e.event_id ? { scheduled_order_id: e.event_id } : {};

    return {
        ...e, ...event_id,
        location_id: e.locations[0].location_id,
        location: e.locations[0],
        scheduled_order_details: e.event_details as SO_Detail[],
        cost_estimate: NaN,
        whier_id: e.whier.whier_id,
        planned_end_date: e.planned_end_date,
        planned_start_date: e.planned_start_date,
        scheduled_order_id: e.event_id,
        arrival_worker_id: e.arrival_worker?.user_id ?? null
    }
}

export type ScheduledOrderType = ReturnType<typeof toScheduledOrder>
