import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Curvy } from 'curvy';
import { register_global } from '@task-utils/utils';
import { TaskDirectivesModule } from '../directives/task-directives.module';
import { TextBoxDialog } from './textbox-dialog.component';
import { InputDialog } from './input-dialog.component';
import { SupplierPickerDialog } from './supplier-picker-dialog.component';
import { PickerDialog } from './picker-dialog.component';
import { ConfirmDialog } from './confirm-dialog.component';
import { BackButton } from './back-button.component';
import { GetImage } from './get-image.component';
import { ResponsiveContainer } from './responsive-container.component';
import { PriceComponent } from './price.component';
import { WhierPickerComponent } from './whier-picker.component';
import { InventoryPickerComponent } from './inventory-picker.component';
import { InventoryCardsComponent } from './inventory-cards.component';
import { WorkersComponent } from './task-workers.component';
import { MultiImageSelect } from './multi-image-select.component';
import { MultiImageSelectNew } from './multi-image-select-new.component';
import { ImageDialog } from './image-dialog.component';
import { ClickToggle } from './click-toggle.component';
import { KAMInventoryComponent } from './kam-inventory.component';
import { AccordionListComponent } from './task-accordion-list.comopnent';
import { MultiFileSelect } from './upload-files.component';
import { MultiFileSelectNew } from './multi-files-select.component';
import { FileComponent } from './file.component';
import { FileNewComponent } from './file-new.component';
import { ScheduledDates } from './scheduled-dates';
import { UsersPool } from './task-users-pool.component';
import { SearchLocationsByGroup } from './search-locations-by-group';
import { EntityInfoComponent } from './entity-info.component';

let components = [
	TextBoxDialog,
	InputDialog,
	SupplierPickerDialog,
	PickerDialog,
	ConfirmDialog,
	BackButton,
	GetImage,
	ResponsiveContainer,
	PriceComponent,
	WhierPickerComponent,
	InventoryPickerComponent,
	InventoryCardsComponent,
	WorkersComponent,
	MultiImageSelect,
	MultiImageSelectNew,
	ImageDialog,
	ClickToggle,
	KAMInventoryComponent,
	AccordionListComponent,
	MultiFileSelect,
	MultiFileSelectNew,
	FileComponent,
	FileNewComponent,
	ScheduledDates,
	UsersPool,
	SearchLocationsByGroup, 
	EntityInfoComponent
];

register_global("ImageDialog", ImageDialog);

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		Curvy,
		TaskDirectivesModule
	],
	declarations: components,
	exports: components
})
export class TaskComponentsModule {}
