
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "LOGIN.CREDENTIALS_TITLE": "Authentication failed",
        "LOGIN.CREDENTIALS_TEXT": "The user credentials you entered are incorrect. Please try again.",
        "LOGIN.CONNECTION_TITLE": "Connection error",
        "LOGIN.CONNECTION_TEXT": "Could not reach server. Please make sure that you are connected to the internet.",
        "LOGIN.ERROR_TITLE": "Authentication error",
        "LOGIN.ERROR_TEXT": "An error occured while trying to authenticate. Please notify your system administrator.",
        "LOGIN.LOGIN": "Login",
        "LOGIN.FORGOTTEN_PASS": "Forgotten password",
        "LOGIN.BACK_TO_LOGIN": "Back to login",
        "LOGIN.PASS_RESET_DESCR": "Enter e-mail address on which to send reqest for password reset.",
        "LOGIN.PASS_RESET.SUCC_TITLE": "Request has been sent",
        "LOGIN.PASS_RESET.SUCC_TEXT": "If the e-mail address is in the system, the requested link will be sent. Please, check the inbox.",
        "LOGIN.SEND_NEW_PASS": "Send request",
        "LOGIN.LOG_IN": "Login",
        "LOGIN.PASS_RESET": "Password reset request"
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "LOGIN.CREDENTIALS_TITLE": "Neuspješna autentikacija",
        "LOGIN.CREDENTIALS_TEXT": "Podaci o korisniku su neispravni. Molimo, pokušajte ponovono.",
        "LOGIN.CONNECTION_TITLE": "Greška u konekciji",
        "LOGIN.CONNECTION_TEXT": "Veza do servera je onemogućena. Molimo, provjerite svoju internet vezu.",
        "LOGIN.ERROR_TITLE": "Greška kod autentikacije",
        "LOGIN.ERROR_TEXT": "Došlo je do greške prilikom autentikacije. Molimo, pokušajte ponovno ili se obratite administratoru.",
        "LOGIN.LOGIN": "Prijavi se",
        "LOGIN.FORGOTTEN_PASS": "Zaboravljena lozinka",
        "LOGIN.BACK_TO_LOGIN": "Nazad na prijavu",
        "LOGIN.PASS_RESET_DESCR": "Unesite adresu elektroničke pošte na koju će se poslati zahtjev za kreiranje nove lozinke.",
        "LOGIN.PASS_RESET.SUCC_TITLE": "Zahtjev uspješno poslan",
        "LOGIN.PASS_RESET.SUCC_TEXT": "Ukoliko adresa elektroničke pošte postoji u sustavu, na istu će stići upute s linkom za izmjenu lozinke. Provjerite sandučić elektroničke pošte.",
        "LOGIN.SEND_NEW_PASS": "Pošalji zahtjev",
        "LOGIN.LOG_IN": "Prijava",
        "LOGIN.PASS_RESET": "Zahtjev za izmjenu lozinke"

    }
};

export const login_translations = [english, croatian];
