import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';

type InType = {
	title: string;
	label: string;
	required?: boolean;
	check_required?: boolean;
};

@Component({
	selector: 'task-input-dialog',
	template: `
		<h2 class="modal-title">{{ injectedData.title | trans }}</h2>
		<div class="flex-column" form-collector #iForm="collector">
			<label class="input outlined flex-dynamic" [title]="injectedData.label | trans">
				<input [(ngModel)]="text" [required]="injectedData.required || !check" [disabled]="check"/>
			</label>

			<label class="checkbox-input pointer" *ngIf="injectedData.check_required">
				<input type="checkbox" [(ngModel)]="check" (ngModelChange)="check ? text='' : text">
				<span style="margin-left:.3em;">{{ "ORDER.INVOICE_CHECK" | trans }}</span>
			</label>
		</div>

		<div class="flex-row modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				<span>{{ 'CANCEL' | trans }}</span>
			</button>

			<div class="flex-dynamic"></div>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="iForm.containsErrors()">
				<i icon="check"></i>
				<span>{{ 'SAVE' | trans }}</span>
			</button>
		</div>
	`
})
export class InputDialog extends ModalBase<InType, string> {
	preventSoftClose = true;
	text:string;
	check = false;

	done() {
		this.submit(this.text);
	}

	cancel() {
		this.submit(null);
	}
}
