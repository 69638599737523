import {
	Component, ViewEncapsulation, OnInit, OnChanges, ChangeDetectorRef,
	Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { language } from 'curvy';
import { Task } from '@task-utils/types';
import { orders_translations } from './orders.trans';
import { User } from '@task-modules/users';

@Component({
	selector: 'task-change-order',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'change-order.component.html',
	styles: [`
		task-change-order {
			max-width: 600px;
		}

		task-change-order .field-grid {
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr;
			grid-gap: 0 8px;
		}

		task-change-order knw-search-picker {
			display: contents;
		}

		@media only screen and (max-width: 600px) {
			task-change-order .field-grid {
				grid-template-columns: 1fr;
			}
		}
	`]
})
export class ChangeOrderComponent implements OnInit {
	@Input()
	order: Task.Order;

	@Output('result')
	result: EventEmitter<Task.Order> = new EventEmitter();

	investment_types = Task.Investment_Type;


	ngOnInit() {
		this.init();
	}
	
	async init() {
		language.load_translations(orders_translations);

	}

	done() {
		this.order["whier_id"] = this.order.whier.whier_id;
		this.result.emit(this.order);
	}

	cancel() {
		this.result.emit(null);
	}
}
