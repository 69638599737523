<div class="flex-column fullheight-noscroll plan-arrival-modal" form-collector #planForm="collector">
	<h2 class="modal-title">
		{{ (start_time_picker ? 'ARRIVAL.PLAN.TITLE' : 'ARRIVAL.QR.CONFIRM') | trans }}
	</h2>

	<p *ngIf="start_time_picker" style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span
			style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>

	<div class="flex-row">

		<knw-date-picker-input class="flex-dynamic input outlined" 
			[classes]="'flex-dynamic input outlined'"
			[required]="true" [label]="'ARRIVAL.PLAN.DATE2' | trans" 
			[disabled]="!can_edit_time || !start_time_picker"
			[min-date]="emergency_intervention && can_add_emergency_arrival ? null : today"
			[(ngModel)]="start_time"
		></knw-date-picker-input>

		<knw-time-picker-input class="flex-dynamic input outlined" 
			[classes]="'flex-dynamic input outlined'"
			[required]="true" [label]="'ARRIVAL.PLAN.TIME' | trans" 
			[disabled]="!can_edit_time || !start_time_picker"
			[(ngModel)]="start_time"></knw-time-picker-input>
	</div>

	<ng-container *ngIf="emergency_intervention && can_add_emergency_arrival">
		<div class="flex-row">
			<knw-date-picker-input class="flex-dynamic input outlined" 
				[classes]="'flex-dynamic input outlined'"
				[required]="true" 
				[label]="'ARRIVAL.PLAN.END_DATE2' | trans" [disabled]="!can_edit_time || !start_time_picker"
				[(ngModel)]="end_time"
				[max-date]="today"
			></knw-date-picker-input>
			
			<knw-time-picker-input class="flex-dynamic input outlined" 
				[classes]="'flex-dynamic input outlined'"
				[required]="true" 
				[label]="'ARRIVAL.PLAN.END_TIME' | trans" 
				[disabled]="!can_edit_time || !start_time_picker"
				[(ngModel)]="end_time">
			</knw-time-picker-input>
		
		</div>
		<div class="flex-row">
			<p style="color:rgb(var(--warn));">{{'ARRIVAL.DETAILS.EMERGENCY_TEXT' | trans}}</p>
		</div>
	</ng-container>


	<div class="flex-column" *ngIf="start_time_picker && can_add_emergency_arrival">
		<label class="checkbox-input pointer">
			<input type="checkbox" [(ngModel)]="emergency_intervention" (ngModelChange)="handle_start_date()">
			<span style="margin-left:.3em;">{{ "ARRIVAL.DETAILS.EMERGENCY_TITLE" | trans }}</span>

		</label>
	</div>

	<div class="flex-column" style="overflow: hidden">
		<task-workers [workers]="workers" [arrival_id]="arrival.arrival_id" required>
		</task-workers>
	</div>

	<div class="flex-row align-center" style="margin-top: 1.5em;" *ngIf="!start_time_picker">
		<label class="checkbox-input pointer" [attr.color]="is_confirmed ? 'primary' : 'warn'">
			<input type="checkbox" [(ngModel)]="is_confirmed" />
			<span [style]="is_confirmed ? '' : 'color: rgb(var(--warn));'" style="margin-left:.3em;">*{{
				"ARRIVAL.DETAILS.CONFIRM.MSG" | trans }}.</span>
		</label>

	</div>

	<div class="flex-row modal-footer">
		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" [disabled]="is_disabled(planForm)" (click)="done()">
			<i icon="check"></i>
			<span style="white-space: pre-line">
				{{ (start_time_picker ? 'SAVE' : 'ARRIVAL.QR.SAVE') | trans }}
			</span>
		</button>

	</div>
</div>