import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';


@Component({
    selector: 'task-dashboard-info',
    template: `
        <div class="flex-column">
            <span class="flex-row" style="gap: 1em;">
            <i icon="info" [style.color]="(setStatus | fn : status_id).color"></i>
            {{info}}</span>
        </div>
    `, 
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-dashboard-info .flex-column {
            padding: 1rem;
            max-width: 350px;
        }
    `]
})

export class DashboardInfoPopover extends ModalBase<{tooltip: string | undefined, status: number}, any> {

    info: string | undefined;
    status_id: number;

    setStatus(id: number) {
		return Task.get_status_icon(id);
	}

    ngOnInit(): void {
        this.info = this.injectedData.tooltip;
        this.status_id = this.injectedData.status;
    }
}