import { Component, Input, Output, ViewChild, ElementRef, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { files, get_global } from '@task-utils/utils';
import { modal, language, ConfirmDialogComponent, color, noOp } from 'curvy';
import { components_trans } from './components.trans';

const CUSTOM_CVA = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiImageSelect),
    multi: true
};

// @NOTE: This can be repurposed to be a general file uploader.
// @TODO: Make styling better!

@Component({
    selector: 'task-multi-image',
    encapsulation: ViewEncapsulation.None,
    template: `
        <label class="input"
               title="HIDDEN IMAGE CONTAINER"
               style="display: none">
            <input [ngModel]="images.length"
                   #imageLength
                   type="number"
                   [min]="min_images > 0 ? min_images : null"
                   [max]="(max_images < min_images || max_images <= 0) ? null : max_images"
                   [required]="min_images > 0"/>
        </label>
        <div class="flex-row wrappable">
            <ng-container *ngIf="disabled;">
                <ng-container *ngFor="let image of images; let i = index">
                    <div class="flex-row item" *ngIf="i < 2">
                        <img clickable [src]="image.src" [imgs]="images" [imgIndex]="i" />

                        <div class="flex-dynamic"></div>
                        <button class="round filled"
                                *ngIf="!disabled && !(image.is_uploaded && !allow_deleting_uploaded)"
                                color="warn"
                                [title]="'DELETE' | trans"
                                (click)="remove_image(i)">
                            <i icon="clear"></i>
                        </button>
                    </div>
                </ng-container>
                <button *ngIf="images.length > 2"
                    class="round filled"
                    [title]="'VIEW_ALL' | trans"
                    color="secondary"
                    (click)="openImagesDialog()">
                        <i icon="more_horiz"></i>
                </button>
            </ng-container>

            <ng-container *ngIf="!disabled">
                <ng-container *ngFor="let image of images; let i = index">
                    <div class="flex-row item">
                        <img clickable [src]="image.src" [imgs]="images" [imgIndex]="i"/>

                        <div class="flex-dynamic"></div>
                        <button class="round filled"
                                *ngIf="!disabled && !(image.is_uploaded && !allow_deleting_uploaded)"
                                color="warn"
                                [title]="'DELETE' | trans"
                                (click)="remove_image(i)">
                            <i icon="clear"></i>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <button (click)="get_images()" *ngIf="!disabled" color="primary" class="pill"
                [disabled]="(max_images > 0 && images.length >= max_images)" style="margin: .7em 0;">
                <i icon="add_circle"></i>
            <span>{{ "ADD_IMAGE" | trans }}</span>
        </button>
    `,
    styleUrls: ['./components.scss'],
    providers: [ CUSTOM_CVA ]
})
export class MultiImageSelect implements ControlValueAccessor {
    @ViewChild('imageLength')
    image_length_input: ElementRef<HTMLInputElement>;

    @Input()
    max_width = 1024;

    @Input()
    max_height = 1024;

    @Input()
    jpeg_quality = 0.85;

    @Input()
    max_images = 0;

    @Input()
    min_images = 0;

    @Input('allow-deleting-uploaded')
    allow_deleting_uploaded = true;

    images: files.IImage[] = [];
    private on_changed;
    private on_touched;

    @Input()
    disabled = false;

    constructor() {
        language.load_translations(components_trans);
    }

    async get_images() {
        let opts = {
            multiple: this.max_images != 1,
            max_width: this.max_width,
            max_height: this.max_height,
            jpeg_quality: this.jpeg_quality,
            accept: "image/*"
        };
        let prom = files.get_images(opts);
        let images = await prom;
        if (images === null) { return; }

        for (let img of images) {
            this.add_image(img);
        }
    }

    async add_image(img: string | files.IImage, do_events=true) {
        if (do_events && this.on_touched) { this.on_touched(); }
        this.image_length_input.nativeElement.dispatchEvent(new Event("input"));

        if (this.max_images > 0) {
            if (this.images.length >= this.max_images) {
                return;
            }
        }

        if (typeof img === "string") {
            img = await files.url_to_iimage(img);
        }

        if (files.is_iimage(img)) {
            this.images.push(img);
            if (do_events && this.on_changed) {
                this.on_changed(this.images);
            }
        } else {
            console.error("Attempt to add invalid image", img);
        }

    }

    remove_image(index: number, do_events=true) {

        modal.open(ConfirmDialogComponent, async (succ) => {
            if (succ) {

                if (do_events && this.on_touched) { this.on_touched(); }
                this.image_length_input.nativeElement.dispatchEvent(new Event("input"));

                let old_len = this.images.length;
                this.images.splice(index, 1);

                if (old_len != this.images.length) {
                    if (do_events && this.on_changed) {
                        this.on_changed(this.images);
                    }
                }
            }
        }, {
            title: language.translate("DELETE"),
            message: language.translate("DELETE.IMAGE.TEXT"),
            icon: "delete",
            iconColor: color.variable(color.Variable.warn)
        })
    }

    openImagesDialog() {
        const ImageDialog = get_global("ImageDialog");
        modal.open(ImageDialog, noOp, {imgs: this.images, index: 0});
    }

    writeValue(val: (string | files.IImage)[]) {
        val = val || [];

        this.images = [];
        for (let img of val) {
            this.add_image(img, false);
        }
    }

    registerOnChange(fn) {
        this.on_changed = fn;
    }

    registerOnTouched(fn) {
        this.on_touched = fn;
    }

    setDisabledState(state: boolean) {
        this.disabled = state;
    }
}
