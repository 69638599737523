import { Component, Input } from '@angular/core';
import { modal, ConfirmDialogComponent } from 'curvy';

@Component({
    selector: 'task-back-button',
    template: `
		<button class="round" (click)="go_back()">
			<i icon="arrow_back"></i>
		</button>
    `
})
export class BackButton {
	
	@Input()
	confirm_data: {
		title?: string;
		icon?: string;
		iconColor?: string;
		message: string;
		confirmButtonText?: string;
		denyButtonText?: string;
		switchButtons?: boolean;
	} | null = null;

	go_back() {
		if(this.confirm_data) {
			modal.open(ConfirmDialogComponent, (res)=>{
				if (res) {
					history.go(-1);
				}
			}, this.confirm_data)
		} else {
			history.go(-1);
		}
	}
}
