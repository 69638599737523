import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users/users';
import { Frame, language, SearchPickerComponent } from 'curvy';
import { users_translations } from '@task-modules/users/users.trans';
import { checklists_translations } from './checklists.trans';
import { TaskRoutes } from '@task-utils/routes';


@Component({
   selector: 'task-create-edit-question',
   templateUrl: './create-edit-question.component.html',
   styles: `
      task-create-edit-question label {
         grid-column: 1 / -1!important;
         margin-left: 0!important;
         margin-right: 0!important;
      }

   `,
   encapsulation: ViewEncapsulation.None,

})

export class CreateEditQuestionComponent implements OnInit, OnChanges {

   @Input()
   question: Omit<Task.CheckListQuestion, 'company_id'|`created_${string}`> = {
      checklist_question_id: null,
      name: "",
      description: "",
      locations: [],
      location_groups: [],
      whier_id: null,
      whier: null,
      frequency: null
   }

   @Output('result')
   result = new EventEmitter<typeof this["question"]>();

   is_edit_form = false;
	yearly_plans = {...Task.Annual_Frequency};


   select_whier_id(event: Task.WorkHierarchy) {
		if (!event) this.question.whier_id = null;
		else this.question.whier_id = event.whier_id;
	}

   search_locations = async(search: string) => {
      let res = await TaskRoutes.locations.api_location_search_responsible(
         { search: [search], attributes: [`cin+ +name+`] },
         { page_no: 1, page_size: 100 }
      );

      return res.data;
	}

   format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

   async search_location_groups(search: string) {
		let res = await TaskRoutes.location_groups.api_location_groups_search_responsible(
			{ search: [search], attributes: ['name'] },
			{ page_no: 1, page_size: 50 },
			true
		)

		return res.data;
	}

   format_location_group_name(loc: Task.LocationGroup) {
		if (!loc) { return ""; }
		return `${loc.name}`
	}

   ngOnInit() {
      language.load_translations(checklists_translations);

      this.update_data();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes["question"]) {
         this.question = {...changes["question"].currentValue};
      }

      this.update_data();
   }

   async update_data() {
      if (this.question.checklist_question_id) {
         this.is_edit_form = true;


      }


   }

   done() {
      this.result.emit(this.question);
   }

   cancel() {
      this.result.emit(null);
   }


}
