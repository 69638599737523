
import { Component, OnInit } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users/users';
import { tickets_translations } from '../tickets.trans';

@Component({
  selector: 'task-ticket-dialog',
  template: '<task-ticket [ticket]="ticket"></task-ticket>'
})
export class TicketDialog extends ModalBase<Task.Ticket | Promise<Task.Ticket>> implements OnInit {
    ticket: Task.Ticket = null;

    set_ticket(t: Task.Ticket) {
        this.ticket = t;
    }

    ngOnInit() {
        language.load_translations(tickets_translations);

        if (this.injectedData instanceof Promise) {
            this.injectedData.then(this.set_ticket);
        } else {
            this.set_ticket(this.injectedData);
        }
    }
}
