import { Component, Input, ViewEncapsulation, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users/users';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, modal, utils, template, DataTableComponent, GenericFilterComponent, ConfirmDialogComponent, color, notification } from 'curvy';
import { arrivals_translations } from './arrivals.trans';
import { orders_translations } from '@task-modules/orders/orders.trans';
import { Arrival } from './arrivals';
import { ConfirmArrivalDialog } from './confirm-arrival.dialog';
import { PlanArrivalDialog } from './plan-arrival.dialog';
import { CompleteArrivalDialog } from './complete-arrival.dialog';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';

import STATUS = Task.STATUS;
import USER_TYPE = Task.USER_TYPE;


interface OrderDisplay {
    // title: template.EscapedHTML;
    order_detail: template.EscapedHTML;
};
type OrderTable = DataTableComponent<
    Task.OrderDetail,
    OrderDisplay,
    'arrival-component-order',
    GenericFilterComponent>;

interface ArrivalDisplay {
    title: template.EscapedHTML;
    detail: template.EscapedHTML;
};
type ArrivalTable = DataTableComponent<
    Task.ArrivalDetail,
    ArrivalDisplay,
    'arrival-component-arrival',
    GenericFilterComponent>;

interface WorkerDisplay {
    worker: template.EscapedHTML;
};
type WorkerTable = DataTableComponent<
    Task.ArrivalWorker,
    WorkerDisplay,
    'arrival-component-worker',
    GenericFilterComponent>;

function translated_title(title: string, ...load: language.TranslationDefinition[]) {
    if (load) {
        language.load_translations(load);
    }

    let translated = language.translate(title);
    return template.transform(`{{ text }}`, { text: translated }, 'table-title');
}

function is_responsible(user: Task.User, order: Task.Order) {
    return order.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

@Component({
    selector: 'task-arrival',
    templateUrl: './arrival.component.html',
    styleUrls: ['./arrival.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArrivalComponent implements OnChanges, OnInit {
    @ViewChild('orderTable')
    order_table: OrderTable;

    @ViewChild('arrivalTable')
    arrival_table: ArrivalTable;

    @ViewChild('workerTable')
    worker_table: WorkerTable;


    @Input()
    arrival: Task.Arrival;

    order: Task.Order;
    tickets: Task.Ticket[] = [];

    entity = Task.ENTITY.ARRIVAL;
    got_work_order = false;

    icon: string;
    color: string;
    status_name: string;

    can_delete = false;
    can_admin_delete = false;
    can_complete = false;

    orderConfig: OrderTable["config"];
    arrivalConfig: ArrivalTable["config"];
    workerConfig: WorkerTable["config"];

    canSeeTickets = false;
    canStartArrival = false;
    canGetPDF = false;
    canAccessFinancials = false;
    can_see_individual_prices: boolean = false

    constructor(private ar: ActivatedRoute) {
        language.load_translations(arrivals_translations);
        language.load_translations(orders_translations);

        this.orderConfig = {
            tableName: 'arrival-component-order',
            getData: () => {
                let od = (this.order || {}).order_details || [];
                return Promise.resolve({
                    data: od.sort((a, b)=>{
                        return a.order_detail_type_id - b.order_detail_type_id;
                    }),
                    count: od.length
                });
            },
            getHeader: () => {

                return {
                    icon: null,
                    label: null,
                    sortable: false
                }
            },
            additionalRows: {
                beforeHeader: [translated_title("ARRIVAL.ORDER.DETAILS", ...arrivals_translations)]
            },
            rowActions: [{
                label: language.translate("DOWNLOAD"),
                icon: "download",
                onClick: async (od)=>{
                    await TaskRoutes.orders.api_get_document_by_order_detail_id(od.order_detail_id, true)
                },
                priority: 1,
                isVisible: async(od) => {
                    let is_document = od.order_detail_type_id === Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT;
                    let is_admin =  await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN);

                    return is_admin && is_document;
                }
            }],
            unwrapData: (detail) => {
                let text = `{{ description }}`;

                switch (detail.order_detail_type_id) {
                    case Task.ORDER_DETAIL_TYPE.IMAGE:
                        text = `
                            <div style="text-align: center;" class="flex-row justify-center">
                                <a href="{{ picture_url }}"
                                    class="d-contents"
                                    target="_blank">
                                    <img class="image-container" src="{{ picture_url }}"/>
                                </a>
                            </div>`;
                        break;
                    case Task.ORDER_DETAIL_TYPE.INVENTORY:
                        let inv_no = detail.inventory_no ? `<i style="font-size: 0.8em">#{{ inventory_no }}</i>` : "";
                        let ser_no = detail.inventory_serial_no ? `<i style="font-size: 0.8em">#{{ inventory_serial_no }}</i>` : "";

                        text = `
                            <div class="flex-row justify-center">
                                <div class="flex-column">
                                    ${inv_no}
                                    <b>{{ inventory_name }}</b>
                                    ${ser_no}
                                </div>
                            </div>
                        `;
                        break;

                    case Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT || Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT:
                        text = `
                           <p>{{dms_document_file_name}}</p>
                        `;
                        break;
                }
                return {
                    order_detail: template.transform(text, detail),
                }
            },
            hidePaginator: true,
        };

        this.arrivalConfig = {
            tableName: 'arrival-component-arrival',
            getData: () => {
                return Promise.resolve({
                    data: this.arrival.arrival_details,
                    count: this.arrival.arrival_details.length
                });
            },
            getHeader: (header) => {
                return {
                    icon: null,
                    label: `ARRIVAL.DETAIL.${header.toUpperCase()}`,
                    sortable: false
                }
            },
            rowActions: [{
                label: language.translate("DOWNLOAD"),
                icon: "download",
                onClick: async (ad)=> {
                    return await TaskRoutes.arrivals.api_get_document_by_arrival_detail_id(ad.arrival_detail_id, true);
                },
                priority: 1,
                isVisible: async (ad) => {
                    let is_document = ad.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT;
                    let can_download =  await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN, Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SAFETY_MANAGER);

                    return can_download && is_document;
                }
            }],
            unwrapData: (detail) => {
                let title = "";
                let description = "";

                switch (detail.arrival_detail_type_id) {
                    case Task.ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION:
                    case Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON:
                    case Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY: {
                        title = `<div class="flex-column align-flex-start" style="width: 100%; text-align: left">{{ creator }}</div>`;

                        if (detail.picture_url) {
                            description += `
                                <a href="{{ picture_url }}"
                                        target="_blank"
                                        class="d-contents">
                                    <img class="image-container"
                                            src="{{ picture_url }}"></img>
                                </a>
                            `;
                        } else {
                            description += `<div class="flex-column align-flex-start" style="width: 100%; text-align: left"><p class='prewrap'>{{ description }}</p></div>`;
                        }
                    } break;
                    case Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT: {
                        title = `
                            <div class="flex-column align-flex-start" style="width: 100%; text-align: left">
                                <b>{{ name }}</b>`;

                        if (detail.element_cin && detail.element_cin.length > 0) {
                            title += `<i>#{{ cin }}</i>`;
                        }

                        title += "</div>";

                        description += `
                            <div class="flex-column align-flex-start" style="width: 100%; text-align: left">
                                <span>{{ quantity | float }}`;

                        if (detail.element_uom) {
                            description += " {{ uom }}"
                        }

                        //this.element_price_manager_2 nemoj prikazat pojedinacne cijene ako je manager 2 u pitanju
                        if (detail.element_price != undefined && this.can_see_individual_prices) {
                            description += " x "
                            description += "{{ price | float }} {{ currency }}</span>";
                            description += "<span><b>{{ total | float << minimumFractionDigits=2 << maximumFractionDigits=2 }} {{ currency }}</b>";
                        }

                        description += "</span></div>"
                    } break;

                    case Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT: {
                        let name = detail.dms_document_file_name || "Dokument";
                        title = `<div class="flex-column align-flex-start" style="width: 100%; text-align: left"><span>{{ creator }}</span></div>`;
                        description = `<div class="flex-column align-flex-start" style="width: 100%; text-align: left"><span>${name}</span></div>`
                    } break;
                    case Task.ARRIVAL_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT: {
                        let name = detail.dms_document_file_name || "Dokument";
                        title = `<div class="flex-column align-flex-start" style="width: 100%; text-align: left"><span>{{ creator }}</span></div>`;
                        description = `<div class="flex-column align-flex-start" style="width: 100%; text-align: left"><span>${name}</span></div>`
                    } break;
                }

                let total = 0;

                if (!detail.lump_sum) {
                    total = (detail.element_price || 0) * (detail.element_quantity || 0);
                }

                let obj = {
                    cin: detail.element_cin,
                    name: detail.element_name,
                    quantity: detail.element_quantity || 0,
                    price: detail.element_price || 0,
                    uom: detail.element_uom,
                    total: total,
                    currency: detail.element_currency,
                    creator: `${detail.created_by_first_name} ${detail.created_by_last_name}`,
                    picture_url: detail.picture_url,
                    description: detail.description || detail.work_description || ""
                };

                return {
                    title: template.transform(title, obj),
                    detail: template.transform(description, obj)
                }
            },
            hidePaginator: true,
            additionalRows: {
                beforeHeader: [translated_title("ARRIVAL.DETAILS", ...arrivals_translations)],
                afterBody: [(data) => {
                    let total = 0;
                    let currency = "";

                    if (data.length === 0) {
                        return template.transform(``, {});
                    }

                    for (let d of data) {
                        if (d.element_price && d.element_quantity && !d.lump_sum) {
                            let element_price = Math.round(d.element_price*100)/100;
                            total += Math.round((element_price * d.element_quantity)*100)/100;
                            currency = d.element_currency;
                        }
                    }

                    if (total !== total ||
                        total === null ||
                        total === undefined ||
                        currency === undefined ||
                        currency === null ||
                        currency === ""
                    ) {
                        return template.transform(``, {});
                    }

                    return template.transform(`
                        <div class="flex-row table-title justify-space-between" style="padding: 0.25em">
                            <b>TOT: </b>
                            <b>{{ total | float }} {{ currency }}</b>
                        </div>
                    `, { total, currency }, "sticky-bottom");
                }]
            }
        };

        this.workerConfig = {
            tableName: 'arrival-component-worker',
            getData: () => {
                return Promise.resolve({
                    data: this.arrival.arrival_workers,
                    count: this.arrival.arrival_workers.length
                });
            },
            getHeader: () => {
                return {
                    icon: null,
                    label: "ARRIVAL.WORKERS",
                    sortable: false
                }
            },
            unwrapData: (worker) => {
                let full_name = `${worker.first_name || ""} ${worker.last_name || ""}`.trim();
                let name_str = "<b>{{ full_name }}</b>"
                if (full_name === "") {
                    name_str = "<i icon='remove'></i>"
                }

                let desc_str = "<i>{{ description }}</i>"
                if (!worker.description || worker.description === "") {
                    desc_str = "<i icon='remove'></i>"
                }

                return {
                    worker: template.transform(`
                        <div class="flex-column align-center">
                            ${name_str}
                            ${desc_str}
                        </div>
                    `, {
                        full_name,
                        description: worker.description,
                    }, "flex-column")
                }
            },
            hidePaginator: true
        };
    }


    ngOnInit() {
        this.initialize();
    }

    async initialize() {
        if (this.arrival == null) {
            const arrival_id = this.ar.snapshot.paramMap.get("id");

            Frame.set({
                title: language.translate("NAVROUTE.ARRIVAL", arrival_id),
                visible: true,
                layout: "top-middle",
                size: "scroll"
            });

            Arrival.get(+arrival_id).then(res => {
                this.arrival = res.data;
                this.update_values();
            }).catch(() => {
                utils.router.navigateByUrl('/arrivals');
            })

        } else {
            this.update_values();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.update_values();
    }

    show_financial_details() {
        utils.router.navigateByUrl("/arrivals/:id/financial-details");
    }

    async update_values() {
        let user = await User.currentUserPromise;
        //koristi se za sakrivanje pojedinacnih cijena
        this.can_see_individual_prices = !(await User.currentTypeIs(USER_TYPE.MANAGER_2))

        let icon = Task.get_status_icon(this.arrival.status_id);
        this.icon = icon.icon;
        this.color = icon.color;
        this.status_name = language.translate('STATUS.' + Task.Status[this.arrival.status_id]);

        let o = await TaskRoutes.orders.api_order_get_single(this.arrival.order_id);
        this.order = o.data;
        this.order_table.getData();

        this.tickets = (await TaskRoutes.orders.api_order_tickets_get_all(this.arrival.order_id, { page_no: 1, page_size: 1000 }, true)).data;

        this.canSeeTickets = !(await User.currentTypeIs(
            USER_TYPE.SUPPLIER_ADMIN,
            USER_TYPE.SUPPLIER_WORKER
        ));

        let is_in_status = (this.arrival.status_id === STATUS.CREATED || this.arrival.status_id === STATUS.IN_EXECUTION)

        if (await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER)) {
            this.canStartArrival = is_in_status && is_responsible(user, this.order)
        } else if (!(await User.currentTypeIs(USER_TYPE.SUPPLIER_ADMIN, USER_TYPE.SUPPLIER_WORKER, USER_TYPE.COMPANY_WORKER))) {
            this.canStartArrival = is_in_status;
        }

        // this.canStartArrival = !(await User.currentTypeIs(USER_TYPE.SUPPLIER_ADMIN, USER_TYPE.SUPPLIER_WORKER, USER_TYPE.COMPANY_WORKER)) && is_in_status;

        //manager_2 nema pristup pdf-u, (radnom nalogu)
        this.canGetPDF = !(await User.currentTypeIs(USER_TYPE.STORE_MANAGER, USER_TYPE.COMPANY_WORKER, USER_TYPE.REGIONAL_RETAIL_MANAGER, USER_TYPE.AUTHORIZED_PERSONEL, USER_TYPE.MANAGER_2)) &&
            (this.arrival.status_id === STATUS.EXECUTED || this.arrival.status_id === STATUS.INVOICED);

        this.canAccessFinancials = !(await User.currentTypeIs(USER_TYPE.STORE_MANAGER, USER_TYPE.COMPANY_WORKER, USER_TYPE.AUTHORIZED_PERSONEL)) &&
            (this.arrival.order.status_id === STATUS.ON_COMPANY_REVIEW || this.arrival.order.status_id === STATUS.ON_SUPPLIER_REVIEW);

        this.can_admin_delete = await User.currentTypeIs(USER_TYPE.ADMIN, USER_TYPE.SYSTEM_ADMIN);

        this.can_delete = (
            this.arrival.status_id === Task.STATUS.CREATED && await User.currentTypeIs(
                USER_TYPE.ADMIN,
                USER_TYPE.SYSTEM_ADMIN,
                USER_TYPE.SUPPLIER_ADMIN,
                USER_TYPE.COMPANY_WORKER)
        ) || (
            this.arrival.status_id === Task.STATUS.IN_EXECUTION && await User.currentTypeIs(
                USER_TYPE.ADMIN,
                USER_TYPE.SYSTEM_ADMIN)
        );

        if (this.can_delete && await User.currentTypeIs(USER_TYPE.COMPANY_WORKER)) {
            this.can_delete = this.order.arrival_worker_id == user.user_id;
        }

        this.can_complete = await User.currentTypeIs(
            USER_TYPE.ADMIN,
            USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.SAFETY_MANAGER,
        ) && (this.arrival.status_id === STATUS.IN_EXECUTION || this.arrival.status_id === STATUS.CREATED)
    }

    show_qr_code() {
        modal.open(PlanArrivalDialog, async (res) => {
            if (res) {

                // @HACK: Fix in curvy.
                setTimeout(() => {
                    modal.open(ConfirmArrivalDialog, () => {
                        this.arrival = null;
                        this.initialize();
                    }, this.arrival);
                }, 500);
            } else {
                this.arrival = null;
                this.initialize();
            }
        }, {
            arrival: this.arrival,
            start_time_picker: false
        });
    }

    async get_report() {
        this.got_work_order = true;
        await TaskRoutes.arrivals.api_arrival_work_order_get(this.arrival.arrival_id, true);
    }

    async complete_arrival() {
        let arrival = this.arrival;
        modal.open(
            CompleteArrivalDialog,
            async (res) => {
                if (res) {
                    try {
                        let response;
                        console.log(arrival.status_id)
                        if (arrival.status_id == Task.STATUS.CREATED) {
                            response = await TaskRoutes.arrivals.api_arrival_end_new(this.arrival.arrival_id, {
                                start_time: res.start_date,
                                end_time: res.end_date
                            }, true)
                        } else {
                            response = await TaskRoutes.arrivals.api_arrival_end(this.arrival.arrival_id, {end_time: res.end_date}, true);
                        }



                        this.arrival = response.data;
                        this.update_values();

                    } catch (error) {
                        let msg;
                        if (error.internal_error_code == 4001) {
                            msg = language.translate('ERROR.INVALID_DATE_TEXT');
                        } else {
                            msg = language.translate('ERROR.GENERIC_TEXT')
                        }
                        notification.show({
                            icon: "error",
                            color: color.Variable.warn,
                            title: language.translate('ERROR'),
                            message: msg
                        })
                    }
                }
            },
            this.arrival
        )
    }

    admin_delete_arrival() {
        modal.open(
            TextBoxDialog,
            async (reason) => {
                if (!reason) return;
                await TaskRoutes.arrivals.api_arrival_admin_delete(this.arrival.arrival_id, {reason});
                let res = await TaskRoutes.arrivals.api_arrival_get_single(this.arrival.arrival_id, true);
                this.arrival = res.data;
                this.update_values();
            }, {
                title: language.translate("ARRIVAL.DELETE.TITLE"),
                message: {
                    message: language.translate("ARRIVAL.DELETE.MSG", this.arrival.arrival_id),
                    icon: "today",
                    iconColor: color.variable(color.Variable.warn)
                },
                label: "ARRIVAL.DELETE.REASON",
                placeholder: "ARRIVAL.DELETE.ENTER_REASON",
                required: true,
                buttonColor: color.variable(color.Variable.warn),
                confirmText: "DELETE"
            }
        );
    }

    async delete_arrival() {
        modal.open(
            ConfirmDialogComponent,
            async (res) => {
                if (res) {
                    await Arrival.remove(this.arrival.arrival_id)
                    utils.router.navigateByUrl("/arrivals");
                }
            },
            {
                title: language.translate("ARRIVAL.DELETE.TITLE"),
                message: language.translate("ARRIVAL.DELETE.MSG", this.arrival.arrival_id),
                icon: "today",
                iconColor: color.variable(color.Variable.warn)
            }
        );
    }
}
