<div class="flex-column"
     style="height: 100%"
     *ngIf="whier"
     form-collector #qForm="collector">
    <h2 class="modal-title">
        {{ 'QUESTIONNAIRES.EDIT' | trans : whier.description }}
    </h2>

    <details style="margin-bottom: 0.5em"
             *ngIf="static_questionnaires.length > 0">
        <summary>
            <h4 style="margin: 0">
                {{ 'QUESTIONNAIRES.PARENT_WHIERS' | trans }}
            </h4>
        </summary>
        <task-edit-questionnaire [questionnaire]="questionnaire"
            [disabled]="true"
            *ngFor="let questionnaire of static_questionnaires">
        </task-edit-questionnaire>
    </details>

    <div class="flex-column flex-dynamic"
         style="overflow: auto;">
        <div class="single-questionnaire flex-row" style="margin-bottom: 1em;"
             *ngFor="let questionnaire of questionnaires">
            <task-edit-questionnaire class="flex-dynamic"
                    [questionnaire]="questionnaire">
            </task-edit-questionnaire>
            <button class="round flex-static"
                    color="warn"
                    (click)="delete_question(questionnaire)">
                <i icon="delete"></i>
            </button>
        </div>
    </div>

    <div class="flex-row justify-center"
         style="margin: 0.5em">
        <button class="filled pill"
                (click)="add_question()"
                color="primary">
            <i icon="add_circle"></i> &nbsp;
            <span>{{ 'QUESTIONNAIRES.ADD_QUESTION' | trans }}</span>
        </button>
    </div>

    <div class="flex-row flex-static modal-footer">
        <button color="primary" (click)="cancel()">
            <i icon="clear"></i>
            <span>{{ 'CANCEL' | trans }}</span>
        </button>
        <button class="filled"
                color="secondary"
                (click)="save_changes()"
                [disabled]="qForm.containsErrors()">
            <i icon="check"></i>
            <span>{{ 'SAVE' | trans }}</span>
        </button>
    </div>
</div>
