import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Curvy } from 'curvy';
import { FullnamePipe } from './fullname.pipe';
import { DateMultiFormat } from './date_multi_format.pipe';
import { FeaturePipe } from './feature.pipe';
import { ArrivalTimePipe } from './arrival_time.pipe';


let pipes = [
	FullnamePipe,
	DateMultiFormat,
	FeaturePipe,
	ArrivalTimePipe
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		Curvy
	],
	declarations: pipes,
	exports: pipes
})
export class TaskPipesModule {}
