import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';

@Component({
    selector: 'task-create-edit-work-hierarchy-dialog',
    template: `
        <task-create-edit-work-hierarchy></task-create-edit-work-hierarchy>
    `
})
export class CreateEditWorkHierarchyDialog extends ModalBase<any, any> implements OnInit {
    ngOnInit() {}
}
