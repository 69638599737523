<div class="flex-column fullheight-noscroll">
	<h2 class="modal-title">
		{{ 'ORDER.NEW' | trans }}
	</h2>

	<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>


	<div style="overflow: auto">
		<div class="field-grid" form-collector #orderForm="collector">
			<label class="input outlined" title="{{ 'ORDER.TITLE' | trans }}">
				<i icon="ballot"></i>
				<input [(ngModel)]="order.title" placeholder="{{ 'ORDER.TITLE' | trans }}"
				conditions="nonempty" />
			</label>

			<task-search-locations [(locations)]="picked_location"></task-search-locations>

			<task-whier-picker [required]="true"
					[(ngModel)]="whier"
					[requiredLevel]="3"
					[maxLevel]="3">
			</task-whier-picker>

			<h4 class="whole-row" style="margin: 0.5em 0">{{ 'DESCRIPTION' | trans }}</h4>
			<label class="textarea" title="{{ 'DESCRIPTION' | trans }}">
				<textarea rows="4"
					placeholder="{{ 'DESCRIPTION' | trans }}"
					[(ngModel)]="order_description">
				</textarea>
			</label>

			<knw-auto-search
				[required]="true"
				[function]="search_suppliers"
				[to-label]="format_supplier_name"
				label="{{ 'SUPPLIER' | trans }}"
				[(ngModel)]="picked_supplier"
				(ngModelChange)="picked_supplier_handler($event)"
				classes="outlined whole-row">
			</knw-auto-search>

			<knw-auto-search
				*ngIf="is_internal_supplier"
				[required]="true"
				[function]="search_workers"
				[to-label]="format_worker_name"
				label="{{'USER.TYPE.COMPANY_WORKER' | trans }}"
				[(ngModel)]="picked_worker"
				classes="outlined whole-row">
			</knw-auto-search>

			<div class="whole-row" style="margin: 0.5em 0">
				<h4 class="whole-row" style="margin: 0.5em 0">{{'ORDER.ADDITIONAL_EMAIL' | trans}}</h4>

				<div class="whole-row flex-column">
					@for (_ of cc_emails; track i; let i = $index) {
						<div class="flex-row">
							<label class="input outlined flex-dynamic" title="{{'ORDER.CC_EMAIL' | trans}}" >
								<input type="text"
									[(ngModel)]="cc_emails[i]"
									placeholder="{{'ORDER.CC_EMAIL' | trans}}">
							</label>

							<button class="round" color="warn" (click)="remove_cc_mail(i)">
								<i icon="clear"></i>
							</button>
						</div>
					}
				</div>

				<button class="flex-static pill" style="margin: auto;" (click)="add_cc_email()">
					<i icon="add_circle"></i>
					<span>{{'ORDER.ADD_EMAIL' | trans}}</span>
				</button>
			</div>



			<label class="input outlined" title="{{ 'ORDER.COST_ESTIMATE' | trans }}">
				<input [(ngModel)]="order.cost_estimate"
						placeholder="{{ 'ORDER.COST_ESTIMATE' | trans }}"
						type="number" /><i style="padding-left: 0.2em; vertical-align: middle;">€</i>
			</label>

			<label class="input outlined" title="{{ 'ORDER.INVESTMENT_TYPE' | trans }}">
				<select [(ngModel)]="order.investment_type" required>
					<option *ngFor="let type of investment_types | keyvalue" [ngValue]="+type.key">
							{{ ("ORDER.INVESTMENT_TYPE." + type.value) | trans }}
					</option>
				</select>
			</label>
		</div>

		<task-inventory-cards
				[disabled]="false"
				[(ngModel)]="inventory"
				(ngModelChange)="
					inventory=$event;
					edited=true"
				[location_cin]="picked_location?.cin"
				[in_grid]="true"
		></task-inventory-cards>
		<!-- <task-inventory-picker [(ngModel)]="inventory" [location_cin]="picked_location?.cin">
		</task-inventory-picker> -->

		<div class="flex-row align-center wrappable" style="margin-top: 1em;">
			<label class="checkbox-input">
				<input type="checkbox"
						[(ngModel)]="order.insurance" />
				{{"ORDER.INSURANCE" | trans }}
			</label>
			<label class="checkbox-input">
				<input type="checkbox"
						[(ngModel)]="order.lease" />
					{{"ORDER.LEASE" | trans }}
			</label>
			<label class="checkbox-input">
				<input type="checkbox"
						[(ngModel)]="order.preinvoice" />
					{{"ORDER.PREINVOICE" | trans }}
			</label>

			<label class="checkbox-input">
				<input type="checkbox"
						[(ngModel)]="order.complaint" />
					{{"ORDER.RECLAMATION" | trans }}
			</label>

			<label class="checkbox-input">
				<input type="checkbox"
						[(ngModel)]="order.inspection" />
				{{"ORDER.INSPECTION" | trans }}
			</label>
		</div>


		<!-- <task-multi-image [(ngModel)]="images">
		</task-multi-image> -->

		<div style="margin-top: 1.5em;">
			<task-multi-image-new [(ngModel)]="images"></task-multi-image-new>
		</div>

		<task-upload-files
			[(ngModel)]="financial_files"
			[allow-deleting-uploaded]="true"
			[type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
			[button-label]="'ADD_FINANCIAL_FILE'">
		</task-upload-files>

		<task-upload-files
			[(ngModel)]="service_files"
			[allow-deleting-uploaded]="true"
			[type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
			[button-label]="'ADD_FILE'">
		</task-upload-files>
	</div>

	<div class="flex-row modal-footer">

		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" (click)="done()" [disabled]="orderForm.containsErrors()">
			<i icon="check"></i>
			{{ 'SAVE' | trans }}
		</button>

	</div>
</div>
