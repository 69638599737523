import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users/users';
import { Frame, language, SearchPickerComponent } from 'curvy';
import { locations_translations } from './locations.trans';
import { users_translations } from '@task-modules/users/users.trans';
import { location_groups_translations } from '@task-modules/location-groups';

@Component({
    selector: 'task-create-edit-location',
    templateUrl: './create-edit-location.component.html',
    styleUrls: ['./create-edit-location.component.scss']
})

export class CreateEditLocationComponent implements OnChanges {
    @ViewChild('userpicker')
    userpicker: SearchPickerComponent;

    @Input()
    location: Partial<Task.Location> = {
        name: "",
        area: "",
        format: "",
        description: "",
        location_type_id: null,
        country: "",
        region: "",
        city: "",
        address: "",
        district: "",
        postal_code: "",
        contact_phone: "",
        email: "",
        location_users: [],
        location_groups_users: [],
        gross_area: null,
        net_area: null,
        nfc: false
    };

    @Output()
    result = new EventEmitter<Task.Location>();

    is_edit_form = false;
    location_types = Task.Location_Type;
    user_types= { ...Task.User_Type };
    is_disabled = false;


    constructor() {
        language.load_translations(locations_translations);
        language.load_translations(users_translations);
        language.load_translations(location_groups_translations);
    }

    save_without_users() {
        if (this.is_edit_form) {
            let has_no_users = (this.location.location_users.length === 0 && this.location.location_groups_users.length === 0);
            return has_no_users;

        } else {
            return  false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["location"]) {
            this.location = {...changes["location"].currentValue};
        }
        this.is_edit_form = !!this.location.location_id;


        this.save_without_users();
    }

    ngOnInit() {
        if (this.location.location_groups_users) {
            this.location.location_groups_users.sort((a,b)=>{
                if(a.user_type_id<b.user_type_id) return -1;
                if (a.user_type_id>b.user_type_id) return 1;
                else return 0;
            })
        }

        this.save_without_users();
    }

    getUserType(id:number) {
        return language.translate('USER.TYPE.'+this.user_types[id]);
    }

    done() {
        this.result.emit(this.location as Task.Location);
    }

    cancel() {
        this.result.emit(null);
    }
}


