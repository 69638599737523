
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "DASHBOARD.UNFINISHED_ORDERS": "Number of unfinished orders",
        "DASHBOARD.IN_EXECUTION_ORDERS": "Number of orders in execution",
        "DASHBOARD.CREATED_2_DAYS_BEFORE": "Orders created more than 2 days ago",
        "DASHBOARD.UNINVOICED_ORDERS": "Number of orders that haven't been invoiced yet",
        "DASHBOARD.SCHEDULED_ORDER_ORDERS": "Number of orders created from scheduled orders",
        "DASHBOARD.UNFINISHED_SCHEUDLED_ORDERS": "Number of unfinished scheduled orders",
        "DASHBOARD.IN_EXECTION_SCHEDULED_ORDERS": "Number of scheduled orders in execution",
        "DASHBOARD.TOOLTIP_0": "Number of unfinished or uninvoiced orders. Scheduled orders are not included.", 
        "DASHBOARD.TOOLTIP_3": "Number of finished orders that are not invoiced.",
        "DASHBOARD.TOOLTIP_4": "Number of scheduled orders that orders have been created for.",
        "DASHBOARD.TOOLTIP_5": "Number of orders for scheduled orders whose planned end date has passed.",
        "DASHBOARD.TOOLTIP_6": "Number of orders created from scheduled orders that are in execution.",

    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "DASHBOARD.UNFINISHED_ORDERS": "Broj nerealiziranih naloga",
        "DASHBOARD.IN_EXECUTION_ORDERS": "Broj naloga u izvršavanju",
        "DASHBOARD.CREATED_2_DAYS_BEFORE": "Broj nezapočetih naloga starijih od 2 dana",
        "DASHBOARD.UNINVOICED_ORDERS": "Broj nefakturiranih naloga",
        "DASHBOARD.SCHEDULED_ORDER_ORDERS": "Broj kreiranih naloga po servisu",
        "DASHBOARD.UNFINISHED_SCHEUDLED_ORDERS": "Broj neizvršenih naloga po servisu",
        "DASHBOARD.IN_EXECTION_SCHEDULED_ORDERS": "Broj servisa u izvršavanju",
        "DASHBOARD.TOOLTIP_0": "Broj naloga koji nisu izvršeni niti fakturirani. Ne prikazuju se servisni nalozi.", 
        "DASHBOARD.TOOLTIP_3": "Broj izvršenih naloga koji još nisu fakturirani.",
        "DASHBOARD.TOOLTIP_4": "Broj najavljenih servisa s kreiranim nalozima.",
        "DASHBOARD.TOOLTIP_5": "Broj naloga za najavljene servise kojima je prošao krajnji datum izvršavanja.",
        "DASHBOARD.TOOLTIP_6": "Broj servisnih naloga koji su u izvršavanju.",
    }
};

export const dashboard_translations = [english, croatian];
