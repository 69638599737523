import { Component } from '@angular/core';
import { Frame, language } from 'curvy';
import { Task } from '@task-utils/types';
import { WorkHierarchy, Node, node_depth } from './work-hierarchies';
import { work_hierarchy_translations } from './work-hierarchy.trans';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';

@Component({
    selector: 'task-work-hierarchy',
    template: `
        @if (isSysAdmin) {
            <div style="margin-top: 10px; margin-left: 10px">
                <knw-auto-search
                    [function]="search_companies"
                    [to-label]="format_company_name"
                    label="{{'COMPANY' | trans}}"
                    [ngModel]="picked_company"
                    (ngModelChange)="filter_by_company($event)"
                    [classes]="'input filled'"
                    popover-width="'auto'"
                />
            </div>
        }

        <task-whier-node [node]="filteredRoot"></task-whier-node>
    `
})
export class WorkHierarchyComponent {
    root: Node<Task.WorkHierarchy> = {data: null, children: []};
    filteredRoot: Node<Task.WorkHierarchy> = {data: null, children: []};
    isSysAdmin: boolean = false;
    picked_company: Task.Company;

    constructor() {
        Frame.set({
            title: "NAVROUTE.WORKHIER",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(work_hierarchy_translations);
        this.getInit()
        this.getWorkHierarchy();
    }

    async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

   filter_by_company(event) {
        this.filteredRoot = {...this.root, children: this.root.children.filter((node) => node.data.company_id === Number(event.company_id))}
   }

    getInit = async () => {
        let user =  await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)
        this.isSysAdmin = user

    }

    async getWorkHierarchy() {
        this.root = await WorkHierarchy.get_constructed();
        this.filteredRoot = {...this.root}
    }

}

interface Company {
    name: string
    value: number
}
