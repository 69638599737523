import { Component, Input, OnInit, ViewEncapsulation, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { language, SearchPickerComponent, async } from 'curvy';
import { components_trans } from './components.trans';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InventoryCardsComponent),
	multi: true
};


@Component({
	selector: 'task-inventory-cards',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="inventory-picker flex-column">
			<div class="flex-row" *ngIf="!disabled" style="margin-bottom: .6rem;">
				<knw-auto-search
						#invpicker
						label="{{ 'COMP.INVENTORY' | trans }}"
						[function]="search_inventory"
						[multi]="true"
						[to-label]="format_kam_name"
						[(ngModel)]="picked_inventory"
						(ngModelChange)="add_inventory()"
						[disabled]="!location_cin || disabled"
						[classes]="classes">
				</knw-auto-search>
			</div>

			<div class="inventory-grid inventory-items"
			[class.inventory-flexible]="!in_grid">
				<button *ngFor="let inv of picked_inventory" class="filled flex-row inventory-item"
						color="secondary"
						style="gap: .5em;"
						[attr.color]="(inv.inventory_warranty_period && !(is_in_past | fn : inv.inventory_warranty_period)) ? 'orange' : 'secondary'"
						>
					<div class="flex-column">
					<i *ngIf="inv.inventory_no">#{{ inv.inventory_no }}</i>
						<b style="white-space:break-spaces;">{{ inv.inventory_name }}</b>
						<i *ngIf="inv.inventory_serial_no">{{ inv.inventory_serial_no }}</i>
						<i class="flex-row align-center" *ngIf="inv.inventory_warranty_period && !(is_in_past | fn : inv.inventory_warranty_period)">
							<i icon="verified_user"></i>
							<strong>{{'WARRANTY_EXP' | trans}}:</strong> &nbsp;
							{{ inv.inventory_warranty_period | datefmt }}
						</i>
					</div>
					<button class="round outlined"
							color="secondary-text"
							style="pointer-events: all"
							(click)="delete_inventory(inv)"
							*ngIf="!disabled">
						<i icon="clear"></i>
					</button>
				</button>

			</div>
		</div>
	`,
	styleUrls: ['./components.scss'],
	providers: [ CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR ]
})
export class InventoryCardsComponent implements OnInit, ControlValueAccessor {
	@ViewChild('invpicker')
	invpicker: SearchPickerComponent;

	@Input()
	picked_inventory: Task.KAM_Inventory[] = [];

	format_kam_name = format_kam_name;

	location_cin: string = null;
	get _location_cin() {
		return this.location_cin;
	}
	@Input('location_cin') set _location_cin(val: string) {
		let old_location_cin = this.location_cin;
		this.location_cin = val;
		if (this.location_cin == null || old_location_cin != this.location_cin) {
			this.picked_inventory = [];
			if (this.invpicker) {
				this.invpicker.search_options = [];
				this.invpicker.writeValue(null);
			}
		}
	}

	@Input()
	classes = 'outlined flex-dynamic';

	private on_changed;
	private on_touched;

	@Input()
	disabled = false;

	@Input()
	required = false;

	@Input()
    in_grid = false;

	ngOnInit() {
		language.load_translations(components_trans);
	}

	is_in_past(date:Date | string) {
		let today = new Date().setHours(0,0,0,0);
		return new Date(date).setHours(0,0,0,0) < today;
	}

	add_inventory(do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }

		if (do_events && this.on_changed) {
			this.on_changed(this.picked_inventory);
		}
	}

	delete_inventory(inv: Task.KAM_Inventory, do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }

		let index = this.picked_inventory.indexOf(inv);
		if (index != -1) {
			this.picked_inventory.splice(index, 1);

			if (do_events && this.on_changed) {
				this.on_changed(this.picked_inventory);
			}
		}
	}

	// picked_inventory: Task.KAM_Inventory = null;
	search_inventory = async (search: string) => {
		if (!this.location_cin) {
			return [];
		}

		let res = (await TaskRoutes.kam_data.api_kam_inventory_search({
			location_cin: this.location_cin,
			inventory_name: search || null,
			inventory_no: search ||  null,
			inventory_serial_no: search || null
		}, {page_no: 1, page_size: 5})) as TaskRoutes.Payload<Task.KAM_Inventory[]>;

		let out_data = [];
		for (let d of res.data) {
			if (!this.picked_inventory.some(i => i.inventory_id == d.inventory_id)) {
				out_data.push(d);

				if (out_data.length == 5) {
					break;
				}
			}
		}

		return out_data;
	}


	writeValue(val: Task.KAM_Inventory[]) {
		val = val || [];
		this.picked_inventory = [...val];
		this.add_inventory(false);
	}

	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

	setDisabledState(state: boolean) {
		this.disabled = state;
	}
}

export function format_kam_name(inv: Task.KAM_Inventory) {
	if (!inv) { return ""; }
	let name = inv.inventory_name || "";

	if (inv.inventory_no) {
		name = "#" + inv.inventory_no + " " + name;
	}

	if (inv.inventory_serial_no) {
		name = name + "(" + inv.inventory_serial_no + ")"
	}

	return name;
}
