import { type Type } from '@angular/core';
import { template, language, modal, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';

const globals_map = {};

export function register_global(global_name: string, global_value: any) {
	globals_map[global_name] = global_value;
}

export function get_global(global_name: string) {
	return globals_map[global_name];
}

window["get_global"] = get_global;
window["modal"] = modal;

export namespace utils {
	export async function when(condition, retry_time_ms = 100, retry_count = 10): Promise<void> {
		return new Promise((resolve, reject) => {
			if (condition()) {
				resolve();
			}
			let retries = 0;

			let int = setInterval(() => {
				retries++;

				if (condition()) {
					resolve();
				}

				if (retries => retry_count) {
					clearInterval(int);
					reject();
				}
			}, retry_time_ms);
		});
	}

	export function has_props<T extends string>(obj: any, ...props: T[]): obj is Record<typeof props[number], any> {
		if (obj === null || obj === undefined) {
			return false;
		}

		for (let prop of props) {
			if (!(prop in obj)) {
				return false;
			}
		}

		return true;
	}
}

export type FileType = (files.IFile | Task.DMSFile);


export namespace files {
	export interface IFile {
		file: File;
		is_uploaded: boolean;
		is_financial?: boolean;
	}

	export interface GetFilesOptions {
		multiple?: boolean;
		accept?: string;
		max_file_size?: number;
		on_files_too_large?: (files: File[]) => void;
	}

	const default_get_files_opts: GetFilesOptions = {
		multiple: false,
		max_file_size: 10000000
	} as const;

	export function is_dms_file(file: FileType): file is Task.DMSFile {
		return file.hasOwnProperty('dms_document_id');
	}

	function create_file_input() {
		let el = document.createElement("input");
		el.type = "file";
		el.style.display = "none";
		return el;
	}

	let file_input = create_file_input();

	function file_to_ifile(file: File): IFile {
		return { file: file, is_uploaded: false, is_financial: false };
	}

	export function get_files(opts: GetFilesOptions = default_get_files_opts) {
		return new Promise<IFile[] | null>((resolve) => {
			if (typeof opts.accept === "string") {
				file_input.accept = opts.accept;
			}

			document.body.append(file_input);

			if (opts.multiple) {
				file_input.multiple = true;
			}

			file_input.onchange = () => {
				opts.max_file_size = opts.max_file_size ?? Infinity;
				if (file_input.files && file_input.files.length > 0) {
					let input_files = Array.from(file_input.files);
					let files = input_files
						.filter(f => f.size <= opts.max_file_size)
						.map(file_to_ifile);
					if (files.length !== input_files.length) {
						if (opts.on_files_too_large) {
							opts.on_files_too_large(
								input_files.filter(f => f.size > opts.max_file_size)
							);
						}
					}
					resolve(files);
				} else {
					resolve(null);
				}
			};
			file_input.click();
		}).finally(() => {
			file_input.remove();
			file_input = create_file_input();
		});
	}

	export interface IImage extends IFile {
		blob: Blob;
		src: string;
		img: HTMLImageElement;
	}

	export interface GetImagesOptions extends GetFilesOptions {
		max_width?: number;
		max_height?: number;
		jpeg_quality?: number;
	}

	const default_get_images_opts: GetImagesOptions = {
		multiple: false,
		accept: "image/*",
		max_width: 1024,
		max_height: 1024,
		jpeg_quality: 0.85
	} as const;

	function load_image(src: string) {
		return new Promise<HTMLImageElement | null>((resolve, reject) => {
			let img = new Image();
			img.onload = () => {
				resolve(img);
			};
			img.onerror = () => {
				resolve(null);
			};
			img.src = src;
		});
	}

	function blob_to_file(blob: Blob, filename: string): File {
		return new File([blob], filename, { lastModified: Date.now() });
	}

	function url_to_filename(url: string) {
		let uurl = new URL(url);
		let pn = uurl.pathname.split("/").filter(s => s.length > 0).pop();

		if (pn) { return pn };
		return uurl.hostname.replace(/[^a-zA-Z0-9-_]/g,"");
	}

	function replace_extension(filename: string, new_extension: string) {
		let words = filename.split(".");
		if (words.length > 1) {
			words.pop();
		}

		words.push(new_extension);

		return words.join(".");
	}

	export function is_ifile(obj: any): obj is IFile {
		return utils.has_props(obj, "file", "is_uploaded");
	}

	export function is_iimage(obj: any): obj is IImage {
		return is_ifile(obj) && utils.has_props(obj, "src", "blob", "img");
	}

	export function is_iimage_array(obj: any): obj is IImage[] {
		return Array.isArray(obj) && is_iimage(obj[0]);
	}

	export function is_ifile_array(obj: any): obj is IFile[] {
		return Array.isArray(obj) && is_ifile(obj[0]);
	}

	function canvas_blob(
		canvas: HTMLCanvasElement,
		quality: number
	): Promise<Blob> {
		let mime = "image/jpeg";

		if (quality === 1) {
			mime = undefined;
			quality = undefined;
		}

		return new Promise(resolve => {
			canvas.toBlob(blob => {
				resolve(blob);
			}, mime, quality);
		});
	}

	async function image_blob(
		img: HTMLImageElement,
		opts: GetImagesOptions
	): Promise<Blob> {
		// @TODO: Maybe pool canvas elements?
		let canvas = document.createElement("canvas");

		let width = img.width;
		let height = img.height;

		if (opts.max_width) {
			width = Math.min(width, opts.max_width);
		}

		if (opts.max_height) {
			height = Math.min(height, opts.max_height);
		}

		let ratio = Math.min(width/img.width, height/img.height);
		if (ratio != 1) {
			width = img.width * ratio;
			height = img.height * ratio;
		}

		canvas.width = width;
		canvas.height = height;
		let ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		return await canvas_blob(canvas, opts.jpeg_quality || 1);
	}

	function read_file(file: File): Promise<string | null> {
		return new Promise(resolve => {
			let reader = new FileReader();
			reader.onloadend = async () => {
				resolve(reader.result as string);
			};
			reader.onerror = () => {
				resolve(null);
			};
			reader.readAsDataURL(file);
		});
	}

	async function ifile_to_iimage(ifile: IFile, opts: GetImagesOptions): Promise<IImage | null> {
		let src = await read_file(ifile.file);
		if (src === null) { return null };

		let img = await load_image(src);
		if (img === null) { return null };

		let blob = await image_blob(img, opts);
		let file = blob_to_file(blob, ifile.file.name);

		return {
			blob, file, src, img,
			is_uploaded: ifile.is_uploaded,
			is_financial: false
		};
	}

	export async function url_to_iimage(url: string): Promise<IImage> {
		let src = url;
		let img = await load_image(src);
		if (img === null) { return null };

		let opts = {
			max_width: img.width,
			max_height: img.height,
			jpeg_quality: 1
		};

		// @TODO: CORS problems with canvas.toBlob() (Tainted canvas)
		//let blob = await image_blob(img, opts);
		//let file = blob_to_file(blob, url_to_filename(src));
		let blob = null;
		let file = { name: url_to_filename(src) } as unknown as File;

		return {
			file, src, img, blob,
			is_uploaded: true,
			is_financial: false
		};
	}

	export async function get_images(opts: GetImagesOptions = default_get_images_opts): Promise<IImage[]> {
		let files = await get_files(opts);
		if (files === null) { return null; }

		let image_promises = files.map((f) => ifile_to_iimage(f, opts));
		let images = await Promise.all(image_promises)
		return images;
	}
}

export namespace Structs {
	export type Icon = {icon: string, color: string};
	export type StatusIcon = {icon: string, color: string, status_name: string};
	
	export function icon(icon: string, color: string): Icon {
		return { icon: icon, color: color };
	}

	export function get_ticket_status(ticket: Task.Ticket):StatusIcon {
		let status = {
			icon: "", 
			color: "", 
			status_name: ""
		};

		let ticket_icon = Task.get_status_icon(ticket.status_id);
		status.icon = ticket_icon.icon;
		status.color = ticket_icon.color;
		status.status_name = language.translate('STATUS.' + Task.Status[ticket.status_id]);

		return status;
	}

	export function get_arrival_status(arrival: Task.Arrival):StatusIcon {
		let status = {
			icon: "", 
			color: "", 
			status_name: ""
		};

		let arrival_icon = Task.get_status_icon(arrival.status_id);

		status.icon = arrival_icon.icon;
		status.color = arrival_icon.color;
		status.status_name = language.translate('STATUS.' + Task.Status[arrival.status_id]);

		return status;
	}

	export function get_order_status(order: Task.Order):StatusIcon {
		let status = {
			icon: "", 
			color: "", 
			status_name: ""
		};

		let order_has_arrivals = order.status_id === Task.STATUS.CREATED && order.active_arrivals > 0;

		let order_icon = () => {
            let icon = {} as Structs.Icon;

            if (order_has_arrivals) {
                icon = Task.HAS_ARRIVAL_ICON;
            } else {
                icon = Task.get_status_icon(order.status_id);
            }

            return icon;
        } 

		status.icon = order_icon().icon;
		status.color = order_icon().color;
		status.status_name = order_has_arrivals ? language.translate('STATUS.HAS_ARRIVAL') : language.translate('STATUS.' + Task.Status[this.order.status_id]);

		return status;
	}
}

export namespace Templates {
	export function icon(ico: Structs.Icon) {
		return template.transform(`
			<i icon="{{icon}}" style="color: {{color}}"></i>
		`, ico);
	}

	export function table_header(title: string, icon?: string, class_name = ''): template.EscapedHTML {
		return template.transform(
			`${icon ? '{{headerIcon|icon}}' : ''}
				<div class='spacer screen-big'></div>
				<span class="screen-big">{{title}}</span>`, { headerIcon: icon, title: language.translate(title) },
			class_name
		);
	}

	export function user(obj: { first_name: string, last_name: string, pic_url: string}) {
		if (obj == null || obj == undefined) {
			return template.prebuilt.icon("remove");
		}

		return template.transform(`
			<div class="flex-row" style="width: 100%">
				<div class="flex-row flex-dynamic" style="justify-content: center; align-items: center">
					<img style="border-radius: 100%" src="{{imgurl}}"></img>
				</div>
				<div class="flex-dynamic" style="flex: 3">{{name}}</div>
			</div>
		`, {
			name: obj.first_name + " " + obj.last_name,
			imgurl: obj.pic_url || "assets/icons/profile.svg"
		});
	}

	export function created_by(obj: Task.Created) {
		let creator = "System";
		let shortname = "System";

		if (obj.created_by_id != null) {
			let full_name = `${obj.created_by_first_name} ${obj.created_by_last_name}`;
			let words = full_name.split(" ");

			for (let i = 0; i < words.length - 1; i++) {
				words[i] = words[i][0].toUpperCase() + ".";
			}

			creator = full_name;
			shortname = words.join(" ");
		}

		return template.transform(`
			<span class="screen-big">{{creator}} </span>
			<span class="screen-small">{{shortname}} </span>
			<i>{{created_date | date}}</i>
		`, {
			creator: creator,
			shortname: shortname,
			created_date: new Date(obj.created_date)
		}, "hard-column");
	}

	export function email(email: string, label = email) {
		return template.transform(`
			<button class="round screen-small"
					onclick="window.location.href='mailto:{{email}}'"
					style="color: rgb(var(--secondary))">
				<i icon="email"></i>
			</button>
			<a class="screen-big" href="mailto:{{email}}">{{label}}</a>
		`, { email: email, label: label });
	}
}

export namespace Modal {
	type Modal_Options<IN_TYPE, OUT_TYPE> = {
		component: ModalBase<IN_TYPE, OUT_TYPE>;
		data: IN_TYPE;
	};

	export async function open<IN_TYPE, OUT_TYPE>(
		component: Type<ModalBase<IN_TYPE, OUT_TYPE>>,
		data: IN_TYPE
	): Promise<{data: OUT_TYPE}|{closed: true}> {
		return new Promise(resolve => {
			let submit_resolve = (data: OUT_TYPE) => {
				setTimeout(() => resolve({data}), 200);
			};
			let close_resolve = () => {
				setTimeout(() => resolve({closed: true}), 200);
			};
			modal.open(component, submit_resolve, data, close_resolve);
		});
	}
}
