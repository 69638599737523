import { Component, OnInit, Input } from '@angular/core';
import { Task } from '@task-utils/types';
import { files } from '@task-utils/utils';


type FileType = (files.IFile | Task.DMSFile)
@Component({
  selector: 'task-file',
  template: `
      
    
      <a class="plain-link flex-row align-center" *ngIf="download_file" (click)="download_file(file)" >
        <i icon="download" *ngIf="show_icon"></i>&nbsp;<span>{{ name() }}</span> 
      </a>

      <p class="flex-row align-center" style="margin: 0;" *ngIf="!download_file">
        <i icon="download" *ngIf="show_icon"></i>&nbsp;<span>{{ name() }}</span>
      </p>
    `,
  styles: [`
    
      .plain-link {
          color: rgb(var(--primary));
          font-weight: normal;
         cursor: pointer;
      }
  `]
})

export class FileComponent implements OnInit {
  @Input()
  file: FileType;
  show_icon:boolean;

  @Input ()
  download_file:(file:FileType)=>Promise<Blob> | null;
  
  ngOnInit() {
    if ("file" in this.file) {
      this.show_icon = false;
    } else {
      this.show_icon = true;
    }
 }


  name() {
    if ("file" in this.file) {
        return this.file.file.name;
    } else {
      return this.file.dms_document_file_name;
    }
  }

}


