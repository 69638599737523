
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
	locale: 'en-us',
	translations: {
		"USER.NAME": "Name",
		"USER.EDIT": "Edit user",
		"USER.NEW": "New user",
		"USER.SEND_PASSWORD_EMAIL": "Send a password change email",
		"USER.SEND_PASSWORD_EMAIL_SENT": "Successfully sent a password change email.",
		"USER.SEND_PASSWORD_EMAIL_NOT_SENT": "Password change email was sent recently. Please wait 1 minute before trying again.",
		"USER.SEND_PASSWORD_EMAIL_ERROR": "There was an error while sending the password change email.",
		"USER.TYPE": "User type",
		"USER.TYPE.SYSTEM_ADMIN": "System administrator",
		"USER.TYPE.ADMIN": "Administrator",
		"USER.TYPE.SUPER_USER": "Superuser",
		"USER.TYPE.AUTHORIZED_PERSONEL": "Authorized personnel",
		"USER.TYPE.REGIONAL_MAINTENANCE_MANAGER": "Regional maintenance manager",
		"USER.TYPE.REGIONAL_RETAIL_MANAGER": "Regional retail/logistics manager",
		"USER.TYPE.STORE_MANAGER": "Store/warehouse manager",
		"USER.TYPE.INTERNAL_SUPPLIER": "Internal supplier",
		"USER.TYPE.SUPPLIER_ADMIN": "Supplier admin",
		"USER.TYPE.SUPPLIER_WORKER": "Supplier worker",
		"USER.TYPE.COMPANY_ADMIN": "Company admin",
		"USER.TYPE.COMPANY_WORKER": "Company worker",
		"USER.TYPE.SAFETY_MANAGER": "Manager of other services",
		"USER.TYPE.MANAGER_1": 'Manager 1',
		"USER.TYPE.MANAGER_2": 'Manager 2',
		"USER.COMPANY": 'Company',
		"USER.ADD_NEW": "Create user",


		"USER.DELETE.TITLE": "User deletion",
		"USER.DELETE.MSG": (first, last) => `Are you sure you want to delete the user ${first} ${last}?`,

		"USER.QUALIFICATION": "Qualification",
		"USER.QUALIFICATION.NKV": "Unqualified",
		"USER.QUALIFICATION.KV": "Qualified",
		"USER.QUALIFICATION.VKV": "Highly qualified",
		"USER.TAKE_OVER_LOCATIONS": "User replacement",
		"USER.SHOW_HISTORY": "Replacements list",
		"USER.TAKEOVER_ERROR_TITLE" : "Submission has failed",
		"USER.TAKEOVER.CANCEL.ERROR_TITLE" : "Abortion failed",
		"USER.TAKEOVER_ERROR_DESC": "Please, contact application administrator",
		"USER.USER_TO_REPLACE": "Replace user",
		"USER.END_DATE": "Until",
		"USER.TAKOVER_SUCC_TITLE": "Changes have been applied",

		"USER.TAKOVER_SUCC_DESC": (first, last, date)=> {
			let formatDate = date.toLocaleDateString("en-US", {
				day:'numeric',
				month: 'numeric',
				year: 'numeric'
			});
			return `Replacement refers to manager: ${first} ${last} until: ${formatDate}`
		},

		"USER.TAKOVER.CANCEL.SUCC_DESC": (first, last)=> `Replacement successfuly aborted for manager: ${first} ${last}.`,
		"USER.TAKE_OVER.DESCRIPTION_ADMIN" : (first, last) => `Pick the user that is going to be replaced by ${first} ${last} and the expiration date of the replacement.`,
		"USER.TAKE_OVER.DESCRIPTION_RETAIL_MNGR" : "Pick the colleague you are going to replace and the expiration date of the replacement."

	}
};

export const croatian: language.TranslationDefinition = {
	locale: 'hr-hr',
	translations: {
		"USER.NAME": "Korisnik",
		"USER.EDIT": "Uređivanje korisnika",
		"USER.NEW": "Novi korisnik",
		"USER.SEND_PASSWORD_EMAIL": "Pošalji email za promjenu lozinke",
		"USER.SEND_PASSWORD_EMAIL_SENT": "Email za promjenu lozinke uspješno poslan.",
		"USER.SEND_PASSWORD_EMAIL_NOT_SENT": "Email za promjenu lozinke je poslan nedavno. Pričekajte 1 minutu prije nego što pokušate ponovno.",
		"USER.SEND_PASSWORD_EMAIL_ERROR": "Došlo je do greške pri slanju emaila za promjenu lozinke.",
		"USER.TYPE": "Tip korisnika",
		"USER.TYPE.SYSTEM_ADMIN": "Sistemski administrator",
		"USER.TYPE.ADMIN": "Administrator",
		"USER.TYPE.SUPER_USER": "Superkorisnik",
		"USER.TYPE.AUTHORIZED_PERSONEL": "Ovlaštena osoba",
		"USER.TYPE.REGIONAL_MAINTENANCE_MANAGER": "Područni voditelj održavanja",
		"USER.TYPE.REGIONAL_RETAIL_MANAGER": "Područni voditelj maloprodaje/najmova/logistike",
		"USER.TYPE.STORE_MANAGER": "Voditelj prodavaonice/skladišta",
		"USER.TYPE.INTERNAL_SUPPLIER": "Interni dobavljač",
		"USER.TYPE.SUPPLIER_ADMIN": "Administrator dobavljača",
		"USER.TYPE.SUPPLIER_WORKER": "Radnik dobavljača",
		"USER.TYPE.COMPANY_ADMIN": "Koordinator mobilnih servisera",
		"USER.TYPE.COMPANY_WORKER": "Mobilni serviser",
		"USER.TYPE.SAFETY_MANAGER": "Voditelj ostalih službi",
		"USER.TYPE.MANAGER_1": 'Voditelj 1',
		"USER.TYPE.MANAGER_2": 'Voditelj 2',
		"USER.COMPANY": 'Tvrtka',
		"USER.ADD_NEW": "Dodaj korisnika",


		"USER.DELETE.TITLE": "Brisanje korisnika",
		"USER.DELETE.MSG": (first, last) => `Jeste li sigurni da želite obrisati korisnika ${first} ${last}?`,

		"USER.QUALIFICATION": "Kvalifikacija",
		"USER.QUALIFICATION.NKV": "Nekvalificiran",
		"USER.QUALIFICATION.KV": "Kvalificiran",
		"USER.QUALIFICATION.VKV": "Visokokvalificiran",
		"USER.TAKE_OVER_LOCATIONS": "Zamjena pri odsustvu",
		"USER.SHOW_HISTORY": "Lista odsustava",
		"USER.TAKEOVER_ERROR_TITLE" : "Dane izmjene nisu prihvaćene",
		"USER.TAKEOVER.CANCEL.ERROR_TITLE" : "Otkazivanje nije uspjelo",
		"USER.TAKEOVER_ERROR_DESC": "Obratite se adminu aplikacije",
		"USER.USER_TO_REPLACE": "Zamijeniti korisnika",
		"USER.END_DATE": "Do datuma",
		"USER.TAKOVER_SUCC_TITLE": "Izmjene su spremljene",
		"USER.TAKOVER_SUCC_DESC": (first, last, date)=> {
			let formatDate = date.toLocaleDateString("hr-HR", {
				day:'numeric',
				month: 'numeric',
				year: 'numeric'
			});
			return `Zamijena se odnosi za voditelja: ${first} ${last} do: ${formatDate}`
		},
		"USER.TAKOVER.CANCEL.SUCC_DESC": (first, last)=> `Zamjena uspješno otkazana za: ${first} ${last}.`,
		"USER.TAKE_OVER.DESCRIPTION_ADMIN" : (first, last) => `Odaberite osobu koju će zamijeniti ${first} ${last} i vrijeme trajanja zamjene.`,
		"USER.TAKE_OVER.DESCRIPTION" : "Odaberite kolegu kojeg ćete mijenjati i vrijeme trajanja zamjene."
	}
};

export const users_translations = [english, croatian];
