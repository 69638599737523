import { Component, Input, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { language, async } from 'curvy';
import { components_trans } from './components.trans';
import { Node, WorkHierarchy, find_node, get_chain } from '@task-modules/work-hierarchy/work-hierarchies';
import { Task } from '@task-utils/types';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WhierPickerComponent),
	multi: true
};

function is_whier(obj: any): obj is Task.WorkHierarchy {
	if (typeof obj === "object") {
		if (!obj) { return false; }
		if (obj["whier_id"] !== undefined) {
			return true;
		}
	}

	return false;
}

@Component({
	selector: 'task-whier-picker',
	encapsulation: ViewEncapsulation.None,
	template: `
		<ng-container *ngFor="let node of picked_nodes; let i = index">
			<label [class]="'input ' + select_class"
					*ngIf="node.children.length > 0 && i < maxLevel"
					title="{{ 'COMP.WHIER' | trans }} {{maxLevel > 1 ? i+1 : ''}}">
				<!--i icon="account_tree"></i-->
				<select [ngModel]="picked_nodes[i+1]"
						(ngModelChange)="pick_node($event)"
						[required]="i < requiredLevel"
						[disabled]="disabled">
					<option *ngFor="let child of node.children"
							[ngValue]="child">
						{{ child.data.description }}
					</option>
				</select>
			</label>
		</ng-container>
	`,
	styles: [`task-whier-picker { display: contents }`],
	providers: [ CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR ]
})
export class WhierPickerComponent implements OnInit, ControlValueAccessor {
	@Input('selectClass')
	select_class = 'outlined';

	root: Node<Task.WorkHierarchy>;
	picked_nodes: Node<Task.WorkHierarchy>[] = [];

	got_root: Promise<void> = null;

	private on_changed;
	private on_touched;

	@Input()
	disabled = false;

	@Input()
	required = false;

	@Input()
	maxLevel = 2;

	@Input()
	requiredLevel = 1;

	ngOnInit() {
		language.load_translations(components_trans);
		this.got_root = this.get_hierarchy();
	}

	async get_hierarchy() {
		this.root = await WorkHierarchy.get_constructed();
		this.picked_nodes = [this.root];
	}

	find_node(whier_id: number, root = this.root): Node<Task.WorkHierarchy> {
		if (root.data && root.data.whier_id === whier_id) {
			return root;
		}

		if (root.children) {
			for (let child of root.children) {
				let found = this.find_node(whier_id, child);
				if (found) { return found; }
			}
		}

		return null;
	}

	pick_node(node: Node<Task.WorkHierarchy>, call_events=true) {
		if (node == null) {
			return;
		}

		if (call_events) {
			this.on_touched();
		}

		let first_picked_parent_index = -1;

		for (let i = 0; i < this.picked_nodes.length; i++) {
			let picked = this.picked_nodes[i];
			if (picked.children.find(n => n.data.whier_id === node.data.whier_id)) {
				first_picked_parent_index = i;
				break;
			}
		}

		if (first_picked_parent_index == -1) {
			return;
		}

		let result = null;

		this.picked_nodes = this.picked_nodes.slice(0, first_picked_parent_index + 1);
		this.picked_nodes.push(node);
		let picked_depth = this.picked_nodes.length - 1;

		if (picked_depth >= this.requiredLevel) {
			result = node.data;
		}

		if (call_events) {
			this.on_changed(result);
		}
	}

	writeValue(val: any) {
	
		setTimeout(async () => {
			await this.got_root;
			if (is_whier(val)) {
				let node = this.find_node(val.whier_id);

				if (node) {
					if (node.parent && node.parent.data !== null) {
						if (node.parent.parent && node.parent.parent.data !== null) {
							this.pick_node(node.parent.parent, false);
						}
						this.pick_node(node.parent, false);
					}
					
					this.pick_node(node, false);
					
				}
			} else {
				this.pick_node(val, false);
			}
		});
	}

	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

	setDisabledState(state: boolean) {
		this.disabled = state;
	}
}
