import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        'LOCGROUP.TITLE': 'Group\'s title',
        'LOCGROUP.NO_OF_LOCATIONS': 'Number of locations',
        'LOCGROUP.FORM_EDIT_TITLE': (name)=>'Edit location group ' + name ,
        'LOCGROUP.FORM_NEW_TITLE': 'Add new location group',
        'LOCGROUP.LOCATIONS_IN_GROUP': 'Locations in group',
        'LOCGROUP.ADD_NEW': 'Add location group',
        'LOCGROUP.NFC_IS_ON': 'NFC enabled',
        "LOCGROUP.USERS_IN_GROUP": 'Users in location\'s group',
        "LOCGROUP.DELETE.TITLE": "Location group deletion",
		"LOCGROUP.DELETE.MSG": (name) => `Are you sure you want to delete location group ${name}?`,
        "LOCGROUP.COMPANY": 'Company',


    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        'LOCGROUP.TITLE': 'Naziv grupe',
        'LOCGROUP.NO_OF_LOCATIONS': 'Broj lokacija',
        'LOCGROUP.FORM_EDIT_TITLE': (name)=>'Izmjena grupe ' + name ,
        'LOCGROUP.FORM_NEW_TITLE': 'Nova grupa lokacija',
        'LOCGROUP.LOCATIONS_IN_GROUP': 'Lokacije u grupi',
        'LOCGROUP.ADD_NEW': 'Dodaj grupu lokacija',
        'LOCGROUP.NFC_IS_ON': 'NFC ukuljučen',
        "LOCGROUP.USERS_IN_GROUP": 'Korisnici grupe lokacije',
        "LOCGROUP.DELETE.TITLE": "Brisanje grupe lokacija",
		"LOCGROUP.DELETE.MSG": (name) => `Da li ste sigurni da želite obrisati grupu \"${name}\"?`,
        "LOCGROUP.COMPANY": 'Tvrtka',

    }
};

export const location_groups_translations = [english, croatian];
