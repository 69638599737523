import {
    Component, Input,
    OnInit, OnChanges, ViewEncapsulation,
    ChangeDetectorRef, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, modal, FormCollectorDirective, ConfirmDialogComponent } from 'curvy';
import { Order } from '@task-modules/orders/orders';
import { arrivals_translations } from '../arrivals.trans';
import { pricelist_translations } from '../../pricelists/pricelist.trans';
import { User } from '@task-modules/users/users';
import { ChangeOrderDialog } from '@task-modules/orders/change-order.dialog';

function is_responsible(user: Task.User, order: Task.Order) {
    return order.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

function can_confirm(user: Task.User, order: Task.Order): boolean {

    switch (order.status_id) {
        case Task.STATUS.ON_SUPPLIER_REVIEW:
            if (user.company_id === order.supplier_id || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            break;
        case Task.STATUS.ON_COMPANY_REVIEW:
            if (user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            else if ((user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER || user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) && is_responsible(user, order)) return true;
            break;
        case Task.STATUS.EXECUTED:
        case Task.STATUS.INVOICED:
        default:
            return false;
    }
}

function can_edit(user: Task.User, order: Task.Order): boolean {

    switch (order.status_id) {
        case Task.STATUS.ON_SUPPLIER_REVIEW:
            if (user.company_id === order.supplier_id || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            break;
        case Task.STATUS.ON_COMPANY_REVIEW:
            if (user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            else if ((user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER || user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) && is_responsible(user, order)) return true;
            break;
        case Task.STATUS.EXECUTED:
        case Task.STATUS.INVOICED:
        default:
            return false;
    }
}

@Component({
    selector: 'task-financial-items',// api_get_all_arrivals_of_single_order
    encapsulation: ViewEncapsulation.None,
    templateUrl: './financial-items.component.html',
    styleUrls: ['./financial-items.component.scss']
})
export class FinancialItemsComponent implements OnInit, OnChanges {
    @ViewChild('financeForm', { static: false })
    finance_form: FormCollectorDirective;

    @Input() order: Task.Order;

    financial_items: Task.ArrivalDetail[] = [];
    arrivals: Record<number, Task.Arrival> = {};
    has_errors = false;
    allow_confirmation = false;
    can_edit_financial_details = false;
    has_completed_arrivals = false;

    items_to_approve_count = 0;


    constructor(private ar: ActivatedRoute, private cdr: ChangeDetectorRef) {
        language.load_translations(arrivals_translations);
        language.load_translations(pricelist_translations);
    }

    ngOnInit() {
        if (this.order == null) {
            const order_id = this.ar.snapshot.paramMap.get("id");

            Order.get(+order_id).then(res => {
                this.order = res;

                Frame.set({
                    title: language.translate("Financial details", order_id),
                    visible: true,
                    layout: "middle",
                    size: "full"
                });

                this.update_values();
            });
        }
    }

    detectErrors(form: FormCollectorDirective) {
        this.cdr.detectChanges();
        this.has_errors = form.containsErrors();
    }

    async send_confirmation() {
        let user = await User.currentUserPromise;
        let updates = [];
        let changed = 0;

        for (let item of this.financial_items) {

            let old_price = item.element_old_price;
            let new_price = item.element_price;
            let price_changed = old_price !== null && old_price != new_price;
            let old_quantity = item.element_old_quantity;
            let new_quantity = item.element_quantity;
            let quantity_changed = old_quantity !== null && old_quantity != new_quantity;
            let old_lump_sum = item.old_lump_sum;
            let new_lump_sum = item.lump_sum;
            let lump_sum_changed = old_lump_sum !== null && old_lump_sum != new_lump_sum;

            if ( price_changed || quantity_changed || lump_sum_changed) changed++;

            updates.push(item);
        }

        let canApprove = is_responsible(user, this.order);
        let isCompanyUser = this.order.status_id === Task.STATUS.ON_COMPANY_REVIEW && user.company_id === this.order.company_id && canApprove;
        let isAdmin = user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN;

        let allChanged = changed == this.items_to_approve_count;


        if ((isCompanyUser || isAdmin) && !changed ) {
            // console.log("modal")
            modal.open(ChangeOrderDialog, async (order) => {
                if (order) {
                    await TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });
                    window.history.back();
                }
            }, this.order);
        } else {
            // console.log("ne modal")
            if (allChanged || this.items_to_approve_count == 1) {
                await TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });
                    window.history.back();
            } else {
                modal.open(ConfirmDialogComponent, async(succ)=>{
                    if(succ) {
                        await TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });
                        window.history.back();
                    }
                }, {
                    title: language.translate("FINANCIAL.CONFIRM_TITLE", this.items_to_approve_count - changed),
                    message: language.translate("FINANCIAL.CONFIRM_TEXT")
                })
            }
        }

    }

    async download_pricelist() {
        await TaskRoutes.pricelists.api_pricelist_xlsx_generate(this.order.supplier_id, true);
    }

    async download_by_store_approval() {
        await TaskRoutes.orders.api_arrivals_work_order_snapshot_get(this.order.order_id, true);
    }

    async download_all() {
        await TaskRoutes.orders.api_arrivals_work_order__get(this.order.order_id, true);
    }

    async update_values() {

        let user = await User.currentUserPromise;

        if (this.order) {
            let res = await TaskRoutes.orders.api_order_arrivals_get_all(this.order.order_id, { page_no: 1, page_size: 1000 }, true);
            this.arrivals = {};
            let arrival_details = [];

            this.allow_confirmation = can_confirm(user, this.order);

            for (let a of res.data) {
                this.arrivals[a.arrival_id] = a;
                arrival_details.push(...a.arrival_details);
            }

            this.financial_items = arrival_details.filter(ad => {
                return ad.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT &&
                ad.arrival_status_id !== Task.STATUS.DELETED;
            });

            this.items_to_approve_count = this.financial_items.filter(i=>!i.approved).length;

            this.has_completed_arrivals = res.data.some(a=>a.status_id == Task.STATUS.EXECUTED);

            this.financial_items.sort((i1, i2) => {
                if (!i1.approved) {
                    if (i1.comment) {
                        return 0;
                    }
                    return 500;
                }
                return 1000;
            });

            if (this.finance_form) {
                this.detectErrors(this.finance_form);
            }

            this.can_edit_financial_details = can_edit(user, this.order);

        } else {
            this.financial_items = [];
        }
    }

    sum() {
        let total = 0;
        let currency = "";

        for (let item of this.financial_items) {
            if (item.lump_sum) continue;
            if (item.arrival_status_id == Task.STATUS.DELETED) continue;
            if (item.arrival_detail_type_id !== Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) continue;

            total += Math.round((item.element_price * item.element_quantity)*100) / 100;
            currency = item.element_currency;
        }

        if (currency == '' || currency == '?' || currency == undefined) return 0;
        return total.toLocaleString('hr', { style: 'currency', currency});
    }

    ngOnChanges() {
        this.update_values()
    }
}
