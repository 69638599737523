import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { language, ModalBase, notification, color } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Arrival } from './arrivals';
import { Order } from '@task-modules/orders/orders';
import { arrivals_translations } from './arrivals.trans';

type InputType = {
    start_time_picker: boolean,
    arrival: Pick<Task.Arrival, 'order_id' | 'planned_start_time'> & Partial<Pick<Task.Arrival, "arrival_workers">>,
};

@Component({
    selector: 'task-plan-arrival-dialog',
    template: `
        <task-plan-arrival *ngIf="arrival"
                [arrival]="arrival"
                [start_time_picker]="startTimePicker"
                (result)="handle_result($event)">
        </task-plan-arrival>
    `, 
    encapsulation: ViewEncapsulation.None,
    styles: [`
        @media screen and (min-width: 600px) {
            task-plan-arrival-dialog {
                min-width: 400px;
            }
        }
    `]
})
export class PlanArrivalDialog extends ModalBase<InputType | Promise<InputType>, Task.Arrival> implements OnInit {
    arrival: InputType["arrival"];

    preventSoftClose = true;
    startTimePicker = true;
    allowSubmit = true;

    ngOnInit() {
        language.load_translations(arrivals_translations);
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(this.init);
        } else {
            this.init(this.injectedData);
        }
    }

    init(data: InputType) {
        this.arrival = data.arrival;
        this.startTimePicker = data.start_time_picker;
    }

    async handle_result(arrival: Task.Arrival) {
        if(this.allowSubmit) {
            this.allowSubmit = false;
            if (arrival) {

                console.log(arrival.planned_start_time)
                let prom: Promise<Task.Payload<Task.Arrival>>;
    
                if (arrival["arrival_id"] == null) {
                    
                    if (arrival.emergency) {
                        prom = TaskRoutes.arrivals.api_arrival_emergency_add(arrival, false)
                        
                        prom.catch(error=>{
                            let msg;
                            if (error.internal_error_code == 4001) {
                                msg = language.translate('ERROR.INVALID_DATE_TEXT');
                            } else {
                                msg = language.translate('ERROR.GENERIC_TEXT')
                            }
                            notification.show({
                                icon: "error",
                                color: color.Variable.warn,
                                title: language.translate('ERROR'),
                                message: msg
                            })
                            this.allowSubmit = true;
                        })
                    } else {
                        prom = TaskRoutes.arrivals.api_arrival_add(arrival, false);
                    }
                } else {
                    prom = TaskRoutes.arrivals.api_arrival_modify(arrival.arrival_id, arrival, false)
                }

                let res = await prom;
                (this.arrival as Task.Arrival).arrival_id = res.data.arrival_id;
                this.submit(this.arrival as Task.Arrival);
            } else {
                this.submit(null);
            }
            this.allowSubmit = true;
        }
    }
}
