
import { Component, OnInit } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from './orders.trans';

@Component({
  selector: 'task-change-order-dialog',
  template: `
    <task-change-order [order]="order"
                       (result)="handle_result($event)">
    </task-change-order>
    `
})
export class ChangeOrderDialog extends ModalBase<Task.Order | Promise<Task.Order>, Task.Order> implements OnInit {
    order: Task.Order;
    preventSoftClose = true;
    allowSubmit = true;

    ngOnInit() {
        language.load_translations(orders_translations);

        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => this.order = res);
        } else {
            this.order = this.injectedData;
        }
    }

    async handle_result(order: Task.Order) {

        if (this.allowSubmit) {
			this.allowSubmit = false;
        
            if (order) {
             
                let res = await TaskRoutes.orders.api_order_modify(this.order.order_id, order, false);

                this.submit(res.data);
            } else {
                this.submit(null);
            }

            this.allowSubmit = true;
        }
    }
}
