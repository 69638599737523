import { Component, Input, ViewEncapsulation, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Frame, language, modal, utils, noOp, FormCollectorDirective, color, notification, progress, ConfirmDialogComponent, AutoSearchComponent } from 'curvy';
import { Task, } from '@task-utils/types';
import { Structs, files } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { User } from '@task-modules/users/users';
import { Arrival } from '@task-modules/arrivals/arrivals';
import { ConfirmArrivalDialog } from '@task-modules/arrivals/confirm-arrival.dialog';
import { PlanArrivalDialog } from '@task-modules/arrivals/plan-arrival.dialog';
import { CompleteArrivalDialog } from '@task-modules/arrivals/complete-arrival.dialog';
import { popover, PopoverMenuComponent } from 'curvy';
import { arrivals_translations } from '@task-modules/arrivals/arrivals.trans';
import { orders_translations } from '@task-modules/orders/orders.trans';
import { tickets_translations } from '@task-modules/tickets/tickets.trans';
import { suppliers_translations } from '@task-modules/suppliers';
import { pricelist_translations } from '@task-modules/pricelists';
import { components_trans } from '@task-components/components.trans';
import { ActivatedRoute } from '@angular/router';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';


import STATUS = Task.STATUS;
import USER_TYPE = Task.USER_TYPE;

const MenuDetailType = {
	WORK_ELEMENT: 'WORK_ELEMENT',
	FILES: 'FILES'
} as const


function is_arrival_detail(detail: Task.ArrivalDetail | Task.OrderDetail): detail is Task.ArrivalDetail {
	return (detail as Task.ArrivalDetail).arrival_detail_id !== undefined;
}

function is_responsible(user: Task.User, order: Task.Order) {
    return order.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

function is_dms_file(file: FileType): file is Task.DMSFile {
    return file.hasOwnProperty('dms_document_id');
}

interface TaskArrivalDetailExtended extends Task.ArrivalDetail {
	is_created: boolean;
	is_edited: boolean;
	is_deleted: boolean;
	tab: string;
	work_desc: Task.WorkDescription;
	element?: Task.Pricelist;
}


type FileType = (files.IFile | Task.DMSFile);


@Component({
    selector: 'task-arrival-single',
    templateUrl: './arrival-single.component.html',
    styleUrls: ['./arrival-single.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ArrivalSingleComponent implements OnInit, OnChanges {
	@ViewChild('arrivalDetailsForm') arrivalDetailsForm: FormCollectorDirective;
	@ViewChild('container') container: ElementRef;
	@ViewChild('work_element') work_element: ElementRef;


	//ARRIVALS
	arrival_id: string
	@Input()
   arrival: Task.Arrival;
	ar_details: TaskArrivalDetailExtended[] = [];
   DETAIL_TYPES = {...Task.Arrival_Detail_Type};
	arrival_images: files.IImage[] = [];
	arrival_files: FileType[] = [];
	data_before_edit: Task.Arrival = null;

	//ORDERS
	order: Task.Order;
	order_images: files.IImage[] = [];
	order_financial_files: FileType[] = [];
   order_service_files: FileType[] = [];
	order_inventory: Task.KAM_Inventory[] = [];
   tickets: Task.Ticket[] = [];

   canSeeTickets = false;
	canStartArrival = false;
   canGetPDF = false;
	canAccessFinancials = false;
	can_complete = false;
	can_delete = false;
   can_admin_delete = false;
	can_delete_detail = false;
	can_edit_detail = false;

	//detalji naloga PRAVA
	can_see_individual_prices: boolean = false;


	entity = Task.ENTITY.ARRIVAL; // for comments section
	got_work_order = false;
	edited = false; // show save/cancel buttons if details were edited


	get_arrival_status = Structs.get_arrival_status;
	get_order_status = Structs.get_order_status;

	arrival_info_props: Record<string, any> = {};
	order_info_props: Record<string, any> = {};
	arrival_tiles: {title: string, color: string} [] = [];

	headers: {id: number, name: string, icon: string, key: string, centered?: boolean, is_right?: boolean}[] = [
		{ id: 0, name: '#', icon: '', key: 'row-number', centered: true },
		{ id: 1, name: language.translate("ARRIVAL.DETAIL.GROUP"), icon: '', key: 'work_description' },
		{ id: 2, name: language.translate("ARRIVAL.DETAIL.ELEMENT_NAME"), icon: '', key: 'element_name' },
		{ id: 3, name: language.translate("SUPPLIER.CIN"), icon: '', key: 'element_cin' },
		{ id: 4, name: language.translate("PRICELIST.TYPE"), icon: '', key: 'element_type_id' },
		{ id: 5, name: language.translate("ARRIVAL.DETAIL.UOM"), icon: '', key: 'element_uom' },
		{ id: 6, name: language.translate("ARRIVAL.DETAIL.ELEMENT_QUANTITY"), icon: '', key: 'element_quantity' },
		{ id: 7, name: language.translate("ARRIVAL.DETAIL.ELEMENT_PRICE"), icon: '', key: 'element_price', is_right: true },
		{ id: 8, name: language.translate("ARRIVAL.DETAIL.ELEMENT_TOTAL"), icon: "", key: 'element_total_price', is_right: true },
		{ id: 9, name: language.translate("ARRIVAL.DETAILS.LUMP"), icon: '', key: 'lump_sum',  centered: true },
		{ id: 10, name: '', icon: '', key: 'delete' }
	];

	searchPicker: AutoSearchComponent;
	currency_sums: Record<string, number> = {};
	show_currency = false;
	show_table = false
	ELEMENT_TYPES = Task.Element_Type
	uom_types = (()=>{
        let types = {...Task.Unit_Of_Measure};
        return types;
    })();

	//ENTITY = Task.ENTITY;
	WHT = Task.Work_Hour_Type;
	itype = Task.Investment_Type;
	check_detail_type = (detail: TaskArrivalDetailExtended) => {
		let is_type = [
			Task.ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION,
			Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY,
			Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON,
			Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT
		].includes(detail.arrival_detail_type_id);

		let is_image = detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY && detail.picture_url !== null;

		return is_type && !is_image;
	}

	can_edit_detail_type(detail_type_id: number) {
		if (detail_type_id in this.DETAIL_TYPES) return true;
		else return false;
	}


	constructor(private ar: ActivatedRoute, private cdr: ChangeDetectorRef) {
		language.load_translations(arrivals_translations);
		language.load_translations(orders_translations);
		language.load_translations(tickets_translations);
		language.load_translations(components_trans);
		language.load_translations(suppliers_translations)
		language.load_translations(pricelist_translations)
	}


	ngOnInit() {
      this.initialize();
   }


	async initialize() {
		if (this.arrival == null) {
			this.arrival_id = this.ar.snapshot.paramMap.get("id");

			Frame.set({
				title: language.translate("NAVROUTE.ARRIVAL", this.arrival_id),
				visible: true,
				layout: "top",
				size: "scroll"
			});

			try {
				const res = await TaskRoutes.arrivals.api_arrival_get_single(+this.arrival_id)
				this.arrival = res.data;
				this.update_values();
				this.cdr.detectChanges();

			} catch {
				utils.router.navigateByUrl('/arrivals');
			}

		} else {
			this.update_values();
			this.cdr.detectChanges()
		}
	}

	ngAfterViewInit() {

	}

	ngOnChanges(changes: SimpleChanges) {
        this.update_values();
    }


	are_work_els = () => {
		return this.ar_details.some(d=>d.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT);
	}

	async update_values(get_arrival = false) {
		this.data_before_edit = JSON.parse(JSON.stringify(this.arrival));

		this.ar_details = this.arrival.arrival_details.map((detail: TaskArrivalDetailExtended) => ({...detail, is_created: true, is_edited: false, is_deleted: false}));


		let user = await User.currentUserPromise;

		this.ar_details.sort((a,b)=>{
			if (a.element_quantity == 0 && b.element_quantity != 0) return 1;
			if (b.element_quantity == 0 && a.element_quantity != 0) return -1;
			let a_desc = a.element_name ?? a.description ?? ("" + a.arrival_detail_id);
			let b_desc = b.element_name ?? b.description ?? ("" + b.arrival_detail_id);
			return a_desc.localeCompare(b_desc);

		});

		this.can_see_individual_prices = !(await User.currentTypeIs(USER_TYPE.MANAGER_2))

		// Get order:
		let o = await TaskRoutes.orders.api_order_get_single(this.arrival.order_id);
        this.order = o.data;

		// Get tickets:
		this.tickets = (await TaskRoutes.orders.api_order_tickets_get_all(this.arrival.order_id, {page_no: 1, page_size: 1000}, true)).data;

		this.canSeeTickets = !(await User.currentTypeIs(
			Task.USER_TYPE.SUPPLIER_ADMIN,
			Task.USER_TYPE.SUPPLIER_WORKER
		));

		let is_in_status = (this.arrival.status_id === STATUS.CREATED || this.arrival.status_id === STATUS.IN_EXECUTION)

        if (await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER)) {
            this.canStartArrival = is_in_status && is_responsible(user, this.order)
        } else if (!(await User.currentTypeIs(USER_TYPE.SUPPLIER_ADMIN, USER_TYPE.SUPPLIER_WORKER, USER_TYPE.COMPANY_WORKER))) {
            this.canStartArrival = is_in_status;
        }

		 this.canGetPDF = !(await User.currentTypeIs(USER_TYPE.STORE_MANAGER, USER_TYPE.COMPANY_WORKER, USER_TYPE.REGIONAL_RETAIL_MANAGER, USER_TYPE.AUTHORIZED_PERSONEL, USER_TYPE.MANAGER_2)) &&
			(this.arrival.status_id === STATUS.EXECUTED || this.arrival.status_id === STATUS.INVOICED);

		this.canAccessFinancials = !(await User.currentTypeIs(USER_TYPE.STORE_MANAGER, USER_TYPE.COMPANY_WORKER, USER_TYPE.AUTHORIZED_PERSONEL)) &&
			(this.arrival.order.status_id === STATUS.ON_COMPANY_REVIEW || this.arrival.order.status_id === STATUS.ON_SUPPLIER_REVIEW);

		this.can_admin_delete = await User.currentTypeIs(USER_TYPE.ADMIN, USER_TYPE.SYSTEM_ADMIN);


		this.can_delete = (
			this.arrival.status_id === Task.STATUS.CREATED && await User.currentTypeIs(
					USER_TYPE.ADMIN,
					USER_TYPE.SYSTEM_ADMIN,
					USER_TYPE.SUPPLIER_ADMIN,
					USER_TYPE.COMPANY_WORKER)
        ) || (
			this.arrival.status_id === Task.STATUS.IN_EXECUTION && await User.currentTypeIs(
					USER_TYPE.ADMIN,
					USER_TYPE.SYSTEM_ADMIN)
        );

        if (this.can_delete && await User.currentTypeIs(USER_TYPE.COMPANY_WORKER)) {
            this.can_delete = this.order.arrival_worker_id == user.user_id;
        }

		this.can_complete = await User.currentTypeIs(
			USER_TYPE.ADMIN,
			USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
			USER_TYPE.SYSTEM_ADMIN,
			Task.USER_TYPE.SAFETY_MANAGER,
		) && (this.arrival.status_id === STATUS.IN_EXECUTION || this.arrival.status_id === STATUS.CREATED)

		// editing arrival details permissions:
		let is_admin = await User.currentTypeIs(
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.ADMIN
        );

		if(is_admin) {
			this.can_edit_detail = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN, Task.USER_TYPE.SUPPLIER_ADMIN) && this.arrival.order.status_id !== Task.STATUS.EXECUTED;
			this.update_detail_types(false);
			this.can_delete_detail = this.can_edit_detail;
		}

		let is_supplier_admin = await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN);

		if (is_supplier_admin) {
			let order_is_in_exec = this.arrival.order.status_id == Task.STATUS.IN_EXECUTION;
			let arrival_created = this.arrival.status_id == Task.STATUS.CREATED;
			let order_created = this.arrival.order.status_id == Task.STATUS.CREATED;
			let is_on_supplier_review = this.arrival.order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW;

			this.can_edit_detail = order_created || order_is_in_exec || is_on_supplier_review;

			if (arrival_created) {
				this.update_detail_types(true);
			} else if (this.can_edit_detail) {
				this.update_detail_types(false);
			}

			this.can_delete_detail = order_is_in_exec || is_on_supplier_review;
		}

		this.show_table = this.show_table_flag()

		// Arrival details stuff:
		this.currency_sums = {};
		this.show_currency = false;
		this.arrival_images = [];
		this.arrival_files = [];
		let arrival_image_requests:Promise<files.IImage>[] = [];

		for (let detail of this.ar_details) {
			if(detail.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT) {
				this.arrival_files.push(detail)
			}
			if(detail.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY && detail.picture_url !== null) {
				arrival_image_requests.push(files.url_to_iimage(detail.picture_url))
			}
			if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
				if(detail.arrival_status_id == Task.STATUS.DELETED) continue;
				if(!detail.element_currency) continue;
				this.show_currency = true;
				this.currency_sums[detail.element_currency] = this.currency_sums[detail.element_currency] || 0;
				if(!detail.lump_sum) {
					let element_price = Math.round(detail.element_price*100)/100;
					this.currency_sums[detail.element_currency] += Math.round(element_price * detail.element_quantity*100)/100;
				}
			}
		}


		let order_image_requests: Promise<files.IImage>[] = [];
		this.order_inventory = [];

		for (let detail of this.order.order_details) {
			switch (detail.order_detail_type_id) {
				case Task.ORDER_DETAIL_TYPE.IMAGE:
					order_image_requests.push((files.url_to_iimage(detail.picture_url)))
					Promise.all(order_image_requests).then(res=>{
						this.order_images = res;
					})
				break;

				case Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT:
					this.order_financial_files.push(detail);
				break;

				case Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT:
					this.order_service_files.push(detail);
				break;

				case Task.ORDER_DETAIL_TYPE.INVENTORY:
					this.order_inventory.push(detail.inventory_data);
						if (detail?.inventory_data?.udas) {
						detail.inventory_data.udas.sort((u1, u2) => {
							return u1.uda_name.localeCompare(u2.uda_name);
						});
					}
				break;
			}
		}
		this.arrival_files = [...this.arrival_files];
		this.arrival_images = await Promise.all(arrival_image_requests)
	}

	async download_file(file: Task.ArrivalDetail | Task.OrderDetail) {
		let can_download = await User.currentTypeIs(
			Task.USER_TYPE.SYSTEM_ADMIN,
			Task.USER_TYPE.ADMIN,
			Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
			Task.USER_TYPE.SUPPLIER_ADMIN,
			Task.USER_TYPE.SAFETY_MANAGER
		);

		if (is_dms_file(file) && can_download) {

			let route: Promise<any>;

			if (is_arrival_detail(file)) {
				route = TaskRoutes.arrivals.api_get_document_by_arrival_detail_id(file.arrival_detail_id, true);
			} else {
				route = TaskRoutes.orders.api_get_document_by_order_detail_id(file.order_detail_id, true);
			}


			try {
				await route;
			} catch(err) {
				notification.show({
					message: language.translate("ERROR.FILE_UNAVAILABLE"),
					title: language.translate("ERROR"),
					icon: "error",
					color: color.Variable.warn
				});

				throw new Error(err)
			}
		}
	}

	update_detail_types(only_comments_and_documents: boolean) {
        if (!only_comments_and_documents) {
            this.DETAIL_TYPES = {...Task.Arrival_Detail_Type};
        } else {
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT];
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION];
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON];
        }
    }

	show_qr_code() {
		modal.open(PlanArrivalDialog, async (res) => {
            if (res) {

                // @HACK: Fix in curvy.
                setTimeout(() => {
                    modal.open(ConfirmArrivalDialog, () => {
                        this.arrival = null;
                        this.initialize();
                    }, this.arrival);
                }, 500);
            } else {
                this.arrival = null;
                this.initialize();
            }
        }, {
            arrival: this.arrival,
            start_time_picker: false
        });
	}

	async get_report() {
        this.got_work_order = true;
        await TaskRoutes.arrivals.api_arrival_work_order_get(this.arrival.arrival_id, true);
    }

	complete_arrival() {
		let arrival = this.arrival;
        modal.open(
            CompleteArrivalDialog,
            async (res) => {
                if (res) {
                    try {
                        let response;

                        if (arrival.status_id == Task.STATUS.CREATED) {
                            response = await TaskRoutes.arrivals.api_arrival_end_new(this.arrival.arrival_id, {
                                start_time: res.start_date,
                                end_time: res.end_date
                            }, true)
                        } else {
                            response = await TaskRoutes.arrivals.api_arrival_end(this.arrival.arrival_id, {end_time: res.end_date}, true);
                        }

                        this.arrival = response.data;
                        this.update_values();

                    } catch (error) {
                        let msg;
                        if (error.internal_error_code == 4001) {
                            msg = language.translate('ERROR.INVALID_DATE_TEXT');
                        } else {
                            msg = language.translate('ERROR.GENERIC_TEXT')
                        }
                        notification.show({
                            icon: "error",
                            color: color.Variable.warn,
                            title: language.translate('ERROR'),
                            message: msg
                        })
                    }
                }
            },
            this.arrival
        )
	}

	get_arrival_title() {
		return `#${this.arrival.arrival_id} ${this.arrival.order.supplier_name}`;
	}

	get_location() {
		return `#${this.arrival.order.location_cin ?? ''} ${this.arrival.order.location_name}`
	}

	get_arrival_tiles(arrival: Task.Arrival) {
		let tiles:{title: string, color: string}[] = [];

		if (arrival.emergency) {
			tiles.push({title: 'ARRIVAL.DETAILS.EMERGENCY_TITLE', color: 'var(--orange)'})
		}

		if (arrival.ended_without_qr) {
			tiles.push({title: 'ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE', color: 'var(--orange)'})
		}

		return tiles;
	}

	get_order_title() {
		return `#${this.order.order_id} ${this.order.title}`
	}

	get_order_description(details:Task.OrderDetail[]) {
		for (let d of details ) {

			if (d.order_detail_type_id == Task.ORDER_DETAIL_TYPE.DESCRIPTION) {
				return d.description;
			}
		}

		return undefined;
	}

	get_order_assigned() {
		let created_by = '';

		let other_authorized_mngrs = this.order.authorized_maintenance_managers;
        other_authorized_mngrs = other_authorized_mngrs.filter(u=>u.user_id !== this.order.created_by_id);

		if (this.order.created_by_id) {
			created_by = `${this.order.created_by_first_name} ${this.order.created_by_last_name}`
		}

		if (other_authorized_mngrs.length > 0) {
			 created_by += ` (`
			other_authorized_mngrs.forEach((u, i) => {
				created_by += `${u.first_name} ${u.last_name}`
				if ( i !== other_authorized_mngrs.length-1) {
					created_by += `, `
				}

			});

			created_by += `)`
		}

		return created_by;
	}

	get_order_rejection() {
		if (this.order.status_id == Task.STATUS.REJECTED && this.order.comment) {
			return `${this.order.comment}`
		}
		return undefined;
	}

	get_order_worker() {
		if (this.order.arrival_worker_id) {
			return `${this.order.arrival_worker_first_name} ${this.order.arrival_worker_last_name}`
		}
		return undefined;
	}


	order_revert_reason = () => {
		if (this.order.comment &&
			this.order.ping_pong_count > 0 &&
			this.order.status_id === Task.STATUS.ON_SUPPLIER_REVIEW
		) { return this.order.comment }
	}

	get_order_investment_type() {
		if (this.order.investment_type !== undefined && this.order.investment_type !== null) {
			return language.translate(`ORDER.INVESTMENT_TYPE.${this.itype[this.order.investment_type]}`)
		}
		return undefined;
	}

	get_cost_estimate = (order: Task.Order) => {
        if(this.order.cost_estimate) {
            return `${this.order.cost_estimate} ${this.order.cost_estimate_currency}`;
        }
    }

	get_order_tiles(order: Task.Order) {
		let tiles:{title: string, color: string}[] = [];

		if (order.complaint) {
			tiles.push({title: 'ORDER.RECLAMATION', color: 'var(--yellow-dark)'});
		}

		if (order.insurance) {
			tiles.push({title: 'ORDER.INSURANCE', color: 'var(--orange)'});
		}

        if (order.preinvoice) {
            tiles.push({title: 'ORDER.PREINVOICE', color: 'var(--orange)'});
        }

        if (order.lease) {
            tiles.push({title: 'ORDER.LEASE', color: 'var(--orange)'});
        }

        if (order.inspection) {
            tiles.push({title: 'ORDER.INSPECTION', color: 'var(--orange)'});
        }


		return tiles;
	}

	open_details_text_menu = (menu_type?: typeof MenuDetailType[keyof typeof MenuDetailType]): typeof MenuDetailType[keyof typeof MenuDetailType] => {
		switch (menu_type) {
        case MenuDetailType.WORK_ELEMENT:
            popover.open(
                PopoverMenuComponent,
                noOp,
                this.add_work_element(),
                null,
                "mouse",
                "auto"
            );
        return MenuDetailType.WORK_ELEMENT;
        default:
            popover.open(
                PopoverMenuComponent,
                noOp,
                this.get_text_details_menu(),
                null,
                "mouse",
                "auto"
            );
        	return null;
		}

	}

	undelete_detail = (detail: TaskArrivalDetailExtended) => {
		if (this.ar_details.map(d => d.arrival_detail_id).includes(detail.arrival_detail_id)) {
			// this.edited = false
			detail["is_deleted"] = false;
        }
    }

	delete_detail = (detail:TaskArrivalDetailExtended) => {
		if(this.ar_details.map(d => d.arrival_detail_id).includes(detail.arrival_detail_id) && !detail['is_edited']) {
			detail['is_deleted'] = true
			this.edited = true
		} else {
			let index = this.ar_details.indexOf(detail)
			this.ar_details.splice(index, 1)
			this.update_edited_prop()
		}
	}

	toggle_delete_detail = (detail: TaskArrivalDetailExtended) => {
		if(detail["is_deleted"]) {
			this.undelete_detail(detail)
		} else {
			this.delete_detail(detail)
		}
	}

	create_detail = (detail_type: Task.ARRIVAL_DETAIL_TYPE) => {
		this.edited = true
		this.ar_details.push(<TaskArrivalDetailExtended>{
			arrival_detail_type_id: detail_type,
			lump_sum: false,
			description: "",
			picture_url: null,
			is_edited: true,
		})
		setTimeout(() => {
			this.scroll_to_container()
		})

	}

	update_edited_prop = () => {
		this.edited = this.is_edited_detail()
		this.show_table = this.show_table_flag()
		this.cdr.detectChanges()
	}

	is_edited_detail = () => {
		for (let item of this.ar_details) {
			if (item['is_edited']) {
				return true;
			}
		}
		return false;
	}


	get_text_details_menu = () => {
		let menu = [
			{
				label: language.translate('ARRIVAL.DETAIL.WORK_DESCRIPTION'),
				icon: "edit",
				data_1: Task.ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION,
				onClick: (d) => this.create_detail(d)
			},
			{
				label: language.translate('ARRIVAL.DETAIL.WORK_COMMENTARY'),
				icon: "edit",
				data_1: Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY,
				onClick: (d) => this.create_detail(d)
			},
			{
				label: language.translate('ARRIVAL.DETAIL.WORK_UNFINISHED_REASON'),
				icon: "edit",
				data_1: Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON,
				onClick: (d) => this.create_detail(d),
			},
		];

		// allow single unfinished reason
		let unfinished_reason_exists = this.ar_details.some(d=>d.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON);

		menu = menu.filter(m => {
			if (!this.can_edit_detail_type(m.data_1)) return false;
			if ( m.data_1 == Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON && unfinished_reason_exists) return false;
			return true;
		})
		return {actions: menu};
	}

	create_table_data = (detail_type: {type: Task.ARRIVAL_DETAIL_TYPE, tab: string}) => {
		this.edited = true;
		let new_obj = {
			arrival_detail_type_id: detail_type.type,
			description: "",
			picture_url: null,
			is_edited: true,
			element_name: '',
			element_quantity: null,
			element_uom: '',
			element_currency: 'EUR',
			element_cin: '',
			work_description: null,
			element_type_id: null,
			lump_sum: false,
			element_price: null,
			tab: detail_type.tab
		} as TaskArrivalDetailExtended;

		this.ar_details.push(new_obj);
		this.show_table = this.show_table_flag();

		setTimeout(() => {
			this.scroll_to_work_element()
		})
	}


	show_table_flag = () => {
		for (let item of this.ar_details) {
			if (item.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
				return true;
			}
		}
		return false;
	}


	add_work_element = () => {
		let troskovnicka_stavka  = {type: Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT, tab: 'troskovnicka'}
		let vantroskovnicka_stavka = {type: Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT, tab: 'van_troskovnicka'}
		let menu = [
			{
				label: language.translate('ARRIVAL.DETAIL.PRICELIST_ELEMENT'),
				icon: "edit",
				data_1: troskovnicka_stavka,
				onClick: (d) => this.create_table_data(d),
			},
			{
				label: language.translate('ARRIVAL.DETAIL.NONPRICELIST_ELEMENT'),
				icon: "edit",
				data_1:  vantroskovnicka_stavka,
				onClick: (d) => this.create_table_data(d),
			}
		]
		menu = menu.filter(m => {
			if (!this.can_edit_detail_type(m.data_1["type"])) return false;
			return true
		})
		return {actions: menu};
	}

	reset_work_element(detail: TaskArrivalDetailExtended) {
		this.searchPicker = null;
		detail['element'] = null;
		detail['work_description'] = null;
		detail['element_cin'] = detail['element_type_id'] = detail['element_price'] = detail['element_total_price'] = detail['lump_sum'] = detail['element_uom'] = detail['element_quantity'] = null;
    }

    make_serach_group(filter: boolean) {
        return async(search: string)=>{
            return await this.search_group(search, filter);
        }
    }

    search_group = async (search: string, filter:boolean) => {
        let res;
        if (filter) {
            res = await TaskRoutes.pricelists.api_pricelist_search_work_groups({
                search: search,
                filter: filter
            })
        } else {
            res = await TaskRoutes.pricelists.api_pricelist_search_work_groups({
                search: search
            })
        }

        return res.data;
    }

    format_group(group:Task.WorkDescription) {
        if (!group) return '';

        return `${group.work_description}`
    }

	make_search_pricelist = (work_desc: Task.WorkDescription|null) => {
		return async (search: string) => {
			return await this.search_pricelist(search, work_desc);
		}
	}

	search_pricelist = async (search: string, work_desc: Task.WorkDescription|null) => {
        let attrs = ["name+ +cin+ +description"];
        let srch = [search];
        if (work_desc) {
            attrs.push("#=:work_description_id");
            srch.push("" + work_desc.work_description_id);
        }

        let res = await TaskRoutes.pricelists.api_pricelist_search({
            attributes: attrs,
            search: srch
        }, {
            page_no: 1,
            page_size: 100
        });
        return res.data;
    };

    format_pricelist(pricelist: Task.Pricelist) {
        if (!pricelist) return '';

        let label = pricelist.name;

        if (pricelist.cin) label += ` - (${pricelist.cin})`;
        if (pricelist.description) label += ` - (${pricelist.description})`;

        return label;
    }

	write_work_description(detail: TaskArrivalDetailExtended, el) {
        if (el) {
            detail.work_description = el.work_description;
            detail.work_description_id = el.work_description_id
        }
    }

	set_element(detail?: TaskArrivalDetailExtended, element?: Task.Pricelist) {
        detail["element_id"] = element.element_id;
        detail.element_price = element.price;
        detail.element_currency = element.currency || 'EUR';
        detail.element_uom = element.unit_of_measure;
        detail.element_quantity = 1;
        detail.lump_sum = element.lump_sum;
    }


	async upload_file(file: files.IFile, arrival_id: number, arrival_detail_type_id: number): Promise<Task.ArrivalDetail|null> {
        if (!is_dms_file(file)) {
            try {
                let uploaded  = await TaskRoutes.arrivals.api_upload_document(arrival_id, file.file, false, arrival_detail_type_id);

                notification.show({
                    icon: "check",
                    color: color.Variable.secondary,
                    title: language.translate('SUCCESS'),
                    message: language.translate('DOCUMENT.UPLOADED_TEXT')
                })
				return uploaded.data;
            } catch(error) {
                let msg;
                if (error.internal_error_code == 4107) {
                    msg = language.translate('DOCUMENT.UNSAFE_FAIL_TEXT');
                } else {
                    msg = language.translate('DOCUMENT.UPLOADED_FAIL_TEXT');
                }

                notification.show({
                    icon: 'error',
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: msg
                })
            }
        }
    }

	async upload_image(img: files.IImage) {
        if (!img.is_uploaded) {
            let res = await TaskRoutes.upload.api_upload_file(img.file);
            img.src = TaskRoutes.upload.uploaded_file_get(res.data);
            img.is_uploaded = true;
        }
        return img;
    }

	create_image_detail = (image: files.IImage) => {
        let detail: {arrival_detail_type_id: number, picture_url: string, description: string, arrival_id: number} = {
            arrival_detail_type_id: Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY,
            picture_url: image.src,
            description: image.src,
            arrival_id: this.arrival.arrival_id
        };
        return detail as Task.ArrivalDetail;
    }


	async save() {
		this.edited = false;
		try {
			let promises: Promise<any>[] = [];
			let img_req_list: Promise<any>[] = [];
			let file_req_list: Promise<any>[] = [];


			//KREIRANJA DETALJA
			for (let detail of this.ar_details) {
				if (detail['is_edited'] &&
					detail.arrival_detail_type_id !== Task.ARRIVAL_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT &&
					detail.arrival_detail_type_id !== Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT) {
					promises.push(TaskRoutes.arrivals.api_arrival_detail_add(
						this.arrival.arrival_id, detail as any, true
					));
				}
			}

			//ARRIVAL_IMAGES
			for(let image of this.arrival_images) {
				if (!image.is_uploaded) {
					img_req_list.push(
						this.upload_image(image).then(uploadedImage => {
							return this.create_image_detail(uploadedImage);
						})
					);
				}
			}

			const all_images = await Promise.all(img_req_list);

			for(let detail_image of all_images) {
				promises.push(TaskRoutes.arrivals.api_arrival_detail_add(
					this.arrival.arrival_id, detail_image as any, true
				));
			}

			this.ar_details.forEach(ad => {
				if (ad.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY && ad.picture_url != null) {
					if (!this.arrival_images.some(image => image.src === ad.picture_url)) {
						ad.is_deleted = true;
					}
				}

				if(ad.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT && ad.dms_document_file_name !== null) {
					if(!this.arrival_files.some(file => file['dms_document_file_name'] === ad.dms_document_file_name)){
						ad.is_deleted = true
					}
				}
			});

			//ARRIVAL_FILES
			for(let file of this.arrival_files) {
				if(!file['is_uploaded']) {
					file_req_list.push(this.upload_file(file as any, this.arrival.arrival_id, Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT))
				}
			}

			const all_files = await Promise.all(file_req_list)


			//BRISANJE
			let to_delete = this.ar_details.filter((d: TaskArrivalDetailExtended) => d['is_deleted'])

			for (let detail of to_delete) {
				if (detail.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
					detail.element_quantity = 0;
					promises.push(TaskRoutes.arrivals.api_arrival_detail_modify(
						detail.arrival_detail_id,
						detail
					));
					console.log('work-element-delete', detail)
				} else {
					promises.push(TaskRoutes.arrivals.api_arrival_detail_delete(detail.arrival_detail_id));
				}
				// promises.push(TaskRoutes.arrivals.api_arrival_detail_delete(detail.arrival_detail_id));
			}


			await progress.listen('edit-arrival-details-save', Promise.all(promises));
			// await Promise.all(promises);

			let p = TaskRoutes.arrivals.api_arrival_get_single(this.arrival.arrival_id, true);
			progress.listen("edit-arrival-details-save", p);

			this.arrival = (await p).data;
			this.cdr.detectChanges();

		} catch (error) {
			console.error(error.message);
		}

		this.update_values();
	}


	cancel = () => {
		this.edited = false
		this.update_values()
	}

	admin_delete_arrival() {
		modal.open(
			TextBoxDialog,
			async (reason) => {
				if (!reason) return;
				await TaskRoutes.arrivals.api_arrival_admin_delete(this.arrival.arrival_id, {reason});
				let res = await TaskRoutes.arrivals.api_arrival_get_single(this.arrival.arrival_id, true);
				this.arrival = res.data;
				this.update_values();
			}, {
				title: language.translate("ARRIVAL.DELETE.TITLE"),
				message: {
					message: language.translate("ARRIVAL.DELETE.MSG", this.arrival.arrival_id),
					icon: "today",
					iconColor: color.variable(color.Variable.warn)
				},
				label: "ARRIVAL.DELETE.REASON",
				placeholder: "ARRIVAL.DELETE.ENTER_REASON",
				required: true,
				buttonColor: color.variable(color.Variable.warn),
				confirmText: "DELETE"
			}
		);
    }


	async delete_arrival() {
		modal.open(
			ConfirmDialogComponent,
			async (res) => {
				if (res) {
					await Arrival.remove(this.arrival.arrival_id)
					utils.router.navigateByUrl("/arrivals");
				}
			},
			{
				title: language.translate("ARRIVAL.DELETE.TITLE"),
				message: language.translate("ARRIVAL.DELETE.MSG", this.arrival.arrival_id),
				icon: "today",
				iconColor: color.variable(color.Variable.warn)
			}
		);
    }

	get_title(detail: Task.ArrivalDetail) {
		if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
			if (detail.element_name && detail.element_name.length > 0) {
				if (detail.element_cin && detail.element_cin.length > 0) {
					return detail.element_name + "(" + detail.element_cin + ")";
				}
				return detail.element_name;
			}
		}
		return language.translate("ARRIVAL.DETAIL." + Task.Arrival_Detail_Type[detail.arrival_detail_type_id]);
    }

	reason_for_unfinished_work_flag = (detail: Task.ArrivalDetail) => {
		const detailKeys: string[] = ['element_name', 'element_quantity', 'element_uom', 'element_price', 'element_currency'];
		const valid = detailKeys.every((key: string) => detail[key] !== null)
		if(valid) {
			return true
		}
		return false
	}

	work_el_is_deleted(detail: TaskArrivalDetailExtended):'soft' | 'hard' {
		if (detail['is_deleted']) {
			return 'soft';
		} else if (detail.element_quantity ==0) return 'hard';

	}

	scroll_to_container = () => {
		const container = this.container?.nativeElement;
		if (container && container !== undefined) {
			container.scrollTop = container.scrollHeight;
		}
	}

	scroll_to_work_element = () => {
		const work_element_container = this.work_element?.nativeElement;
		if (work_element_container && work_element_container !== undefined) {
			work_element_container.scrollTop = work_element_container.scrollHeight;
		}
	}

	show_work_element_type = (detail_type: Task.ELEMENT_TYPE) => {
		switch(detail_type) {
			case Task.ELEMENT_TYPE.WORKING_HOUR:
				return language.translate(`PRICELIST.TYPE.${Task.Element_Type[1]}`);
			case Task.ELEMENT_TYPE.MATERIAL:
				return language.translate(`PRICELIST.TYPE.${Task.Element_Type[2]}`);
			case Task.ELEMENT_TYPE.TRANSPORTATION:
				return language.translate(`PRICELIST.TYPE.${Task.Element_Type[3]}`);
			case Task.ELEMENT_TYPE.FIELD_VISIT:
				return language.translate(`PRICELIST.TYPE.${Task.Element_Type[4]}`);
			case Task.ELEMENT_TYPE.MECHANICAL_WORKS:
				return language.translate(`PRICELIST.TYPE.${Task.Element_Type[5]}`);
			default:
				return '-';
		}
   }
}
