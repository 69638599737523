import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { Frame, language } from 'curvy';
import { files } from '@task-utils/utils';
import { questionnaires_translations } from './questionnaires.trans';

export type Answer_Type = string | files.IImage[] | files.IFile[] |  boolean | Task.KAM_Inventory[];

@Component({
    selector: 'task-answer-questionnaire',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './answer-questionnaire.component.html',
    styleUrls: ['./answer-questionnaire.component.scss']
})
export class AnswerQuestionnaireComponent {
    @Input('location-cin')
    location_cin: string = null;

    @Input()
    questionnaire: Task.Questionnaire;

    @Input()
    is_required = false;

    @Output()
    answer = new EventEmitter<Answer_Type>();

    bool_answer: boolean = null;

    constructor() {
        language.load_translations(questionnaires_translations);
    }

    got_images(images: files.IImage[]) {
        this.answer.emit(images);
    }

    got_files(files: files.IFile[]) {
        this.answer.emit(files);
    }

    got_text(text: string) {
        this.answer.emit(text);
    }

    got_bool(bool: boolean) {
        this.bool_answer = bool;
        this.answer.emit(bool);
    }

    got_inventory(inv: Task.KAM_Inventory[]) {
        this.answer.emit(inv);
    }
}
