import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { language, notification, color, AutoSearchComponent } from 'curvy';
import { reports_translations } from './reports.trans';

@Component({
	selector: 'task-report-inventory',
	template: `
		<h2 class="">{{ 'REPORTS.INVENTORY_EXPENSE_TITLE' | trans }}</h2>
		<div class="flex-row wrappable">
			<h5 class="field-group-title full-width">{{ 'NAVROUTE.WORKHIER' | trans }}:</h5>
			<div class="flex-row wrappable hier-group-width">

				<knw-auto-search
					#catsearch
					[allow-null]="true"
					[function]="search(categories)"
					[to-label]="format_whier"
					[popover-width]="'auto'"
					label="Kategorija"
					[classes]="'filled flex-dynamic'">
				</knw-auto-search>

				<knw-auto-search
					#subcatsearch
					[function]="search(subcategories)"
					[to-label]="format_whier"
					label="Podkategorija"
					[popover-width]="'auto'"
					[allow-null]="true"
					[classes]="'filled flex-dynamic'">
				</knw-auto-search>

				<knw-auto-search
					#groupsearch
					[function]="search(groups)"
					[to-label]="format_whier"
					label="Grupa"
					[popover-width]="'auto'"
					[allow-null]="true"
					[classes]="'filled flex-dynamic'">
				</knw-auto-search>

			</div>

			<span class="full-width"></span>

			<knw-date-picker-input
				[(ngModel)]="report.dateFrom_inventory"
				[classes]="'filled flex-dynamic'"
				[label]="'STARTDATE'| trans">
			</knw-date-picker-input>

			<knw-date-picker-input
				[(ngModel)]="report.dateTo_inventory"
				[classes]="'filled flex-dynamic'"
				[label]="'ENDDATE'| trans">
			</knw-date-picker-input>
			<knw-auto-search
					[function]="search_locations"
					[to-label]="format_location_name"
					[(ngModel)]="report.picked_location_inventory"
					label="{{ 'LOCATION' | trans }}"
					[allow-null]="true"
					[popover-width]="'auto'"
					[classes]="'filled flex-dynamic'">
			</knw-auto-search>
			<knw-auto-search
					[function]="search_suppliers"
					[to-label]="format_supplier_name"
					[(ngModel)]="report.picked_supplier_inventory"
					label="{{ 'REPORTS.SUPPLIER' | trans }}"
					[allow-null]="true"
					[popover-width]="'auto'"
					[classes]="'filled flex-dynamic'">
			</knw-auto-search>
		</div>
		<div class="report-btn-wrapper">
			<button class="filled pill report-btn"
				(click)="get_inventory_expense_report()"
				*ngIf="!loading; else isLoading">
				<i icon="download"></i>
				<span>{{ 'REPORTS.DOWNLOAD' | trans }}</span>
			</button>

			<ng-template #isLoading>
				<knw-spinner color="primary" size="50"></knw-spinner>
			</ng-template>
		</div>
		`,
	styleUrls: ['./reports.component.scss'],
	encapsulation: ViewEncapsulation.None,

})

export class ReportInventoryComponent implements OnInit, AfterViewInit {
	@ViewChild('catsearch')
	catSearch: AutoSearchComponent;

	@ViewChild('subcatsearch')
	subcatSearch: AutoSearchComponent;

	@ViewChild('groupsearch')
	groupSearch: AutoSearchComponent;

	 report = {
		dateFrom_inventory: null as Date,
		dateTo_inventory: null as Date,
		picked_location_inventory: null as Task.Location,
		picked_supplier_inventory: null as Task.Supplier,
	};

	categories:string[] = [];
	subcategories:string[] = [];
	groups:string[] = [];

	loading = false;

	ngOnInit() {
		language.load_translations(reports_translations);
		this.getAllHierarchies();
	}

	ngAfterViewInit() {
		console.log(this.catSearch);
	}

	async getAllHierarchies() {
		let {data:res} = await TaskRoutes.work_hierarchies.api_work_hierarchy_get_all({page_no: 1, page_size: 1000});

		this.categories = res.filter(cat=>cat.level == 1).map(cat=>cat.description);
		this.subcategories = res.filter(cat=>cat.level == 2).map(cat=>cat.description);
		this.groups = res.filter(cat=>cat.level == 3).map(cat=>cat.description);

	}

	async search_locations(search: string) {
		let res = await TaskRoutes.locations.api_location_search_visible({ search: [search], attributes: ["cin+ +name"] });
		return res.data;
	}

	async search_suppliers(search: string) {
		let res = await TaskRoutes.suppliers.api_supplier_search({ search: [search], attributes: ["company.name"] }, {page_no: 1, page_size: 15});
		return res.data;
	}

	search(arr: any[]) {
		return async (search: string) => {
			search = search.toLowerCase();
			return arr.filter(item => item.toLowerCase().includes(search));
		}
	}

	format_whier(hier: Task.WorkHierarchy) {
		if (!hier) return "";
		return `${hier}`;
	}

	format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

	format_supplier_name(sup: Task.Supplier) {
		if (!sup) { return ""; }
		return `${sup.name}`;
	}

	getData() {
		let parsed_filter = { search: [], attributes: []};

		if (this.report.picked_location_inventory) {
			parsed_filter.search.push(this.report.picked_location_inventory.location_id)
			parsed_filter.attributes.push("#=:order.location_id")
		}

		if (this.report.picked_supplier_inventory) {
			parsed_filter.search.push(this.report.picked_supplier_inventory.supplier_id)
			parsed_filter.attributes.push("#=:order.supplier_id")
		}

		if (this.report.dateFrom_inventory) {
			parsed_filter.search.push(this.report.dateFrom_inventory.toISOString())
			parsed_filter.attributes.push("-d:order.created_date");
		}

		if (this.report.dateTo_inventory) {
			parsed_filter.search.push(this.report.dateTo_inventory.toISOString())
			parsed_filter.attributes.push("+d:order.created_date");
		}

		// if (this.catSearch.search_value) {
		// 	parsed_filter.search.push(this.catSearch.search_value);
		// 	parsed_filter.attributes.push("work_hierarchy.category_name+ +work_hierarchy.category_description")
		// }

		// if (this.subcatSearch.search_value) {
		// 	parsed_filter.search.push(this.subcatSearch.search_value);
		// 	parsed_filter.attributes.push("work_hierarchy.subcategory_name+ +work_hierarchy.subcategory_description")
		// }

		// if (this.groupSearch.search_value) {
		// 	parsed_filter.search.push(this.groupSearch.search_value);
		// 	parsed_filter.attributes.push("work_hierarchy.group_name+ +work_hierarchy.group_description")
		// }

		return parsed_filter;
	}

	async get_inventory_expense_report() {
		try {
			this.loading = true;
			await TaskRoutes.reports.api_inventory_by_costs_report_get(this.getData());
		} catch {
			notification.show({
				icon: "report_problem",
				color: color.Variable.warn,
				title: language.translate("REPORTS.NO_DATA_TITLE"),
				message: language.translate("REPORTS.NO_DATA_DESCR")
			});
		}
		this.loading = false;
	}

}
