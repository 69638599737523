
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { ScheduledOrderType } from '../orders.types';




@Component({
  selector: 'task-edit-scheduled-order-dialog',
  encapsulation: ViewEncapsulation.None,
  template: `
    <task-scheduled-order-approved-form [order]="sch_order" form-collector #scheduledOrderApprovedForm="collector"></task-scheduled-order-approved-form>
    <div class="flex-row modal-footer">
		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" (click)="done()" [disabled]="scheduledOrderApprovedForm.containsErrors()">
			<i icon="check"></i>
			{{ 'SAVE' | trans }}
		</button>

	</div>
    `,
    styles: [`

    .modal task-edit-scheduled-order-dialog {
        max-width: 620px;
        box-sizing: border-box;
        padding-bottom: 70px;
        position: relative;
    }
    `]
})
export class EditScheduledOrderApprovedDialog extends ModalBase<ScheduledOrderType | Promise<ScheduledOrderType>, Task.ScheduledOrder> implements OnInit {
    sch_order: ScheduledOrderType;
    preventSoftClose = true;
    allowSubmit = true;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => this.sch_order = res)
        } else {
            this.sch_order = this.injectedData;
        }
        language.load_translations(orders_translations);
    }

    async done() {
        if(this.sch_order) {
            let data = {
                ...this.sch_order,
                arrival_worker:
                    this.sch_order.arrival_worker_id ??
                        this.sch_order.arrival_worker.user_id
            };
            let res = await TaskRoutes.scheduled_orders.api_scheduled_order_modify(
                this.sch_order.scheduled_order_id, data as any
            );
            this.submit(res.data);
        } else {
            this.submit(null);
        }
    }

    cancel() {
        this.submit(null);
    }
}
