import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { date, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { get_dates, last_day_of_year } from '@task-utils/calculate_dates';

interface dateModal {
	annual_frequency: Task.ANNUAL_FREQUENCY,
	start_date: Date,
	end_date: Date,
	cut_date: Date,
}

@Component({
	selector: 'task-scheduled-dates',
	template: `
		<div class="flex-row" style="gap: .8em; width: 100%; align-items: flex-start;">
			<span>
				<strong style="display: inline-block; margin-bottom: .2em;">{{ 'ORDER.SCHEDULED.DATES_TO_CREATE' | trans}}: </strong><br>
				<i>({{ 'ORDER.SCHEDULED.YEARLY_PLAN.'+ annual_value | trans }})</i>
			</span>
			<div class="flex-column" style="max-height: 260px; flex: 1 0 auto; box-sizing: border-box; overflow-x: hidden; overflow-y: auto;">
				<div *ngFor="let span of date_spans; let i = index;" class="flex-row align-center" style="gap: .3em; padding-right: 0.2em;">
					<strong style="display: inline-block; margin-right: 0.2em;">
						<ng-container *ngIf="i<9">0</ng-container><span>{{i+1}}.</span>
					</strong>
					<i>{{ span.start | datefmt : "short_date" }}</i>
					<ng-container *ngIf="span.start !== span.end">
						-&nbsp;
						<i>{{ span.end | datefmt : "short_date" }}</i>
					</ng-container>
				</div>
			</div>

		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	styles: ['']
})


export class ScheduledDates extends ModalBase<dateModal> implements OnInit {
	// @Input()
	// order: Task.ScheduledEvent;

	@Input()
	annual_frequency: Task.ANNUAL_FREQUENCY;

	@Input()
	start_date: Date;

	@Input()
	end_date: Date;

	@Input()
	cut_date: Date;

	date_spans: date.Range[] = null;
	annual_value = '';

	ngOnInit() {
		if (this.injectedData) {
			this.start_date = this.injectedData.start_date;
			this.end_date = this.injectedData.end_date;
			this.cut_date = this.injectedData.cut_date;
			this.annual_frequency = this.injectedData.annual_frequency;
		}

		this.date_spans = get_dates(
			this.annual_frequency,
			{
				start: new Date(this.start_date),
				end: new Date(this.end_date)
			},
			this.cut_date ? new Date(this.cut_date) : last_day_of_year(new Date(this.end_date))
		);
		this.annual_value = Task.Annual_Frequency[this.annual_frequency].toUpperCase();
	}
}

