import { Task } from "@task-utils/types";
import { date } from "curvy";

type YMD = Pick<date.DateSegments, "year" | "date" | "month">
type YMD_RANGE = {start: YMD, end: YMD};

function days_in_month_fixed(d: YMD) {
	return new Date(d.year, d.month, 0, 12).getUTCDate();
}

function add_months(d: YMD, months: number) {
	let next_month = d.month + months - 1;
	d.year += Math.floor(next_month / 12);
	d.month = (next_month % 12) + 1;
}

function range_add_months(d: YMD_RANGE, months: number) {
	add_months(d.start, months);
	add_months(d.end, months);
}

/*function is_weekend(date: Date): boolean {
	let day_of_week = date.getDay();
	return (day_of_week === 6 || day_of_week === 0);
}*/

function clamp_day(d: YMD, target_day: number) {
	d.date = Math.min(target_day, days_in_month_fixed(d));
	return d;
}

function range_clamp_day(d: YMD_RANGE, target: YMD_RANGE) {
	clamp_day(d.start, target.start.date);
	clamp_day(d.end, target.end.date);
}

function range_add(d: YMD_RANGE, delta: Date) {
	let r = range_apply(d, obj_to_date);
	r.start = date.add(r.start, delta);
	r.end = date.add(r.end, delta);
	let dd = range_apply(r, date_to_obj);
	d.start = dd.start;
	d.end = dd.end;
}

function date_to_obj(d: Date): YMD {
	let out = date.parts(d);
	out.hour = 12;
	out.month += 1;
	return out;
}

function obj_to_date(d: YMD) {
	d = {...d, month: d.month-1};
	return date.create(d as date.DateSegments);
}

function date_is_after(d: YMD, cut_date: YMD) {
	if (d.year > cut_date.year) return true;
	if (d.year < cut_date.year) return false;
	if (d.month > cut_date.month) return true;
	if (d.month < cut_date.month) return false;
	if (d.date > cut_date.date) return true;
	if (d.date < cut_date.date) return false;
	return false;
}

function range_apply<IN, OUT>(
	range: {start: IN, end: IN},
	operation: (arg: IN) => OUT,
): {start: OUT, end: OUT} {
	return {
		start: operation(range.start),
		end: operation(range.end),
	};
}

export function last_day_of_year(d: Date) {
	return date.create({
		year: d.getUTCFullYear(),
		month: 11,
		date: 31,
		hour: 12,
		minute: 0,
		second: 0,
		millisecond: 0,
	});
}

const FREQ_REPETITIONS = {
	[Task.ANNUAL_FREQUENCY.YEARLY]: 1,
	[Task.ANNUAL_FREQUENCY.HALF_YEARLY]: 2,
	[Task.ANNUAL_FREQUENCY.QUARTERLY]: 4,
	[Task.ANNUAL_FREQUENCY.MONTHLY]: 12,
	[Task.ANNUAL_FREQUENCY.WEEKLY]: 52,
	[Task.ANNUAL_FREQUENCY.DAILY]: 365,
} as const;

const FREQ_STEP = {
	[Task.ANNUAL_FREQUENCY.WEEKLY]: 7,
	[Task.ANNUAL_FREQUENCY.DAILY]: 1,
} as const;

export function get_dates(
	freq: Task.ANNUAL_FREQUENCY,
	initial_dates: date.Range,
	cut_date: Date|YMD
): date.Range[] {
	cut_date = cut_date instanceof Date ? date_to_obj(cut_date) : cut_date;
	let initial = range_apply(initial_dates, date_to_obj);
	let current = range_apply(initial_dates, date_to_obj);
	let dates: date.Range[] = [];

	let reps = FREQ_REPETITIONS[freq];

	switch (freq) {
		case Task.ANNUAL_FREQUENCY.YEARLY:
		case Task.ANNUAL_FREQUENCY.HALF_YEARLY:
		case Task.ANNUAL_FREQUENCY.QUARTERLY:
		case Task.ANNUAL_FREQUENCY.MONTHLY: {
			let step = Math.floor(12 / reps);

			for (let i=0; i<reps; i++) {
				if (date_is_after(current.start, cut_date)) break;
				dates.push(range_apply(current, obj_to_date));
				range_add_months(current, step);
				range_clamp_day(current, initial);
			}
		} break;

		case Task.ANNUAL_FREQUENCY.WEEKLY:
		case Task.ANNUAL_FREQUENCY.DAILY: {
			let delta = date.duration({date: FREQ_STEP[freq]})

			for (let i=0; i<reps; i++) {
				if (date_is_after(current.start, cut_date)) break;
				dates.push(range_apply(current, obj_to_date));
				range_add(current, delta);
			}
		} break;
	}

	return dates;
}
