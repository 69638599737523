import { Attribute, Component, OnInit, ViewChild } from '@angular/core';
import { color, network, language, ModalBase, notification, date } from 'curvy';
import { Task } from '@task-utils/types';
import { User } from './users';
import { TaskRoutes } from '@task-utils/routes';
import { AccordionListComponent } from '@task-components/task-accordion-list.comopnent';


@Component({
    selector: 'task-user-takeover-dialog',
    template: `
        <div>
            <h2 class="modal-title" style="margin-bottom: 0.7em;">{{'USER.TAKE_OVER_LOCATIONS' | trans }} <span *ngIf="show_name"> - {{user.first_name}} {{ user.last_name }}</span></h2>
            <div form-collector #takeoverForm="collector" class="flex-column">
                <p *ngIf="is_admin" class="min-content">{{ 'USER.TAKE_OVER.DESCRIPTION_ADMIN' | trans : user.first_name : user.last_name }}</p>
                <p *ngIf="!is_admin" class="min-content">{{ 'USER.TAKE_OVER.DESCRIPTION' | trans }}</p>

                <knw-auto-search
                    required
                    [function]="search_users"
                    [to-label]="format_user"
                    label="{{ 'USER.USER_TO_REPLACE' | trans }}"
                    [(ngModel)]="searched_user"
                    classes="outlined">
                </knw-auto-search>
                <knw-date-picker-input
                    style="margin-left: 0; margin-right: 0"
                    [required]="true"
                    [min-date]="today"
                    [(ngModel)]="end_date"
                    [classes]="'outlined flex-dynamic'"
                    [label]="'USER.END_DATE' | trans">

                </knw-date-picker-input>

                <task-accordion-list [fn]="get_history_takeovers" style="width: 380px;" >
                    <h3 summary style="margin-bottom: 0; font-size: .89em;" class="uppercase">{{'USER.SHOW_HISTORY' | trans }}</h3>

                    <ng-template let-el>
                        <div li_element class="list-el flex-row align-center justify-space-between">
                            <i [style.color]="can_cancel(el.status_id) ? 'rgb(var(--secondary)' : 'transparent'" icon="circle" style="font-size: .5em;"></i>
                            <span>{{el.replaced_user_first_name}} {{el.replaced_user_last_name}}</span>
                            <div class="flex-group">
                                <span>{{el.start_date | datefmt: 'short_date' }}</span>
                                <i icon="arrow_right_alt"></i>
                                <span>{{el.end_date | datefmt: 'short_date' }}</span>
                            </div>
                            <button [disabled]="!can_cancel(el.status_id)" class="round" title="{{ 'ABORT' | trans }}" (click)="cancel_takeover(el.user_takeover_history_id, el.replaced_user_first_name, el.replaced_user_last_name)" color="warn"><i icon="cancel" ></i></button>
                        </div>
                    </ng-template>
                </task-accordion-list>

                <div class="flex-row flex-static justify-end modal-footer" style="margin-top: 1.5em;" >
                    <button color="primary"
                        (click)="cancel()">
                        <i icon="clear"></i>
                        <span>{{ 'CLOSE' | trans }}</span>
                    </button>
                    <button class="filled" color="secondary" (click)="handle_result()" [disabled]="takeoverForm.containsErrors()" >
                        <i icon="check"></i>
                        <span>{{ 'SAVE' | trans }}</span>
                    </button>
                </div>


            </div>
        </div>
    `,
    styles: [`
        .flex-group {
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: auto;
            gap: .3em;
        }

        .min-content {
            width: min-content;
            min-width: 100%;
        }

        .list-el {
            width: 100%;
            padding-left: 10px;
        }

        .list-el > i {
            transform: translateX(-70%);
        }

        .list-el:hover {
            background-color: rgba(var(--background-dark), 0.3);
        }
    `]
})
export class UserTakeoverDialog extends ModalBase<Task.User, boolean> implements OnInit {
    @ViewChild(AccordionListComponent)
    accordion: AccordionListComponent;

    preventSoftClose = true;
    searched_user: Task.User;
    end_date: Date;
    today = date.start_of_day(new Date());
    show_name = false;
    is_admin = false;

    user: Task.User;

    ngOnInit() {
        this.init();
    }

    async init() {
        this.user = this.injectedData;

        this.show_name = (await User.currentUserPromise).user_id !== this.user.user_id;
        this.is_admin = await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN);
        // console.log(this.is_admin)
    }

    can_cancel(status: Task.STATUS) {

        if (status == Task.STATUS.CREATED) return true;
        else if (status == Task.STATUS.EXECUTED) return false;
    }

    search_users = async (search: string | null) => {
        search = search || "";
        let user_type;

        if (this.user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER) {
            user_type = Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER + '';
        } else if (this.user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) {
            user_type = Task.USER_TYPE.SAFETY_MANAGER + '';
        } else if (this.user.user_type_id === Task.USER_TYPE.REGIONAL_RETAIL_MANAGER) {
            user_type = Task.USER_TYPE.REGIONAL_RETAIL_MANAGER + '';
        } else if (this.user.user_type_id === Task.USER_TYPE.COMPANY_WORKER) {
            user_type = Task.USER_TYPE.COMPANY_WORKER + '';
        }

        let res = await TaskRoutes.users.api_user_search({
            attributes: ['#=:user_type_id', '#!:user_id', "first_name+ +last_name+ +email"],
            search: [user_type, this.user.user_id, search]
        })

        return res.data;
    }

    format_user(user: Task.User) {
        if (!user) return;

        return `${user.first_name} ${user.last_name}`;
    }

    cancel() {
        this.submit(false);
    }

    get_history_takeovers = async (paginator: network.Headers.Paginator) => {
        return await TaskRoutes.users.api_user_take_over_history_search({
            attributes: ["#=:user_id"],
            search: [`${this.user.user_id}`],
            sort: {
                attribute: "d:start_date",
                direction: "desc"
            }
        }, paginator)
    }

    async cancel_takeover(id:number, first_name, last_name) {
        try{
            await TaskRoutes.users.api_user_take_over_cancel(id);
            this.submit(true);
            this.accordion.get_data();

            notification.show({
                icon: 'check',
                color: color.Variable.secondary,
                title: language.translate('USER.TAKOVER_SUCC_TITLE'),
                message: language.translate('USER.TAKOVER.CANCEL.SUCC_DESC', first_name, last_name)
            });
            notification.set_default_timeout(10000);

        } catch {
            notification.show({
                icon: 'error',
                color: color.Variable.warn,
                title: language.translate('USER.TAKEOVER.CANCEL.ERROR_TITLE'),
                message: ""
            })
        }

    }

    async handle_result() {
        try {
            await TaskRoutes.users.api_take_over_user_locations({
                original_user_id: this.searched_user.user_id,
                expiration_date: this.end_date,
                user_id: this.user.user_id
            });
            this.submit(true);
            notification.show({
                icon: 'check',
                color: color.Variable.secondary,
                title: language.translate('USER.TAKOVER_SUCC_TITLE'),
                message: language.translate('USER.TAKOVER_SUCC_DESC', this.searched_user.first_name, this.searched_user.last_name, this.end_date)
            });
            notification.set_default_timeout(10000);

        } catch {
            notification.show({
                icon: 'error',
                color: color.Variable.warn,
                title: language.translate('USER.TAKEOVER_ERROR_TITLE'),
                message: language.translate('USER.TAKEOVER_ERROR_DESC')
            })
        }
    }
}
