<div class="flex-column fullheight-noscroll">
   <h2 class="modal-title">{{ (is_edit_form ? 'CHECKLISTS.EDIT_Q_TITLE' : 'CHECKLISTS.NEW_Q_TITLE') | trans }}</h2>

   <div style="overflow: auto;">
      <div class="field-grid" form-collector #questionForm="collector">

         <label class="input outlined" [title]="'CHECKLISTS.Q_NAME'|trans" >
            <input type="text"
               [(ngModel)]="question.name"
               [required]="true">
         </label>

         <label class="input outlined" [title]="'CHECKLISTS.Q_DESCRIPTION'|trans" >
            <textarea type="text"
               rows="5"
               [(ngModel)]="question.description"
               [required]="true"></textarea>
         </label>


         <span style="
            margin: .5em 0 .3em;
            grid-column: 1 / -1;
         ">
            *{{ "CHECKLISTS.DISCLAMER" | trans }}
         </span>

         <knw-auto-search
            [function]="search_locations"
            [to-label]="format_location_name"
            label="{{ 'LOCATION' | trans }}"
            [multi]="true"
            [popover-width]="'auto'"
            [(ngModel)]="question.locations"
            (ngModelChange)="update_data()"
            [required]="!question.location_groups.length">
         </knw-auto-search>

         <knw-auto-search
            [function]="search_location_groups"
            [to-label]="format_location_group_name"
            label="{{ 'NAVROUTE.LOCATION_GROUPS' | trans }}"
            [(ngModel)]="question.location_groups"
            (ngModelChange)="update_data()"
            [multi]="true"
            [popover-width]="'auto'"
            [required]="!question.locations.length">
         </knw-auto-search>


         <task-whier-picker required
            [(ngModel)]="question.whier"
            (ngModelChange)="select_whier_id($event)"
         >
         </task-whier-picker>

         <label class="input outlined" title="{{ 'ORDER.SCHEDULED.YEALRY_PLAN_SELECT' | trans }}" style="box-sizing: border-box;">
            <select required [(ngModel)]="question.frequency">
               <option *ngFor="let plan of yearly_plans | keyvalue" [ngValue]="+plan.key">
                  {{ ("ORDER.SCHEDULED.YEARLY_PLAN." + plan.value) | trans }}
               </option>
            </select>
         </label>
      </div>
   </div>

   <div class="flex-row modal-footer">
			<button color="primary"
					(click)="cancel()">
				<i icon="clear"></i>
				<span>{{ 'CANCEL' | trans }}</span>
			</button>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="questionForm.containsErrors()">
				<i icon="check"></i>
				<span>{{ 'SAVE' | trans }}</span>
			</button>

		</div>
</div>