<ng-container *ngIf="order">

<div class="order-header">
    <div class="flex-row" style="margin-bottom: 0.25em">
        <div class="flex-row align-flex-start">
            <i [attr.icon]="icon" [style.color]="color"></i>
            <h2 style="margin: 0 0 2em 0.5ch">#{{order.scheduled_order_id}} {{order.title}}</h2>
        </div>

        <div class="flex-dynamic"></div>

        <i style="align-self: center">
            {{ order.created_date | datefmt }}
        </i>
    </div>


    <div class="flex-column" style="margin-bottom: 1em; grid-gap: .25em;" >
        <span *ngIf="order.investment_type != undefined">
            <b>{{ 'ORDER.INVESTMENT_TYPE' | trans }}:&nbsp;</b>
            {{ 'ORDER.INVESTMENT_TYPE.' + itype[order.investment_type] | trans }}
        </span>
     
    </div>

</div>

<div class="order-details" *ngIf="description_details.length>0 || inventory_details.length >0 || images.length>0">
    <h4 style="margin-bottom: 0.5em;">{{ 'DETAILS' | trans }}</h4>
    <div class="order-details-list">
        <!-- DESCRIPTIONS -->
        <div *ngFor="let detail of description_details" style="margin-bottom: 1.5em;">
            <div class="flex-row">
                <div class="flex-dynamic">
                    {{detail.description}}
                </div>
            </div>
        </div>
   

        <!-- INVENTORY -->
        <ng-container *ngFor="let detail of inventory_details">
            <task-kam-inventory *ngIf="detail.inventory_data"
                    [inventory]="detail.inventory_data">
            </task-kam-inventory>
        </ng-container>

        <div style="margin: 2em 0 1.5em;">
            <p *ngIf="images.length>0">
                <i><b>{{ 'TICKET.IMAGES' | trans }}</b></i>
            </p>
            <task-multi-image
                [(ngModel)]="images"
                [allow-deleting-uploaded]="false"
                [disabled]="true">
            </task-multi-image>
        </div>

    </div>
</div>


</ng-container>
