import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users';
import { language, notification, color, date } from 'curvy';
import { reports_translations } from './reports.trans';

@Component({
    selector: 'task-report-freon',
    template: `
        <h2 class="">{{ 'REPORTS.FREON_TITLE' | trans }}</h2>
        <div class="flex-row wrappable">

            <label class="input filled" [title]="'MONTH' | trans" style="min-width: 7em; max-width: 7em">
                <select [(ngModel)]="selectedMonth">
                    <option *ngFor="let month of months; let i = index" [ngValue]="i">
                        {{ month }}
                    </option>
                </select>
            </label>
            <label class="input filled" [title]="'YEAR' | trans">
                <input [(ngModel)]="selectedYear" style="width: 3em;" type="number" integer />
            </label>

            <knw-auto-search *ngIf="!is_reg_mnt_mng"
                    [function]="search_suppliers"
                    [to-label]="format_supplier_name"
                    [(ngModel)]="report.picked_supplier"
					[popover-width]="'auto'"
                    label="{{ 'REPORTS.SUPPLIER' | trans }}"
                    [allow-null]="true"
                    [classes]="'filled flex-dynamic'">
            </knw-auto-search>
        </div>
        <div class="report-btn-wrapper">
            <button class="filled pill report-btn"
                (click)="get_freon_report()"
                *ngIf="!loading; else isLoading">
                <i icon="download"></i>
				<span>{{ 'REPORTS.DOWNLOAD' | trans }}</span>
            </button>

            <ng-template #isLoading>
				<knw-spinner color="primary" size="50"></knw-spinner>
			</ng-template>
        </div>
		`,
    styleUrls: ['./reports.component.scss'],
    encapsulation: ViewEncapsulation.None,

})

export class ReportFreonComponent implements OnInit {
    report = {
        month: null as Date,
        year: null as Date,
        picked_supplier: null as Task.Supplier,
    };
    selectedYear: number = new Date().getUTCFullYear();
    selectedMonth: number = new Date().getUTCMonth();
    months = language.get_months();
    is_reg_mnt_mng = false;

	loading = false;

    ngOnInit() {
        this.init();
    }

    async init() {
        language.load_translations(reports_translations);
        this.is_reg_mnt_mng = await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER)
    }

    async search_suppliers(search: string) {
        let res = await TaskRoutes.suppliers.api_supplier_search({ search: [search], attributes: ["company.name"] }, {page_no: 1, page_size: 15});
        return res.data;
    }

    format_supplier_name(sup: Task.Supplier) {
        if (!sup) { return ""; }
        return `${sup.name}`;
    }

    getData() {
        let data = { year: this.selectedYear, month: this.selectedMonth+1, supplier_id: this.report.picked_supplier.supplier_id };

        return data;
    }

    async get_freon_report() {
        try {
            this.loading = true;
            await TaskRoutes.reports.api_monthly_invoice_proposal_get(this.getData(), true);
        } catch {
            notification.show({
                icon: "report_problem",
                color: color.Variable.warn,
                title: language.translate("REPORTS.NO_DATA_TITLE"),
                message: language.translate("REPORTS.NO_DATA_DESCR")
            });
        }
        this.loading = false;
    }

}
