<div class="main-page-wrapper" *ngIf="order">
    <!-- Order header section -->
    <header class="order-header" style="padding: 0  0 1em 0;">
        <div class="flex-row flex-dynamic justify-flex-end wrappable" >
            <button class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'PDF.ORDER' | trans }}"
                *ngIf="can_get_pdf"
                (click)="show_invoice_proposal()">
                <i icon="picture_as_pdf"></i>
            </button>

            <button class="round filled" color="#fbb014"
                [disabled]="edited || fin_details_edited"
                title="{{ 'PDF.ORDER.NO_PRICES' | trans }}"
                *ngIf="can_get_pdf_without_prices"
                (click)="show_invoice_proposal(false)">
                <i icon="picture_as_pdf"></i>
            </button>

            <button class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'FINANCIAL.INVOICE' | trans }}"
                *ngIf="can_invoice"
                (click)="invoice()">
                <i icon="receipt_long"></i>
            </button>

            <button *ngIf="can_assign_inventory_items" class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.FINANCIAL_ITEMS_ASSING_TITLE' | trans }}"
                (click)="assign_arrival_details()">
                <i icon="list"></i>
            </button>

            <button class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.REASSIGN' | trans }}"
                *ngIf="can_reassign"
                (click)="reassign_supplier()">
                <i icon="local_shipping"></i>
            </button>

            <!-- <button class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.PLAN' | trans }}"
                *ngIf="can_plan_arrival"
                (click)="create_arrival()">
                <i icon="today"></i>
            </button> -->

            <button class="round filled" color="secondary"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.INTERNAL_APPROVAL.TITLE' | trans }}"
                *ngIf="can_see_internal_approval_status && can_send_approval_email"
                (click)="send_internal_approval_email()">
                <i icon="mark_email_read"></i>
            </button>

            <button class="round filled" color="warn"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.REJECT' | trans }}"
                *ngIf="can_reject"
                (click)="reject_order()">
                <i icon="not_interested"></i>
            </button>

            <button class="round filled" color="warn"
                [disabled]="edited || fin_details_edited"
                title="{{ 'ORDER.DELETE' | trans }}"
                *ngIf="can_delete"
                (click)="delete_order()">
                <i icon="delete"></i>
            </button>

            <knw-spinner color="primary" [size]="32" *ngIf="'order_admin_action' | inProgress" />
            <details *ngIf="!edited && !fin_details_edited && can_see_admin_actions() && !('order_admin_action' | inProgress)"
                #adminMenu
                class="as-button fake-button filled round"
                style="padding: 0; margin-left: 4px;"
                (clickOutside)="adminMenu.open = false"
                title="{{'ADMIN-BUTTONS' | trans}}"
            >
                <summary class="no-arrow" style="padding: .5em">
                    <i icon="admin_panel_settings"></i>
                </summary>
                <div class="admin-floating shadow-bottom">
                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                        *ngIf="can_see_order_history"
                        (click)="get_order_history_report(); adminMenu.open = false">
                        <i [attr.icon]="ICON_ORDER_HISTORY.icon"
                            [style.color]="ICON_ORDER_HISTORY.color"></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="watch_later"></i>
                        {{ 'ORDER.ORDER_HISTORY' | trans }}
                    </button>
                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .4em"
                        *ngIf="can_revert_review"
                        (click)="revert_review(); adminMenu.open = false">
                        <i [attr.icon]="ICON_REVIEW.icon"
                            [style.color]="ICON_REVIEW.color"></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                        {{ 'ORDER.REVERT_REVIEW' | trans }}
                    </button>

                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                        *ngIf="can_revert_execution"
                        (click)="revert_execution() ; adminMenu.open = false">
                        <i [attr.icon]="ICON_EXECUTION.icon"
                            [style.color]="ICON_EXECUTION.color"
                        ></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                        {{ 'ORDER.REVERT_EXECUTION' | trans }}
                    </button>

                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                        *ngIf="can_revert_executed"
                        (click)="revert_executed() ; adminMenu.open = false">
                        <i [attr.icon]="ICON_EXECUTED.icon"
                            [style.color]="ICON_EXECUTED.color"
                        ></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                        {{ 'ORDER.REVERT_EXECUTED' | trans }}
                    </button>

                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                        *ngIf="can_revert_items"
                        (click)="revert_items() ; adminMenu.open = false">
                        <i icon="price_change" style="color: rgb(var(--secondary-dark))"></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                        {{ 'ORDER.REVERT_ITEMS' | trans }}
                    </button>

                    <button class="sharp"
                        style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                        *ngIf="can_admin_delete"
                        (click)="admin_delete() ; adminMenu.open = false">
                        <i icon="delete" style="color: rgb(var(--warn))"></i>
                        <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="clear"></i>
                        {{ 'ORDER.ADMIN_DELETE' | trans }}
                    </button>
                </div>
            </details>
        </div>
    </header>

    <!-- Page sections start here -->
    <section class="single-page-section grid-layout grid-entity-header">
        <div class="grid-card main-info-card">
            <div class="card-header">
                <div class="flex-row justify-space-between align-center width-full">
                    <h3 class="card-header-title">{{'ORDER' | trans }}</h3>
                    <div class="company-title">
                        <strong>{{ order.supplier_name}}</strong>
                    </div>
                </div>
            </div>
            <div class="card-body flex-column" style="gap: .5em;">
                <!-- //Ikonu treba dodat -->
                <task-entity-info
					[title]="{text: get_order_title()}"
					[properties]="{
                        'LOCATION': get_location(),
                        'CREATED_DATE': order.created_date | datefmt : 'short_date_time' : false,
                        'CREATED_BY': (order | fullname : 'created_by'),
						'TICKET.ASSIGNED_TO': assigned_to(),
                        'ORDER.APPROVED_BY': approved_by(),
                        'ORDER.APPROVED_DATE': order.status_id == 7 ? (order.status_date | datefmt : 'short_date_time' : false) : undefined,
                        'ORDER.IS_SCH_ORDER_SINGLE': order.is_generated_from_scheduled_order ? ('YES'| trans): undefined,
                        'TICKET.REJECTED_REASON': order_comment(),
                        'ORDER.REVERT.REASON': order_revert_reason(),
                        'USER.TYPE.COMPANY_WORKER': worker_company(),
                        'ORDER.INVESTMENT_TYPE': order_investment_type(),
                        'ORDER.COST_ESTIMATE': cost_estimate(),
                        'ORDER.INVOICE_NO': order_invoice(),
                        'NAVROUTE.WORKHIER': order.whier,
					}"
					[status]="get_order_status(order)"
					[tiles]="get_order_tiles | fn : order">
                </task-entity-info>
            </div>
        </div>

        <!-- Povezane prijave -->
        <div class="grid-card linked-entity-card" *ngIf="tickets.length>0 && can_see_tickets">
            <div class="card-header">
                <h3 class="card-header-title">{{'RELATED.TICKETS' | trans }}</h3>
            </div>
            <div class="card-body flex-column wrappable align-flex-start" style="margin-bottom: 1.5rem; gap: .6em;">
                <ng-container *ngFor="let ticket of tickets">
                    <button class="filled pill flex-row justify-flex-start align-flex-start"
                            color="secondary"
                            [goto]="'/tickets/' + ticket.ticket_id">
                        <b>#{{ticket.ticket_id}}</b> &nbsp;
                        <i class="truncate-btn-text">{{ticket.title}}</i>
                    </button>
                </ng-container>
            </div>
        </div>

        <!-- Povezani dolasci -->
        <div class="grid-card linked-entity-card">
            <div class="card-header">
                <h3 class="card-header-title">{{'RELATED.ARRIVALS' | trans }}</h3>
                    <!-- <button  class="add-btn pill filled"
                        color="primary"
                        [disabled]="edited || fin_details_edited"
                        *ngIf="can_plan_arrival"
                        (click)="create_arrival()">
                        <i icon="add"></i>
                        <span>{{'ORDER.PLAN' | trans}}</span>
                    </button> -->

                <button  class="add-btn filled round"
                    color="primary"
                    [attr.tooltip-left]="('ORDER.PLAN' | trans)"
                    [disabled]="edited || fin_details_edited"
                    *ngIf="can_plan_arrival"
                    (click)="create_arrival()">
                    <i icon="add"></i>
                </button>
            </div>

            <div class="card-body">
                <div class="flex-column" style="gap: .3rem; overflow: auto;">

                    <details *ngFor="let arrival of arrivals"
                        class="flex-static details card-details-item"
                        [ngClass]="{'no-items': arrivalHasNoDetails | fn:arrival}"
                        style="padding: 0;"
                    >
                        <summary class="flex-row justify-space-between align-center" style="padding-left: .2em;">
                            <a [goto]="'/arrivals/' + arrival.arrival_id"
                            class="plain-link align-center flex-row"
                            style="cursor: pointer;">
                                <i [style.color]="arrival['icon_color']" [attr.icon]="arrival['icon_name']" [title]="arrival['status_name']" ></i>

                                <span *ngIf="!(arrival.emergency || arrival.ended_without_qr)" style="display: inline-block; margin: 0 0.3em;">{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}}</span>

                                <ng-container *ngIf="arrival.emergency || arrival.ended_without_qr">
                                    <span  style="display: inline-block; margin: 0 0.3em;" [attr.tooltip-right]="(arrival.emergency?'ARRIVAL.DETAILS.EMERGENCY_TITLE' : 'ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE') | trans">{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}} </span>
                                    <i *ngIf="arrival.emergency" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                                    <i *ngIf="arrival.ended_without_qr" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                                </ng-container>
                            </a>

                            <i>{{arrival | arrival_time | datefmt : 'short_date': false}}</i>
                        </summary>

                        <div class="flex-column" style="gap: .1rem; padding: .5rem 1.2rem .5rem 1.5rem;">
                            <ng-container *ngFor="let detail of arrival.arrival_details">
                                <div *ngIf="detail.arrival_detail_type_id == 3"
                                        class="flex-row justify-space-between arrival-detail align-flex-end"
                                        [title]="detail.element_name">
                                    <b style="font-size: .95rem;">{{ detail.element_name }}</b>
                                    <span style="white-space: nowrap" *ngIf="can_see_individual_prices">
                                        <task-price
                                            [label]="null"
                                            [price]="detail.element_price * detail.element_quantity"
                                            [currency]="detail.element_currency"
                                            [style.font-size]="'.95rem'"
                                        ></task-price>
                                    </span>
                                </div>
                            </ng-container>
                        </div>

                    </details>

                </div>
            </div>

            <div class="total-price "
                style="padding-top: 0.6rem; margin-top: auto; " *ngIf="show_currency">
                <div class="flex-row align-flex-end justify-space-between" style="margin-top: .5rem; column-gap: .5em;">
                    @if (arrivals.length) {
                        <h4 class="flex-row wrappable" style="column-gap: 0.5em; margin: 0; ">
                            <span>{{'ORDER.ARRIVALS_TOTAL_TIME' | trans}}:</span>
                            <span>{{get_total_work_time | fn}}</span>
                        </h4>
                    }
                    <div
                        *ngFor="let sum of currency_sums | keyvalue"
                    >
                        <strong>
                            <task-price [currency]="sum.key" [price]="sum.value || 0" [label]="'ORDER.TOTAL'"></task-price>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Order details section -->
    <section class="single-page-section">
        <div class="sticky-header">
            <h2>{{'ORDER.ORDER.DETAILS' | trans}}</h2>

            <div class="flex-row wrappable justify-flex-end" style="gap: .5em;" *ngIf="edited && !fin_details_edited">
                <button class="filled pill outlined"
                        color="secondary"
                        (click)="save()">
                    <i icon="save"></i><span class="screen-big">{{ "SAVE" | trans }}</span>
                </button>

                <button class="outlined pill"
                        style="margin-right: 0"
                        color="primary"
                        (click)="cancel_edit()">
                    <i icon="cancel"></i><span class="screen-big">{{ "CANCEL" | trans}}</span>
                </button>
            </div>
        </div>

        <div class="grid-layout grid-entity-detilas" style="margin-bottom: .6rem;">
            <!-- Order description detail -->
            <div class="grid-card text-details-card">
                <div class="card-header" style="display: flex; align-items: center; justify-content: space-between;">
                    <h3 class="card-header-title">{{ 'ORDER.DESCRIPTION' | trans }}</h3>
                </div>
                <div class="card-body">
                    <ng-container *ngFor="let detail of description_details">
                        <div class="flex-row wrappable text-details-uneditable">
                            <div class="flex-row wrappable flex-dynamic align-flex-start" [ngClass]="{'editable': can_edit && !fin_details_edited}">
                                <task-click-toggle [can-toggle]="can_edit && !fin_details_edited" [classList]="'flex-dynamic flex-row align-flex-start width-full'">
                                    <div on class="flex-dynamic">
                                        <label class="textarea">
                                            <textarea [(ngModel)]="detail.description"
                                                (ngModelChange)="edited = true">
                                            </textarea>
                                        </label>
                                    </div>

                                    <div off class="flex-row justify-space-between insert-text-toggle width-full">
                                        <span [ngStyle]="{'cursor': can_edit && !fin_details_edited ? 'pointer': 'auto'}">{{detail.description}}</span>
                                        <button *ngIf="can_edit && !fin_details_edited" class="add-btn round" color="primary">
                                            <i icon="edit"></i>
                                        </button>
                                    </div>
                                </task-click-toggle>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Inventory component -->
            <div class="grid-card inventory-card">
                <div class="card-header">
                    <h3 class="card-header-title">{{'ORDER.INVENTORY' | trans}}</h3>
                </div>
                <div class="card-body">
                    <task-inventory-cards
                        *ngIf="can_edit"
                        [disabled]="fin_details_edited ? true : false"
                        [ngModel]="additional_inventory"
                        (ngModelChange)="
                        additional_inventory=$event;
                        edited=true"
                        [location_cin]="order.location_cin"
                        [in_grid]="true"
                        [classes]="' flex-dynamic'"
					></task-inventory-cards>
                    <task-kam-inventory *ngFor="let detail of inventory_details"
                        [inventory]="detail?.inventory_data || detail">
                    </task-kam-inventory>
                </div>
            </div>

            <!-- Images component -->
            <div class="grid-card images-card">
                <div class="card-header">
                    <h3 class="card-header-title">{{'TICKET.IMAGES' | trans}}</h3>
                    <button
                        class="add-btn round filled"
                        color="primary"
                        (click)="image.get_images()"
                        *ngIf="can_edit_images"
                        [disabled]="fin_details_edited"
                    >
                        <i icon="add"></i>
                    </button>
                </div>
                <div class="card-body">
                    <task-multi-image-new
                        #image
                        [(ngModel)]="images"
                        (ngModelChange)="images=$event;edited=true"
                        [allow-deleting-uploaded]="true"
                        [in_grid]="true"
                        [disabled]="!can_edit_images"
                    >
                    </task-multi-image-new>

                </div>
            </div>

            <!-- Documnets component -->
            <div class="grid-card files-card">
                <div style="display: flex; justify-content: space-between; height: 3rem;">
                    <h3 class="card-header-title">{{'TICKET.DOCUMENTS' | trans}}</h3>
                    <div>
                        <button *ngIf="can_edit || (can_edit_financial_documents && can_edit_documents)"
                            class="add-btn round filled"
                            color="primary"
                            (click)="open_files_text_menu()"
                            [disabled]="fin_details_edited"
                        >
                            <i icon="add"></i>
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <h4 class="card-header-title" style="margin-top: 1em;" *ngIf="financial_files.length > 0">
                        {{ 'FINANCIAL_FILES' | trans }}:
                    </h4>
                    <ng-container *ngIf="can_see_financial_documents">
                        <task-multi-files
                            #financial_file
                            [(ngModel)]="financial_files"
                            (ngModelChange)="financial_files=$event;edited=true"
                            [allow-deleting-uploaded]="true"
                            [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                            [download_file]="download_file"
                            [disabled]="!can_edit_financial_documents"
                            [in_grid]="true"
                        ></task-multi-files>
                    </ng-container>

                    <h4 class="card-header-title" style="margin-top: 1em;" *ngIf="service_files.length > 0">
                        {{ 'REST_FILES' | trans }}:
                    </h4>
                    <task-multi-files
                        #service_file
                        [(ngModel)]="service_files"
                        (ngModelChange)="service_files=$event;edited=true"
                        [allow-deleting-uploaded]="true"
                        [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                        [download_file]="download_file"
                        [disabled]="!can_edit_documents"
                        [in_grid]="true"
                    ></task-multi-files>
                </div>
            </div>
        </div>
    </section>

     <!-- Ping-pong section -->
    <section class="single-page-section" *ngIf="can_edit_financial_details">
        <div class="sticky-header">
            <h2>{{ 'FINANCIAL.TITLE' | trans }}</h2>
        </div>

        <!-- Editable table component -->
        <div class="grid-card costs-card">
            <div class="card-header">
				<h3 class="card-header-title">{{ 'FINANCIAL_2.TITLE' | trans }}</h3>
                <div class="flex-row wrappable align-center justify-flex-end" style="margin-left: auto; gap: .5em; font-size: 90%;">
                    <button *ngIf="has_completed_arrivals" class="pill filled"
                        color="primary-light"
                        style="margin: 0;"
                        (click)="download_by_store_approval()">
                        <i icon="picture_as_pdf"></i>
                        <span>{{ 'FINANCIAL.PDF_BY_LOCATION' | trans }}</span>
                    </button>

                    <button *ngIf="has_completed_arrivals" class="pill filled"
                        color="primary-light"
                        style="margin: 0;"
                        (click)="download_all()">
                        <i icon="picture_as_pdf"></i>
                        <span>{{ 'FINANCIAL.PDF_ALL' | trans }}</span>
                    </button>

                    <button class="pill filled"
                        color="primary-light"
                        style="margin: 0;"
                        (click)="download_pricelist()">
                        <i icon="request_quote"></i>
                        <span>{{ 'PRICELIST.DOWNLOAD' | trans }}</span>
                    </button>
                </div>
			</div>

            <div class="card-body" >
               <task-ping-pong
                    [order]="order"
                    #fin_details
                    [disable]="edited"
                    (submitted)="refetch_order()"
                >
                </task-ping-pong>
            </div>
        </div>



    </section>

    <!-- Comments section -->
	<section class="single-page-section" style="margin-bottom: 0;">
		<h2>{{ 'COMMENTS' | trans }}</h2>
		<task-new-comments [entity]="entity" [entity_id]="order.order_id"></task-new-comments>
	</section>
</div>
