import { Component } from '@angular/core';
import { Frame, language } from 'curvy';
import { User } from '@task-modules/users';
import { user_settings_translations } from './user-settings.trans';

@Component({
    selector: 'task-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})

export class UserSettingsComponent {

	languages = language.get_languages();
	lang = language.get_current_language().locale;

    constructor() {
        Frame.set({
            title: "SETTINGS.TITLE",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(user_settings_translations);
    }

	changedLanguage() {
		language.set_current_language(this.lang);
		User.updateFrame();
	}

}

