<div class="header">
    <div class="flex-row">
        <label class="input filled" [title]="'MONTH' | trans" style="min-width: 7em; max-width: 7em">
            <select [(ngModel)]="selectedMonth" (ngModelChange)="onChangeDate()">
                <option *ngFor="let month of months; let i = index" [ngValue]="i">
                    {{ month }}
                </option>
            </select>
        </label>
        <label class="input filled" [title]="'YEAR' | trans">
            <input [(ngModel)]="selectedYear" (ngModelChange)="onChangeDate()" style="width: 3em;" type="number" integer />
        </label>
        <ng-container>
            <button class="round filled" color="secondary" (click)="switchMonth(-1)">
                <i icon="chevron_left"></i>
            </button>
            <button class="round filled" color="secondary" (click)="switchMonth(1)">
                <i icon="chevron_right"></i>
            </button>
        </ng-container>
    </div>

    <!-- LEGEND -->
    <div class="flex-row flex-dynamic">
        <div class="flex-row label-item wrappable" style="margin-left: auto; margin-right: auto;">
            <ng-container *ngFor="let legend of allStatuses;">
                <div *ngIf="legend.visible" (click)="filterStatus(legend)" class="flex-row" style="margin-bottom: .25em; margin-left: 1em; margin-top: .25em;">
                    <i [attr.icon]="legend.icon" [ngStyle]="{color: legend.selected ? legend.color : 'gray'}"></i> &nbsp; <span>{{legend.name}}</span>
                </div>
            </ng-container>
        </div>
        <div class="flex-row" style="margin-left: auto;" >
            <button (click)="printDocument()" class="round filled" color="secondary" style="margin-left: 1.5em; margin-top: .25em; margin-bottom: .25em;">
                <i icon="print"></i>
            </button>
        </div>
    </div>


    <div class="flex-wrap calendar-filters-wrapper flex-row wrappable" style="flex: 0 0 100%">
        <div class="align-self-center title-wrapper flex-column" style="margin-left: 1em; margin-right: 1em;">
            <span style="font-size: 0.85em; margin-bottom: .25em;">Tip događaja:</span>
            <h2 *ngIf="selectedType && selectedType === eventTypes[0]" style="margin: 0;">{{ 'NAVROUTE.ORDERS' | trans }}</h2>
            <h2 *ngIf="selectedType && selectedType === eventTypes[1]" style="margin: 0;">{{ 'NAVROUTE.ARRIVALS' | trans }}</h2>
            <h2 *ngIf="selectedType && selectedType === eventTypes[2]" style="margin: 0;">{{ 'NAVROUTE.SCHEDULED_ORDERS' | trans }}</h2>
        </div>

        <label class="input filled" [title]="'EVENT.PICK' | trans" style="min-width: 8em; max-width: 11.5em">
            <select [(ngModel)]="selectedType" (ngModelChange)="updateLegend(); getData()">
                <ng-container *ngFor="let event of eventTypes; let i = index">
                    <option [ngValue]="event" [attr.selected]="event==eventTypes[0]">
                        {{ event | trans }}
                    </option>
                </ng-container>
            </select>
        </label>

        <knw-auto-search
            [function]="search_locations"
            [to-label]="format_location_name"
            [allow-null]="true"
            [popover-width]="'auto'"
            [(ngModel)]="selectedLocation"
            (ngModelChange)="onChangeDate()"
            label="{{ 'LOCATION' | trans }}"
            [classes]="'filled'">
        </knw-auto-search>

        <knw-auto-search
            [function]="search_suppliers"
            [to-label]="format_supplier_name"
            [allow-null]="true"
            [popover-width]="'auto'"
            [(ngModel)]="selectedSupplier"
            (ngModelChange)="onChangeDate()"
            label="{{ 'SUPPLIER' | trans }}"
            [classes]="'filled'">
        </knw-auto-search>


        @if (is_admin) {
            <knw-auto-search
                [function]="search_companies"
                [to-label]="format_company_name"
                label="{{'COMPANY' | trans}}"
                [(ngModel)]="selectedCompany"
                (ngModelChange)="updateLegend(); getData()"
                [classes]="'input filled'"
                popover-width="'auto'"
            />
        }

        <label class="input filled" [title]="'ASSIGNED' | trans" style="min-width: 8em; max-width: 11.5em;" *ngIf="canSeeReassign">
            <select [(ngModel)]="assignedToEvent" (ngModelChange)="getData()">
                <option value="null">Svejedno</option>
                <option value="me">{{'BY_ME' | trans}}</option>
                <option value="others">{{'BY_OTHERS' | trans}}</option>
            </select>
        </label>
        <button class="round" style="margin-left: auto;"><i icon="clear" (click)="clear_filter()" ></i></button>
    </div>


</div>

<div class="body">
    <div class="blind" [ngClass]="{
        open: !('load-calendar' | inProgress)
    }">
    <knw-spinner color="primary" size="100"></knw-spinner>
    </div>
    <div class="date-grid counter">
        <ng-container>
            <th *ngFor="let day of days">{{ day[0] }}</th>
        </ng-container>
        <ng-container *ngFor="let week of (0 | upto: 5)">
            <ng-container *ngFor="let day of days; let dayNum = index">
                <ng-container *ngVar="week*7+dayNum-startDay as monthDay">
                    <div class="day"
                        *ngVar="(dayEvents | fn : monthDay : refreshCount) as getDayEvents"
                        [class.this-month]="isThisMonth(monthDay)"
                        [class.today]="isToday(monthDay)">

                        <span class="day-label">
                            <span class="flex-row align-ceneter" style="width: 100%;" *ngIf="getDayEvents.length > 0" (click)="openEventsInfo(getDayEvents)">
                                <i icon="more_horiz"></i>
                                <span class="expand-icon"
                                    style="font-size: 80%; letter-spacing: .15em; padding: .5em; text-transform: uppercase;">{{ 'SHOW_MORE' | trans : getDayEvents.length }}</span>
                            </span>
                            <b>{{ getDayLabel(monthDay) }}</b>
                        </span>
                        <div class="orders-container" *ngIf="!('load-calendar' | inProgress)">
                            <ng-container *ngFor="let event of getDayEvents ; let i = index">
                                <a *ngIf="i<=3" class="flex-row day-order" [ngStyle]="{
                                        borderLeft: '6px solid '+(setStatus | fn : event.status_id).color
                                    }" [href]="event.url" (click)="openLink(event); $event.preventDefault()">
                                    <i [attr.icon]="(setStatus | fn : event.status_id).icon"
                                        [ngStyle]="{marginRight: '1em', color: (setStatus | fn: event.status_id).color}"></i>
                                    <span class="flex-column align-flex-start flex-dynamic">
                                        <span class="flex-row align-flex-start"><b>#{{ event.event_id }}</b>&nbsp;<i>{{ event.event_title }}</i></span>
            							<!-- <span>#{{ event.location_cin }} {{event.location_name}}</span> -->
                                        <strong>{{ event.supplier_name }}</strong>
                                    </span>
                                </a>
                            </ng-container>

                            <button *ngIf="getDayEvents.length > 3"
                                (click)="openEventsInfo(getDayEvents)"
                                color="background-text"
                                style="margin-left: auto; margin-right: auto; margin-top: .7em; font-size: 80%; letter-spacing: .15em; padding: .5em; width: 100%;">
                                <i icon="more_horiz"></i>
                                <span>{{ 'SHOW_MORE' | trans : getDayEvents.length }}</span>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
