import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalBase, language, network } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { files } from '@task-utils/utils';
import { AccordionListComponent } from '@task-components/task-accordion-list.comopnent';

interface InternalError {
    error_argument: string;
    internal_error_code: number;
    status: string;
};
function is_internal_error(obj: any): obj is InternalError {
    return "error_argument" in obj && "internal_error_code" in obj && "status" in obj;
}

@Component({
    selector: 'task-import-scheduled-order-dialog',
    template: `
        <h2 class="modal-title" style="margin-bottom: 1.2em;">{{ "SUPPLIER.DIALOG_TITLE" | trans : supplier.name }}</h2>
        
        <details style="box-shadow: none; grid-column: 1 / -1; padding-left: 0; padding-right: 0;  margin-top: 0px; ">
            <summary stlye="">
                <h3 style="margin-bottom: 0.3em; font-size: .89em;" class="uppercase flex-row align-center">
                    <i icon="info_outline" style="font-size: .7em;5 display: inline-block; margin-right: .5em;"></i>
                    <span>Pravila za uređivanje servisa</span>
                </h3>
            </summary>
             <ul>
                <li>Zabranjeno brisanje kolona</li>
                <li>Zabranjeno dodavanje novih kolona</li>
                <li>Zabranjeno skrivanje kolona</li>
                <li>Kolone označene sa * su obavezne za popuniti.</li>
                <li>Polje planirani datum mora biti u obliku &nbsp; <b>DD.MM.GGGG.</b></li>
                <li>Određene kolone imaju dropdown vrijednost (Radna hijerarhija 1,2 i 3)</li>
                <li>Radne hijerarhije potrebno je odabrati po redosljedu: Radna hijerarhija 1 -> Radna hijerarhija 2 -> Radna hijerarhija 3</li>
                <li>Planirane servise iz tablice, ukoliko postoje, možete ažurirati po potrebi</li>
                <li>Nove servise dodati na kraj popisa ili umetnuti odgovarajući redak u tablicu </li>
             </ul>
        </details>   
        
        <div form-collector #scheduledOrderImportForms="collector" style="">
            <div *ngIf="!msgs.showMsg" class="flex-row justify-space-around" style="margin-top: 1.5em;">
                <button class="filled"
                    color="secondary"
                    (click)="download()"
                    [disabled]="scheduledOrderImportForms.containsErrors()">
                    <i icon="file_download"></i>
                    <span>{{ 'SUPPLIER.DOWNLOAD' | trans }}</span>
                </button>
                <button class="filled"
                    color="primary"
                    (click)="getFile()"
                    [disabled]="scheduledOrderImportForms.containsErrors()">
                    <i icon="file_upload"></i>
                    <span>{{ 'SUPPLIER.IMPORT' | trans }}</span>
                </button>
            </div>
        </div>

        <div *ngIf="msgs.showMsg" class="info-msg" style="">
            <p style="color:rgb(var(--{{msgs.msg_color}})); white-space: pre-wrap;">{{ msgs.msg }}</p>
        </div>
        
        <div class="flex-row flex-static justify-end modal-footer">
            <button color="primary"
                    (click)="cancel()">
                <i icon="clear"></i>
                <span>{{ 'CLOSE' | trans }}</span>
            </button>
        </div>
       
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../../pricelists/pricelists.scss']
})
export class ImportScheduledOrderDialog extends ModalBase<Task.Supplier, any> {
    @ViewChild(AccordionListComponent)

    preventSoftClose = true;
    file: files.IFile [];

    msgs = {
        msg: language.translate("SUPPLIER.SUCCESS"),
        showMsg: false,
        msg_color: 'secondary-dark'
    };
    
    supplier: Task.Supplier;

    ngOnInit() {
        this.supplier = this.injectedData;
    }

    async download() {
        await TaskRoutes.scheduled_orders.api_download_template_xlsx(this.supplier.supplier_id, true);
        this.cancel();
    }

    async getFile() {
        this.file = await files.get_files({
            multiple: false,
            accept: ".xlsx"
        })

        if (this.file) {
            try {
                await TaskRoutes.scheduled_orders.api_upload_scheduled_orders_for_supplier(this.supplier.supplier_id, this.file[0].file);
                this.msgs.msg_color = 'secondary-dark';
                this.msgs.showMsg = true;

            } catch(error) { 
                // console.log(error)
                
                this.msgs.showMsg = true;
                this.msgs.msg_color = 'warn';
                if (is_internal_error(error)) {
                    this.msgs.msg = language.translate("SUPPLIER.ERRORS");
                } else {
                    this.msgs.msg = language.translate("SUPPLIER.ERRORS.DEFAULT") + error.data.join(', ');
                }

            }
        }
    }

    cancel() {
		this.submit(null);
	}

    
}
