import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { language } from 'curvy';
import { components_trans } from './components.trans';

@Component({
	selector: 'task-price',
	encapsulation: ViewEncapsulation.None,
	template: `
		<b *ngIf="label">{{ label | trans }}:&nbsp; </b>

		<ng-container *ngIf="price == price">
			{{ (lump_sum ? (format_price | fn : 0 : currency) : (format_price | fn : (price ?? 0) : currency)) }}
		</ng-container>
    `,
    styles: [`task-price { display: contents }`]
})
export class PriceComponent implements OnInit {
	@Input() label: string = "PRICE";
	@Input() price: number = 0;
	@Input() currency: string = "";
	@Input() lump_sum = false;

	ngOnInit() {

		language.load_translations(components_trans);
	}

	format_price(price: number, currency: string) {
		return price.toLocaleString('hr', {style: 'currency', currency: currency || 'EUR'});
	}
}
