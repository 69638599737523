import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    Frame, language, TableConfig, GenericFilterComponent, network,
    template, GenericFilter, noOp, modal, DataTableComponent, utils,
    ConfirmDialogComponent, color
} from 'curvy';
import { Task } from '@task-utils/types';
import { Templates } from '@task-utils/utils';
import { User } from '@task-modules/users';
import { suppliers_translations } from './suppliers.trans';
import { CreateEditSupplierDialog } from './create-edit-supplier.dialog';
import { TaskRoutes } from '@task-utils/routes';
import { ImportScheduledOrderDialog } from '@task-modules/orders/scheduled-orders/import-scheduled-order.dialog';

@Component({
    selector: 'task-suppliers',
    template: `
        <div class="temp-add-button">
            <button (click)="openAdd()"
                    class="filled pill"
                    color="primary"
                    *ngIf="can_create">
                <i icon="add_circle"></i>
                <span>{{ 'SUPPLIER.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #supplierstable [config]="tableConfig"></knw-data-table>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-suppliers {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }
    `]
})
export class SuppliersComponent {
    @ViewChild('supplierstable') table: DataTableComponent<Task.Supplier, DisplaySupplier, 'suppliers', GenericFilterComponent>;

    can_create = false;

    constructor() {
        Frame.set({
            title: "NAVROUTE.SUPPLIERS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(suppliers_translations);

        User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN
        ).then(res => {
            this.can_create = true;
        });
    }

    async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

    tableConfig: TableConfig<Task.Supplier, DisplaySupplier, 'suppliers', GenericFilterComponent> = {
        tableName: 'suppliers',
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = { search: [], attributes: [], sort: null };

            for (let key in filter) {
                 if (key === 'company' && filter['main_company_id'] !== null) {
                    parsed_filter.attributes.push('#=:main_company_id')
                    parsed_filter.search.push(filter[key].company_id)

                } else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key]);
                }
            }

            if (sort_header) {
                switch (sort_header.label) {
                    case 'supplier':
                        sort_header.label = 'company.name';
                    break;

                    case 'contact':
                        sort_header.label = 'company.contact_name';
                    break;
                }
            }

            parsed_filter.sort = sort_header;

            return await TaskRoutes.suppliers.api_supplier_search(parsed_filter, paginator);

        },
        getHeader: (header) => {
            switch (header) {
                case 'supplier':
                    return { icon: "local_shipping", label: "SUPPLIER.SUPPLIER", sortable: true };

                case 'contact':
                    return { icon: "contacts", label: "CONTACT", sortable: true };

                case 'oib':
                    return { icon: "tag", label: "OIB", sortable: false };

                case 'email':
                    return { icon: "email", label: "EMAIL", sortable: false };
            }
        },
        unwrapData: (supp) => {
            let phone = (supp.contact_phone || '').trim();

            let phone_icon = '';
            if (phone !== '') {
                phone_icon = `<i icon="phone" style="font-size: 70%; margin-right: 0.3em;"></i><div>{{phone_no}}</div>`;
            }

            return {
                supplier: template.transform(`
                    <div class="flex-column">
                        <b>{{name}}</b> <span>{{cin}}</span>
                    </div>
                    `, { name: supp.name, cin: supp.cin || '' }),
                oib: supp.identification_no,
                contact: template.transform(`
                    <div class="flex-column">
                        <b>{{name}}</b>
                        <div class="flex-row align-center justify-center">${phone_icon}</div>
                    </div>
                    `, { phone_no: phone, name: supp.contact_name || "" }),
                email: Templates.email(supp.email),
            }
        },

        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (supp)=>{
                modal.open(CreateEditSupplierDialog, (res)=>{
                    if (res) {
                        this.table.getData()
                    }
                }, supp)
            },
            priority: 1,
            isVisible: async() => await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)
        }, {
            label: language.translate('NAVROUTE.PRICELIST'),
            icon: "request_quote",
            onClick: (supp) => {
                console.log(supp);
                utils.router.navigateByUrl("/pricelists/" + supp.supplier_id)
            },
            priority: 1,
            isVisible: () => true,
        }, {
            label: language.translate('SUPPLIER.IMPORT'),
            icon: "publish",
            onClick: (supp)=>{
                modal.open(ImportScheduledOrderDialog, noOp, supp)
            },
            priority: 2,
            isVisible: async() => await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)
        }],
        filterComponent: GenericFilterComponent,
        filterInput: async ()=> {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("SUPPLIER.SUPPLIER", "company.name+ +company.identification_no"),
                GenericFilter.text("CONTACT", "company.contact_name"),
                GenericFilter.text("PHONE", "company.contact_phone"),
            ];

            if (await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)) {

                let sectors = await TaskRoutes.sectors.api_sector_get_all(null, false);
                let filter_sector = sectors.data.map(s=>({
                    label: s.name,
                    value: s.sector_id
                }))
                filters.push(GenericFilter.dropdown("SECTOR", "#=:sectors.sector_id", filter_sector));
            }

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
                filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }

            return filters;
        }
    }

    openAdd() {
        modal.open(CreateEditSupplierDialog, (res) => {
            if (!res) return;
            this.table.getData();
        });
    }

}

type DisplaySupplier = {
    supplier: template.EscapedHTML,
    contact: template.EscapedHTML,
    email: template.EscapedHTML,
    oib: string,
}
