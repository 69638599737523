import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { language, modal, noOp, Frame } from 'curvy';
import { files } from '@task-utils/utils';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { tickets_translations } from '@task-modules/tickets/tickets.trans';


@Component({
    selector: 'task-scheduled-order',
    templateUrl: './scheduled-order.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../order.component.scss']
})
export class ScheduledOrderComponent implements OnInit {
    @Input()
    order: Task.ScheduledOrder;

    icon: string;
    color: string;

    itype = Task.Investment_Type;


    images: files.IImage[] = [];

    inventory_details: Task.ScheduledOrderDetail[] = [];
    description_details: Task.ScheduledOrderDetail[] = [];

    async update_values() {


     
        let icon = Task.get_status_icon(this.order.status_id);
        this.icon = icon.icon;
        this.color = icon.color;


        let image_requests: Promise<files.IImage>[] = [];

        this.inventory_details = [];
        this.description_details = [];

        for (let detail of this.order.scheduled_order_details) {
            if (detail.order_detail_type_id == Task.ORDER_DETAIL_TYPE.IMAGE) {
                image_requests.push(
                    files.url_to_iimage(detail.picture_url)
                );
            }

            if (detail?.inventory_data?.udas) {
                this.inventory_details.push(detail);
                detail.inventory_data.udas.sort((u1, u2) => {
                    return u1.uda_name.localeCompare(u2.uda_name);
                });
            }

            if (detail.order_detail_type_id == Task.ORDER_DETAIL_TYPE.DESCRIPTION) {
                this.description_details.push(detail);
            }
        }

        Promise.all(image_requests).then((res) => {
            this.images = res;
        });
    }

    constructor(private ar: ActivatedRoute) { }

    ngOnInit() {

        language.load_translations(orders_translations);
        language.load_translations(tickets_translations);


        if (this.order == null) {
            const order_id = this.ar.snapshot.paramMap.get("id");

            TaskRoutes.scheduled_orders.api_scheduled_order_get_single(+order_id, true).then(res => {
                this.order = res.data;
                this.update_values();

        
                Frame.set({
                    title: language.translate("NAVROUTE.SCHEDULED_ORDERS", order_id),
                    visible: true,
                    layout: "middle",
                    size: "full"
                });
            });
        } else {
            this.update_values();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        let order_changed = !!changes["order"];

        if (order_changed) {
            this.update_values();
        }
    }

}
