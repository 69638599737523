
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Frame, language, progress, utils, notification, color, navigation } from 'curvy';
import { set_pass_translations } from './set-password.trans';
import { User } from '../users/users';
import { TaskRoutes } from '@task-utils/routes';

@Component({
	selector: 'task-set-password',
	template: `

		<div class="flex-column" form-collector #loginForm="collector">

			<h2>{{ 'SETPASS.TITLE' | trans }}</h2>

			<div class="flex-row">
				<label class="input filled flex-dynamic" color="primary-dark">
					<i icon="lock"></i>
					<input [placeholder]="'PASS' | trans" 
						[type]="show_pass ? 'text' : 'password'" 
						(input)="handle_error_msgs(password)"
						[(ngModel)]="password"
						tabindex="1"/>
				</label>
				<button class="small round" (click)="toggleShowPass(1)" color="primary-dark" tabindex="3">
					<i [attr.icon]="show_pass ? 'visibility_off' : 'visibility'"></i>
				</button>
			</div>

			<div class="flex-row">
				<label class="input filled flex-dynamic" color="primary-dark">
					<i icon="lock"></i>
					<input 
						[placeholder]="'SETPASS.CONFIRM' | trans" 
						[type]="show_pass_again ? 'text' : 'password'" 
						(input)="password !== password_again ? again_incorrect = true : again_incorrect = false"
						[(ngModel)]="password_again"
						tabindex="2"/>
				</label>
				<button class="small round" (click)="toggleShowPass(2)" color="primary-dark" tabindex="4">
					<i [attr.icon]="show_pass_again ? 'visibility_off' : 'visibility'"></i>
				</button>
			</div>

			<div class="flex-column">
				<p *ngFor="let msg of error_message" style="color: rgb(var(--warn)); margin: 0;">{{msg}}</p>
				<p *ngIf="again_incorrect" style="color: rgb(var(--warn)); margin: 0;">{{ "SETPASS.AGAIN_ERROR" | trans }}</p>
			</div>

			<button class="login-btn" (click)="submit()" color="primary-dark" [disabled]="password.length == 0 || password !== password_again || ('set_password' | inProgress) || show_message" tabindex="5">{{ 'SETPASS.SAVE' | trans }}</button>
		</div>
	`,
	styleUrls: ["./set-password.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SetPasswordComponent implements OnInit {
	password = "";
	show_pass = false;

	password_again = "";
	show_pass_again = false;

	key = "";
	can_change = false;

	show_message = false; 
	again_incorrect = false;
	error_message = [];
	specChars = '!@#$%^&*()-_=+\|[]{};:/?.><';

	constructor(private ar: ActivatedRoute) {
		Frame.set({
			title: "NAVROUTE.SETPASS",
			visible: false,
			layout: "middle",
			size: "full"
		});
		language.load_translations(set_pass_translations);
	}

	ngOnInit() {
		this.key = this.ar.snapshot.params["key"] || this.ar.snapshot.queryParams["key"];
		if (this.key == null || this.key == undefined) {
			console.log("redirect key null")
			utils.router.navigateByUrl("/login");
			return;
		}

		let prom = TaskRoutes.auth.api_can_register({ key: this.key }).then(res => {
			if (res.data)
				this.can_change = res.data;
			else {
				console.log("redirect can't change")
				utils.router.navigateByUrl("/login");
			}
		}).catch(err => {
			const ERR_BASE = {
				title: language.translate("SETPASS.ERROR"),
				icon: "error",
				color: color.Variable.warn
			};

			switch (err.internal_error_code) {
				case 4104: {
					notification.show({
						...ERR_BASE,
						message: language.translate("SETPASS.ERROR.NOUSER"),
					});
				} break;
				case 4107: {
					if (err.error_argument == "Email expired.") {
						notification.show({
							...ERR_BASE,
							message: language.translate("SETPASS.ERROR.EXPIRED"),
						});
					} else {
						notification.show({
							...ERR_BASE,
							message: language.translate("SETPASS.ERROR.INVALID"),
						});
					}
				} break;
			}

			console.log("redirect ", err)
			utils.router.navigateByUrl("/login");
		});

		progress.listen("set_password", prom);
	}

	toggleShowPass(num: 1 | 2) {
		if (num === 1) {
			this.show_pass = !this.show_pass;
		} else {
			this.show_pass_again = !this.show_pass_again;
		}
	}


	handle_error_msgs(str) {
		this.error_message = [];
		this.show_message = false;

		if (this.password.length < 8) {
			this.show_message = true;
			this.error_message.push(language.translate("SETPASS.LENGTH"));
		}

		let uppercase = /\p{Uppercase_Letter}/u;
		if (!uppercase.test(str)) {
			this.show_message = true;
			this.error_message.push(language.translate("SETPASS.UPPERCASE")) ;
		}

		let lowercase = /\p{Lowercase_Letter}/u;
		if (!lowercase.test(str)) {
			this.show_message = true;
			this.error_message.push(language.translate("SETPASS.LOWERCASE")) ;
		}

		let number = /[0-9]/;
		if (!number.test(str)) {
			this.show_message = true;
			this.error_message.push(language.translate("SETPASS.NUMBER")) ;
		}

		let spec = /[!@#$%^&*()_=+\\|[\]{};:/?.><]|-/;
		if (!spec.test(str)) {
			this.show_message = true;
			this.error_message.push(language.translate("SETPASS.SPEC", this.specChars)) ;
		}

	}

	async submit() {
		if (!this.password) {
			return;
		}

		let prom = TaskRoutes.auth.api_user_register({ key: this.key, password: this.password });
		progress.listen("set_password", prom);

		try {
			await prom;
			// @TODO: Display helpful message!
			utils.router.navigateByUrl("/login");
		} catch (err) {
			const ERR_BASE = {
				title: language.translate("SETPASS.ERROR"),
				icon: "error",
				color: color.Variable.warn
			};

			switch (err.internal_error_code) {
				case 4104: {
					notification.show({
						...ERR_BASE,
						message: language.translate("SETPASS.ERROR.NOUSER"),
					});
				} break;
				case 4107: {
					if (err.error_argument == "Email expired.") {
						notification.show({
							...ERR_BASE,
							message: language.translate("SETPASS.ERROR.EXPIRED"),
						});
					} else {
						notification.show({
							...ERR_BASE,
							message: language.translate("SETPASS.ERROR.INVALID"),
						});
					}
				} break;
			}

			utils.router.navigateByUrl("/login");
		}
	}
}
