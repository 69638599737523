<ng-container *ngIf="order">
<div class="order-header">
    <div class="flex-row justify-space-between" style="margin-bottom: 0.25em">
        <div class="flex-column">
            <div class="flex-row">
                <i [attr.icon]="icon" [style.color]="color" [title]="status_name"></i>
                <h2 style="margin: 0 0 0 0.5ch">#{{order.order_id}} {{order.title}}</h2>
            </div>
            <div class="flex-row align-baseline" style="padding-left: 25px; margin-top: .7em;">
                <i><strong>#{{ order.location_cin}}</strong></i> &nbsp;
                <i style="display: inline-block; margin-top: .25em;">{{ order.location_name }}</i>
            </div>
        </div>

        <div class="flex-column align-flex-end" style="padding-left: 25px; margin-top: .7em;">
            <i><strong>{{ order.supplier_name}}</strong></i>
            <i style="display: inline-block; margin-top: .25em;">{{ order.created_date | datefmt:"short_date_time" : false }}</i>
        </div>
    </div>

    <div class="for-toolbar flex-row" *ngIf="!edited">
        <button class="round filled" color="secondary"
            title="{{ 'FINANCIAL.TITLE' | trans }}"
            *ngIf="can_edit_financial_details"
            (click)="show_financial_details()">
            <i icon="price_change"></i>
        </button>

        <button class="round filled" color="secondary"
            title="{{ 'PDF.ORDER' | trans }}"
            *ngIf="can_get_pdf"
            (click)="show_invoice_proposal()">
            <i icon="picture_as_pdf"></i>
        </button>

        <button class="round filled" color="#fbb014"
            title="{{ 'PDF.ORDER.NO_PRICES' | trans }}"
            *ngIf="can_get_pdf_without_prices"
            (click)="show_invoice_proposal(false)">
            <i icon="picture_as_pdf"></i>
        </button>

        <button class="round filled" color="secondary"
            title="{{ 'FINANCIAL.INVOICE' | trans }}"
            *ngIf="can_invoice"
            (click)="invoice()">
            <i icon="receipt_long"></i>
        </button>

        <button *ngIf="isOrderTypeInventory && isArrivalDetails" class="round filled" color="secondary"
            title="{{ 'ORDER.FINANCIAL_ITEMS_ASSING_TITLE' | trans }}"
            (click)="assign_arrival_details()">
            <i icon="list"></i>
        </button>

        <div class="flex-dynamic"></div>

        <button class="round filled" color="secondary"
            title="{{ 'ORDER.REASSIGN' | trans }}"
            *ngIf="can_reassign"
            (click)="reassign_supplier()">
            <i icon="local_shipping"></i>
        </button>

        <button class="round filled" color="secondary"
            title="{{ 'ORDER.PLAN' | trans }}"
            *ngIf="can_plan_arrival"
            (click)="create_arrival()">
            <i icon="today"></i>
        </button>

        <button class="round filled" color="secondary"
            title="{{ 'ORDER.INTERNAL_APPROVAL.TITLE' | trans }}"
            *ngIf="can_see_internal_approval_status && can_send_approval_email"
            (click)="send_internal_approval_email()">
            <i icon="mark_email_read"></i>
        </button>

        <button class="round filled" color="warn"
            title="{{ 'ORDER.REJECT' | trans }}"
            *ngIf="can_reject"
            (click)="reject_order()">
            <i icon="not_interested"></i>
        </button>

        <button class="round filled" color="warn"
            title="{{ 'ORDER.DELETE' | trans }}"
            *ngIf="can_delete"
            (click)="delete_order()">
            <i icon="delete"></i>
        </button>

        <knw-spinner color="primary" size=32 *ngIf="'order_admin_action' | inProgress" />
        <details *ngIf="can_see_admin_actions() && !('order_admin_action' | inProgress)"
            #adminMenu
            class="as-button fake-button filled round"
            style="padding: 0; margin-left: 4px"
            (clickOutside)="adminMenu.open = false"
        >
            <summary class="no-arrow" style="padding: .5em">
                <i icon="admin_panel_settings"></i>
            </summary>
            <div class="admin-floating shadow-bottom">
                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_see_order_history"
                    (click)="get_order_history_report(); adminMenu.open = false">
                    <i [attr.icon]="ICON_ORDER_HISTORY.icon"
                        [style.color]="ICON_ORDER_HISTORY.color"></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="watch_later"></i>
                    {{ 'ORDER.ORDER_HISTORY' | trans }}
                </button>

                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_revert_review"
                    (click)="revert_review(); adminMenu.open = false">
                    <i [attr.icon]="ICON_REVIEW.icon"
                        [style.color]="ICON_REVIEW.color"></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                    {{ 'ORDER.REVERT_REVIEW' | trans }}
                </button>

                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_revert_execution"
                    (click)="revert_execution() ; adminMenu.open = false">
                    <i [attr.icon]="ICON_EXECUTION.icon"
                        [style.color]="ICON_EXECUTION.color"
                    ></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                    {{ 'ORDER.REVERT_EXECUTION' | trans }}
                </button>

                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_revert_executed"
                    (click)="revert_executed() ; adminMenu.open = false">
                    <i [attr.icon]="ICON_EXECUTED.icon"
                        [style.color]="ICON_EXECUTED.color"
                    ></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                    {{ 'ORDER.REVERT_EXECUTED' | trans }}
                </button>

                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_revert_items"
                    (click)="revert_items() ; adminMenu.open = false">
                    <i icon="price_change" style="color: rgb(var(--secondary-dark))"></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_rewind"></i>
                    {{ 'ORDER.REVERT_ITEMS' | trans }}
                </button>

                <button class="sharp"
                    style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                    *ngIf="can_admin_delete"
                    (click)="admin_delete() ; adminMenu.open = false">
                    <i icon="delete" style="color: rgb(var(--warn))"></i>
                    <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="clear"></i>
                    {{ 'ORDER.ADMIN_DELETE' | trans }}
                </button>

            </div>
        </details>

    </div>

    <div class="flex-row for-toolbar justify-flex-end" *ngIf="edited">
        <button class="filled pill outlined"
                color="secondary"
                (click)="save()">
            <span class="screen-big">
                {{ "SAVE" | trans }}
            </span>
            <i icon="save"></i>
        </button>


        <button class="outlined pill"
                style="margin-right: 0"
                color="primary"
                (click)="cancel_edit()">
            <span class="screen-big">
                {{ "CANCEL" | trans}}
            </span>
            <i icon="cancel"></i>
        </button>
    </div>

    <div class="flex-column" style="margin-bottom: 1em; grid-gap: .25em;" >
        <span *ngIf="order.created_by_id">
            <b>{{ 'TICKET.ASSIGNED_TO' | trans }}</b>:&nbsp;
            <ng-container *ngIf="order.authorized_maintenance_managers.length>0">
                <span *ngFor="let mng of order.authorized_maintenance_managers; let i = index;">
                    {{ mng.first_name }} {{ mng.last_name }}
                    <span *ngIf="i !== order.authorized_maintenance_managers.length - 1">, </span>
                </span>
            </ng-container>
        </span>

        <span *ngIf="order.approved_by_id">
            <b>{{ 'ORDER.APPROVED_BY' | trans }}:&nbsp;</b>
            {{ order.approved_by_first_name }} {{ order.approved_by_last_name }}
        </span>

        <span *ngIf="order.status_id === 7">
            <b>{{ 'ORDER.APPROVED_DATE' | trans }}:&nbsp;</b>
            {{ order.status_date | datefmt : "short_date_time" : false }}
        </span>

        <span *ngIf="is_rejected && order.comment">
            <b>{{ 'TICKET.REJECTED_REASON' | trans }}</b>:&nbsp;
            {{ order.comment }}
        </span>

        <span *ngIf="is_deleted && order.comment">
            <b>{{ 'ORDER.DELETED.REASON' | trans }}</b>:&nbsp;
            {{ order.comment }}
        </span>

        <span *ngIf="order.arrival_worker_id">
            <b>{{ 'USER.TYPE.COMPANY_WORKER' | trans }}:&nbsp;</b>
            {{ order.arrival_worker_first_name }} {{ order.arrival_worker_last_name }}
        </span>

        <span *ngIf="order.investment_type != undefined">
            <b>{{ 'ORDER.INVESTMENT_TYPE' | trans }}:&nbsp;</b>
            {{ 'ORDER.INVESTMENT_TYPE.' + itype[order.investment_type] | trans }}
        </span>
        <span *ngIf="order.cost_estimate != undefined">
            <b>{{ 'ORDER.COST_ESTIMATE' | trans }}:&nbsp;</b>
            {{ order.cost_estimate }}
        </span>
        <span *ngIf="order.invoice_no">
            <b>{{ 'ORDER.INVOICE_NO' | trans }}:&nbsp;</b>
            {{ order.invoice_no }}
        </span>
        <span class="flex-row align-center wrappable">
            <b>{{ 'NAVROUTE.WORKHIER' | trans }}:&nbsp;</b>
            <i>{{ order.whier.category_description }}</i>  <i icon="chevron_right"></i>
            <i>{{  order.whier.subcategory_description  }}</i>  <i icon="chevron_right"></i>
            <i>{{  order.whier.description  }}</i>
        </span>
        <span *ngIf="order.insurance || order.preinvoice || order.lease || order.inspection">
            <b>{{ 'ORDER.CODE' | trans }}:&nbsp;&nbsp;</b>
            <span *ngIf="order.insurance">OS<span *ngIf="order.preinvoice || order.lease || order.inspection">,&nbsp;</span></span>
            <span *ngIf="order.preinvoice">PR<span *ngIf=" order.lease || order.inspection">,&nbsp;</span></span>
            <span *ngIf="order.lease">NA<span *ngIf="order.inspection">,&nbsp;</span></span>
            <span *ngIf="order.inspection">IN</span>

        </span>

        <strong *ngIf="order.complaint" class="label-pill uppercase" color="yellow-dark" style="align-self: flex-start; font-size: 80%; margin-top: .5em;">
            {{'ORDER.RECLAMATION' | trans }}
        </strong>
    </div>

</div>

<div class="order-details">
    <h4 style="margin-bottom: 0.5em;">{{ 'DETAILS' | trans }}</h4>
    <div class="order-details-list">
        <!-- DESCRIPTIONS -->
        <p *ngFor="let detail of description_details" style="margin-bottom: 1.5em;">
            <task-click-toggle [can-toggle]="can_edit">
                <div on>
                    <label class="textarea" on>
                        <textarea [(ngModel)]="detail.description"
                                  (ngModelChange)="edited = true">
                        </textarea>
                    </label>
                </div>
                <div class="flex-row"
                    [ngClass]="{'bg-hover': can_edit}" off>
                    <div class="flex-dynamic">
                        {{detail.description}}
                    </div>
                    <i icon="edit" *ngIf="can_edit"></i>
                </div>
            </task-click-toggle>
        </p>

        <!-- INVENTORY -->
        <task-kam-inventory *ngFor="let detail of inventory_details"
                [inventory]="detail?.inventory_data || detail">
        </task-kam-inventory>

        <div  style="margin: 2em 0 1.5em;">
            <i style="margin-bottom: .25em; display: block;"><b>{{ 'INVENTORY.PICK' | trans }}</b></i>
            <task-inventory-picker
                *ngIf="can_edit"
                [ngModel]="additional_inventory"
                (ngModelChange)="
                    additional_inventory=$event;
                    edited=true"
                [location_cin]="order.location_cin">
            </task-inventory-picker>
        </div>

        <p>
            <i><b>{{ 'TICKET.IMAGES' | trans }}</b></i>
        </p>
        <task-multi-image
            [(ngModel)]="images"
            (ngModelChange)="edited=true"
            [allow-deleting-uploaded]="true"
            [disabled]="!can_edit_images">
        </task-multi-image>

        <ng-container *ngIf="can_see_financial_documents">
            <p>
                <i><b>{{ 'FINANCIAL_FILES' | trans }}</b></i>
            </p>
            <task-upload-files
                [(ngModel)]="financial_files"
                (ngModelChange)="edited=true"
                [allow-deleting-uploaded]="true"
                [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                [disabled]="!can_edit_financial_documents"
                [download_file]="download_file"
                [button-label]="'ADD_FINANCIAL_FILE'">
            </task-upload-files>
        </ng-container>

        <ng-container>
            <p>
                <i><b>{{ 'FILES' | trans }}</b></i>
            </p>
            <task-upload-files
                [(ngModel)]="service_files"
                (ngModelChange)="edited=true"
                [allow-deleting-uploaded]="true"
                [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                [disabled]="!can_edit_documents"
                [download_file]="download_file"
                [button-label]="'ADD_FILE'">
            </task-upload-files>
        </ng-container>

    </div>
</div>

<task-responsive-container *ngIf="can_see_tickets"
        [classes]="'order-tickets'"
        [title]="'TICKET.RELATED'">
    <ng-template>
    <div class="flex-column flex-dynamic" style="overflow: auto">
        <ng-container *ngFor="let ticket of tickets">
            <button class="filled flex-row align-flex-start justify-flex-start"
                    color="secondary"
                    [goto]="'/tickets/' + ticket.ticket_id">
                <b> #{{ticket.ticket_id}}</b> &nbsp;
                <i>{{ticket.title}}</i>
            </button>
        </ng-container>
    </div>
    </ng-template>
</task-responsive-container>

<div class="order-arrivals screen-big">
    <h4 style="margin: 0">{{ 'ARRIVALS.RELATED' | trans }}</h4>
    <div class="flex-dynamic" style="overflow: auto">
        <details *ngFor="let arrival of arrivals"
        class="flex-static"
                [ngClass]="{'no-items': arrivalHasNoDetails | fn:arrival}"
                style="padding: 0 0.5em">
            <summary class="flex-row justify-space-between align-center">
                <a [goto]="'/arrivals/' + arrival.arrival_id"
                class="plain-link align-center flex-row"
                style="cursor: pointer;">
                    <i [style.color]="arrival.icon_color" [attr.icon]="arrival.icon_name" [title]="arrival.status_name" ></i>

                    <span *ngIf="!(arrival.emergency || arrival.ended_without_qr)" style="display: inline-block; margin: 0 0.3em;">{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}}</span>

                    <ng-container *ngIf="arrival.emergency || arrival.ended_without_qr">
                        <span  style="display: inline-block; margin: 0 0.3em;" [attr.tooltip-right]="(arrival.emergency?'ARRIVAL.DETAILS.EMERGENCY_TITLE' : 'ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE') | trans">{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}} </span>
                        <i *ngIf="arrival.emergency" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                        <i *ngIf="arrival.ended_without_qr" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                    </ng-container>
                </a>

                <i>{{arrival | arrival_time | datefmt}}</i>

            </summary>

            <ng-container *ngFor="let detail of arrival.arrival_details">
                <div *ngIf="detail.arrival_detail_type_id == 3"
                        class="flex-row justify-space-between arrival-detail"
                        [title]="detail.element_name">
                    <b>{{ detail.element_name }}</b>
                    <span style="white-space: nowrap" *ngIf="can_see_individual_prices">
                        <task-price [label]="null"
                            [price]="detail.element_price * detail.element_quantity"
                            [currency]="detail.element_currency"></task-price>
                    </span>
                </div>
            </ng-container>

        </details>
    </div>

    <div style="padding: 0 0.5em" class="flex-row justify-space-between" *ngIf="show_currency">
        <h3>{{ 'ORDER.TOTAL' | trans }}</h3>

            <div style="margin: 0.5em"
                    *ngFor="let sum of currency_sums | keyvalue">
                <task-price [currency]="sum.key" [price]="sum.value || 0"></task-price>
            </div>

    </div>
</div>

<details class="order-arrivals screen-small">
    <summary>
        <h4 style="margin: 0">{{ 'ARRIVALS.RELATED' | trans }}</h4>
        <div class="flex-row jusfity-space-between">
            <div style="margin: 0.5em 0.5em .5em 0;"
                    *ngFor="let sum of currency_sums | keyvalue">
                <task-price [currency]="sum.key" [price]="sum.value || 0"></task-price>
            </div>
        </div>
    </summary>
    <div class="flex-dynamic" style="overflow: auto">
        <div *ngFor="let arrival of arrivals"
                [ngClass]="{'no-items': arrivalHasNoDetails | fn:arrival}"
                class="flex-static"
                style="padding: 0.5em 0.5em">
            <div class="flex-row justify-space-between align-center">
                <a [goto]="'/arrivals/' + arrival.arrival_id"
                class="plain-link align-center flex-row"
                style="cursor: pointer;">
                    <i [style.color]="arrival.icon_color" [attr.icon]="arrival.icon_name" [title]="arrival.status_name" ></i>

                    <span *ngIf="!arrival.emergency" style="display: inline-block; margin: 0 0.3em;">{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}}</span>

                    <ng-container *ngIf="arrival.emergency">
                        <span  style="display: inline-block; margin: 0 0.3em;" tooltip-right='Izvanredna intervencija'>{{ 'ARRIVAL' | trans }} #{{arrival.arrival_id}} </span>
                        <i icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                    </ng-container>
                </a>
                <i>{{arrival.planned_start_time | datefmt}}</i>
            </div>
        </div>
    </div>
</details>

<div class="order-comments">
    <task-comments [entity]="entity" [entity_id]="order.order_id"></task-comments>
</div>

</ng-container>
