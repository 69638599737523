import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalBase, modal, utils } from 'curvy';
import { Task } from '@task-utils/types';

type CalendarEvent = Task.CalendarEvent & { day: number };

@Component({
	selector: 'task-event-info',
	template: `
		<div class="fullheight-noscroll flex-column">
			<div class="flex-row justify-space-between align-center">
				<h2 class="modal-title">
					<span *ngIf="events[0].type === 'ORDER'" style="margin: 0;">{{ 'NAVROUTE.ORDERS' | trans }}</span>
					<span *ngIf="events[0].type === 'ARRIVAL'" style="margin: 0;">{{ 'NAVROUTE.ARRIVALS' | trans }}</span>
					<span *ngIf="events[0].type === 'SCHEDULED_ORDER'" style="margin: 0;">{{ 'NAVROUTE.SCHEDULED_ORDERS' | trans }}</span>
					- <span style="font-size: 80%;">{{date | datefmt : 'short_date'}}</span>
				</h2>
				
				<button (click)="submit(null)" class="round filled" color="primary" style="margin-left: auto; margin-bottom: 1em; "><i icon="close"></i></button>
			</div>
			<div class="flex-column calendar-popover-wrapper" style="width: 100%; gap: .5em; overflow: auto; box-sizing: border-box;">
				<ng-container *ngFor="let event of events">
					<a class="flex-row day-order" [ngStyle]="{
							borderLeft: '6px solid '+(setStatus | fn : event.status_id).color
						}" [href]="event.url" (click)="openLink(event); $event.preventDefault()">
						<i [attr.icon]="(setStatus | fn : event.status_id).icon"
							[ngStyle]="{marginRight: '1em', color: (setStatus | fn: event.status_id).color}"></i>
						<span class="flex-column align-flex-start flex-dynamic">
							<span class="flex-row align-flex-start"><b>#{{ event.event_id }}</b>&nbsp;<i class="truncate-text" style="padding-right: 0.15em;">{{ event.event_title }}</i></span>
							<span>#{{ event.location_cin }} {{event.location_name}}</span>
							<strong>{{ event.supplier_name }}</strong>
						</span>
					</a>
				</ng-container>
			</div>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
    styleUrls: ['./orders-calendar.component.scss']
})

export class EventInfoPopover extends ModalBase<CalendarEvent[], any> implements OnInit {
	@Input()
	events: CalendarEvent[];
	date: Date = null;

	setStatus(id: number) {
		return Task.get_status_icon(id);
	}

	openLink(event:Task.CalendarEvent) {
		if(event.has_own_url) {
			utils.router.navigateByUrl(event.url);	
			modal.close();	
		}
	}

	ngOnInit(): void {
		if (this.injectedData instanceof Promise) {
			this.injectedData.then(res=> this.events = res)
		} else {
			this.events = this.injectedData;
		}

		this.date = this.events[0].date;
	}
}