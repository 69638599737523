import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';

type InType = {
	title: string;
	label: string;
	placeholder?: string;

	message?: {
		message: string;
		icon: string;
		iconColor: string;
	};

	required?: boolean;
	confirmText?: string;
	cancelText?: string;
	buttonColor?: string;
};

@Component({
	selector: 'task-textbox-dialog',
	template: `
		<h2 class="modal-title">{{ injectedData.title | trans }}</h2>

		<div class="flex-row align-center" *ngIf="injectedData.message">
			<i class="large flex-auto"
				[attr.icon]="injectedData.message.icon"
				[knwStyle]="{
					color: injectedData.message.iconColor
				}"
			></i>
			<div class="flex-dynamic" style="flex: 12; white-space: pre-line">
				{{injectedData.message.message}}
			</div>
		</div>

		<label class="textarea" [title]="injectedData.label | trans">
			<textarea rows="4"
					[placeholder]="(injectedData.placeholder || injectedData.label) | trans"
					[(ngModel)]="text">
			</textarea>
		</label>

		<div class="flex-row modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				{{ (injectedData?.cancelText || 'CANCEL') | trans }}
			</button>

			<div class="flex-dynamic"></div>
			<button class="filled"
					color="secondary"
					[attr.color]="injectedData.buttonColor || 'secondary'"
					(click)="done()"
					[disabled]="injectedData.required && (text ?? '').trim().length == 0"
			>
				<i [attr.icon]="injectedData?.message?.icon ?? 'check'"></i>
				{{ (injectedData?.confirmText || 'SAVE') | trans }}
			</button>
		</div>
	`
})
export class TextBoxDialog extends ModalBase<InType, string> {
	preventSoftClose = true;
	text: string;

	done() {
		this.submit(this.text);
	}

	cancel() {
		this.submit(null);
	}
}
