<ng-container *ngIf="arrival">

<div class="arrival-header">
    <div class="flex-row" style="margin-bottom: 0.25em">
        <div class="flex-column">
            <div class="flex-row">
                <i [attr.icon]="icon" [style.color]="color" [title]="status_name"></i>
                <h2 style="margin: 0 0 0 0.5ch">#{{arrival.arrival_id}} {{arrival.order.supplier_name}}</h2>
            </div>
            <div class="flex-row align-baseline" style="padding-left: 25px; margin-top: .7em; margin-bottom: .25em;">
                <i><strong>#{{ arrival.order.location_cin}}</strong></i> &nbsp;
                <i style="display: inline-block; margin-top: .25em;">{{ arrival.order.location_name }}</i>
            </div>
        </div>

        <div class="flex-dynamic"></div>

        <div class="flex-column align-flex-end" style="padding-left: 25px;">
            <i style="display: inline-block">
                {{ (arrival.created_date) | datefmt }}
            </i>

        </div>
    </div>

    <div class="flex-row" style="margin-bottom: 0.25em; padding-left: 25px;">
        <b>{{ "ARRIVAL.PLAN.DATE" | trans }}:&nbsp;</b>
        {{ (arrival.start_time || arrival.planned_start_time) | datefmt : 'short_date_time' : false }}
        ({{ (arrival.start_time || arrival.planned_start_time) | datefmt : 'R' }})
    </div>

    <div class="flex-row" style="margin-bottom: 0.25em; padding-left: 25px;" *ngIf="arrival.end_time">
        <b>{{ "ARRIVAL.PLAN.END_DATE" | trans }}:&nbsp;</b>
        {{ arrival.end_time | datefmt : 'short_date_time' : false }}
        ({{ arrival.end_time | datefmt : 'R' }})
    </div>

    <div class="flex-row" style="margin-bottom: 0.25em; padding-left: 25px;">
        <b>{{ "ARRIVAL.CREATED_BY" | trans}}:&nbsp;</b>
        {{ arrival.created_by_first_name }} {{ arrival.created_by_last_name }}
    </div>

    <div *ngIf="arrival.ended_without_qr" class="flex-row" style="margin-bottom: 0.25em; padding-left: 25px;">
        <b>{{ "ARRIVAL.ENDED_BY" | trans}}:&nbsp;</b>
        {{ arrival.ended_by_first_name }} {{ arrival.ended_by_last_name }}
    </div>

    <div  class="flex-row wrappable" style="margin-bottom: 0.5em; padding-left: 25px; gap: .3em;">
        <strong *ngIf="arrival.emergency"  class="label-pill uppercase" color="orange" style="align-self: flex-start; font-size: 80%; margin-top: .5em; background-color:rgb(var(--orange));">
            {{'ARRIVAL.DETAILS.EMERGENCY_TITLE' | trans }}
        </strong>

        <strong *ngIf="arrival.ended_without_qr" class="label-pill uppercase" style="align-self: flex-start; font-size: 80%; margin-top: .5em; background-color:rgb(var(--orange));">
            {{'ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE' | trans }}
        </strong>
    </div>

    <div class="flex-row justify-center"
         style="margin-bottom: 0.25em"
         >
        <button class="filled pill screen-big"
                color="primary"
                (click)="show_qr_code()"
                style="padding: .8em 1.4em; letter-spacing: 0.07em;"
                *ngIf="canStartArrival">
            <i icon="qr_code_scanner"></i>
                &nbsp;
                QR -
                <strong *ngIf="arrival.status_id == 1">
                    {{ 'ARRIVAL.START' | trans }}
                </strong>
                <strong *ngIf="arrival.status_id == 6">
                    {{ 'ARRIVAL.END' | trans }}
                </strong>
        </button>

        <button class="filled"
                *ngIf="can_complete"
                color="primary"
                title="{{ 'ARRIVAL.COMPLETION' | trans }}"
                [ngClass]="[canStartArrival ? 'round' : 'pill']"
                [ngStyle]="{'padding': canStartArrival ? '.76em' : '.8em 1.4em', 'letter-spacing': canStartArrival ? '' : '.07em'}"
                (click)="complete_arrival()">
            <i icon="fact_check"></i>
            <ng-container *ngIf="!canStartArrival">
                &nbsp;
                <strong>{{'ARRIVAL.COMPLETION' | trans}}</strong>
            </ng-container>
        </button>
    </div>

    <div class="flex-row justify-space-between wrappable" style="padding: 1em 0;">
        <div class="flex-row flex-align-center">
            <a [goto]="'/orders/' + arrival.order_id" style="cursor: pointer">
                <i style="vertical-align: middle"
                        icon="ballot"></i>
                <span class="truncate-text" style="vertical-align: middle;">
                    #{{arrival.order_id}}
                    <i>{{ arrival.order.title }}</i>
                </span>
            </a>

        </div>

        <button class="filled round screen-small"
                color="primary"
                (click)="show_qr_code()"
                *ngIf="canStartArrival">
            <i icon="qr_code_scanner"></i>
        </button>

        <div class="flex-row">
            <button class="round filled"
                    color="secondary"
                    title="{{ 'ARRIVAL.DETAILS' | trans }}"
                    [goto]="'/arrivals/' + arrival.arrival_id + '/details'">
                <i icon="list"></i>
            </button>
            <button class="round filled" color="secondary" (click)="get_report()"
                *ngIf="canGetPDF" title="{{ 'PDF.ARRIVALS' | trans }}">
                <i icon="picture_as_pdf"></i>
            </button>

            <button class="round filled" color="warn"
                title="{{ 'ARRIVAL.DELETE.TITLE' | trans }}"
                *ngIf="can_delete"
                (click)="delete_arrival()">
                <i icon="delete"></i>
            </button>

            <button class="round filled" color="warn"
                title="{{ 'ARRIVAL.DELETE.TITLE' | trans }}"
                *ngIf="!can_delete && can_admin_delete"
                (click)="admin_delete_arrival()">
                <i icon="delete_forever"></i>
            </button>

        </div>
    </div>
</div>

<knw-data-table class="order-table"   #orderTable   [config]="orderConfig">
</knw-data-table>

<knw-data-table class="arrival-table" #arrivalTable [config]="arrivalConfig">
</knw-data-table>

<knw-data-table class="worker-table"  #workerTable  [config]="workerConfig">
</knw-data-table>

<task-responsive-container *ngIf="canSeeTickets"
        [classes]="'tickets screen-big'"
        [title]="'ARRIVAL.RELATED.TICKETS'">
    <ng-template>
    <div class="flex-column flex-dynamic" style="overflow: auto">
        <ng-container *ngFor="let ticket of tickets">
            <button class="filled flex-row justify-flex-start align-flex-start"
                    color="secondary"
                    [goto]="'/tickets/' + ticket.ticket_id">
                <b> #{{ticket.ticket_id}}</b> &nbsp;
                <i style="white-space: normal">{{ticket.title}}</i>
            </button>
        </ng-container>
    </div>
    </ng-template>
</task-responsive-container>

<div class="arrival-comments">
    <task-comments [entity]="entity" [entity_id]="arrival.arrival_id"></task-comments>
</div>
</ng-container>
