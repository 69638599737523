import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Task } from '@task-utils/types';
import { language } from 'curvy';
import { Comments } from './comments';
import { comments_translations } from './comments.trans';

@Component({
  selector: 'task-new-comments',
  templateUrl: './comments-new.component.html',
  styleUrls: ['./comments-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsNewComponent implements OnChanges {
  @ViewChild('commentscontainer')
  comments_container: any = ElementRef;

  @Input()
  entity: Task.ENTITY;
  @Input()
  entity_id: number;

  comments: Task.Comment[] = [];
  comment_text = '';

  constructor() {
    language.load_translations(comments_translations);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.get_comments();
  }

  scroll_to_bottom() {
    this.comments_container.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  async get_comments() {
    if (this.entity && this.entity_id) {
      try {
        const res = await Comments.get(this.entity, this.entity_id);
        if (res.status === 'success') {
          this.comments = res.data;
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
  }

  async add_comment() {
    if (this.entity && this.entity_id && this.comment_text.trim().length > 0) {
      try {
        await Comments.add(this.entity, this.entity_id, this.comment_text);
        this.comment_text = '';
        await this.get_comments();
        setTimeout(() => {
          this.scroll_to_bottom();
        }, 1);
      } catch (error) {
        console.error('Error', error);
      }
    }
  }
}
