import { Component,ViewEncapsulation, ViewChild } from '@angular/core';
import { language, modal, color, noOp, Frame, DataTableComponent, template, date, GenericFilterComponent, GenericFilter, ConfirmDialogComponent, spreadsheet, popover, notification, PopoverMenuComponent, utils } from 'curvy';
import { Task } from '@task-utils/types';
import { get_dates, last_day_of_year } from '@task-utils/calculate_dates';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { components_trans } from '@task-components/components.trans';
import { locations_translations } from '@task-modules/locations/locations.trans';
import { tickets_translations } from '@task-modules/tickets/tickets.trans';
import { suppliers_translations } from '@task-modules/suppliers/suppliers.trans';
import { EditScheduledOrderDialog } from './edit-scheduled-order.dialog';
import { EditScheduledOrderApprovedDialog } from './edit-schedule-order-approved.dialog';
import { ScheduledOrderInfo } from './scheduled-order-info.component';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';
import { SelectedRowsInfoComponent } from './selected-rows-info.component';
import { set_sch_order_to_copy } from './scheduled-orders';
import { toScheduledOrder, toScheduledOrderTemplate } from '../orders.types';

type TableType = DataTableComponent<Task.ScheduledEvent, DisplayScheduledOrder,  'scheduled_orders', GenericFilterComponent>;
@Component({
    selector: 'task-scheduled-orders',
    template: `
    <div class="temp-add-button">
        <button [goto]="'/scheduled-orders/create-scheduled-order'"
            class="filled pill"
            color="primary"
            *ngIf="can_create_order">
            <i icon="add_circle"></i>
            <span>{{ 'ORDER.SCHEDULED.NEW' | trans }}</span>
        </button>
    </div>

    <knw-data-table #scheduledordersTable [config]="tableConfig"></knw-data-table>`,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-scheduled-orders {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }

        html task-scheduled-orders .table-wrapper .table-column-details.table-body-cell,
        html task-scheduled-orders .table-wrapper .table-column-status.table-body-cell,
        html task-scheduled-orders .table-wrapper .table-column-supplier.table-body-cell,
        html task-scheduled-orders .table-wrapper .table-column-title.table-body-cell {
            display: flex;
            flex-flow: column nowrap;
        }

        html task-scheduled-orders .table-wrapper .table-column-title.table-body-cell {
            align-items: stretch;
            text-align: left;
        }
    `]
})
export class ScheduledOrdersComponent /*implements OnInit*/ {

    @ViewChild('scheduledordersTable')
    table: TableType;

    currUser: Task.User = null;

    can_create_order = false;

    annual_frequency = {...Task.Annual_Frequency};

    is_rejected = false;
    is_approved = false;

    constructor() {
        Frame.set({
            title: "NAVROUTE.SCHEDULED_ORDERS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(orders_translations);
        language.load_translations(locations_translations);
        language.load_translations(components_trans);
        language.load_translations(suppliers_translations);
        language.load_translations(tickets_translations);

        this.resolve_rights();
    }

    async resolve_rights() {
        this.can_create_order = await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN
        )
    }

    async search_suppliers(search:string) {
        let res = await TaskRoutes.suppliers.api_supplier_search({
            search: [search], attributes: ["company.name"]
        })

        return res.data;
    }

    format_supplier_name(sup: Task.Supplier) {
        if (!sup) return;
        return `${sup.name}`
    }

    async search_users(search: string) {
        let wanted_types = [
                // Task.USER_TYPE.SAFETY_MANAGER + "",
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER + ""
        ];
        let unwanted_types = Object.keys(Task.User_Type).filter(u => !wanted_types.includes(u));

        let res = await TaskRoutes.users.api_user_search({
            search: [...unwanted_types, search],
            attributes: [...unwanted_types.map(() => "!#:user_type_id"), "first_name+ +last_name"]
        }, {
            page_no: 1,
            page_size: 25
        })

        return ["me", "others", ...res.data]

    }

    format_users(user: Task.User | "others" | "me") {
        if (!user) return;

        let name = "";

        if (typeof(user) == "string") {
            name = user === "me" ? language.translate("BY_ME") : language.translate("BY_OTHERS");
        } else {
            name = `${user.first_name} ${user.last_name}`;
        }

        return name;
    }

    async search_whier(search: string) {
        console.log(search);

        return (await TaskRoutes.work_hierarchies.api_work_hierarchy_search({
            search: [search, Task.WHIER_LEVEL.CATEGORY+""],
            attributes: ["name+ +description", '#=:level']
        }, {
            page_no: 1,
            page_size: 25
        })).data;


    }

    format_whier_name(whier: Task.WorkHierarchy) {
        if (!whier) return;

        return `${whier.description}`
    }

    displayVertMenuButtons = () => {
        let vert_buttons_menu = [
            {
                label: language.translate('ORDER.SCHEDULED.SELECTED_ROWS'),
                icon: 'info',
                data_1: this.table.getSelectedRows(),
                onClick: (data_1: Task.ScheduledEvent[]) => {
                    popover.open(SelectedRowsInfoComponent, noOp, data_1, noOp, "mouse", "auto", "info-popover-wrapper")
                },
            },
            {
                label: language.translate('APPROVE'),
                icon: 'done',
                data_1: this.table.getSelectedRows(),
                onClick: (data_1: Task.ScheduledEvent[]) => {

                    modal.open(ConfirmDialogComponent, async (data) => {
                        let promisesList: any = []
                        let promiseCount: number = 0

                        try {
                            if (!data) return;
                            for(let item of data_1) {
                                let res = TaskRoutes.scheduled_orders_templates.api_scheduled_order_template_approve(item.event_id, false) // TODO složiti bulk rutu za approve, reject i delete
                                promisesList.push(res)
                            }
                            let allPromises = await Promise.all(promisesList)

                            for(let res of allPromises){
                                if(res.status === 'success'){
                                    promiseCount++
                                }
                            }

                            if(promiseCount > 0) {
                                notification.show({
                                    icon: "check",
                                    color: color.Variable.primary,
                                    title: language.translate('ORDER.SCHEDULED.APPROVED'),
                                    message: language.translate('ORDER.SCHEDULED.SELECTED.APPROVED')
                                });

                                this.table.deselectAll();
                            }
                            this.table.getData();
                        } catch (error) {
                            console.log(error.message)
                            notification.show({
                                icon: "error",
                                color: color.Variable.warn,
                                title: language.translate('ERROR'),
                                message: language.translate('ERROR.GENERIC_TEXT')
                            });

                            this.table.deselectAll();
                        }
                    }, {
                        title: language.translate('ORDER.SCHEDULED_APPROVE_TITLE'),
                        icon: 'receipt',
                        iconColor: color.variable(color.Variable.secondaryDark),
                        message: language.translate('ORDER.SCHEDULED_APPROVE_SERVISES'),
                        confirmButtonText: language.translate('APPROVE'),
                        denyButtonText: language.translate('CANCEL')
                    })
                },
                //disabled: User.currentUser.user_type_id === Task.USER_TYPE.ADMIN || User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN
            },
            {
                label: language.translate('REJECT'),
                icon: "close",
                data_1: this.table.getSelectedRows(),
                onClick: (data_1: Task.ScheduledEvent[]) => {

                    modal.open(TextBoxDialog, async (text) => {
                        let promisesList: any = [];
                        let promiseCount: number = 0;

                        try {
                            if(text) {
                                for(let item of data_1) {
                                    const res = TaskRoutes.scheduled_orders_templates.api_scheduled_order_template_reject(item.event_id, {comment: text}, false);
                                    promisesList.push(res)
                                }
                                let allPromises = await Promise.all(promisesList)

                                for(let res of allPromises){
                                    if(res.status === 'success'){
                                        promiseCount++
                                    }
                                }
                                if(promiseCount > 0) {
                                    notification.show({
                                        icon: "check",
                                        color: color.Variable.primary,
                                        title: language.translate('ORDER.SCHEDULED.REJECT'),
                                        message: language.translate('ORDER.SCHEDULED.SELECTED.REJECTED')
                                    })
                                    this.table.deselectAll();
                                }

                                this.table.getData();
                            }
                        } catch (error) {
                            console.log(error.message)
                            notification.show({
                            icon: "error",
                            color: color.Variable.warn,
                            title: language.translate('ERROR'),
                            message: language.translate('ERROR.GENERIC_TEXT')
                            })

                            this.table.deselectAll();
                        }
                        }, {
                            title: language.translate('ORDER.SCHEDULED_REJECT_TITLE'),
                            label: language.translate('ORDER.SCHEDULED_REJECT_REASON'),
                        },
                    )
                },
            },
            {
                label: language.translate('DELETE'),
                icon: "delete",
                data_1: this.table.getSelectedRows(),
                onClick: (data_1: Task.ScheduledEvent[]) => {

                    modal.open(TextBoxDialog, async (text) => {
                    let promisesList: any = []
                    let promiseCount: number = 0

                        try {
                          if(text) {
                            for(let item of data_1) {
                                let res;
                                if (item.status_id == Task.STATUS.CREATED) {
                                    res = TaskRoutes.scheduled_orders_templates.api_scheduled_orders_template_delete(item.event_id, {comment: text});
                                } else if (item.status_id == Task.STATUS.APPROVED) {
                                    res = TaskRoutes.scheduled_orders.api_scheduled_order_delete(item.event_id, {comment: text});
                                }

                              promisesList.push(res)
                            }
                            let allPromises = await Promise.all(promisesList);

                            for (let res of allPromises){
                                if(res.status === 'success'){
                                    promiseCount++
                                }
                            }

                            if (promiseCount > 0) {
                                notification.show({
                                    icon: "check",
                                    color: color.Variable.primary,
                                    title: language.translate('ORDER.SCHEDULED.DELETE'),
                                    message: language.translate('ORDER.SCHEDULED.SELECTED.DELETED')
                                })
                                this.table.deselectAll();
                            }

                            this.table.getData();
                          }
                        } catch(error){

                          notification.show({
                            icon: "error",
                            color: color.Variable.warn,
                            title: language.translate('ERROR'),
                            message: language.translate('ERROR.GENERIC_TEXT')
                          })

                          this.table.deselectAll();
                        }
                      }, {
                        title: language.translate('ORDER.SCHEDULED_DELETE_TITLE'),
                        label: language.translate('ORDER.SCHEDULED_REJECT_REASON'),
                    })
                },
            }

        ];

        let has_approved = this.table.getSelectedRows().some(r => r.status_id === Task.STATUS.APPROVED);

        if (has_approved) {
            vert_buttons_menu = vert_buttons_menu.filter(btn => {
                if (btn.label === language.translate("APPROVE")) return false;
                if (btn.label === language.translate("REJECT")) return false;

                return true;
            });
        }

        return {actions: vert_buttons_menu}
    }

    async search_companies(search: string) {
        let res = await TaskRoutes.companies.api_company_search(
            { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
            { page_no: 1, page_size: 10 }
        )

        return res.data;
    }

    format_company_name(comp: Task.Company) {
        if (!comp) return "";
        return comp.name;
    }


    getFilters = async () => {
        let filters: GenericFilterComponent["filterInput"] = [
            GenericFilter.text('ORDER.TITLE', "title+ +created_by_first_name+ +created_by_last_name"),
            GenericFilter.dropdown("STATUS", "#=:status_id", [
                { label: "STATUS.CREATED", value: Task.STATUS.CREATED },
                { label: "STATUS.REJECTED", value: Task.STATUS.REJECTED },
                { label: "STATUS.APPROVED", value: Task.STATUS.APPROVED },
                { label: "STATUS.IN_EXECUTION", value: Task.STATUS.IN_EXECUTION },
                { label: "STATUS.EXECUTED", value: Task.STATUS.EXECUTED },
                { label: "STATUS.UNDONE", value: Task.STATUS.UNDONE }
            ]),
            GenericFilter.search("LOCATION", "location", this.search_locations, this.format_location_name, true),
            GenericFilter.date_filter("START_PLANNED_DATE", "planned_start_date_from"),
            GenericFilter.date_filter("END_PLANNED_DATE", "planned_start_date_to"),
            GenericFilter.dropdown("ORDER.SHEDULED.YEARLY_PLAN", "#=:annual_frequency", [
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.YEARLY", value: Task.ANNUAL_FREQUENCY.YEARLY },
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.HALF_YEARLY", value: Task.ANNUAL_FREQUENCY.HALF_YEARLY },
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.QUARTERLY", value: Task.ANNUAL_FREQUENCY.QUARTERLY },
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.MONTHLY", value: Task.ANNUAL_FREQUENCY.MONTHLY },
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.WEEKLY", value: Task.ANNUAL_FREQUENCY.WEEKLY },
                { label: "ORDER.SCHEDULED.YEARLY_PLAN.DAILY", value: Task.ANNUAL_FREQUENCY.DAILY },

            ]),
            GenericFilter.search("COMP.WHIER", "whier", this.search_whier, this.format_whier_name),
        ]

        if (!await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)) {
            filters.splice(2, 0, GenericFilter.search('SUPPLIER', 'supplier', this.search_suppliers, this.format_supplier_name ));
            filters.push(GenericFilter.search('USER.TYPE.COMPANY_WORKER', 'arrival_worker_id', this.search_company_workers, this.format_company_workers)
)
        }

        if (await User.currentTypeIs(
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER,
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN)
        ) {
            let default_value = "me";

            if (await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)) {
                default_value = null;
            }

            filters.push(
                GenericFilter.search("ASSIGNED", "assigned", this.search_users, this.format_users, true, default_value)
            );

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)){
                filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }

        }

        return filters;

    }


    tableConfig: TableType["tableConfig"] = {
        tableName: 'scheduled_orders',
        getData: async (filter, paginator, sort_header) => {

            let parsed_filter = { search: [], attributes: [], sort: null};

            for (let key in filter) {
                if (key == 'planned_start_date_from') {
                    let start = date.start_of_day(filter[key]);
                    parsed_filter.attributes.push("-d:planned_start_date");
                    parsed_filter.search.push(start.toISOString());

                } else if(key === 'arrival_worker_id' && filter['arrival_worker_id'] !== null){
                    parsed_filter.attributes.push("#=:order.arrival_worker_id")
                    parsed_filter.search.push(filter.arrival_worker_id.user_id)

                } else if (key === 'company' && filter['company'] !== null) {
                    parsed_filter.attributes.push('#=:main_company_id')
                    parsed_filter.search.push(filter[key].company_id)

                } else if (key == 'planned_start_date_to') {
                    let end = date.end_of_day(filter[key]);
                    parsed_filter.attributes.push("+d:planned_start_date");
                    parsed_filter.search.push(end.toISOString());

                } else if (key == "supplier" && filter["supplier"] !== null) {
                    parsed_filter.attributes.push("#=:supplier_id");
                    parsed_filter.search.push(filter.supplier.supplier_id);

                } else if (key == "location" && filter["location"] !== null) {
                    parsed_filter.attributes.push("location_id");
                    parsed_filter.search.push(filter.location.location_id);

                } else if (key == "assigned" && filter["assigned"] !== null) {
                    parsed_filter.attributes.push("=:assigned_to");
                    parsed_filter.search.push(filter[key].user_id ?? filter[key]);

                } else if (key == "whier") {
                    parsed_filter.attributes.push("#=:whier_id");
                    parsed_filter.search.push(filter.whier.whier_id);

                } else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key] ?? "");
                }

            }

            if (sort_header) {
                switch (sort_header.label) {
                    case 'title':
                    sort_header.label = 'title';
                    break;
                    case 'supplier':
                    sort_header.label = "supplier_name"
                    break;
                    case 'time':
                    sort_header.label = '+d:planned_start_date';
                    break;
                }
                parsed_filter.sort = sort_header;
            }

            return TaskRoutes.scheduled_orders.api_scheduled_orders_plan_get(parsed_filter, paginator)
        },

        selectableRows: true,
        isSelectable: (row, _, selected) => {
            let is_in_statuses = row.status_id === Task.STATUS.CREATED || row.status_id === Task.STATUS.APPROVED;
            let is_user_role = User.currentUser.user_type_id === Task.USER_TYPE.ADMIN || User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN;

            return is_in_statuses && is_user_role;
        },

        tableActions: [
        {
            icon: 'download',
            label: language.translate("DOWNLOAD_TABLE"),
            onClick: async ()=>{
                let data = await this.tableConfig.getData(
                    this.table.filterComponent.filterOutput,
                    {page_no: 1, page_size: this.table.remoteData.count as any},
                    null as any
                );

                data.data = data.data.flatMap(d => {
                    if (d.type === "SCHEDULED_ORDER") return d;
                    let out: Task.ScheduledEvent[]  = [];
                    let date_spans = get_dates(
                        d.annual_frequency,
                        {
                            start: new Date(d.planned_start_date),
                            end: new Date(d.planned_end_date)
                        },
                        d.cut_date ? new Date(d.cut_date) : last_day_of_year(new Date(d.planned_end_date))
                    );
                    let locs = d.locations;

                    for (let loc of locs) {
                        for (let span of date_spans) {
                            out.push({
                                ...d,
                                locations: [loc],
                                planned_start_date: span.start,
                                planned_end_date: span.end,
                                type: "SCHEDULED_ORDER",
                                approved_or_rejected_by_first_name: "Još nije",
                                approved_or_rejected_by_last_name: "odobreno",
                            });
                        }
                    }

                    return out;
                });

                // @ts-ignore
                spreadsheet.download('servisi.xlsx', data.data.map(d => ({
                    "status": language.translate("STATUS." + Task.Status[d.status_id]),
                    "order_id": d.order_id,
                    "schuedluled_order": d.title,
                    "location_cin": d.locations[0].cin ,
                    "location_name": d.locations[0].name,
                    "location_address": d.locations[0].address,
                    "supplier": d.supplier_name,
                    "planned_start_date": date.get_formatted_date(new Date(d.planned_start_date), "short_date", false),
                    "planned_end_date": date.get_formatted_date(new Date(d.planned_end_date), "short_date", false),
                    "whier": d.whier.category_description,
                    "creator": d.created_by_first_name + " " + d.created_by_last_name,
                    "created_date": date.get_formatted_date(new Date(d.created_date),"short_date", false),
                    "approver_rejector": d.approved_or_rejected_by_first_name + " " + d.approved_or_rejected_by_last_name,
                    "approved_rejected_date": d.approved_or_rejected_date ? date.get_formatted_date(new Date(d.approved_or_rejected_date), "short_date", false) : '',
                    "responsible_users": d.responsible_users ? d.responsible_users.join(", ") : "",
                })), {
                    "status": language.translate("STATUS"),
                    "order_id": language.translate("ORDER.ORDER_ID"),
                    "schuedluled_order": language.translate("ORDER.TITLE"),
                    "location_cin": language.translate("LOCATION.CIN"),
                    "location_name": language.translate("LOCATION.NAME"),
                    "location_address": language.translate("LOCATION.ADDRESS"),
                    "supplier": language.translate("SUPPLIER.SUPPLIER"),
                    "planned_start_date": language.translate("ORDER.SCHEDULED.PLANNED_DATE_START"),
                    "planned_end_date": language.translate("ORDER.SCHEDULED.PLANNED_DATE_END"),
                    "whier": language.translate("COMP.WHIER"),
                    "creator": language.translate("ORDER.SCHEDULED.CREATED_BY"),
                    "created_date": language.translate("CREATED_DATE"),
                    "approver_rejector": language.translate("ORDER.SCEHDULED.APPROVED_BY"),
                    "approved_rejected_date": language.translate('ORDER.APPROVED_REJECTED_DATE'),
                    "responsible_users": language.translate('TICKET.ASSIGNED_TO')
                });

            },
            priority: 1,
        }, {
            icon: "more_vert",
            label: language.translate('EDIT'),
            onClick:() => {
                if(this.table.getSelectedRows().length === 0) {
                    notification.show({
                        icon: "error",
                        color: color.Variable.warn,
                        title: language.translate('ERROR'),
                        message: language.translate('ORDER.SCHEDULED.SELECTED.ROWS')
                    })
                } else {
                    popover.open(
                        PopoverMenuComponent,
                        noOp,
                        this.displayVertMenuButtons(),
                        null,
                        "mouse",
                        "auto"
                    );
                }
            },
            priority: 1,
            isVisible: () => {
                let selected = this.table.getSelectedRows()
                return User.currentUser.user_type_id === Task.USER_TYPE.ADMIN || User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN
            }
        }],
        getHeader: (header)=> {
            switch (header) {
                case 'status':
                    return {icon: "offline_bolt", label: "", sortable: false };

                case 'title':
                    return { icon: "ballot", label: "ORDER.TITLE", sortable: true };

                case 'location':
                    return { icon: "place", label: "LOCATION.NAME", sortable: false };

                case 'supplier':
                    return { icon: "local_shipping", label: "SUPPLIER.SUPPLIER", sortable: true };

                case 'time':
                    return { icon: "schedule", label: "ORDER.TIME", sortable: true };

                case 'whier':
                    return { icon: "account_tree", label: `COMP.WHIER`, sortable: false };

                case 'responsible':
                    return { icon: "person", label: "TICKET.ASSIGNED_TO", sortable: false };
            }
        },

        unwrapData: (order)=> {
            let data = {
                icon_name: Task.StatusIcons[order.status_id].icon,
                icon_color: Task.StatusIcons[order.status_id].color,
                status_name: language.translate("STATUS." + Task.Status[order.status_id]),
                id: order.event_id,
                title: order.title,
                order_id: order.order_id,
                cin: order.locations[0].cin|| '',
                location: order.locations[0].name,
                address: order.locations[0].address || '',
                locations_count: order.locations.length,
                supplier_name: order.supplier_name,
                planned_start_date: order.planned_start_date,
                planned_end_date: order.planned_end_date,
                annual_plan: language.translate('ORDER.SCHEDULED.YEARLY_PLAN.' + this.annual_frequency[order.annual_frequency]),
                category: order.whier.category_description,
                subcategory: order.whier.subcategory_description,
                whier_description: order.whier.description,
                number_of_loc: language.translate('ORDER.SCHEDULED.NUMBER_OF_LOC'),
                responsible_users: order.responsible_users,
            }

            let status_template = `
                <div class="flex-column align-center">
                    <div class="flex-row align-center" style="color: {{icon_color}}">{{icon_name|icon}}</div>
                    <div style="font-size: 0.8em">
                        {{ status_name | trans }}
                    </div>
                </div>
            `;
            let title_template;

            if (order.order_id) {
                title_template = `
                    <div style="padding: .25em 0; width: 100%">
                        <a href="/orders/{{order_id}}" style="cursor: pointer; margin-right: 1ch">
                            <span class="truncate-text" style="vertical-align: middle;">
                                #{{ order_id }}
                            </span>
                        </a>
                        <span style="color: rgb(var(--primary)); text-align: left;">
                            <span class="truncate-text">{{ title }}</span>
                        </span>
                    </div>
                `;
            } else {
                title_template = `
                    <div class="flex-column" style="padding: .25em 0; width: 100%; ">
                        <span style="color: rgb(var(--primary)); text-align: left;">
                            <span class="truncate-text">{{ title }}</span>
                        </span>
                    </div>
                `;
            }
            let location_template = () => {
                let temp = ``;

                switch (order.type) {
                    case "SCHEDULED_ORDER":
                        temp = `
                            <div class="flex-column">
                                <b>#{{cin}} {{ location }}</b>
                                <span>{{ address }}</span>
                            </div>
                        `;
                    break;
                    case "TEMPLATE":
                        temp = `
                            <div class="flex-column">
                                <b>{{number_of_loc}}:</b>
                                <span>{{ locations_count }}</span>
                            </div>
                        `;
                        break;
                }

                return temp;
            };
            let supplier_template = `<div class="flex-column">
                     <b>{{supplier_name}}</b>
                </div>
                `;
            let time_template = () => {
                let temp = "";

                switch (order.type) {
                    case "SCHEDULED_ORDER":
                        temp = `
                            <span class="flex-row">
                                <span>
                                    {{ planned_start_date | date << short_date }} - &nbsp;
                                </span>
                                <span> {{ planned_end_date | date << short_date }}</span>
                            </span>
                        `;
                    break;
                    case "TEMPLATE":
                        temp = `
                            <span class="flex-column">
                                <span>
                                    <span>
                                        {{ planned_start_date | date << short_date }} - &nbsp;
                                    </span>
                                    <span> {{ planned_end_date | date << short_date }}</span>
                                </span>
                                <span>{{ annual_plan }}</span>
                            </span>
                        `;
                    break;
                }

                return temp;
            };
            let whier_template = `
                <span class"flex-row align-center wrappable">
                    <i>{{ category }}</i>
                </span>
            `;

            let responsible_template = ()=> {

                let users = data.responsible_users ? data.responsible_users.join(", ") : "";
                let temp = ``;

                switch(order.type) {
                    case "SCHEDULED_ORDER":
                        temp = `
                            <span class="flex-row align-center wrappable">
                                <span> ${users}</span>
                            </span>
                        `;
                        break;
                    case "TEMPLATE":
                        temp = "";
                        break;
                }

                return temp;
            }

            if (User.currentUser.user_type_id===Task.USER_TYPE.SUPPLIER_ADMIN) {
                return {
                    status: template.transform(status_template, data),
                    title: template.transform(title_template, data),
                    location: template.transform(location_template(), data),
                    time: template.transform(time_template(), data),
                    whier: template.transform(whier_template, data)
                }
            } else {
                return {
                    status: template.transform(status_template, data),
                    title: template.transform(title_template, data),
                    location: template.transform(location_template(), data),
                    supplier: template.transform(supplier_template, data),
                    time: template.transform(time_template(), data),
                    responsible: template.transform(responsible_template(), data),
                    whier: template.transform(whier_template, data)
                }
            }
        },
        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
                label: "Info",
                icon: "info",
                onClick: async (order)=>{
                    popover.open(ScheduledOrderInfo, noOp, order, noOp, "mouse", "auto", "info-popover-wrapper");
                },
                priority: 3
            }, {
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: async (order)=>{
                modal.open(
                    EditScheduledOrderDialog,
                    (val)=>{ if (val) this.table.getData(); },
                    toScheduledOrderTemplate(order)
                )
            },
            priority: 3,
            isVisible: (order)=>{
                let users_can_edit = User.currentUser.user_type_id == Task.USER_TYPE.ADMIN ||
                                    User.currentUser.user_type_id == Task.USER_TYPE.SYSTEM_ADMIN;

                if(order.type === "TEMPLATE" && order.status_id !== Task.STATUS.APPROVED && users_can_edit) return true;
                else return false;
            }
        }, {
            label: language.translate('EDIT'),
            icon: 'edit',
            onClick: async (order) => {
                modal.open(
                    EditScheduledOrderApprovedDialog,
                    (val) => { if(val) this.table.getData() },
                    toScheduledOrder(order)
                );
            },
            priority: 3,
            isVisible: async (order) => {
                if(order.type === 'SCHEDULED_ORDER' && order.status_id === Task.STATUS.APPROVED && (User.currentUser.user_type_id===Task.USER_TYPE.ADMIN ||
                    User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN)) {
                    return true
                } else {
                    return false
                }
            }
        },
        {
            label: language.translate('APPROVE'),
            icon: "done",
            onClick: async (order)=>{
                modal.open(ConfirmDialogComponent, (res)=>{
                    if (res) {
                        TaskRoutes.scheduled_orders_templates.api_scheduled_order_template_approve(order.event_id, false).then(res => {
                            this.is_approved = true;
                            this.is_rejected = false;
                            this.table.getData();
                        }).catch(()=>{
                            notification.show({
                                icon: "error",
                                color: color.Variable.warn,
                                title: language.translate('ERROR'),
                                message: language.translate('ERROR.GENERIC_TEXT')
                            })
                        });
                    }
                }, {
                    title: language.translate('ORDER.SCHEDULED_APPROVE_TITLE'),
                    icon: 'receipt',
                    iconColor: color.variable(color.Variable.secondaryDark),
                    message: language.translate('ORDER.SCHEDULED_APPROVE_MSG'),
                    confirmButtonText: language.translate('APPROVE'),
                    denyButtonText: language.translate('CANCEL')
                })
            },
            priority: 3,
            isVisible: async (order)=>{
                if(order.type === "TEMPLATE" && (
                    User.currentUser.user_type_id===Task.USER_TYPE.ADMIN ||
                    User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN)) return true;
                else return false;
            }
        }, {
            label: language.translate('REJECT'),
            icon: "close",
            onClick: async (order)=>{
                modal.open(TextBoxDialog, (text)=>{
                    if (text) {
                        TaskRoutes.scheduled_orders_templates.api_scheduled_order_template_reject(order.event_id, {comment: text}, false);
                        this.is_rejected = true;
                        this.is_approved = false;
                        this.table.getData();

                    }
                }, {
                    title: language.translate('ORDER.SCHEDULED_REJECT_TITLE'),
                    label: language.translate('ORDER.SCHEDULED_REJECT_REASON'),
                })
            },
            priority: 3,
            isVisible: async (order)=>{
                if(order.type === "TEMPLATE" && (
                    User.currentUser.user_type_id===Task.USER_TYPE.ADMIN ||
                    User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) &&
                    order.status_id === Task.STATUS.CREATED) return true;
                else return false;
            }
        },
        {
            label: language.translate('DELETE'),
            icon: "delete",
            color: 'warn',
            onClick: async (order)=>{
                modal.open(TextBoxDialog, async(text)=>{
                    if (text) {

                        if (order.status_id === Task.STATUS.CREATED) {
                            await TaskRoutes.scheduled_orders_templates.api_scheduled_orders_template_delete(order.event_id, {comment: text});
                        } else {
                            await TaskRoutes.scheduled_orders.api_scheduled_order_delete(order.event_id, {comment: text});
                        }

                        this.table.getData();
                    }
                }, {
                    title: language.translate('ORDER.SCHEDULED_DELETE_TITLE'),
                    label: language.translate('ORDER.SCHEDULED_REJECT_REASON'),
                })
            },
            priority: 3,
            isVisible: async (order)=>{
                if((order.status_id === Task.STATUS.CREATED || order.status_id === Task.STATUS.APPROVED) && (
                    User.currentUser.user_type_id === Task.USER_TYPE.ADMIN ||
                    User.currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN)) return true;
                else return false;
            }
        }, {
            label: language.translate("COPY"),
            icon: "content_copy",
            color: "primary",
            onClick: (order)=>{
                set_sch_order_to_copy(order);
                utils.router.navigateByUrl('/scheduled-orders/create-scheduled-order');
            },
            priority: 4,
            isVisible: true
        }],

        filterComponent: GenericFilterComponent,
        filterInput: this.getFilters
    }

    async search_locations(search: string) {
        let res = await TaskRoutes.locations.api_location_search_visible({
            search: [search],
            attributes: ["cin+ +name"]
        }, {
            page_no: 1,
            page_size: 50
        })

        return res.data;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
        return `${loc.cin ?? ''} ${loc.name}`;
    }

    search_company_workers = async (search: string) => {
        let res = await TaskRoutes.users.api_user_search({
            search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
            attributes: ['first_name+ +last_name', "#=:user_type_id"]
        }, {
            page_no: 1,
            page_size: 10
        })
        return res.data;
    }

    format_company_workers = (user: Task.User) => {
        if(!user) {
            return ""
        }
        return `${user.first_name} ${user.last_name}`
    }
}

interface DisplayScheduledOrder {
    status: template.EscapedHTML;
    title: template.EscapedHTML;
    location: template.EscapedHTML;
    supplier?: template.EscapedHTML;
    time: template.EscapedHTML;
    responsible?: template.EscapedHTML;
    whier: template.EscapedHTML;
}

interface ApproveDialogComponentResponse {
    popover_close?: boolean;
    data_submited?: boolean
}
