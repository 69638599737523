import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';

@Component({
	selector: 'task-scheduled-order-info',
	template: `
		<div class="flex-column" style="width: 100%; gap: 1em;" >
				<!-- general info -->

				<span *ngIf="order.type=='SCHEDULED_ORDER'" class="flex-row wrappable" style="gap: .8em; align-items: flex-start;">
					<strong>{{'ORDER.SCEHDULED.APPROVED_BY' | trans}}:  </strong>
					<span>
						<span>{{ order.approved_or_rejected_by_first_name }} {{ order.approved_or_rejected_by_last_name }}, </span> &nbsp;
						<span>{{ order.approved_or_rejected_date |  datefmt : "short_date" }}</span>
					</span>
					<hr class="thin-devider">
				</span>
				<span *ngIf="order.type=='TEMPLATE' && order.approved_or_rejected_by_id" class="flex-column">
					<span class="flex-row wrappable" style="gap: .8em; align-items: flex-start;">
						<strong>{{'ORDER.REJECTED_BY'| trans}}:  </strong>
						<span>
							<span>{{ order.approved_or_rejected_by_first_name }} {{ order.approved_or_rejected_by_last_name }}, </span> &nbsp;
							<span>{{ order.approved_or_rejected_date |  datefmt : "short_date" }}</span>
						</span>
					</span>
					<span class="flex-row" style="gap: .8em; align-items: flex-start; margin-top: 0.5em;">
						<strong>{{'ORDER.SCHEDULED_REJECT_REASON' | trans}}:</strong>
						<span>{{ order.comment }}</span>
					</span>
					<hr class="thin-devider">
				</span>
				<span class="flex-row wrappable" style="gap: .8em; align-items: flex-start;">
					<strong>{{'ORDER.SCHEDULED.CREATED_BY' | trans}}: </strong>
					<span>
						<span>{{ order.created_by_first_name}} {{ order.created_by_last_name }},</span> &nbsp;
						<span>{{ order.created_date | datefmt : "short_date" }}</span>
					</span>
					<hr class="thin-devider">
				</span>

				<ng-container *ngIf="order.event_details.length > 0 && order.event_details[0].description!==''">
					<div *ngFor="let info of order.event_details" class="flex-row wrappable" style="gap: .8em; align-items: flex-start;">
						<strong *ngIf="info.description !== ''" class="flex-row align-items-start" style="gap: .8em; align-items: flex-start;">{{"DESCRIPTION" | trans}}: </strong>
						<p style="margin: 0;">{{ info.description }}</p>
						<hr class="thin-devider">
					</div>
				</ng-container>

				<!-- work hierarchy -->
				<span class="flex-row align-center wrappable" style="width: 100%; align-items: flex-start;">
					<strong>{{ "NAVROUTE.WORKHIER" | trans }}: </strong>
					<div class="flex-row wrappable" style="align-items: flex-start;">
						<i>{{ order.whier.description }}</i> <i icon="chevron_right" style="font-size: 0.8em; vertical-align: middle"></i>
						<i>{{ order.whier.subcategory_description }}</i> <i icon="chevron_right" style="font-size: 0.8em; vertical-align: middle"></i>
						<i>{{ order.whier.category_description }}</i>
					</div>
				</span>


				<ng-container *ngIf="order.type=='TEMPLATE'">
					<hr class="thin-devider">

					<span class="flex-row wrappable" style="gap: .8em; align-items: flex-start;">
						<strong>{{'NAVROUTE.LOCATIONS' | trans }}: </strong>
						<div class="flex-column" style="max-height: 260px; flex: 1 0 auto; box-sizing: border-box; overflow-x: hidden; overflow-y: auto;">
							<span *ngFor="let loc of order.locations" style="white-space: nowrap;"><strong>#{{ loc.cin }}</strong> {{ loc.name }}</span>
						</div>

					</span>

					<hr class="thin-devider">

					<task-scheduled-dates [annual_frequency]="order.annual_frequency" [start_date]="order.planned_start_date" [end_date]="order.planned_end_date" [cut_date]="order.cut_date" ></task-scheduled-dates>
				</ng-container>
			<!-- dates -->
		</div>
	`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../order.component.scss']
})

export class ScheduledOrderInfo extends ModalBase<Task.ScheduledEvent, any> implements OnInit {
	@Input()
	order: Task.ScheduledEvent;


	ngOnInit(): void {
		if (this.injectedData instanceof Promise) {
			this.injectedData.then(res => this.order = res)
		} else {
			this.order = this.injectedData;
		}
	}

}
