import { Task } from "@task-utils/types";
import { ScheduledOrderTemplateType } from "../orders.types";


let scheduled_order_to_copy: ScheduledOrderTemplateType|null = null;

export function get_sch_order_to_copy() {
   return scheduled_order_to_copy;
}

export function set_sch_order_to_copy(order: Task.ScheduledEvent) {
   scheduled_order_to_copy = transform_to_sch_order_to_copy_type(order);

   setTimeout(unset_sch_order_to_copy, 3000);
}

export function unset_sch_order_to_copy() {
   scheduled_order_to_copy = null;
}

function ensure_date(d: Task.TIMESTAMP) {
   return typeof(d) == "string" ? new Date(d) : d;
}

function transform_to_sch_order_to_copy_type(order: Task.ScheduledEvent): ScheduledOrderTemplateType {
   return {
      type: "TEMPLATE",
      responsible_users: order.responsible_users,
      event_id: NaN,
      event_details: order.event_details,
      annual_frequency: order.annual_frequency,
      arrival_worker: order.arrival_worker,
      arrival_worker_id: order.arrival_worker?.user_id ?? null,
      status_id: Task.STATUS.CREATED,
      supplier_id: order.supplier_id,
      supplier_name: order.supplier_name,
      whier: order.whier,
      whier_id: order.whier.whier_id,
      comment: order.comment,
      cut_date: ensure_date(order.cut_date),
      investment_type: order.investment_type,
      locations: order.locations,
      planned_end_date: ensure_date(order.planned_end_date),
      planned_start_date: ensure_date(order.planned_start_date),
      scheduled_orders_template_details: order.event_details as any,
      title: order.title,
   }
}
