import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { language } from 'curvy';
import { components_trans } from './components.trans';

@Component({
	selector: 'task-kam-inventory',
	encapsulation: ViewEncapsulation.None,
	template: `
	<details [class.no-items]="udas.length === 0" class="details-card">
		<summary>
			<div class="flex-column">
				<i style="font-size: 0.9em"
						*ngIf="inventory.inventory_no">
						<strong>{{ "INV.NO" | trans  }}</strong>
					#{{ inventory.inventory_no }}
				</i>
				<b style="margin-top: .1em; margin-bottom: .4em; font-size: 1rem ">{{ inventory.inventory_name }}</b>
				<span class="flex-row wrappable" style="gap: .3em .7em;">
					<i style="font-size: 0.9em;"
							*ngIf="inventory.inventory_serial_no">
							<strong>{{ "SER.NO" | trans }}</strong>
						{{ inventory.inventory_serial_no }}
					</i>
					<i style="font-size: 0.9em;"
							*ngIf="inventory.inventory_brand_name">
							<strong>{{ "BRAND" | trans }}:</strong>
						{{ inventory.inventory_brand_name }}
					</i>
					<i style="font-size: 0.9em;"
							*ngIf="inventory.inventory_supplier_name">
							<strong> {{ "SUPPLIER" | trans }}:</strong>
						{{ inventory.inventory_supplier_name }}
					</i>
				</span>
				<i style="font-size: 0.9em; margin-top: .35em; color: rgb(var(--orange-dark));" class="flex-row align-center wrappable" *ngIf="inventory.inventory_warranty_period && !is_in_past(inventory.inventory_warranty_period)">
						<i icon="verified_user"></i>
						<strong>{{ "WARRANTY_EXP" | trans }}:</strong> &nbsp;
						{{ inventory.inventory_warranty_period | datefmt }} &nbsp;

						<ng-container *ngIf="inventory.inventory_invoice_date"><strong>{{ "WARRANTY_INVOICE_DATE" | trans }}:</strong> &nbsp;
						<span> {{ inventory.inventory_invoice_date | datefmt }}</span></ng-container> &nbsp;

						<ng-container *ngIf="inventory.inventory_invoice_no"><strong>{{ "WARRANTY_INVOICE_NO" | trans }}:</strong> &nbsp;
						<span>{{ inventory.inventory_invoice_no }}</span></ng-container>
					</i>
			</div>
		</summary>
		<div class="inventory-udas">
			<ng-container *ngFor="let uda of udas">
				<div class="inventory-uda"
						*ngIf="uda.uda_value">
					<div class="uda-title" style="font-size: .9em;">
						{{ uda.uda_name }}
					</div>
					<div class="uda-value" style="font-size: .9em;">
						{{ uda.uda_value }}
					</div>
				</div>
			</ng-container>
		</div>
	</details>
	`,
	styleUrls: ['./components.scss']
})
export class KAMInventoryComponent implements OnInit {
	@Input('inventory')
	set _inventory(val: Task.KAM_Inventory) {
		if (val.udas) { this.udas = val.udas.filter(u => !!u.uda_value ); }
		this.inventory = val;
	}

	inventory: Task.KAM_Inventory;
	udas: Task.KAM_Inventory["udas"] = [];

	is_in_past(date:Date | string) {
		let today = new Date().setHours(0,0,0,0);
		return new Date(date).setHours(0,0,0,0) < today;
	}

	ngOnInit() {
		language.load_translations(components_trans);
	}
}
