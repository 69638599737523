import { Component, Input, ViewEncapsulation, ContentChild, TemplateRef } from '@angular/core';
import { Task } from '@task-utils/types';

@Component({
	selector: 'task-responsive-container',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="{{classes}} screen-big">
			<h4 style="margin-bottom: 0.5em;">{{ title | trans }}</h4>
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</div>

		<details class="{{classes}} screen-small">
			<summary>
				<h4 style="margin: 0">{{ title | trans }}</h4>
			</summary>
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</details>
	`,
	styles: [`task-responsive-container { display: contents; }`]
})
export class ResponsiveContainer {
	@ContentChild(TemplateRef) content;
	@Input() classes = "";
	@Input() title = "";
}
