import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, modal, noOp } from 'curvy';
import { arrivals_translations } from '../arrivals.trans';
import { FinancialItemHistoryComponent } from './financial-item-history/financial-item-history.component';


@Component({
    selector: 'task-edit-financial-item',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './edit-financial-item.component.html',
    styleUrls: ['./edit-financial-item.component.scss']
})
export class EditFinancialItemComponent implements OnInit {
	@Input() arrival: Task.Arrival;
	@Input() item: Task.ArrivalDetail;
	@Output() itemChange = new EventEmitter<Task.ArrivalDetail>();

	@Input() can_edit_financial_details:boolean;

	price_changed = false;
	old_price = "";
	new_price = "";

	quantity_changed = false;
	old_quantity = "";
	new_quantity = "";

	lump_sum_changed = false;
	old_lump_sum = false;
	new_lump_sum = false;

	change_comment = "";

	show_comment = false;
	approved = false;

	is_deleted = false;


	constructor(private cdr: ChangeDetectorRef) {
		language.load_translations(arrivals_translations);
	}

	single_sum() {
		let total_price = this.item.element_price * this.item.element_quantity;
		return total_price.toLocaleString('hr', { style: 'currency', currency: this.item.element_currency || 'EUR' });
	}

	update() {

		let changed_price = String(this.item.element_price ) + (this.item.element_currency || "");
		let changed_quantity = String(this.item.element_quantity) + (this.item.element_uom || "");

		this.show_comment = changed_price != this.new_price ||
			changed_quantity != this.new_quantity ||
			this.item.lump_sum != this.new_lump_sum;

		if (!this.show_comment) {
			this.item.comment = "";
		}

		if (this.item.element_quantity === 0 ) {
			this.is_deleted = true;
		} else {
			this.is_deleted = false;
		}

		this.item.approved = !this.show_comment;
		this.itemChange.emit(this.item);
		this.cdr.detectChanges();
	}

	delayed_update() {
		setTimeout(() => {
			this.update();
		}, 250);
	}

	openHistory() {
		modal.open(FinancialItemHistoryComponent, noOp, this.item);
	}

	ngOnInit() {
		let old_price = this.item.element_old_price;
		let new_price = this.item.element_price;
		let price_changed = old_price != null && old_price != new_price;

		this.old_price = String(old_price) + (this.item.element_currency || "");
		this.new_price = String(new_price) + (this.item.element_currency || "");
		this.price_changed = price_changed;

		let old_quantity = this.item.element_old_quantity;
		let new_quantity = this.item.element_quantity;
		let quantity_changed = old_quantity != null && old_quantity != new_quantity;

		this.old_quantity = String(old_quantity) + (this.item.element_uom || "");
		this.new_quantity = String(new_quantity) + (this.item.element_uom || "");
		this.quantity_changed = quantity_changed;

		this.old_lump_sum = this.item.old_lump_sum;
		this.new_lump_sum = this.item.lump_sum;
		this.lump_sum_changed = this.old_lump_sum != null && this.old_lump_sum != this.new_lump_sum;

		if (price_changed || quantity_changed || this.lump_sum_changed) {
			this.change_comment = this.item.comment;
		}

		this.item.old_lump_sum = this.item.lump_sum;
		this.item.element_old_price = this.item.element_price;
		this.item.element_old_quantity = this.item.element_quantity;

		this.item.comment = "";
		this.approved = this.item.approved;
		this.update();
		this.delayed_update();
	}

	search_material = async (query: string) => {
		let res = await TaskRoutes.pricelists.api_pricelist_search({
				attributes: [
					"name+ +cin",
					"#=:element_type_id"],
				search: [
					query,
					String(Task.ELEMENT_TYPE.MATERIAL)]
		});
		return res.data.map(pl => ({ label: pl.name, value: pl }));
	}

	material_selected = (e) => console.log("Mat selected", e);

	search_transport = async (query: string) => {
		let res = await TaskRoutes.pricelists.api_pricelist_search({
				attributes: [
					"name+ +cin",
					"#=:element_type_id"],
				search: [
					query,
					String(Task.ELEMENT_TYPE.TRANSPORTATION)]
		});
		return res.data.map(pl => ({ label: pl.name, value: pl }));
	}

	transport_selected = (e) => console.log("Trans selected", e);
}
