import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Task } from '@task-utils/types';
import { Frame, language } from 'curvy';
import { Comments } from './comments';
import { comments_translations } from './comments.trans';

@Component({
    selector: 'task-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CommentsComponent implements OnChanges, AfterViewInit {
    @ViewChild('commentscontainer')
    comments_container: ElementRef;

    @Input()
    entity: Task.ENTITY;
    @Input()
    entity_id: number;

    comments: Task.Comment[] = [];
    comment_text = "";

    constructor() {
        language.load_translations(comments_translations);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.get_comments();
    }

    ngAfterViewInit() {
        this.scroll_to_bottom();
    }

    scroll_to_bottom() {
        this.comments_container.nativeElement.scrollTop = this.comments_container.nativeElement.scrollHeight;
    }

    get_comments() {
        if (this.entity && this.entity_id) {
            Comments.get(this.entity, this.entity_id).then(res => {
                this.comments = res.data;
                setTimeout(() => {
                    this.scroll_to_bottom();
                }, 1);
            });
        }
    }

    add_comment() {
        if (this.entity && this.entity_id && this.comment_text.trim().length > 0) {
            Comments.add(this.entity, this.entity_id, this.comment_text).then(() => {
                this.comment_text = "";
                this.get_comments();
            });
        }
    }
}
