<div class="flex-column fullheight-noscroll" form-collector #locationGroups="collector">
	<h2 class="modal-title">
		<span *ngIf="is_edit_form">{{ 'LOCGROUP.FORM_EDIT_TITLE' | trans : location_group.name }}</span>
		<span *ngIf="!is_edit_form">{{ 'LOCGROUP.FORM_NEW_TITLE' | trans }}</span>

	</h2>

	<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>

	<div style="overflow: auto; ">
		<div class="flex-column" >
			<label class="input outlined" title="{{ 'LOCGROUP.TITLE' | trans }}">
				<i icon="ballot"></i>
				<input [(ngModel)]="location_group.name" placeholder="{{ 'LOCGROUP.TITLE' | trans }}"
				conditions="nonempty" />
			</label>

			<knw-auto-search
				#locpicker
				[required]="true"
				[function]="search_locations"
				[to-label]="format_location_name"
				label="{{ 'LOCATION' | trans }}"
				[multi]="true"
				[(ngModel)]="location_group.locations"
				classes="outlined">
			</knw-auto-search>

			<label class="checkbox-input whole-row" style="margin-left: auto;" *ngIf="'nfcs' | has_feature">
                <input type="checkbox"
							[(ngModel)]="location_group.nfc" />
                &nbsp;{{"LOC.NFC" | trans }}
            </label>

			<task-users-pool [location]="location_group" [is_edit_form]="is_edit_form" [users_property]="'location_group_users'" style="margin-top: 1.5em;"></task-users-pool>

			<details *ngIf="location_group.locations?.length > 0" class="flex-column" style="box-shadow: none; list-style: none; margin-bottom: 1.5em; margin-top: 0; margin-left: 0; padding: 0; grid-column: 1 / -1; border-top: 1px solid lightgray;" open>
				<summary>
					<h3 style="margin-bottom:0.5em;" >{{ 'LOCGROUP.LOCATIONS_IN_GROUP' | trans }}</h3>
				</summary>
				<div style="padding-bottom: 1em;">
					<span *ngFor="let loc of location_group.locations" class="flex-row align-center" style="flex: 0 1 50%; font-size: .9em; margin-bottom: .25em;">
						@if (loc.nfc && ('nfcs' | has_feature)) {
							<i icon="contactless" style="font-size: 80%; color: rgb(var(--orange))" [title]="'LOCGROUP.NFC_IS_ON'| trans"></i> &nbsp;
						}

						<strong>#{{loc.cin}}</strong> &nbsp; {{loc.name}}
					</span>
				</div>

			</details>

		</div>
	</div>

	<div class="flex-row modal-footer">
		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" (click)="done()" [disabled]="locationGroups.containsErrors() || location_group.location_group_users.length === 0">
			<i icon="check"></i>
			{{ 'SAVE' | trans }}
		</button>

	</div>
</div>
