import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

@Component({
    selector: 'task-create-edit-location-dialog',
    template: `
        <task-create-edit-location [location]="location" (result)="handle_result($event)"></task-create-edit-location>
    `
})
export class CreateEditLocationDialog extends ModalBase<Task.Location, Task.Location> implements OnInit {
    preventSoftClose = true;
    location: Task.Location;

    ngOnInit() {
        this.location = this.injectedData;
    }

    handle_result(loc: Task.Location) {
        if (loc) {
            let prom;
            let req: any = { ...loc };
            
            if (loc.location_users && loc.location_users.length > 0) {
                req.user_ids = loc.location_users.map(u => u.user_id);
            }

            if (!loc.location_id) {
                prom = TaskRoutes.locations.api_location_add(req);
            } else {
                prom = TaskRoutes.locations.api_location_modify(loc.location_id, req);
            }

            prom.then( res => {
                this.submit(loc);
            });
        } else {
            this.submit(loc);
        }
    }
}
