import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { network } from "curvy";


@Component({
  selector: 'task-accordion-list',
  template: `
    
  
  <details style="box-shadow: none; grid-column: 1 / -1; padding-left: 0; padding-right: 0;" 
        *ngIf="list_data?.length">
            <summary>
                <ng-content select=[summary]></ng-content>
            </summary>

            <ng-container *ngFor="let li of list_data">
                <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: li}"></ng-template>
            </ng-container>

            <div class="pager flex-row justify-flex-end" *ngIf="count>paginator.page_size">
                <button (click)="change_page(-1)" class="round paginator-previous" [disabled]="paginator.page_no === 1"><i icon="chevron_left"></i></button>
                <span>{{ paginator.page_no }} / {{ max_page_no }}</span>
                <button (click)="change_page(1)" class="round paginator-next" [disabled]="paginator.page_no === max_page_no"><i icon="chevron_right"></i></button>
            </div>
        </details>
   
  `,
  styles: [`

    .list-el {
        width: 100%;
    }

    .list-el:hover {
        background-color: rgba(var(--background-dark), 0.3);
    }

    .pager {
        margin-top: .4em;
    }

    .uppercase {
        text-transform: uppercase;
    }
`]
})
export class AccordionListComponent implements OnInit {
    @ContentChild(TemplateRef)
    templateRef: TemplateRef<any>;

    @Input()
    fn: (paginator:network.Headers.Paginator) => Promise<TaskRoutes.Payload>;

    list_data=null;
    count: number;
    max_page_no:number;
    paginator = {
        page_no: 1,
        page_size: 6
    };


    ngOnInit(): void {
        this.get_data();
    }

    async get_data() {
        let res = await this.fn(this.paginator);
        this.list_data = res.data;
        this.count = res.count;
        this.max_page_no = Math.ceil(this.count/this.paginator.page_size);
    }

    change_page(dir:number) {
        if (dir==1) {
            this.paginator.page_no= Math.max(this.paginator.page_no++, this.max_page_no);
            this.get_data();
        }
        else {
            this.paginator.page_no = Math.min(this.paginator.page_no--, 1); 
            this.get_data();
        }
    }
}
