import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, AutoSearchComponent } from 'curvy';
import { arrivals_translations } from '../arrivals.trans';
import { suppliers_translations } from '../../suppliers/suppliers.trans';
import { pricelist_translations } from '../../pricelists/pricelist.trans';



@Component({
    selector: 'task-work-element',
    encapsulation: ViewEncapsulation.None,
    template: `
    <ng-container>
    <div class="flex-row" style="margin-bottom: 1.5em;">
        <button color="primary" (click)="switch_tab(0, detail)" [class]="showNonpriceTab ? '': 'active'"><span style="white-space:break-spaces">{{'ARRIVAL.DETAIL.PRICELIST_ELEMENT' | trans}}</span></button>
        <button color="primary" (click)="switch_tab(1, detail)" [class]="showNonpriceTab ? 'active' : ''"><span style="white-space: break-spaces;">{{'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT' | trans}}</span></button>
    </div>
    <div class="flex-row wrappable" *ngIf="showNonpriceTab" >
        <label
            style=""
            class="input outlined flex-dynamic"
            [title]="'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT' | trans">
            <input required type="text" [(ngModel)]="detail.element_name">
        </label>

        <label
            style=""
            class="input outlined flex-dynamic"
            [title]="'ARRIVAL.DETAIL.ELEMENT_QUANTITY' | trans">
            <input required type="number" [(ngModel)]="detail.element_quantity">
        </label>

        <label
            style=""
            class="input outlined flex-dynamic"
            [title]="'ARRIVAL.DETAIL.UOM' | trans">
            <select required [(ngModel)]="detail.element_uom">
                <option *ngFor="let uom of uom_types | keyvalue" [ngValue]="uom.key">{{ ("PRICELIST.UOM." + uom.value) | trans }} <span *ngIf="uom.key !==''">({{uom.key}})</span></option>
            </select>
        </label>

        <label
            style=""
            class="input outlined flex-dynamic"
            [title]="('ARRIVAL.DETAILS.PRICE' | trans) + '(' + (detail.element_currency) + ')'">
            <input required type="number" [(ngModel)]="detail.element_price" min="0">
        </label>

        <label
            style=""
            class="input outlined flex-dynamic"
            [title]="'SUPPLIER.CIN' | trans">
            <input type="text" [(ngModel)]="detail.element_cin">
        </label>


        <knw-auto-search
            [label]="'ARRIVAL.DETAIL.GROUP' | trans"
            classes="outlined flex-dynamic"
            style="margin-left: 4px;
            margin-right: 4px;"
            [function]="make_serach_group(false)"
            [to-label]="format_group"
            [(ngModel)]="picked_work_description"
            (ngModelChange)="write_work_description(detail, $event)"
            required
        ></knw-auto-search>

        <label class="input outlined"
        style=" flex: 1;"
        title="{{ 'PRICELIST.TYPE' | trans }}">
            <select [(ngModel)]="detail.element_type_id" required>
                <option *ngFor="let type of ELEMENT_TYPES | keyvalue"
                        [ngValue]="+type.key">
                    {{ ("PRICELIST.TYPE." + type.value) | trans }}
                </option>
            </select>
        </label>

        <label class="checkbox-input">
            <input type="checkbox"
                    [(ngModel)]="detail.lump_sum"/>
            {{ 'ARRIVAL.DETAILS.LUMP' | trans }}
        </label>
    </div>
    <div class="flex-row wrappable" *ngIf="!showNonpriceTab">
        <knw-auto-search
            [label]="'ARRIVAL.DETAIL.GROUP' | trans"
            classes="outlined flex-dynamic"
            [function]="make_serach_group(true)"
            [to-label]="format_group"
            [allow-null]="true"
            [(ngModel)]="picked_work_description"
            (ngModelChange)="reset_search_picker()"
        ></knw-auto-search>
        <knw-auto-search
            #searchPicker
            [label]="'ARRIVAL.DETAIL.WORK_ELEMENT' | trans"
                classes="outlined flex-dynamic"
                style="display: contents"
                [function]="search_pricelist"
                [to-label]="format_pricelist"
                [(ngModel)]="detail['element']"
                (ngModelChange)="set_element(detail, $event)"
                required>
        </knw-auto-search>

        <label class="input outlined flex-dynamic"
                *ngIf="detail['element']"
                [title]="'ARRIVAL.DETAIL.ELEMENT_QUANTITY' | trans">
            <input type="number" [(ngModel)]="detail.element_quantity" />
            {{ detail.element_uom }}
        </label>
    </div>

    <p *ngIf="detail['element'] && !showNonpriceTab">
        <task-price [label]="'ARRIVAL.DETAIL.ELEMENT_PRICE'"
                [price]="detail.element_price"
                [currency]="detail.element_currency"></task-price>
    </p>

    <p *ngIf="detail['element'] && !showNonpriceTab">
        <task-price [label]="'ARRIVAL.DETAIL.ELEMENT_TOTAL'"
                [price]="detail.element_price * (detail.element_quantity || 0)"
                [currency]="detail.element_currency"></task-price>
    </p>
</ng-container>
    `,
    styleUrls: ['./edit-arrival-details.component.scss']
})
export class WorkElementComponent {
    @ViewChild("searchPicker")
    searchPicker: AutoSearchComponent;

	@Input() detail: Task.ArrivalDetail;
    showNonpriceTab = false;
    picked_work_description:Task.WorkDescription = null;

    uom_types = (()=>{
        let types = {...Task.Unit_Of_Measure};
        return types;
    })();

    ELEMENT_TYPES = Task.Element_Type

    constructor(private ar: ActivatedRoute) {
        language.load_translations(arrivals_translations);
        language.load_translations(suppliers_translations);
        language.load_translations(pricelist_translations);
    }


    switch_tab(tab:number, detail:Task.ArrivalDetail) {
        switch(tab) {
            case 0:
                this.showNonpriceTab = false;
                this.picked_work_description = detail.element_name = detail.element_quantity = detail.element_uom = detail.element_price = detail.element_cin = detail.lump_sum = detail.element_currency = detail['element'] = null;

            break;

            case 1:
                this.showNonpriceTab = true;
                this.picked_work_description = detail.element_name = detail.element_quantity = detail.element_uom = detail.element_price = detail.element_cin = detail.lump_sum = detail.element_type_id = null;
                detail.element_currency = 'EUR';
            break;
        }

        console.log(detail)
    }

    reset_search_picker() {
        this.searchPicker.writeValue(null);
        this.detail['element'] = null;
    }

    make_serach_group(filter: boolean) {
        return async(search: string)=>{
            return await this.search_group(search, filter);
        }
    }

    search_group = async (search: string, filter:boolean) => {
        let res;
        if (filter) {
            res = await TaskRoutes.pricelists.api_pricelist_search_work_groups({
                search: search,
                filter: filter
            })
        } else {
            res = await TaskRoutes.pricelists.api_pricelist_search_work_groups({
                search: search
            })
        }

        return res.data;
    }

    format_group(group:Task.WorkDescription) {
        if (!group) return '';

        return `${group.work_description}`
    }

    search_pricelist = async (search: string) => {
        let attrs = ["name+ +cin+ +description"];
        let srch = [search];
        if (this.picked_work_description) {
            attrs.push("#=:work_description_id");
            srch.push("" + this.picked_work_description.work_description_id);
        }

        let res = await TaskRoutes.pricelists.api_pricelist_search({
            attributes: attrs,
            search: srch
        }, {
            page_no: 1,
            page_size: 100
        });
        return res.data;
    };

    format_pricelist(pricelist: Task.Pricelist) {
        if (!pricelist) return '';

        let label = pricelist.name;

        if (pricelist.cin) label += ` - (${pricelist.cin})`;
        if (pricelist.description) label += ` - (${pricelist.description})`;

        return label;
    }

    write_work_description(detail: Task.ArrivalDetail,el) {
        if (el) {
            detail.work_description = el.work_description;
            detail.work_description_id = el.work_description_id
        }
    }

    set_element(detail?: Task.ArrivalDetail, element?: Task.Pricelist) {
        detail["element_id"] = element.element_id;
        detail.element_price = element.price;
        detail.element_currency = element.currency || 'EUR';
        detail.element_uom = element.unit_of_measure;
        detail.element_quantity = 1;
        detail.lump_sum = element.lump_sum;
    }

    get_title(detail: Task.ArrivalDetail) {
        if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
            if (detail.element_name && detail.element_name.length > 0) {
                if (detail.element_cin && detail.element_cin.length > 0) {
                    return detail.element_name + "(" + detail.element_cin + ")";
                }

                return detail.element_name;
            }
        }

        return language.translate("ARRIVAL.DETAIL." + Task.Arrival_Detail_Type[detail.arrival_detail_type_id]);
    }


}
