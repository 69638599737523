import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { modal, language } from 'curvy';
import { components_trans } from './components.trans';

@Component({
    selector: 'task-get-image',
    encapsulation: ViewEncapsulation.None,
    template: `
        <input #imageinput
               type="file"
               accept="image/*"
               style="display: none"
               (change)="handle_change($event)" />
        <button class="filled pill"
                color="secondary"
                (click)="get_image()"
                *ngIf="!got_image">
            <i icon="add_a_photo"></i> {{ 'ADD_IMAGE' | trans }}
        </button>
        <div *ngIf="got_image"
                class="special-image-container">
            <img [src]="this.image.src" style="width: 100%" />
            <button class="filled round image-delete"
                    color="secondary"
                    (click)="remove_image()">
                <i icon="delete"></i>
            </button>
        </div>
    `,
    styles: [`
        task-get-image {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }

        task-get-image .special-image-container {
            position: relative;
        }

        task-get-image .image-delete {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
        }
    `]
})
export class GetImage {
    @ViewChild('imageinput')
    image_input: ElementRef<HTMLInputElement>;

    @Input()
    max_width = 1024;

    @Input()
    max_height = 1024;

    @Input()
    jpeg_quality = 0.85;

    @Output('image')
    image_out = new EventEmitter<Image>();

    got_image = false;
    image: Image = null;

    constructor() {
        language.load_translations(components_trans);
    }

    get_image() {
        if (!this.got_image) {
            this.image_input.nativeElement.click();
        }
    }

    remove_image() {
        if (this.got_image) {
            this.image_input.nativeElement.value = null;
            this.image = null;
            this.got_image = false;
            this.image_out.emit(null);
        }
    }

    async handle_change(e: Event) {
        if (this.image_input.nativeElement.files && this.image_input.nativeElement.files.length > 0) {
            let file = this.image_input.nativeElement.files[0];
            this.image = await image_to_jpeg(file, this.max_width, this.max_height, this.jpeg_quality);
            this.image_out.emit(this.image);
            this.got_image = true;
        }
    }
}

export interface Image {
    file: File;
    src: string;
    canvas: HTMLCanvasElement;
    blob: Blob;
}

function blob_to_file(blob: Blob, filename: string): File {
    return new File([blob], filename, { lastModified: Date.now() });
}

function replace_extension(filename: string, new_extension: string) {
    let words = filename.split(".");
    if (words.length > 1) {
        words.pop();
    }

    words.push(new_extension);

    return words.join(".");

}

export function image_to_jpeg(file: File, max_width: number, max_height: number, quality: number): Promise<Image> {
    return new Promise(resolve => {
        let image = { file, src: null, canvas: null, blob: null };
        let reader = new FileReader();

        reader.onloadend = () => {
            let canvas = document.createElement("canvas");
            image.canvas = canvas;
            image.src = reader.result as string;

            let img = document.createElement("img");
            img.src = image.src;
            img.onload = (ev) => {
                let width = Math.min(img.width, max_width);
                let height = Math.min(img.height, max_height);
                let ratio = Math.min(width/img.width, height/img.height);
                width = img.width * ratio;
                height = img.height * ratio;

                canvas.width = width;
                canvas.height = height;

                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                image.canvas.toBlob((blob) => {
                    image.blob = blob;
                    image.file = blob_to_file(blob, replace_extension(file.name, "jpeg"));
                    resolve(image);
                }, 'image/jpeg', quality);
            };
        };

        reader.readAsDataURL(file);
    });
}
