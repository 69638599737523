import { Pipe } from "@angular/core";
import { Task } from "@task-utils/types";

@Pipe({
    name: "arrival_time",
    pure: true
})
export class ArrivalTimePipe {
    transform(arrival: Task.Arrival) {
        let arrival_time = arrival.start_time || arrival.planned_start_time
        if (arrival.status_id === Task.STATUS.EXECUTED) {
            return arrival.end_time || arrival_time;
        } else {
            return arrival_time;
        }
    }
}
