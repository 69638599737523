import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { Frame, language } from 'curvy';
import { User } from '@task-modules/users';
import { reports_translations } from './reports.trans';


@Component({
	selector: 'task-reports',
	template: `
		<header class="flex-column reports-container">
			<label class="input outlined input-labelled"  title="Odaberi tip izvještaja">
				<select [(ngModel)]="selectedReport" class="ng-pristine">
					<ng-container *ngFor="let report of reportTypes">
						<option [value]="report.id" *ngIf="report.can_see"> {{report.name}}</option>
					</ng-container>
				</select>
			</label>
		</header>

		<!-- FINANCIAL REPORT -->
		<div class="flex-column reports-container" *ngIf="selectedReport == 'financial'" >
			<task-report-financial></task-report-financial>
		</div>
		
		<!-- SUPPLIER_ADMIN REPORT -->
		<div class="flex-column reports-container" *ngIf="selectedReport === 'supplier_admin'">
			<task-report-supplier_admin></task-report-supplier_admin>
		</div>

		`,
	styleUrls: ['./reports.component.scss'],
	encapsulation: ViewEncapsulation.None,

})
export class ReportsComponent implements OnInit {

	allOrders: Task.Order[];
	selectedReport:string; // default value
	reportTypes = [];
	can_see_everything = false;
	can_see_supplierAdmin = false;

	ngOnInit() {
		this.init();
	}

	async init() {
		language.load_translations(reports_translations);

		Frame.set({
			title: language.translate("NAVROUTE.REPORTS"),
			visible: true,
			layout: "top-middle",
			size: "full"
		});


		this.can_see_everything = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN, Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER);

		this.can_see_supplierAdmin = await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)

		this.selectedReport = this.can_see_everything ? 'financial' : this.can_see_supplierAdmin ? 'supplier_admin' : 'financial';

		this.reportTypes = [
			{
				id: "financial",
				name: language.translate("REPORTS.FINANCIAL_TITLE"),
				can_see: await User.currentTypeIs(
					Task.USER_TYPE.SYSTEM_ADMIN,
					Task.USER_TYPE.ADMIN,
					Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
					Task.USER_TYPE.SAFETY_MANAGER
				)
			},
			{
				id: "supplier_admin",
				name: language.translate("REPORTS.SUPPLIER_ADMIN"),
				can_see: await User.currentTypeIs(
					Task.USER_TYPE.SYSTEM_ADMIN,
					Task.USER_TYPE.SUPPLIER_ADMIN
				)
			},
		];
	}
}
