import {
	Component, SimpleChanges,
	Input, Output, EventEmitter } from '@angular/core';
import { Task } from '@task-utils/types';
import { language } from 'curvy';
import { WorkHierarchy } from '@task-modules/work-hierarchy';

import { sectors_translations } from './sectors.trans';

type SectorType = Omit<Task.Sector, 'company_id' | 'sector_id'>;
type Whier_Type = SectorType["whiers"][number];

@Component({
	selector: 'task-create-edit-sector',
    templateUrl: './create-edit-sector.component.html',
    styleUrls: ['./create-edit-sector.component.scss']
})
export class CreateEditSectorComponent {

	constructor() {
      language.load_translations(sectors_translations);
	}

	@Input()
	sector: SectorType = {
        name: "",
        whiers: []
    };

	@Output('result')
	result: EventEmitter<Task.Sector | SectorType> = new EventEmitter();

	is_edit_form: boolean = false;

    levels = [
      [], [], []
    ];
    first_level: Whier_Type[] = [];
    whiers_pool: Whier_Type[] = []

    temp_selected_index: number = -1;
    temp_whier: Whier_Type = null;

	ngOnInit() {
		language.load_translations(sectors_translations);
        WorkHierarchy.get_first_level()
            .then(
                ( res ) => { this.levels[0] = res.data; this.first_level = res.data; this.sortFirstLevel() }
            );

        this.whiers_pool = [ ...this.sector.whiers ];
        this.sortWhiers();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["sector"]) {
			this.sector = {...changes["sector"].currentValue};
		}
		this.is_edit_form = this.sector["sector_id"] != null;
	}

    sortFirstLevel() {
        this.first_level.sort((a,b) => { return a.name.localeCompare( b.name ); });
    }

    sortWhiers() {
        if( this.whiers_pool ) {
            this.whiers_pool.sort((a,b) => { return a.name.localeCompare( b.name ); });
        }
    }

    // changeLevelValue(ind: number) {
    //     if( ind < 3 ) {
    //         let selected_whier = this.levels[ind][this.temp_selected_index[ind]];
    //         let parentId = selected_whier.whier_id;
    //         let filter = {"search": [parentId], "attributes": ["#=:parent_whier_id"]}
    //         this.temp_whier = selected_whier;
    //         WorkHierarchy.get_child_level( null, filter ).then(res => this.levels[ind+1] = res.data );
    //         this.is_insert_whier = false;
    //     }
    // }

    insertWhier() {
        let selectedIndex: number = this.temp_selected_index;
        if( selectedIndex != -1 ) {
            this.whiers_pool.push(this.levels[0][selectedIndex]);
        }
        this.sortWhiers();
    }

    isVisibleOption( wh: Whier_Type ) {
        let is = true;
        this.whiers_pool.forEach(
            (whiersItem: Whier_Type) => {
                if( whiersItem.whier_id == wh.whier_id ) {
                    is = false;
                }
            }
        );
        return is;
    }

    clearLevels() {
        this.temp_selected_index = -1;
        this.levels = [[],[],[]];
        // this.levels[2] = [];
        this.levels[0] = this.first_level;
    }

    removeWhier( ind: number ) {
        this.whiers_pool.splice(ind, 1);
    }

    done() {
        this.sector.whiers = this.whiers_pool;
        this.result.emit(this.sector);
    }

    cancel() {
        this.result.emit(null);
    }
}
