import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { modal, ModalBase } from 'curvy';

@Component({
    selector: 'task-image-dialog',
    encapsulation: ViewEncapsulation.None,
    template: `
    	<div class="flex-column justify-space-between imgs-container">
			<div class="img-main-container">
				<button class="round fake-button left" *ngIf="images.length>1" (click)="slide(-1)" color="background-dark"><i icon="keyboard_arrow_left"></i></button>
				<img [src]="injectedData.imgs[selectedIndex]['src']" />
				<button class="round fake-button right" *ngIf="images.length>1" (click)="slide(1)" color="background-dark"><i icon="keyboard_arrow_right"></i></button>
			</div>

			<div *ngIf="injectedData.imgs.length>1" class="img-thumb-container">
				<div *ngFor="let img of injectedData.imgs; let i=index" class="img-thumb" [class.active]="i == selectedIndex">
					<img [src]="img.src" (click)="selectImage(i)">
				</div>
			</div>
			<button color="primary-text" (click)="cancel()">
				<i icon="clear"></i>
				<span>{{ 'CLOSE' | trans }}</span>
			</button>
		</div>
    `,
    styleUrls: ['./components.scss']
})
export class ImageDialog extends ModalBase<{imgs:object[], index: number}, void> implements OnInit, AfterViewInit, OnDestroy {
    preventSoftClose = false;
	parent = null;
	selectedIndex = 0;
	images = [];

	ngOnInit(): void {
		this.parent = this.elRef.nativeElement.parentElement;
		this.parent.classList.add("imgs-modal");
		this.selectedIndex = this.injectedData.index;
		this.images = this.injectedData.imgs;
	}
	
	ngOnDestroy() {
		this.parent.classList.remove("imgs-modal");
	}

	ngAfterViewInit() {
		const scrollContainer = document.querySelector(".img-thumb-container");
		if (!scrollContainer) return; 
		
		scrollContainer.addEventListener("wheel", (evt) => {
			evt.preventDefault();
			scrollContainer.scrollLeft += evt["deltaY"];
		});
	}

	selectImage(index) {
		this.selectedIndex = index;
	}

	slide(dir:number) {
		if (dir == 1) {
			this.selectedIndex = this.selectedIndex == this.images.length-1 ? 0 : ++this.selectedIndex; 
		} else {
			this.selectedIndex = this.selectedIndex == 0 ? this.images.length-1 : --this.selectedIndex;
		}
	}

	cancel() {
		this.submit(null);
	}
}
