<div form-collector #financeForm="collector" style="display: contents;">

   <div class="table-container" >
      <table>
         <thead>
               <tr class="row header-row">
               @for (head of headers; track $index) {
                  <th [ngClass]="{
                     'is-centered': head.centered,
                     'is-right' : head.is_right
                  }">
                     <div class="header-cell"
                        [style.justify-content]="head.key==='detail_history' ? 'flex-end': null">
                           {{ head.name | trans }}
                           <i *ngIf="head.icon" [attr.icon]="head.icon || ''" style="margin-left: .5em;"></i>
                     </div>
                  </th>
               }
               </tr>
         </thead>

            <tbody>
               @if ('edit-financial-detilas-save' | inProgress) {
                  <tr>
                     <td [colSpan]="headers.length" class="is-centered">
                        <div>
                           <knw-spinner color="primary" size=32 />
                        </div>
                     </td>
                  </tr>

               } @else {

                  @if (!financial_items.length) {
                     <tr style="border-bottom: none">
                        <td [attr.colspan]="headers.length">
                           <p style="text-align: center;">{{ "ARRIVAL.DETAIL.NO_FIN_DETAILS" | trans }}</p>
                        </td>
                     </tr>

                  } @else {
                     @for (item of financial_items; track item_i; let item_i=$index) {

                        <ng-container
                           *ngVar="{
                              item_changed: item.price_changed || item.quantity_changed || item.lump_sum_changed,
                              arrival: arrivals[item.arrival_id],
                           } as itemVars"
                        >

                           <tr class="row"
                              (click)="row_click(item)"
                              [ngClass]="{
                                 'is-deleted-soft':item.element_quantity == 0,
                                 'is-expanded':item.show_details || item.show_comment
                              }"
                              [style.border-bottom]="item.show_details || item.show_comment ? 'none' : null"
                              [style.background-color]="item.show_details || item.show_comment ? 'rgb(var(--background), 1)' : null"
                           >


                              @for (col of headers; track $index) {
                                 <td [style.justify-content]="col.key==='detail_history' ? 'flex-end': null"

                                 [ngClass]="{
                                    'is-changed': $index == 0 && itemVars.item_changed,
                                    'is-new': $index == 0 && item.is_item_new,
                                    'is-centered': col.centered,
                                    'is-right': col.is_right
                                 }"
                                 >
                                    @switch (col.key) {
                                       @case ('arrival') {
                                          <div>
                                             <a [goto]="'/arrivals/' + itemVars.arrival.arrival_id"
                                             class="plain-link align-center flex-row"
                                             style="cursor: pointer; display: inline-flex;">
                                                <i [style.color]="itemVars.arrival.icon_color" [attr.icon]="itemVars.arrival.icon_name" [title]="itemVars.arrival.status_name" ></i>
                                                <span *ngIf="!(itemVars.arrival.emergency || itemVars.arrival.ended_without_qr)" style="display: inline-block; margin: 0 0.3em;">{{ 'ARRIVAL' | trans }} #{{itemVars.arrival.arrival_id}}</span>
                                                <ng-container *ngIf="itemVars.arrival.emergency || itemVars.arrival.ended_without_qr">
                                                      <span  style="display: inline-block; margin: 0 0.3em;" [attr.tooltip-right]="(itemVars.arrival.emergency?'ARRIVAL.DETAILS.EMERGENCY_TITLE' : 'ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE') | trans">{{ 'ARRIVAL' | trans }} #{{itemVars.arrival.arrival_id}} </span>
                                                      <i *ngIf="itemVars.arrival.emergency" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                                                      <i *ngIf="itemVars.arrival.ended_without_qr" icon="emergency" style="font-size: 50%; color: rgb(var(--orange));" ></i>
                                                </ng-container>
                                             </a>
                                          </div>
                                       }

                                       @case ('row-number') {
                                          <div class="row-counter"></div>
                                       }

                                       @case ('element_name') {
                                          <div class="flex-row align-center" style="gap: .2em;">
                                             @if (itemVars.item_changed) {
                                                <div class="tooltip-icon"
                                                   style=" color: rgba(var(--warn));"
                                                   (click)="$event.stopPropagation()"
                                                   [attr.tooltip]="'FINANCIAL.CHANGED_ITEM'|trans"
                                                >
                                                   <i icon="notifications_active" ></i>
                                                </div>
                                             }

                                             @if (item.is_item_new) {
                                                <div class="tooltip-icon"
                                                   style=" color: rgba(var(--yellow-light));"
                                                   (click)="$event.stopPropagation()"
                                                   [attr.tooltip]="'FINANCIAL.NEW_ITEM'|trans"
                                                >
                                                   <i icon="new_releases" ></i>
                                                </div>
                                             }

                                             @if (!item.element_id) {
                                                <div
                                                   [attr.tooltip]="'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT'|trans"
                                                   class="tooltip-icon"
                                                   style="
                                                      background-color: rgb(var(--primary));
                                                      color: white;
                                                   "
                                                   *ngIf="!item.element_id"
                                                   (click)="$event.stopPropagation()"
                                                ><span>VS</span>
                                                </div>
                                             }

                                             <strong class="truncate-text">{{ item[col.key] }}</strong>
                                          </div>
                                       }

                                       @case ('element_quantity') {
                                          @if (!(item.is_approved || !can_edit_financial_details)) {
                                             <div>
                                                <label class="input outlined" title="{{ 'ARRIVAL.DETAILS.QUANTITY' | trans }}"
                                                   style="width: 150px;"
                                                   (click)="$event.stopPropagation()">
                                                   <input type="number"
                                                      [disabled]="disable"
                                                      required
                                                      constrain
                                                      min=0
                                                      max=999999999
                                                      [(ngModel)]="item.element_quantity"
                                                      (input)="update(item)"
                                                      (change)="update(item)"
                                                   /> &nbsp;
                                                   <span>{{ item.element_uom || "" }}</span>
                                                </label>
                                             </div>
                                          } @else {
                                             <div>
                                                {{ item[col.key] }} {{ item.element_uom || "" }}
                                             </div>
                                          }
                                       }

                                       @case ('element_price') {
                                          @if (!(item.element_id || (item.is_approved || !can_edit_financial_details))) {
                                             <div>
                                                <label class="input outlined" title="{{ 'ARRIVAL.DETAILS.PRICE' | trans }}"
                                                   style="width: 150px;"
                                                   (click)="$event.stopPropagation()">
                                                   <input type="number"
                                                      [disabled]="disable"
                                                      required
                                                      constrain
                                                      min=0
                                                      max=999999999
                                                      [(ngModel)]="item.element_price"
                                                      (input)="update(item)"
                                                      (change)="update(item)"
                                                   /> &nbsp;
                                                   <span>{{ item.element_currency || "" }}</span>
                                                </label>
                                             </div>
                                          } @else {
                                             <div style="min-width: 75px;">
                                                <task-price [label]="null" [currency]="item.element_currency" [price]="item.element_price"></task-price>
                                             </div>
                                          }
                                       }

                                       @case ('element_total_price') {
                                          <div>
                                             <strong>
                                                {{ single_sum | fn : item.element_quantity : item.element_price : item.element_currency }}
                                             </strong>
                                          </div>
                                       }

                                       @case ('lump_sum') {
                                          @if (!(item.is_approved || !can_edit_financial_details)) {
                                             <div>
                                                <label class="checkbox-input" (click)="$event.stopPropagation()">
                                                   <input type="checkbox"
                                                      [disabled]="disable"
                                                      [(ngModel)]="item.lump_sum"
                                                      (ngModelChange)="update(item)"
                                                   />
                                                </label>
                                             </div>
                                          } @else {
                                             <div>
                                                <i *ngIf="item.lump_sum" icon="check"></i>
                                             </div>
                                          }
                                       }

                                       @case ('row-action-button') {
                                          <div style="justify-content: flex-end;">
                                             <button class="filled round"
                                                [title]="'CLICK_TO_EXPAND'| trans"
                                                color="primary"
                                                style="transform: scale(.8);"
                                             >
                                                @if (item.show_details || item.show_comment) {
                                                   <i icon="keyboard_arrow_up"></i>

                                                } @else {
                                                   <i icon="keyboard_arrow_down"></i>

                                                }
                                             </button>
                                          </div>
                                       }

                                       @default {
                                          <div>
                                             {{item[col.key]}}
                                          </div>
                                       }
                                    }
                                 </td>
                              }
                           </tr>

                           <!-- COLLAPSABLE DETAILS -->
                           @if (item.show_details || item.show_comment) {
                              <tr class="row expended-details"
                              >
                                 <td [attr.colspan]="headers.length"
                                    [ngClass]="{
                                       'is-changed':itemVars.item_changed,
                                       'is-new': item.is_item_new
                                    }"
                                 >
                                    <div class="spanned-container">

                                       <!-- Reason of the current change made -->
                                       @if (item.show_comment) {
                                          <label class="input outlined placeholder-warn"
                                             title="{{ 'ARRIVAL.DETAILS.REASON' | trans }}"
                                             style="margin-left: 0; margin-right: 0; width: 100%; flex: 1 0 100%;">
                                             <input [(ngModel)]="item.comment"
                                                required
                                                placeholder="{{ 'ARRIVAL.DETAIL.REASON' | trans }}"
                                                (input)="update(item)"
                                                [disabled]="item.is_approved || !can_edit_financial_details"/>
                                          </label>
                                       }

                                       <!-- Last change of the item -->
                                       @if (itemVars.item_changed) {
                                          <div class="details-changed details-item">
                                             <div class="flex-row align-center" style="gap: .4em; color: rgb(var(--warn), 1);">
                                                <i icon="history" ></i> <h5 class="card-header-title" style="margin: 0;">{{'FINANCIAL.CHANGED_ITEM' | trans}}</h5>
                                             </div>

                                             <div class="changed-params">
                                                @if (item.price_changed) {
                                                   <span class="flex-row align-center">
                                                      <strong class="flex-dynamic">{{ item.el_old_price }}</strong>
                                                      <i icon="arrow_forward"></i>
                                                      <strong class="flex-dynamic">{{ item.el_new_price }}</strong>
                                                   </span>
                                                }

                                                @if (item.quantity_changed) {
                                                   <span class="flex-row align-center">
                                                      <strong class="flex-dynamic">{{ item.el_old_quantity }}</strong>
                                                      <i icon="arrow_forward"></i>
                                                      <strong class="flex-dynamic">{{ item.el_new_quantity }}</strong>
                                                   </span>
                                                }

                                                @if (item.lump_sum_changed) {
                                                   <span class="flex-row align-center">
                                                      <span class="flex-dynamic flex-row justify-center align-center" style="gap: .2em;">
                                                         <i [attr.icon]="item.el_old_lump_sum ? 'check' : 'close'"></i>
                                                         <strong>{{ 'ARRIVAL.DETAILS.LUMP' | trans }}</strong>
                                                      </span>
                                                      <i icon="arrow_forward"></i>
                                                      <span class="flex-dynamic flex-row justify-center align-center" style="gap: .2em;">
                                                         <i [attr.icon]="item.el_new_lump_sum ? 'check' : 'close'"></i>
                                                         <strong>{{ 'ARRIVAL.DETAILS.LUMP' | trans }}</strong>
                                                      </span>
                                                   </span>
                                                }
                                             </div>

                                             <div class="flex-row align-center" style="gap: .4em;" *ngIf="item.change_comment">
                                                <i icon="comment"></i>
                                                <i>{{ item.change_comment }}</i>
                                             </div>
                                          </div>

                                       }

                                       <!-- Detailed item info -->
                                       <dl class="details-item">

                                          <div class="flex-row align-center" style="gap: .4em;">
                                             <h5 class="card-header-title" style="margin-top: 0;">{{'FINANCIAL.ITEM_DETAILS' | trans}}</h5>
                                          </div>

                                          <div class="dl-group">
                                             <dt>{{ 'ARRIVAL.PLAN.DATE2' | trans }}:</dt>
                                             <dd>{{ itemVars.arrival | arrival_time | datefmt : 'short_date' : false}}</dd>
                                          </div>

                                          @if (itemVars.arrival.emergency) {
                                             <div class="dl-group">
                                                <dt>{{ "ARRIVAL.CREATED_BY" | trans}}:</dt>
                                             <dd [style.color]="item.created_by_type_id === 9 ? 'red' : null">{{ itemVars.arrival.created_by_first_name }} {{ itemVars.arrival.created_by_last_name }}</dd>
                                             </div>
                                          }

                                          <div class="dl-group">
                                             <dt>{{ "ARRIVAL.DETAILS.CREATED_BY" | trans }}: </dt>
                                             <dd>{{ item.created_by_first_name }} {{ item.created_by_last_name }}</dd>
                                          </div>

                                          <div class="dl-group">
                                             <dt>{{ "PRICELIST.TYPE" | trans }}:</dt>
                                             <dd>{{ show_work_element_type(item.element_type_id)}}</dd>
                                          </div>

                                          <div class="dl-group">
                                             <dt>{{"SUPPLIER.CIN" | trans}}:</dt>
                                             <dd>{{ item.element_cin ?? "-" }}</dd>
                                          </div>
                                       </dl>

                                       <!-- Action buttons -->
                                       <div class="flex-column align-self-start" style="gap: .6rem;">

                                          @if (
                                             item.element_price !== item.element_old_price ||
                                             item.element_quantity !== item.element_old_quantity ||
                                             item.lump_sum !== item.old_lump_sum
                                          ) {
                                             <button
                                                class="filled round"
                                                [title]="'ARRIVAL.DETAIL.UNDELETE'| trans"
                                                color="warn"
                                                (click)="revert_item(item)"
                                             >
                                                <i icon="undo"></i>
                                             </button>
                                          }

                                          <button
                                             class="filled round"
                                             [title]="'ARRIVAL.DETAILS.HISTORY'| trans"
                                             color="primary"
                                             (click)="open_history(item)"
                                          >
                                             <i icon="history"></i>
                                          </button>
                                       </div>

                                       <!-- Special labels on the item -->
                                       @if (item.element_quantity == 0 || itemVars.arrival.emergency || !item.element_id) {
                                          <div class="label-pills-container" style="flex: 1 0 100%;">
                                             @if (item.element_quantity == 0) {
                                                <strong class="label-pill uppercase" [style.backgroundColor]="'rgb(var(--warn))'">{{ "DELETED" | trans}}</strong>
                                             }

                                             @if (itemVars.arrival.emergency) {
                                                <strong class="label-pill uppercase" [style.backgroundColor]="'rgb(var(--orange))'">{{ "ARRIVAL.DETAILS.EMERGENCY_TITLE" | trans}}</strong>
                                             }

                                             @if (!item.element_id) {
                                                <strong class="label-pill uppercase" [style.backgroundColor]="'rgb(var(--primary))'">{{ "ARRIVAL.DETAIL.NONPRICELIST_ELEMENT" | trans}}</strong>
                                             }
                                          </div>
                                       }
                                    </div>
                                 </td>
                              </tr>
                           }
                        </ng-container>
                     }
                  }
               }
         </tbody>
      </table>
   </div>


   <div class="flex-row wrappable justify-space-between"
      style="gap: .5em;"
   >
      <div class="flex-row wrappable"
         style="gap: .5em;"
         *ngIf="allow_confirmation"
      >
         <button class="filled outlined"
            color="secondary"
            style="border-radius: 6px; font-size: 1.1rem;"
            [disabled]="financeForm.containsErrors() || ('edit-financial-detilas-save' | inProgress) || disable"
            (click)="send_confirmation();"
         >
            <i icon="check"></i>
            <span class="screen-big">{{ "ORDER.CONFIRM" | trans }}</span>
         </button>

         <button *ngIf="edited"
            class="outlined"
            style="margin-right: 0; border-radius: 6px; font-size: 1.1rem;"
            color="primary"
            (click)="cancel_order()">
            <i icon="cancel"></i><span class="screen-big">{{ "CANCEL" | trans}}</span>
         </button>
      </div>


      <!-- total price -->
      <div class="total-price" style="margin-left: auto;">
         <strong>
            <task-price [label]="'ORDER.TOTAL'" [currency]="financial_items[0]?.element_currency ?? 'EUR'" [price]="sum()"></task-price>
         </strong>
         <p style="font-size: 78%; margin: 0.35em 0; font-weight: 400;">{{ 'FINANCIAL.TAX_DISCLAIMER' | trans}}.</p>

      </div>
   </div>

</div>
