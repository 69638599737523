
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "LOCATION.NAME": "Location",
        "LOCATION.CONTACT": "Contact",
        "LOCATION.ADDRESS": "Address",
        "LOCATION.NEW": "New Location",
        "LOCATION.EDIT": "Edit Location",
        "LOCATION.LOCATION": "Location",
        "LOCATION.DESCRIPTION": "Description",
        "LOCATION.COUNTRY": "Country",
        "LOCATION.REGION": "Region",
        "LOCATION.CITY": "City",
        "LOCATION.DISTRICT": "District",
        "LOCATION.POSTAL_CODE": "Postal code",
        "LOCATION.CONTACT_PHONE": "Contact phone",
        "LOCATION.TYPE": "Type",
        "LOCATION.TYPE.WAREHOUSE": "Warehouse",
        "LOCATION.TYPE.STORE": "Store",
        "LOCATION.TYPE.OFFICE": "Office",
        "LOCATION.TYPE.SUPPLIER_LOCATION": "Supplier location",
        "LOCATION.TYPE.LOCATION_GROUP": "Location group",
        "LOCATION.TYPE.OTHER": "Other",
        "LOCATION.TYPE.BUSINESS_FACILITY": "Business faciltiy",
        "LOCATION.TYPE.LEASED_LOCATION": "Leased location",
        "LOCATION.DELETE.TITLE": "Location deletion",
		"LOCATION.DELETE.MSG": (name) => `Are you sure you want to delete location ${name}?`,
        "LOC.NFC": 'Enable NFC scanning',
        "LOCATION.CIN": "MT code",
        "LOCATION.COMPANY": 'Company',
        "LOCATION.FORMAT": "Format",
        "LOCATION.AREA": "Area",
        "LOCATION.GROSS_AREA": "Gross area",
        "LOCATION.NET_AREA": "Total area",
        "LOCATION.ADD_NEW": "Create location"
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "LOCATION.NAME": "Lokacija",
        "LOCATION.CONTACT": "Kontakt",
        "LOCATION.ADDRESS": "Adresa",
        "LOCATION.NEW": "Nova Lokacija",
        "LOCATION.EDIT": "Uređivanje lokacije",
        "LOCATION.LOCATION": "Lokacija",
        "LOCATION.DESCRIPTION": "Opis",
        "LOCATION.COUNTRY": "Zemlja",
        "LOCATION.REGION": "Regija",
        "LOCATION.CITY": "Grad",
        "LOCATION.DISTRICT": "Naselje",
        "LOCATION.POSTAL_CODE": "Poštanski Broj",
        "LOCATION.CONTACT_PHONE": "Kontakt Telefon",
        "LOCATION.TYPE": "Tip",
        "LOCATION.TYPE.WAREHOUSE": "Skladište",
        "LOCATION.TYPE.STORE": "Dućan",
        "LOCATION.TYPE.OFFICE": "Ured",
        "LOCATION.TYPE.SUPPLIER_LOCATION": "Lokacija Dobavljača",
        "LOCATION.TYPE.LOCATION_GROUP": "Grupa Lokacija",
        "LOCATION.TYPE.OTHER": "Ostalo",
        "LOCATION.TYPE.BUSINESS_FACILITY": "Poslovni objekt",
        "LOCATION.TYPE.LEASED_LOCATION": "Prostor u najmu",
        "LOCATION.DELETE.TITLE": "Brisanje lokacije",
		"LOCATION.DELETE.MSG": (name) => `Da li ste sigurni da želite obrisati lokaciju ${name}?`,
        "LOC.NFC": 'Omogući skeniranje dolaska NFC-om',
        "LOCATION.CIN": "MT šifra",
        "LOCATION.COMPANY": 'Tvrtka',
        "LOCATION.FORMAT": "Format",
        "LOCATION.AREA": "Županija",
        "LOCATION.GROSS_AREA": "Prodajna površina",
        "LOCATION.NET_AREA": "Ukupna površina",
        "LOCATION.ADD_NEW": "Dodaj lokaciju"

    }
};

export const locations_translations = [english, croatian];
