import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "QUESTIONNAIRE": "Questionnaire",
        "QUESTIONNAIRES.EDIT": (whier_name) => whier_name ?
            `Edit questionnaire for ${whier_name}` :
            "Edit default questionnaire",
        "QUESTIONNAIRES.PARENT_WHIERS": "Questionnaires from parent work hierarchies",
        "QUESTIONNAIRES.ADD_QUESTION": "Add new question",
        "QUESTION": "Question",
        "QUESTION.TYPE": "Answer type",
        "QUESTION.TYPE.TEXT": "Text",
        "QUESTION.TYPE.IMAGE": "Image",
        "QUESTION.TYPE.INVENTORY": "Inventory",
        "QUESTION.TYPE.TOGGLE": "Yes / No",
        "QUESTION.TYPE.DOCUMENT": "Document",

        "QUESTION.IMAGE.MIN": "Minimum number of images",
        "QUESTION.IMAGE.MAX": "Maximum number of images",
        "QUESTION.IMAGE.MAXTEXT": (max) => `Up to ${max} images.`,

        "QUESTION.IMAGE.MINMAXEQC": (val) => `Total images: ${val}`,
        "QUESTION.IMAGE.MINMAXC": (min, max) => `Total images: ${min}-${max}`,
        "QUESTION.IMAGE.MINC": (min) => `Minimum images: ${min}`,
        "QUESTION.IMAGE.MAXC": (max) => `Maximum images: ${max}`,
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "QUESTIONNAIRE": "Upitnik",
        "QUESTIONNAIRES.EDIT": (whier_name) => whier_name ?
            `Uređivanje upitnika za ${whier_name}` :
            "Uređivanje osnovnog upitnika",
        "QUESTIONNAIRES.PARENT_WHIERS": "Upitnici sa viših razina radne hijerarhije",
        "QUESTIONNAIRES.ADD_QUESTION": "Dodaj novo pitanje",
        "QUESTION": "Pitanje",
        "QUESTION.TYPE": "Tip odgovora",
        "QUESTION.TYPE.TEXT": "Tekst",
        "QUESTION.TYPE.IMAGE": "Slika",
        "QUESTION.TYPE.INVENTORY": "Inventar",
        "QUESTION.TYPE.TOGGLE": "Da / Ne",
        "QUESTION.TYPE.DOCUMENT": "Dokument",

        "QUESTION.IMAGE.MIN": "Minimalni broj slika",
        "QUESTION.IMAGE.MAX": "Maksimalni broj slika",

        "QUESTION.IMAGE.MINMAXEQC": (val) => `Broj slika: ${val}`,
        "QUESTION.IMAGE.MINMAXC": (min, max) => `Broj slika: ${min}-${max}`,
        "QUESTION.IMAGE.MINC": (min) => `Minimalno slika: ${min}`,
        "QUESTION.IMAGE.MAXC": (max) => `Maksimalno slika: ${max}`,

    }
};

export const questionnaires_translations = [english, croatian];
