import { Component, Input, ViewChild, ElementRef, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { files, get_global } from '@task-utils/utils';
import { modal, language, ConfirmDialogComponent, color, noOp } from 'curvy';
import { components_trans } from './components.trans';

const CUSTOM_CVA = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiImageSelectNew),
    multi: true
};

// @NOTE: This can be repurposed to be a general file uploader.
// @TODO: Make styling better!

@Component({
    selector: 'task-multi-image-new',
    encapsulation: ViewEncapsulation.None,
    template: `
        <label class="input"
               title="HIDDEN IMAGE CONTAINER"
               style="display: none">
            <input [ngModel]="images.length"
                   #imageLength
                   type="number"
                   [min]="min_images"
                   [max]="max_images < min_images ? 999 : max_images"
                   [required]="min_images > 0"/>
        </label>
        <div class="thumbs-grid" [class.thumbs-flex]="!in_grid">
            <div *ngFor="let i of 0 | upto : max_image()">
                <img clickable [src]="images[i].src" [imgs]="images" [imgIndex]="i" />

                <div class="show_on_hover">
                    <i icon="zoom_in"></i>
                </div>
                <button class="round filled delete-img"
                        *ngIf="should_delete(images[i])"
                        color="warn"
                        [title]="'DELETE' | trans"
                        (click)="remove_image(i)">
                    <i [attr.icon]="images[i].is_uploaded ? 'delete' : 'clear'"></i>
                </button>
            </div>
            
            <button *ngIf="show_rest()" 
                class="round filled view-more" 
                [title]="'VIEW_ALL' | trans"
                color="secondary" 
                (click)="openImagesDialog()">
                    <i icon="more_horiz"></i>
            </button>
        </div>

        <button (click)="get_images()" *ngIf="!disabled && !in_grid" color="primary" class="pill"
                [disabled]="(max_images > 0 && images.length >= max_images)" style="margin: .7em auto;">
                <i icon="add_circle"></i>
            <span>{{ "ADD_IMAGE" | trans }}</span>
        </button>
    `,
    styleUrls: ['./components.scss'],
    providers: [ CUSTOM_CVA ]
})
export class MultiImageSelectNew implements ControlValueAccessor {
    @ViewChild('imageLength')
    image_length_input: ElementRef<HTMLInputElement>;

    max_image():number {
        let can_edit = !this.disabled;
        if(!can_edit) {

            if (this.images.length > 4) {

                return 2;
            } else {
                return this.images.length-1;
            }

        } 
        else return this.images.length-1;
    }

    show_rest() {
        return this.disabled && this.images.length > 4;
    }

    should_delete(img: files.IImage) {
        if (this.disabled) return false;

        if (!img.is_uploaded) return true;

        if (this.allow_deleting_uploaded) return true;

        return false;
    }



    @Input()
    max_width = 1024;

    @Input()
    max_height = 1024;

    @Input()
    jpeg_quality = 0.85;

    @Input()
    max_images = 999;

    @Input()
    min_images = 0;

    @Input('allow-deleting-uploaded')
    allow_deleting_uploaded = true;

    @Input()
    in_grid = false;

    images: files.IImage[] = [];
    private on_changed;
    private on_touched;

    @Input()
    disabled = false;

    constructor() {
        language.load_translations(components_trans);
    }

    async get_images() {
        let opts = {
            multiple: this.max_images != 1,
            max_width: this.max_width,
            max_height: this.max_height,
            jpeg_quality: this.jpeg_quality,
            accept: "image/*"
        };
        let prom = files.get_images(opts);
        let images = await prom;
        console.log('getimages', images)
        if (images === null) { return; }

        for (let img of images) {
            this.add_image(img);
        }
    }

    async add_image(img: string | files.IImage, do_events=true) {
        if (do_events && this.on_touched) { this.on_touched(); }
        this.image_length_input.nativeElement.dispatchEvent(new Event("input"));

        if (this.max_images > 0) {
            if (this.images.length >= this.max_images) {
                return;
            }
        }

        if (typeof img === "string") {
            img = await files.url_to_iimage(img);
        }

        if (files.is_iimage(img)) {
            this.images.push(img);
            if (do_events && this.on_changed) {
                this.on_changed(this.images);
            }
        } else {
            console.error("Attempt to add invalid image", img);
        }

    }

    remove_image(index: number, do_events=true) {

        if (this.images[index].is_uploaded) {
            modal.open(ConfirmDialogComponent, async (succ) => {
                if (succ) {
                    
                    if (do_events && this.on_touched) { this.on_touched(); }
                    this.image_length_input.nativeElement.dispatchEvent(new Event("input"));
            
                    let old_len = this.images.length;
                    this.images.splice(index, 1);
            
                    if (old_len != this.images.length) {
                        if (do_events && this.on_changed) {
                            this.on_changed(this.images);
                        }
                    }
                }
            }, {
                title: language.translate("DELETE"),
                message: language.translate("DELETE.IMAGE.TEXT"),
                icon: "delete",
                iconColor: color.variable(color.Variable.warn)
            })

        } else {

            if (do_events && this.on_touched) { this.on_touched(); }
            this.image_length_input.nativeElement.dispatchEvent(new Event("input"));
    
            let old_len = this.images.length;
            this.images.splice(index, 1);
    
            if (old_len != this.images.length) {
                if (do_events && this.on_changed) {
                    this.on_changed(this.images);
                }
            }
        }

    }

    openImagesDialog() {
        const ImageDialog = get_global("ImageDialog");
        modal.open(ImageDialog, noOp, {imgs: this.images, index: 0});
    }

    writeValue(val: (string | files.IImage)[]) {
        val = val || [];
        this.images = [];
        for (let img of val) {
            this.add_image(img, false);
        }
    }

    registerOnChange(fn) {
        this.on_changed = fn;
    }

    registerOnTouched(fn) {
        this.on_touched = fn;
    }

    setDisabledState(state: boolean) {
        this.disabled = state;
    }
}
