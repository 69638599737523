import { NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { network, utils, modal, cookies, language, Frame, noOp, navigation, async } from 'curvy';
import { users_translations } from './users.trans';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { utils as task_utils, get_global } from '@task-utils/utils';

type Paginator = network.Headers.Paginator;

// @HACK for chrome. @TODO: Move this to curvy!
// It appears that chrome no longer removes expired empty cookies
// from document.cookie. This caused a false positive in
// cookie.exists, which caused us to detect a spoof token
// to exist under the cookie X-Original-Token.
// The small adjustment below is all that is neccessary to make
// everything work fine again.
let original_exists = cookies.exists;
cookies.exists = (...args) => {
	let exists = original_exists(...args);

	if (exists && cookies.get(...args) === "") {
		return false;
	}

	return exists;
}

export namespace User {
	interface Current extends Task.User {
		locations: Exclude<Task.User["locations"], number>
	}

	export let fetching = false;
	export let backendVersion: string = "DEV";
	export let currentUser: Current = null;
	export let currentUserPromise: Promise<Current> = Promise.resolve(null);

	export let adminUser: Current = null;
	export let adminUserPromise: Promise<Current> = Promise.resolve(null);
	export let currentToken: string = null;

	export async function is_logged_in() {
		return !!(await User.currentUserPromise);
	}

	export async function is_not_logged_in() {
		return !(await User.currentUserPromise);
	}

	export async function is(...user_types: Task.USER_TYPE[]) {
		return !!(await User.currentUserPromise) && await User.currentTypeIs(...user_types);
	}

	export async function is_not(...user_types: Task.USER_TYPE[]) {
		return !!(await User.currentUserPromise) && !(await User.currentTypeIs(...user_types));
	}

	export function getUserMenu() {
		if (!currentUser) {
			return {actions:[]};
		}

		const create_edit_user_dialog = get_global("CreateEditUserDialog");
		const user_takeover_dialog = get_global("UserTakeoverDialog");
		const spoof_user_dialog = get_global("SpoofUserDialog");

		let base_menu = [
			{
				label: language.translate( 'NAVROUTE.SETTINGS' ),
				icon: 'settings',
				data_1: null,
				onClick: () => utils.router.navigateByUrl('/user-settings')
			},
			{
				label: language.translate( 'MANAGE_ACCOUNT' ),
				icon: 'manage_accounts',
				data_1: null,
				onClick: async () => {
					modal.open(
						create_edit_user_dialog,
						noOp,
						await currentUserPromise);
				}
			},
			// {
			// 	label: language.translate( 'MANUAL' ),
			// 	icon: 'menu_book',
			// 	data_1: null,
			// 	onClick: () => TaskRoutes.upload.api_user_manual_get()
			// },
			{
				label: language.translate( 'LOGOUT' ),
				icon: 'exit_to_app',
				data_1: null,
				onClick: User.logOut
			}
		];


		if (!adminUser && currentUser.user_type_id == Task.USER_TYPE.SYSTEM_ADMIN && !environment.production){
			base_menu.push({
				label: "Spoof",
				icon: 'badge',
				data_1: null,
				onClick: async() => {
					modal.open(spoof_user_dialog, (res)=> {
						if (res) {
							spoofUser(res.user_id);
						}

					}, await currentUserPromise )
				},
			});
		}
		if (adminUser) {
			base_menu.push({
				label: "Unspoof",
				icon: 'no_accounts',
				data_1: null,
				onClick: unspoof
			});
		}

		if (currentUser.user_type_id === Task.USER_TYPE.REGIONAL_RETAIL_MANAGER ||
			currentUser.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER ||
			currentUser.user_type_id === Task.USER_TYPE.COMPANY_WORKER ||
			currentUser.user_type_id === Task.USER_TYPE.SAFETY_MANAGER ||
			currentUser.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) {
			base_menu.splice(2, 0, {
				label: language.translate('USER.TAKE_OVER_LOCATIONS'),
				icon: 'find_replace',
				data_1: null,
				onClick: async ()=> {
					modal.open(user_takeover_dialog, noOp, await currentUserPromise)
				}
			});
		}

		return {actions: base_menu};
	}

	export function setToken(token: string, spoof = false) {
		if (spoof) {
			if (currentToken) {
				cookies.set("X-Original-Token", currentToken);
			} else {
				console.error("Can't spoof when not logged in!");
			}
		} else {
			if (cookies.exists("X-Original-Token")) {
				cookies.remove("X-Original-Token");
			}
		}

		cookies.set('Token', token);
		network.Headers.append_default_header({
			'Auth-Token': () => cookies.get('Token')
		});


		currentToken = token;
	}

	export function removeToken() {
		currentToken = null;
		cookies.remove("Token");
		cookies.remove("X-Original-Token");
		network.Headers.remove_default_header("Auth-Token");
	}

	export async function setTokenFromCookie(navigate=true) {
		clearLocalStorage();
		if (cookies.exists("Token")) {
			let token = cookies.get("Token");
			if (cookies.exists("X-Original-Token")) {
				token = cookies.get("X-Original-Token");
				cookies.remove("X-Original-Token");
			}

			setToken(token);
			await getCurrent();
		} else {
			await clearCredentials(navigate);
		}
	}

	async function first<T>(...promises: Promise<T>[]) {
		return await Promise.race(promises);
	}

	function wait_for_nav() {
		return new Promise<NavigationEnd>(resolve => {
			let s: Subscription;
			s = utils.router.events.subscribe((e) => {
				if (e instanceof NavigationEnd) {
					resolve(e);
					s.unsubscribe();
				}
			});
			return s;
		});
	}

	export async function clearCredentials(navigate=true) {
		currentUser = null;
		currentUserPromise = Promise.resolve(null);
		removeToken();

		await task_utils.when(() => !!utils.router);

		utils.zone.run(() => {
			network.Headers.remove_default_header('Auth-Token');
			if (navigate) {
				updateFrame();

				first<any>(
					wait_for_nav(),
					async.sleep(1)
				).then(() => {
					utils.router.navigateByUrl('/login');
				});
			}
		});
	}

	export function updateFrame() {
		language.load_translations(users_translations);

		if (!currentUser) {
			Frame.set({ right_text: "" });
			return;
		}

		Frame.set({right_text: frame_text()});
	}

	/** Clears all items from localstorage except navigation-date so we can redirect to dashboard in app.module.ts */
	function clearLocalStorage () {
		let nav_date_item = localStorage.getItem("navigation-date");
		localStorage.clear();
		if (nav_date_item)
			localStorage.setItem("navigation-date", nav_date_item);
	}

	export async function logIn(credentials: { email: string; password: string }) {
		clearLocalStorage();
		try {
			let prom = TaskRoutes.auth.api_user_login(credentials);
			currentUserPromise = prom.then(r => r.data as Current);

			let res = await prom;
			currentUser = res.data as Current;
			setToken(res.auth_token);

			// console.log("TODO: Warn supplier worker role when logged in with notification")
			// if (currentUser.user_type_id === Task.USER_TYPE.SUPPLIER_WORKER) {
			// 	let base_href = document.getElementsByTagName('base')[0].href;
			// 	window.location = (base_href + "mobile/?token=" + res.auth_token) as unknown as Location;
			// }

			Frame.set({
				user_name: currentUser.first_name + " " + currentUser.last_name
			});
			updateFrame();
			return res;
		} catch (err) {
			clearCredentials();
			throw err;
		}
	}

	export async function logOut() {
		if (adminUser) {
			await unspoof();
		}

		return TaskRoutes.auth.api_user_logout().then(res => {
			clearCredentials();
			return res;
		});
	}

	export function fullname(user: Task.User) {
		return `${user.first_name} ${user.last_name}`;
	}

	export function type_text(user: Task.User) {
		let usertype = Task.User_Type[user.user_type_id];
		usertype = language.translate("USER.TYPE." + usertype);
		return usertype;
	}

	export function frame_text() {
		let ft = "";
		if (adminUser) {
			ft = `${fullname(adminUser)} as ${fullname(currentUser)} (${type_text(currentUser)})`;
		} else {
			ft = `${fullname(currentUser)} (${type_text(currentUser)})`;
		}

		if (!environment.production && TaskRoutes.backend.includes("localhost")) {
			ft = "❀ " + ft;
		}
		return ft;
	}

	function refresh_navigation() {
		let ev = { urlAfterRedirects: utils.router.url } as NavigationEnd;
		for (const item of navigation.routes) {
			navigation.update_item_activity(item, ev);
		}
	}

	export async function spoofUser(user_id: number) {
		await unspoof();

		if (user_id === currentUser.user_id) {
			return;
		}

		let prom = TaskRoutes.auth.api_admin_user_spoof({user_id});

		adminUserPromise = currentUserPromise;
		adminUser = currentUser;

		currentUserPromise = prom.then(res => res.data as Current);
		let res = await prom;
		currentUser = res.data as Current;

		setToken(res.auth_token, true);

		Frame.set({
			user_name: currentUser.first_name + " " + currentUser.last_name,
			right_text: frame_text()
		});

		refresh_navigation();
	}

	export async function unspoof() {
		if (!adminUser) {
			return;
		}

		currentUserPromise = adminUserPromise;
		currentUser = await adminUserPromise;
		adminUserPromise = Promise.resolve(null);
		adminUser = null;

		setToken(cookies.get('X-Original-Token'));

		Frame.set({
			user_name: currentUser.first_name + " " + currentUser.last_name,
			right_text: frame_text()
		});

		refresh_navigation();
	}

	export async function getCurrent() {
		currentUserPromise = TaskRoutes.users.api_user_check().then(res => {
			currentUser = res.data as Current;

			Frame.set({
				user_name: currentUser.first_name + " " + currentUser.last_name
			});
			updateFrame();
			return currentUser;
		}).catch((err) => {
			clearCredentials(true);
			throw err;
		});

		return await currentUserPromise;
	}

	export async function currentType(): Promise<Task.USER_TYPE | null> {
		const current = await currentUserPromise;
		if (current) {
			return current.user_type_id;
		}

		return null;
	}

	export async function currentTypeIs(...types: Task.USER_TYPE[]): Promise<boolean> {
		let user_type = await currentType();

		for (let check_type of types) {
			if (check_type === user_type) {
				return true;
			}
		}

		return false;
	}

	export async function getAll(paginator?: Paginator) {
		return await TaskRoutes.users.api_user_get_all(paginator);
	}

	export async function search(pagination: Paginator, filter: { search: string[], attributes: string[] }) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;


		if (search_len === 0 || attr_len === 0) {
			return await getAll(pagination);
		}

		return await TaskRoutes.users.api_user_search(filter, pagination);
	}

	export async function get(id: number) {
		return (await TaskRoutes.users.api_user_get_single(id)).data;
	}

	export const add = TaskRoutes.users.api_user_add;

	export async function modify(user: Task.User) {
		return await TaskRoutes.users.api_user_modify(user.user_id, user);
	}

	export async function remove(user: Task.User) {
		return await TaskRoutes.users.api_user_delete(user.user_id);
	}

	export async function send_password_email(user_id: number) {
		return await TaskRoutes.users.api_send_password_recovery(user_id);
	}

	export async function work_groups(user_id: number) {
		return await TaskRoutes.users.api_user_work_groups_get_all(user_id);
	}

	export function feature_flag_enabled(feature_flag: keyof Task.User["feature_flags"]) {
		return async () => {
			let curr_user = await User.currentUserPromise;
			if (!curr_user) return false;

			return curr_user.feature_flags[feature_flag];
		}
	}

	export async function is_feature_flag_enabled(feature_flag: keyof Task.User["feature_flags"]) {
		let check_fn = feature_flag_enabled(feature_flag);

		return await check_fn();
	}

	window["_user"] = User;
	window.addEventListener("pageshow", (ev) => {
		if (ev.persisted) {
			User.getCurrent();
		}
	});
}
