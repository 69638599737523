
import { Component, ViewEncapsulation } from '@angular/core';
import { Frame, language, template, date, modal, noOp, popover } from 'curvy';
import { Task } from '@task-utils/types';
import { utils as task_utils } from '@task-utils/utils';
import { dashboard_translations } from './dashboard.trans';
import { User } from '../users/users';
import { TicketDialog } from '@task-modules/tickets/ticket/ticket.dialog';
import { TaskRoutes } from '@task-utils/routes';
import {DashboardInfoPopover} from './dashboard-info.component';


type CardObject = {
	status_id: number,
	order_id: number,
	title: string,
	location_cin: string,
	location_name: string,
	supplier_name: string
}
type CardType = {
	data: CardObject[],
	count: number,
	title: string,
	loading: boolean,
	status_id: number
};


@Component({
	selector: 'task-dashboard',
	template: `
	<div class="main-page-wrapper">
		<ng-container *ngIf="currentUser">
			<h1>{{"GREETING_TEXT" | trans }}, {{currentUser.first_name}}!</h1>

			<div>
				<h2>{{ "NAVROUTE.ORDERS" | trans}}</h2>

				<div class="dashboard-grid">
					<div class="flex-column dashboard-card" *ngFor="let order of orders; let i = index;">

						<div class="blind" [ngClass]="{
							open: !order.loading
						}">
							<knw-spinner color="primary" size="50"></knw-spinner>
						</div>

						<h3 class="dashboard-card-title">
							<span class="flex-row" style="gap: .7rem">
								<i class="dashboard-info-icon"
									icon="info"
									*ngIf="get_tooltip | fn : i"
									(click)="open_tooltip(i, order.status_id)"
									[style.color]="(setStatus | fn : order.status_id).color"></i>
								{{ order.title }}
							</span>
							<strong [style.backgroundColor]="(setStatus | fn : order.status_id).color">{{order.count}}</strong>


						</h3>

						<!-- <div class="flex-column calendar-popover-wrapper" style="width: 100%; gap: .5em; overflow: auto; box-sizing: border-box;">
							<ng-container *ngFor="let order of order.data">
								<a class="flex-row day-order" [ngStyle]="{
										borderLeft: '6px solid '+(setStatus | fn : i == 5 ? 10 : order.status_id).color
									}" [href]="'orders/'+order.order_id">
									<i [attr.icon]="(setStatus | fn : i == 5 ? 10 : order.status_id).icon"
										[ngStyle]="{marginRight: '1em', color: (setStatus | fn : i == 5 ? 10 : order.status_id).color}"></i>
									<span class="flex-column align-flex-start flex-dynamic">
										<span class="flex-row align-flex-start"><b>#{{ order.order_id }}</b>&nbsp;<i class="truncate-text" style="padding-right: 0.15em;">{{ order.title }}</i></span>
										<span>#{{ order.location_cin }} {{order.location_name}}</span>
										<strong>{{ order.supplier_name }}</strong>
									</span>
								</a>
							</ng-container>
							<div *ngIf="order.count > 10" style="margin-left: 3em; margin-top: .7em; letter-spacing: .15em; padding: .5em;">
								<i icon="more_horiz"></i>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</ng-container>
	</div>
  `,
  styleUrls: ['../orders-calendar/orders-calendar.component.scss', 'dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None

})

export class DashboardComponent {
	currentUser = User.currentUser;

	orders: CardType[] = [];

	setStatus(id: number) {
		return Task.get_status_icon(id);
	}

	constructor() {
		Frame.set({
			title: "NAVROUTE.DASHBOARD",
			visible: true,
			layout: "top",
			size: "scroll"
		});
		language.load_translations(dashboard_translations);


		task_utils.when(() => !!User.currentUser).then(() => {
			this.currentUser = User.currentUser;
		});
	}

	ngOnInit() {
		this.init();
	}

	open_tooltip(i: number, status: number) {
		let tooltip = this.get_tooltip(i);


		popover.open(DashboardInfoPopover, noOp, {tooltip, status}, noOp, "mouse", "auto", "dashboard-popover-wrapper");
	}


	get_tooltip(i: number) {
		let tooltip:string | undefined;
		switch (i) {
			case 0:
			case 3:
			case 4:
			case 5:
			case 6:
				tooltip = language.translate(`DASHBOARD.TOOLTIP_${i}`);
				break;
			default:
				tooltip = undefined;
		}


		return tooltip;
	}

	async init() {
		let pagination = {page_no: 1, page_size: 1};


		this.orders = [
			{data: [], count: 0, title: language.translate('DASHBOARD.UNFINISHED_ORDERS'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.IN_EXECUTION_ORDERS'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.CREATED_2_DAYS_BEFORE'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.UNINVOICED_ORDERS'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.SCHEDULED_ORDER_ORDERS'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.UNFINISHED_SCHEUDLED_ORDERS'), loading: true, status_id: 1},
			{data: [], count: 0, title: language.translate('DASHBOARD.IN_EXECTION_SCHEDULED_ORDERS'), loading: true, status_id: 1},
		]

		let is_admin = await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN);
		let extra_attr = is_admin ? [] : ["assigned_to"];
		let extra_search = is_admin ? [] : ["me"];

		// 1. Broj nerealiziranih naloga - broj naloga koji nisu u statusu izvršen ili fakturiran. Ne prikazivati servisne naloge.
		TaskRoutes.orders.api_order_search({
				"attributes": ["#!:status_id", "#!:status_id", "=?:scheduled_order_id", ...extra_attr],
				"search": ["" + Task.STATUS.INVOICED, "" + Task.STATUS.EXECUTED, "", ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[0].data = res.data;
				this.orders[0].loading = false;
				if (res.data.length) this.orders[0].status_id = res.data[0].status_id;
				this.orders[0].count = res.count;
			});

		// 2. Broj naloga u izvršavanju
		TaskRoutes.orders.api_order_search({
				"attributes": ["#=:status_id", ...extra_attr],
				"search": ["" + Task.STATUS.IN_EXECUTION, ...extra_search]
			}, pagination, false).then(res=> {
				this.orders[1].data = res.data;
				this.orders[1].count = res.count;
				if (res.data.length) this.orders[1].status_id = res.data[0].status_id;
				this.orders[1].loading = false;
			});


		let date_under_48h = date.subtract(new Date(), date.duration({date: 2})).toISOString();
		// 3. Broj naloga u statusu kreiran duže od 48 sati
		TaskRoutes.orders.api_order_search({
				"attributes": ["#=:status_id", "+d:created_date", ...extra_attr],
				"search": [""+ Task.STATUS.CREATED, date_under_48h, ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[2].count = res.count;
				this.orders[2].data = res.data;
				if (res.data.length) this.orders[2].status_id = res.data[0].status_id;
				this.orders[2].loading = false;
			});

		// 4. Broj izvršenih naloga koji nisu fakturirani
		TaskRoutes.orders.api_order_search({
				"attributes": ["#=:status_id", ...extra_attr],
				"search": [""+ Task.STATUS.EXECUTED, ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[3].count = res.count;
				this.orders[3].data = res.data;
				if (res.data.length) this.orders[3].status_id = res.data[0].status_id;
				this.orders[3].loading = false;
			});

		if (await User.is_feature_flag_enabled('scheduled_orders')) {
			// 5. Broj najavljenih servisa (nalozi za servise u status krerian).
			TaskRoutes.orders.api_order_search({
				"attributes": ["#=:status_id", "!?:scheduled_order_id", ...extra_attr],
				"search": ["" + Task.STATUS.CREATED, "", ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[4].count = res.count;
				this.orders[4].data = res.data;
				if (res.data.length) this.orders[4].status_id = res.data[0].status_id;
				this.orders[4].loading = false;
			});

			// 6. Broj neizvršenih servisa (naloga za servise koji su u statusu Neizvršen”)
			TaskRoutes.orders.api_order_search({
				"attributes": ["!?:scheduled_order_id", "#=:status_id", "+d:scheduled_order.planned_end_date", ...extra_attr],
				"search": ["", ""+Task.STATUS.CREATED, new Date().toISOString(), ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[5].count = res.count;
				this.orders[5].data = res.data;
				this.orders[5].status_id = 10;
				this.orders[5].loading = false;
			});


			// 7. Broj servisa u izvršavanju (nalozi za servise koji su u tijeku)
			TaskRoutes.orders.api_order_search({
				"attributes": ["!?:scheduled_order_id","#=:status_id", ...extra_attr],
				"search": ["", ""+ Task.STATUS.IN_EXECUTION, ...extra_search]
			}, pagination, false).then(res=>{
				this.orders[6].count = res.count;
				this.orders[6].data = res.data;
				if (res.data.length) this.orders[6].status_id = res.data[0].status_id;
				this.orders[6].loading = false;
			})

		} else {
			this.orders.splice(4)
		}


	}


	openTicket(ticket: Task.Ticket) {
		modal.open(TicketDialog, noOp, ticket);
	}
}

