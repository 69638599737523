import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Frame, TableConfig, GenericFilterComponent, GenericFilter, template, date } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

const COMPANY_TYPE_ICON = {
    [Task.COMPANY_TYPE.MAIN]: "storefront",
    [Task.COMPANY_TYPE.EXTERNAL_SUPPLIER]: "engineering",
    [Task.COMPANY_TYPE.INTERNAL_SUPPLIER]: "handyman",
};

@Component({
    selector: 'task-companies',
    template: `
        @if (jobs_running) {
            <div style="padding: .5em">
                Jobs are currently running.
            </div>
        }
        <knw-data-table [config]="tableConfig"></knw-data-table>
    `,
    styles: [`
        :host {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
            outline: 2px red;
        }
    `]
})
export class CompaniesComponent {
    constructor(public cdr: ChangeDetectorRef) {
        Frame.set({
            title: "NAVROUTE.COMPANIES",
            visible: true,
            layout: "middle",
            size: "full"
        });
    }

    jobs_running = false;
    attached = true;

    async check_jobs() {
        if (!this.attached) return;

        let was_running = this.jobs_running;
        this.jobs_running = (await TaskRoutes.jobs.api_are_jobs_running()).data;
        this.cdr.detectChanges();

        if (was_running && !this.jobs_running) {
            this.tableConfig.table_component.getData();
        }

        if (this.jobs_running) {
            setTimeout(() => {
                this.check_jobs();
            }, 1000);
        }
    }

    ngOnDestroy() {
        this.attached = false;
    }

    tableConfig: TableConfig<Task.Company, DisplayCompany, 'companies', GenericFilterComponent> = {
        tableName: 'companies',
        getData: async (filter, paginator, sort_header) => {

            let parsed_filter = {
                search: [],
                attributes: [],
                sort: undefined
            };

            for (let key in filter) {
                parsed_filter.attributes.push(key);
                parsed_filter.search.push(filter[key])
            }

            if (sort_header) {
                switch (sort_header.label) {
                    case 'company':
                        sort_header.label = '#:company_id';
                        break;
                }

                parsed_filter.sort = sort_header;
            } else {
                parsed_filter.sort = {
                    label: "#:company_id",
                    direction: "asc"
                };
            }

            this.check_jobs();
            return await TaskRoutes.companies.api_company_search(parsed_filter, paginator);
        },
        getHeader: (header) => {
            switch (header) {
                case 'company':
                    return { icon: "apartment", label: "COMPANY", sortable: true };

                case 'smtp':
                    return { icon: "mail", label: "SMTP", sortable: false };

                case 'flags':
                    return { icon: "flag", label: "FLAGS", sortable: false };

                case 'jobs':
                    return { icon: "timer", label: "JOBS", sortable: false };
            }
        },
        showHeaderWhenEmpty: true,
        unwrapData: (company) => {
            let flags = "";
            let flags_obj: Record<string, string> = {};
            for (let key in company.flags) {
                let name = key.replace("flag_", "");
                flags += `<div class="flex-row align-center">
                    {{${key} | icon}}<b>${name}</b>
                </div>`;
                flags_obj[key] = company.flags[key] ? 'check' : 'remove';
            }

            let job_names = new Set<string>();
            for (let key in company.jobs) {
                let job_name = key.replace(/^job_/, "");
                job_name = job_name.replace(/_time$|_last$/, "");
                job_names.add(job_name);
            }

            let jobs = "";
            for (let name of job_names) {
                let last = company.jobs[`job_${name}_last`] ?? "never";
                let time = company.jobs[`job_${name}_time`];

                if (last != "never") {
                    last = date.get_formatted_date(new Date(last), "short_date_time", false);
                }

                if (time === undefined) {
                    jobs += `<div><b>${name}</b> ${last}</div>`;

                } else if (time === null) {
                    jobs += `<div><b>${name}</b> disabled</div>`;

                } else {
                    jobs += `<div>
                        <b>${name}</b>
                        <div style="padding-left: 1em">at: ${time}</div>
                        <div style="padding-left: 1em">last: ${last}</div>
                    </div>`;

                }
            }

            return {
                company: template.transform(`
                    <div class="flex-row flex-dynamic" style="text-align: left">
                        <div style="width: 5ch; display: inline-block">#{{company_id}}</div>
                        <i style="padding-inline: 1em"  icon="${COMPANY_TYPE_ICON[company.company_type_id]}"></i>
                        <b>{{name}}</b>
                    </div>
                    `, company
                ),

                smtp: template.transform(`
                    <div class="flex-column flex-dynamic" style="text-align: left">
                        <div>
                            <b>Server</b>: ${company.smtp_email}
                        </div>
                        <div>
                            <b>Port</b>: ${company.smtp_port}
                        </div>
                        <div>
                            <b>Default</b>: ${company.smtp_use_default}
                        </div>
                    </div>
                `, company),

                flags: template.transform(`
                    <div class="flex-column flex-dynamic" style="text-align: left">
                        ${flags}
                    </div>
                `, flags_obj),

                jobs: template.transform(`
                    <div class="flex-column flex-dynamic" style="text-align: left">
                        ${jobs}
                    </div>
                `)
            }
        },

        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("COMPANY", "name"),
                GenericFilter.dropdown(
                    "TYPE", "#=:company_type_id",
                    [
                        { label: "COMPANY", value: Task.COMPANY_TYPE.MAIN },
                        { label: "SUPPLIER", value: Task.COMPANY_TYPE.EXTERNAL_SUPPLIER },
                    ]
                ),
            ];
            return filters;
        },

        tableActions: [
            {
                priority: 1,
                icon: "timer",
                label: "Run jobs",
                onClick: async () => {
                    await TaskRoutes.jobs.api_run_jobs();
                    this.check_jobs();
                }
            }
        ]
    }
}

type DisplayCompany = {
    company: template.EscapedHTML;
    smtp: template.EscapedHTML;
    flags: template.EscapedHTML;
    jobs: template.EscapedHTML;
}
