<div class="comments-container flex-column">
	<div class="comments flex-column flex-dynamic">
		<div class="comment" *ngFor="let comment of comments; let i = index" [attr.title]="comment.created_date | datefmt">
                <span style="font-size: 0.9em; font-weight: 500;">{{comment.created_date | datefmt : 'short_date_time' : false}}</span>
            <div class="comment-text">
                <strong style="font-weight: bold">
				{{comment.created_by_first_name}} {{comment.created_by_last_name}}:
			    </strong>
			    <p >{{comment.comment}}</p>
            </div>
		</div>
	</div>
    <div #commentscontainer></div>

	<div class="flex-row flex-static align-center" style="margin-top: 0.6rem; gap: 1rem;">
		<label class="textarea filled flex-dynamic" (keyup.enter)="add_comment()">
			<textarea placeholder="{{ 'COMMENT' | trans }}" [(ngModel)]="comment_text"></textarea>
		</label>
		<button class="filled round" color="secondary" (click)="add_comment()" style="margin-left: 0;">
			<i icon="send"></i>
		</button>
	</div>
</div>
