import { network, modal, noOp, language, color } from 'curvy';
import { get_global } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users';
import { ScheduledOrderTemplateType } from './orders.types';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';

const { Get, Delete } = network;
type Paginator = network.Headers.Paginator;

export namespace Order {
	export async function is_supplier_admin() {
		return await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN);
	}

	export async function open_order(order_id: number) {
		modal.open(get_global('OrderDialog'), noOp, await get(order_id));
	}

	export async function getAll(paginator?: Paginator, expand=true) {
		return await TaskRoutes.orders.api_order_get_all(paginator, expand);
	}

	export async function search(
		pagination: Paginator,
		filter: { search: string[], attributes: string[] },
		expand=true
	) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;

		if (search_len === 0 || attr_len === 0) {
			return getAll(pagination, expand);
		}

		return await TaskRoutes.orders.api_order_search(filter, pagination, expand);
	}

	export async function get(id: number) {
		return (await TaskRoutes.orders.api_order_get_single(id)).data;
	}

	export async function add(order: Task.Order, expand=false) {
		return await TaskRoutes.orders.api_order_add(order as any, expand);
	}

	export async function modify(order: Task.Order, expand=false) {
		return await TaskRoutes.orders.api_order_modify(order.order_id, order, expand);
	}

	export async function remove(order: Task.Order) {
		return await Delete(TaskRoutes.orders.order_delete(order.order_id));
	}

	export async function get_tickets(order_id: number) {
		return await Get<Task.Payload<Task.Ticket[]>>(
			TaskRoutes.orders.order_tickets_get_all(order_id)).then(res=>res.data);
	}

	export function scheduled_order_template_empty(): ScheduledOrderTemplateType {
		return {
			type: "TEMPLATE",
			annual_frequency: null,
			locations: [],
			planned_start_date: null,
			planned_end_date: null,
			cut_date: null,
			supplier_id: null,
			supplier_name: null,
			title: null,
			status_id: Task.STATUS.CREATED,
			investment_type: Task.INVESTMENT_TYPE.DA,
			scheduled_orders_template_details: [
				{description: "", order_detail_type_id: Task.ORDER_DETAIL_TYPE.DESCRIPTION}
			],
			event_id: NaN,
			event_details: [],
			whier_id: null,
			whier: null,
			arrival_worker: null,
			arrival_worker_id: NaN,
			responsible_users: []
		};
	}

	export function delete_order_dialog(order: Task.Order, afterDelete?: () => void) {
		modal.open(
			TextBoxDialog,
			async(result) => {
				if (result === null) return;
				if (result == "" || result === undefined) {
					delete_order_dialog(order, afterDelete);
					return;
				}
				if (result) {
					await TaskRoutes.orders.api_order_modify(order.order_id, {comment: result});
					await TaskRoutes.orders.api_order_delete(order.order_id);
					afterDelete?.();
				}
			}, {
				title: language.translate("ORDER.DELETE.TITLE", order.order_id),
				label: "ORDER.DELETE.REASON",
				message: {
					message:
						order.status_id === Task.STATUS.IN_EXECUTION ?
							language.translate("ORDER.DELETE.IN_EXECUTION") :
							language.translate("ORDER.DELETE.MSG"),
					icon: "delete",
					iconColor: color.variable(color.Variable.warn)
				},
				placeholder: "ORDER.DELETE.ENTER_REASON",
				cancelText: "CANCEL",
				confirmText: "DELETE",
				buttonColor: "warn"
			}
		);
	}

}

