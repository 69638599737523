import { network, Omit } from 'curvy';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';

const { Post, Get, Patch, Delete, Headers } = network;
type Paginator = network.Headers.Paginator;

export namespace Comments {
	export async function get(entity: Task.ENTITY, entity_id: number) {
		return await TaskRoutes.comments.api_comments_by_entity_value_get_all(entity, entity_id);
	}

	export async function add(entity: Task.ENTITY, entity_id: number, text: string) {
		return await TaskRoutes.comments.api_comment_add({
			entity_id: entity,
			entity_value: entity_id,
			comment: text
		});
	}
}
