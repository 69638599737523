import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import type { Task } from './app/utils/types';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { defaultEncapsulation: ViewEncapsulation.None })
    .catch(err => console.error(err));


if (!environment.production) {
    async function wait_for(cond: () => boolean) {
        return new Promise<void>(resolve => {
            let int = setInterval(() => {
                if (!cond()) return;
                clearInterval(int);
                resolve();
            }, 100);
        });
    }

    function pick<K>(arr: K[]): K {
        if (arr.length == 0) return null;
        return arr[Math.floor(Math.random() * arr.length)];
    }

    async function get_one<K>(
        route: (s: any, p: any, b: boolean) => Promise<{ data: K[] }>
    ): Promise<K> {
        let res = pick(
            (await route({search:[], attributes:[]}, {page_no: 1, page_size: 50}, false)).data
        );
        if (!res) {
            throw new ZeroError(`Can't find any data: ${route.name}`);
        }
        return res;
    }

    type TestConfig = ({ user_type: number } | { user_id: number } | {}) & { screen?: string };

    class ZeroError extends Error {};

    class TestRunner {
        private static existing: TestRunner = null;
        private static original_fetch = window.fetch;
        private static test_element = document.createElement("progress");

        static New() {
            if (!TestRunner.existing) {
                TestRunner.existing = new TestRunner();
            }
            return TestRunner.existing;
        }

        mod: typeof import('./app/modules');
        User: (typeof import('./app/modules/users/users'))["User"];
        types: typeof import('./app/utils/types')["Task"];
        routes: (typeof import('./app/utils/routes'))["TaskRoutes"];
        module: (typeof import('./app/app.module'));
        curvy: typeof import('curvy');
        private imports_done = false;

        private current_user: Task.User;
        private screen_timings: Record<string, ScreenTiming> = {};
        private screen: string;
        private original_token: string;

        private static getprop<K extends keyof TestRunner>(name: K): TestRunner[K] {
            if (!this.existing)
                throw new Error("TestRunner not initialized yet!");
            return this.existing[name];
        }

        static get mod() { return this.getprop("mod"); }
        static get User() { return this.getprop("User"); }
        static get types() { return this.getprop("types"); }
        static get routes() { return this.getprop("routes"); }
        static get module() { return this.getprop("module"); }
        static get curvy() { return this.getprop("curvy"); }

        set_admin_token(token: string) { this.original_token = token; }
        reset() { this.screen_timings = {}; }

        private get screen_timing() {
            if (!this.screen)
                throw new Error("Screen is null!");
            return this.screen_timings[this.screen];
        }

        private async do_imports() {
            if (this.imports_done) return;
            this.imports_done = true;
            this.mod = await import("./app/modules");
            this.User = (await import("./app/modules/users/users")).User;
            this.routes = (await import("./app/utils/routes"))["TaskRoutes"];
            this.types = (await import("./app/utils/types"))["Task"];
            this.module = (await import("./app/app.module"));
            this.curvy = await import("curvy");
            this.set_admin_token(this.curvy.cookies.get("Token"));
        }

        private init_localstorage() {
            localStorage.clear();
            localStorage.setItem("default-page-size", "200");
            localStorage.setItem("navigation-date", new Date().toISOString());
        }

        private async set_user_type(user_type: Task.USER_TYPE) {
            this.init_localstorage();

            this.current_user = null;
            await this.User.unspoof();
            this.User.setToken(this.original_token, false);
            const res = await this.routes.users.api_user_search({
                "attributes": ["#=:user_type_id", "#!:company_id"],
                "search": [user_type.toString(), "1"]
            }, { page_no: 1, page_size: 50 });
            const selected = pick(res.data);
            await this.User.spoofUser(selected.user_id)
            this.current_user = selected;
        }

        private async set_user(user_id: number) {
            this.init_localstorage();

            this.current_user = null;
            await this.User.unspoof();
            this.User.setToken(this.original_token, false);
            const selected = (await this.routes.users.api_user_get_single(user_id)).data;
            await this.User.spoofUser(selected.user_id);
            this.current_user = selected;
        }

        private init_screen_timing(screen: string) {
            if (!(screen in this.screen_timings)) {
                this.screen_timings[screen] = new ScreenTiming(screen);
            }
            this.screen = screen;
        }

        private async run_screen_test(screen: string=null) {
            await this.do_imports();
            if (!this.current_user) throw new Error("current_user is null");

            let screens: string[] = [];
            if (screen) {
                screens = [screen];
            } else {
                screens = this.mod.routes.map(r => r.path);
            }

            for (let screen of screens) {
                this.screen = null;
                this.init_screen_timing(screen);
                this.update_test_element(1);
                this.restore_fetch();
                this.patch_fetch();
                if (!screen) continue;
                if (screen.includes("login")) continue;
                if (screen.includes("set-password")) continue;
                if (screen.includes("user-settings")) continue;
                if (screen.includes("reports")) continue;

                if (!await this.curvy.navigation.can_see_route("/" + screen.split("/")[0])) {
                    continue;
                }

                let url: string;
                try {
                    url = await this.get_url(screen);
                } catch(err) {
                    if (err instanceof ZeroError) {
                        console.info(err.message);
                        continue;
                    }

                    console.error("Failed to get url:", err);
                    continue;
                }

                try {
                    await this.curvy.utils.zone.run(async () => {
                        try {
                            await this.curvy.utils.router.navigateByUrl("/");
                            await this.curvy.utils.router.navigateByUrl(url);
                        } catch (err) {
                            throw err;
                        }
                    });
                } catch (err) {
                    console.error("ERROR while navigating to", url, err);
                    continue;
                }

                await this.curvy.async.sleep(100);

                await wait_for(() => {
                    if (this.screen_timing.fetches.length == 0) {
                        console.log(this.screen, "0 requests");
                        return true;
                    }
                    return this.screen_timing.finished;
                });
            }

            this.restore_fetch();
            this.screen = null;
        }


        private test_case_count(config: TestConfig) {
            let screen_mul = "screen" in config ? 1 : this.mod.routes.length;
            let has_user_spec = "user_type" in config || "user_id" in config;
            let type_mul = has_user_spec ? 1 : Object.keys(this.types.User_Type).length;
            return screen_mul * type_mul;
        }

        async run_test(config: TestConfig={}) {
            await this.do_imports();

            this.inject_test_element(this.test_case_count(config));

            if ("user_id" in config) {
                await this.set_user(config.user_id);
                await this.run_screen_test(config.screen);

            } else if ("user_type" in config) {
                await this.set_user_type(config.user_type)
                await this.run_screen_test(config.screen);

            } else {
                for (let ut in this.types.User_Type) {
                    try {
                        await this.set_user_type(parseInt(ut, 10));
                    } catch (err) {
                        console.groupCollapsed("Failed to set user type", this.types.User_Type[ut]);
                        console.warn(err);
                        console.groupEnd();
                        continue;
                    }

                    try {
                        await this.run_screen_test(config.screen);
                    } catch (err) {
                        console.groupCollapsed(
                            "Failed to run test",
                            config.screen ?? "All screens",
                            this.types.User_Type[ut]);
                        console.warn(err);
                        console.groupEnd();
                    }
                }
            }

            this.screen = null;
            this.remove_test_element();
        }

        private cases = 0;
        private current_case = 0;
        private inject_test_element(cases: number) {
            this.cases = cases;
            this.current_case = 0;
            TestRunner.test_element.style.position = "absolute";
            TestRunner.test_element.style.left = "10px";
            TestRunner.test_element.style.top = "32px";
            TestRunner.test_element.style.width = "calc(100vw - 20px)";
            TestRunner.test_element.style.zIndex = "1000000";
            TestRunner.test_element.max = 1;
            TestRunner.test_element.value = 0;
            document.body.append(TestRunner.test_element);
        }

        private update_test_element(cases: number) {
            this.current_case += cases;
            TestRunner.test_element.value = this.current_case/this.cases;
        }

        private remove_test_element() {
            TestRunner.test_element.remove();
        }

        async get_url(screen_url: string): Promise<string> {
            if (!screen_url.includes("/:")) { return screen_url; }

            if (screen_url.startsWith("arrivals/")) {
                let arrival_id = (await get_one(this.routes.arrivals.api_arrival_search)).arrival_id;
                return screen_url.replace(":id", arrival_id + "");
            }

            if (screen_url.startsWith("orders/")) {
                let order_id = (await get_one(this.routes.orders.api_order_search)).order_id;
                return screen_url.replace(":id", order_id + "");
            }

            if (screen_url.startsWith("tickets/")) {
                let ticket_id = (await get_one(this.routes.tickets.api_ticket_search)).ticket_id;
                return screen_url.replace(":id", ticket_id + "");
            }

            if (screen_url.startsWith("work-hierarchy/")) {
                let whier_id = (await get_one(this.routes.work_hierarchies.api_work_hierarchy_search)).whier_id;
                return screen_url.replace(":id", whier_id + "");
            }

            if (screen_url.startsWith("pricelists/")) {
                let element_id = (await get_one(this.routes.pricelists.api_pricelist_search)).element_id;
                return screen_url.replace(":id", element_id + "");
            }

            throw Error(`Don't know how to navigate to ${screen_url}`);
        }

        private restore_fetch() {
            window.fetch = TestRunner.original_fetch;
        }

        private patch_fetch() {
            if (!this.screen) {
                throw new Error("Failed to patch fetch: Screen is not set.")
            }

            const st = this.screen_timings[this.screen];
            if (!st) {
                throw new Error("Failed to patch fetch: ScreenTiming is null.")
            }

            const u = this.current_user;
            const ut = this.types.User_Type[u.user_type_id];
            const old_fetch = TestRunner.original_fetch;

            window.fetch = async (...args: [any, any]) => {
                let t = performance.now();
                let url = args[0];
                let method = args[1].method;
                let token = this.curvy.network.default_headers["Auth-Token"];
                if (typeof(token) == "function") token = token();
                if (typeof(token) != "string") token = token.toString();
                let ft = new FetchTiming(
                    ut,
                    url.toString(),
                    method,
                    token,
                    args[1]);
                st.fetches.push(ft);

                let res: Response = null;
                try {
                    res = await old_fetch(...args);
                    if (res.status >= 400) {
                        let cpy = res.clone();
                        try {
                            let json = await cpy.json();
                            ft.error = json["error_argument"] || json;
                        } catch {
                            let text = await cpy.text();
                            ft.error = text;
                        }
                    }
                } catch (err) {
                    ft.error = err;
                }
                ft.timing = performance.now() - t;
                ft.finished = true;
                return res;
            }
        }

        report() {
            for (let sn in this.screen_timings) {
                let st = this.screen_timings[sn];
                let errs = st.errors(null).length;
                console.groupCollapsed(st.screen, {max: st.max, avg: st.avg, errs});
                for (let ut in this.types.User_Type) {
                    let name = this.types.User_Type[ut];
                    let total = st.total(ut);
                    if (total == 0) continue;
                    let errs = st.errors(ut).length;
                    console.info(name, {total, errs}, st.filter(ut));
                }
                console.groupEnd();
            }
        }
    }

    /**
        FetchTiming times a single call to fetch().
        We use this to keep track of how long each request
        takes to complete, which user type initiated it, etc.
        Because FetchTiming objects keep around the arguments
        passed to fetch(), as well as the user's token, it is
        possible to use them to reproduce a request by calling
        `retry`.
    */
    class FetchTiming {
        finished = false;
        timing = 0;
        error: any|null = null;
        constructor(
            public user_type: string,
            public url: string,
            public method: string,
            public token: string,
            public arg2: any,
        ) {}

        /** Reruns the request with the same parameters */
        async retry() {
            // let {User} = await imports();
            // await User.unspoof();
            // await User.setToken(this.token, true);
            let res = await window.fetch(this.url, this.arg2)
            // await User.unspoof();
            return res;
        }
    }

    /**
        Screen timing is a container for all
        fetches that happen on a single screen.

        It contains convenience functions for getting
        various metrics about the executed fetches.
    */
    class ScreenTiming {
        fetches: FetchTiming[] = [];
        start = performance.now();
        constructor(
            public screen: string,
        ) {}

        get finished() {
            return !this.fetches.some(f => !f.finished);
        }

        filter(ut: number|string=null) {
            if (!ut) return this.fetches;
            if (typeof(ut) == "number" || ut.length <= 2) {
                ut = TestRunner.types.User_Type[ut];
            }
            return this.fetches.filter(f => f.user_type == ut);
        }

        get max() {
            let max = 0;
            for (let t in TestRunner.types.User_Type) {
                let val = this.total(t);
                if (val > max) { max = val; }
            }
            return max;
        }

        total(ut: number|string) {
            let times = this.filter(ut).map(f => f.timing);
            let sum = 0;
            for (let time of times) {
                sum += time;
            }
            return sum;
        }

        get avg() {
            let sum = 0;
            let count = 0;
            for (let t in TestRunner.types.User_Type) {
                let val = this.total(t);
                if (val <= 0) continue;
                sum += val;
                count++;
            }

            return sum / count;
        }

        errors(ut: number|string) {
            return this.filter(ut).filter(f => f.error).map(f => f.error);
        }
    }

    window["__test"] = TestRunner.New();
}
