import { Pipe } from "@angular/core";
import { date } from 'curvy';


@Pipe({
	name: "date_multifmt",
	pure: true
})

export class DateMultiFormat {
	transform(
		d: Date | string,
		format_1: string,
		format_2?: string
	) {
		if (d == undefined) {
			return undefined;
		}		

		if (typeof d === "string") {
			d = new Date(d);
		}

		let formatted_date = "";

		try {
			formatted_date = date.get_formatted_date(d, format_1 as any, false);
		} catch {
			formatted_date = date.format(format_1, d, false);
		}

		if (format_2) {
			try {
				formatted_date += ` (${date.get_formatted_date(d, format_2 as any, false)})` ;
			} catch {
				formatted_date += ` (${date.format(format_2, d, false)})`;
			}
		}

		return formatted_date;

	}
}