import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

@Component({
    selector: 'task-create-edit-location-group-dialog',
    template: `
        <task-create-edit-location-group [location_group]="location_group" (result)="handle_result($event)"></task-create-edit-location-group>
    `
})
export class CreateEditLocationGroupDialog extends ModalBase<Task.LocationGroup, Task.LocationGroup> implements OnInit {
    preventSoftClose = true;
    location_group: Task.LocationGroup;
    
    handle_result(location_group) {
        if(location_group) {

            let prom;
            let payload = {...location_group};

            if (location_group.location_group_users) {
                payload.user_ids = location_group.location_group_users.map(u=>u.user_id);
            }

            if (location_group.location_group_id) {
                prom = TaskRoutes.location_groups.api_location_group_modify(this.injectedData.location_group_id, payload)
            } else {
                prom = TaskRoutes.location_groups.api_location_group_add(payload)
            }

            prom.then(res=>{
                this.submit(location_group)
            })

        } else {
            this.submit(location_group)
        }
    }

    ngOnInit() {
        this.location_group = this.injectedData;

    }
}
