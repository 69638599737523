import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, NavigationStart, NavigationEnd } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import {
	Curvy, language, utils, navigation, CurvyRootComponent, color,
	localData, notification, cookies, date
} from 'curvy';

import { environment } from 'environments/environment';
import { nav_translations } from './app.trans';

import { AppComponent } from './app.component';
import * as modules from './modules';
import { TaskComponentsModule } from '@task-components/task-components.module';
import { TaskDirectivesModule } from './core/directives/task-directives.module';
import { User } from '@task-modules/users/users';
import { Task } from '@task-utils/types';
import { TaskPipesModule } from './core/pipes/task-pipes.module';

function user_is_logged_in() {
	return async () => {
		return !!(await User.currentUserPromise);
	};
}

function user_is_not_logged_in() {
	return async () => {
		return !(await User.currentUserPromise);
	};
}

function user_is(...user_types: Task.USER_TYPE[]) {
	return async () => {
		return !!(await User.currentUserPromise) && await User.currentTypeIs(...user_types);
	};
}

function user_is_not(...user_types: Task.USER_TYPE[]) {
	return async () => {
		return !!(await User.currentUserPromise) && !(await User.currentTypeIs(...user_types));
	};
}

function And(...args: Array<() => Promise<boolean>>) {
	return async () => {
		for (let arg of args) {
			if (!(await arg())) return false;

		}

		return true;
	}
}

function Or(...args: Array<() => Promise<boolean>>) {
	return async () => {
		for (let arg of args) {
			if (await arg()) return true;
		}

		return false;
	}
}

function Not(arg: () => Promise<boolean>) {
	return async () => {
		return !(await arg());
	};
}



@NgModule({
	declarations: [
		AppComponent,
		...modules.components
	],
	imports: [
		BrowserModule,
		Curvy,
		RouterModule.forRoot([
			...modules.routes,
			{ path: '', redirectTo: '/login', pathMatch: 'full' },
			{ path: '**', redirectTo: '/login' }
		], {}),
		TaskDirectivesModule,
		TaskComponentsModule,
		TaskPipesModule,
		FormsModule
	],
	providers: [],
	bootstrap: [AppComponent, CurvyRootComponent]
})
export class AppModule {
	constructor(injector: Injector) {
		// @TODO: Hardcoded theme. Remove this.
		color.themes["Task"] = {
			primary: color.from_hex("#1b0040"), // @TODO: Consider changing this to #5a5
			secondary: color.from_hex("#01db8f"),
			background: color.from_hex("#f4f4f4"),
			warn: color.from_hex("#ff6161")
		};

		Curvy.Init(
			injector,
			[language.english_base, language.croatian_base],
			language.croatian_base.locale,
			color.themes["Task"],
			false);
		utils.set_environment(environment);

		color.set_theme(color.themes["Task"]);

		// @TODO @HACK: This should not be by default.
		if (!cookies.exists("HR_OVERRIDE")) {
			language.set_current_language(
				language.croatian_base.locale
			);
		}

		language.load_translations(nav_translations);

		utils.router.events.subscribe(ev => {
			if (!User.currentUser) return;

			let USERS_FOR_DASHBOARD = [
				Task.USER_TYPE.ADMIN,
				Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
				Task.USER_TYPE.SAFETY_MANAGER
			];

			if (!USERS_FOR_DASHBOARD.includes(User.currentUser.user_type_id)) return;

			if (window.location.toString().endsWith('/dashboard')) return;

			if (ev instanceof NavigationStart) {

				if (!(ev.url.endsWith("/login") || ev.url.endsWith("/set-password"))) {

					if (!ev.url.endsWith("/dashboard")) {
						let ls_date = undefined;

						try {
							ls_date = localData.get("navigation-date");
						} catch {}

						if (!ls_date) {
							utils.router.navigateByUrl("/dashboard");
							localData.set("navigation-date", new Date().toISOString());
							return;
						}

						let last_navigation_date = new Date(ls_date);
						let today = new Date();

						if (date.start_of_day(today) > date.end_of_day(last_navigation_date)) {
							utils.router.navigateByUrl("/dashboard");
							localData.set("navigation-date", new Date().toISOString());
						}
					}
				}
			}

			if (ev instanceof NavigationEnd) {
				if (!(ev.url.endsWith("/login") || ev.url.endsWith("/set-password"))) {
					localData.set("navigation-date", new Date().toISOString());
				}
			}
		});


		navigation.set_default_route("/login");
		window["navigation"] = navigation;
		window["utils"] = utils;
		localData.set("default-page-size", 100);

		notification.set_default_timeout(5000)
		navigation.set_navigation_items([
		{
			label: "DASHBOARD",
			icon: "dashboard",
			link: "/dashboard",
			visible: user_is(
				Task.USER_TYPE.ADMIN,
				Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
				Task.USER_TYPE.SAFETY_MANAGER,
				Task.USER_TYPE.SYSTEM_ADMIN
			)
		},
		{
			label: "MAIN",
			icon: "star",
			children: [
			{
				label: "COMPANIES",
				icon: "apartment",
				link: "/companies",
				visible: user_is(Task.USER_TYPE.SYSTEM_ADMIN)
			}, {
				label: "USERS",
				icon: "person",
				link: "/users",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL,
					Task.USER_TYPE.SAFETY_MANAGER)
			}, /*{
				label: "WORKGROUPS",
				icon: "people",
				link: "/work-groups"
			},*/ {
				label: "SECTORS",
				icon: "people",
				link: "/sectors",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
					Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL,
					Task.USER_TYPE.SAFETY_MANAGER)
			}, {
				label: "WORKHIER",
				icon: "account_tree",
				link: "/work-hierarchy",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL),
				subroutes: [/^[/]work-hierarchy[/]\d+[/]edit-questionnaires$/]
			}, {
				label: "LOCATIONS",
				icon: "location_on",
				link: "/locations",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL)
			}, {
				label: "LOCATION_GROUPS",
				icon: "holiday_village",
				link: "/location-groups",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL)
			},{
				label: "SUPPLIERS",
				icon: "local_shipping",
				link: "/suppliers",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL),
				subroutes: [
					/^[/]pricelists[/]\d+$/
				]
			}, {
				label: "PRICELIST",
				icon: "request_quote",
				link: "/pricelists",
				visible: user_is_not(
					Task.USER_TYPE.STORE_MANAGER,
					Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
					Task.USER_TYPE.COMPANY_WORKER,
					Task.USER_TYPE.AUTHORIZED_PERSONEL)
			}, {
				label: "WORKGROUPS",
				icon: "group_work",
				link: "/work-groups",
				visible: user_is(
					Task.USER_TYPE.ADMIN,
					Task.USER_TYPE.SYSTEM_ADMIN
				)
			}, {
				label: "CHECKLISTS",
				icon: "checklist",
				link: "/checklists",
				visible: And(
					user_is(Task.USER_TYPE.ADMIN,Task.USER_TYPE.SYSTEM_ADMIN),
					User.feature_flag_enabled('checklists')
				)
			}]
		}, {
			label: "TICKETS",
			icon: "receipt",
			link: "/tickets",
			subroutes: [
				/^[/]tickets[/]\d+$/,
				/^[/]ticket-single[/]\d+$/,
			],
			visible: user_is_not(
				Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER)
		}, {
			label: "ORDERS",
			icon: "ballot",
			link: "/orders",
			visible: user_is_logged_in(),
			subroutes: [
				/^[/]orders[/]\d+$/,
				/^[/]order-single[/]\d+$/,
				/^[/]orders[/]\d+[/]financial-details$/,
			]
		}, {
			label: "ARRIVALS",
			icon: "today",
			link: "/arrivals",
			visible: user_is_logged_in(),
			subroutes: [
				/^[/]arrivals[/]\d+$/,
				/^[/]arrival-single[/]\d+$/,
				/^[/]arrivals[/]\d+[/]details$/

			]
		}, {
			label: "SCHEDULED_ORDERS",
			icon: "settings",
			link: "/scheduled-orders",
			visible: And(
				user_is_not(Task.USER_TYPE.SUPPLIER_WORKER, Task.USER_TYPE.COMPANY_WORKER),
				User.feature_flag_enabled('scheduled_orders')),
			subroutes: [
				/^[/]scheduled-orders[/]\d+$/,
				/^[/]scheduled-orders[/]create-scheduled-order$/,
			]
		}, {
			label: "CALENDAR",
			icon: "calendar_today",
			link: "/orders-calendar",
			visible: user_is(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN, Task.USER_TYPE.SUPPLIER_ADMIN,
				Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
				Task.USER_TYPE.STORE_MANAGER,
				Task.USER_TYPE.COMPANY_WORKER,
				Task.USER_TYPE.AUTHORIZED_PERSONEL,
				Task.USER_TYPE.SAFETY_MANAGER
		),
			subroutes: [
				/^[/]orders-calendar[/]$/,
			]
		}, {
			label: "REPORTS",
			icon: "text_snippet",
			link: "/reports",
			visible: user_is(
				Task.USER_TYPE.SYSTEM_ADMIN,
				Task.USER_TYPE.ADMIN,
				Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
				Task.USER_TYPE.SUPPLIER_ADMIN,
				Task.USER_TYPE.SAFETY_MANAGER
			)
		}, {
			label: "ANALYTICS",
			icon: "assessment",
			link: "",
			visible: user_is_logged_in(),
		}, {
			label: "SETPASS",
			icon: "question",
			link: "/set-password",
			subroutes: [
				/^[/]set-password[/].+$/
			],
			visible: user_is_not_logged_in()
		}]);

		User.setTokenFromCookie(!location.pathname.startsWith("/set-password")).then(async () => {
			if (User.currentUser) {
				navigation.can_see_route(utils.router.url).then(visible => {
					if (visible && utils.router.url != "/login") {
						utils.router.navigateByUrl(utils.router.url);
					} else {
						navigation.get_first_elligible_route(navigation.routes).then(route => {
							utils.router.navigateByUrl(route);
						});
					}
				});
			}
		});
	}
}
