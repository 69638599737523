<h2 class="modal-title">{{ (is_edit_form ? 'WORKGROUP.EDIT' : 'WORKGROUP.NEW') | trans }}</h2>

<div class="flex-column">


	<label class="input outlined" [title]="'WORKGROUP.NAME' | trans" style="grid-column: 1 / -1">
		<input [(ngModel)]="workgroup.work_description" />
	</label>

	<label class="checkbox-input" *ngIf="is_edit_form">
		<input type="checkbox"
				[(ngModel)]="workgroup.automatically_update_arrival_details" />
		{{"WORKGROUP.UPPDATE_ALL_MSG" | trans }}
	</label>


</div>

<div class="buttons">
	<button color="primary" (click)="cancel()">
		<i icon="clear"></i>
		<span>{{ 'CANCEL' | trans }}</span>
	</button>


	<button class="filled" color="secondary" (click)="done()">
		<i icon="check"></i>
		<span>{{ 'SAVE' | trans }}</span>
	</button>

</div>
