
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
		"REPORTS.DATE": "Date and time",
    "REPORTS.SUPPLIER": "Supplier",
    "REPORTS.FINANCIAL_TITLE": "Reports",
    "REPORTS.INVENTORY_EXPENSE_TITLE": "Inventory expense report",
    "REPORTS.PRICELIST_ELEMENT_TITLE": "Pricelist element expense report",
    "REPORTS.DOWNLOAD": "Download report",
    "REPORTS.RESPONSIBLE_USER": "Responsible user",
    "REPORTS.NO_DATA_TITLE" : "Error in fatching data",
    "REPORTS.NO_DATA_DESCR" : "There is no report for given data",
    "REPORTS.PRICELIST_EL": "Pricelist element",
    "REPORTS.FREON_TITLE": "Freon expense report",
    "REPORTS.SUPPLIER_ADMIN": "Report for supplier admin",
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
		"REPORTS.DATE": "Datum i vrijeme",
    "REPORTS.SUPPLIER": "Dobavljač",
    "REPORTS.FINANCIAL_TITLE": "Izvještaji",
    "REPORTS.INVENTORY_EXPENSE_TITLE": "Izvještaj troškova inventara",
    "REPORTS.PRICELIST_ELEMENT_TITLE": "Izvještaj troškova po elementu cjenika",
    "REPORTS.DOWNLOAD": "Preuzmi izvještaj",
    "REPORTS.RESPONSIBLE_USER": "Odgovorna osoba",
    "REPORTS.NO_DATA_TITLE" : "Greška pri dohvaćanju izvještaja",
    "REPORTS.NO_DATA_DESCR" : "Ne postoji izvještaj za dane podatke",
    "REPORTS.PRICELIST_EL": "Element cjenika",
    "REPORTS.FREON_TITLE": "Izvještaj za potrošnju freona",
    "REPORTS.SUPPLIER_ADMIN": "Izvještaj za dobavljača",
    }
};

export const reports_translations = [english, croatian];
