import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { files } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { color, ConfirmDialogComponent, file, language, modal, notification } from "curvy";
import { components_trans } from './components.trans';
import { Task } from '@task-utils/types';


const CUSTOM_CVA = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiFileSelect),
    multi: true
};

type FileType = (files.IFile | Task.DMSFile);

@Component({
  selector: 'task-upload-files',
  template: `
    <div [class]="position == 'stretched' ? 'flex-row align-center justify-space-between' : 'flex-column align-center' " style="margin-top: 1.5em;">
        <div class="flex-row wrappable" style="gap: 1em; ">
            <span class="flex-row align-center file-representation" *ngFor="let file of files; let i = index; ">
                <task-file [file]="file" [download_file]="download_file"></task-file>

                <button class="round filled"
                    color="warn"
                    style="width: 20px; height: 20px;"
                    [title]="'DELETE' | trans"
                    (click)="remove_file(i, file)"
                    *ngIf="!disabled && can_delete(file)"
                >
                    <i icon="clear"></i>
                </button>
            </span>
        </div>
        <button *ngIf="!disabled" (click)="get_files_for_upload()" color="primary" class="pill" style="margin: .7em 0;">
            <i icon="add_circle"></i>
        <span>{{ button_label | trans }}</span>
        </button>
    </div>

  `,
  styles: [`
    .file-representation {
        gap: 0.3em;
    }

    .file-representation i {
        cursor: pointer;
        font-size: 75%;
        margin-top: 0.1em;
    }

    .file-representation span {
        display: inline-block;
    }
`],
providers: [ CUSTOM_CVA ]
})
export class MultiFileSelect implements OnInit, ControlValueAccessor {
    msgs = {
        msg: language.translate("FILE_UPLOAD.SUCCESS_MSG"),
        showMsg: false,
        msg_color: 'secondary-dark'
    };

    @Input ()
    position = '';
    files: FileType [] = [];

    @Input ()
    download_file:(file: FileType)=>Promise<Blob> | null;

    @Input()
    disabled = false;

    @Input('allow-deleting-uploaded')
    allow_deleting_uploaded = true;

    @Input('type-of-files')
    type_of_files: string = null;

    @Input('button-label')
    button_label = "ADD_FILE";

    private on_changed;
    private on_touched;

    ngOnInit() {
        language.load_translations(components_trans);
    }

    async get_files_for_upload() {
        let options: files.GetFilesOptions = {
            multiple: true,
            max_file_size: 40000000, //10000000
            on_files_too_large: (files) => {
                let names = files.map(f => f.name).join(", ");
                notification.show({
                    icon: "error",
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: language.translate('DOCUMENT.UPLOADED_TOO_BIG_TEXT', names)
                })
            }
        }
        if (this.type_of_files) options["accept"] = this.type_of_files;
        let out_files = await files.get_files(options)
        this.files.push(...out_files);
        this.on_changed(this.files);
    }

    add_file(file: FileType, do_events=true) {
        this.files.push(file);

        if (do_events && this.on_changed) {
            this.on_changed(this.files);
        }
    }

    name(file: FileType) {
        if ("file" in file) {
            return file.file.name;
        } else {
          return file.dms_document_file_name;
        }
      }

    remove_file(index, file) {
        modal.open(ConfirmDialogComponent, async (succ)=>{
            if (succ) {
                this.files.splice(index, 1);
                this.on_changed(this.files);
            }
        }, {
            title: language.translate("DELETE"),
            message: language.translate("DELETE.FILE.TEXT", `${this.name(file)}`),
            icon: "delete",
            iconColor: color.variable(color.Variable.warn)
        })
    }

    can_delete(file: FileType) {
        if ("dms_document_id" in file) {
            return this.allow_deleting_uploaded;
        } else {
            return true;
        }
    }

    writeValue(val: FileType[]) {
        val = val || [];

        this.files = [];
        for (let file of val) {
            this.add_file(file, false);
        }
    }

    registerOnChange(fn) {
        this.on_changed = fn;
    }

    registerOnTouched(fn) {
        this.on_touched = fn;
    }

    setDisabledState(state: boolean) {
        this.disabled = state;
    }
}
