<ng-container *ngIf="arrival">

<div class="flex-row" style="margin-bottom: 0.25em">
	<task-back-button></task-back-button>
	<h2 style="margin: 0 0 0 0.5ch">{{ 'ARRIVAL.DETAILS' | trans }}</h2>
</div>

<div class="flex-column" style="overflow: auto; height: 100%" form-collector #workelementForm="collector">
	<div class="detail"
			[ngClass]="{ deleted: detail['is_deleted'] }"
			*ngFor="let detail of original_details">

		<div class="flex-row justify-space-between">
			<h4 style="margin-top: 0">
				{{ get_title | fn : detail }}
			</h4>
			<button *ngIf="can_delete" class="round" color="warn"
					(click)="toggle_delete_detail(detail)">
				<i [attr.icon]="detail['is_deleted'] ? 'undo' : 'delete'"></i>
			</button>
		</div>

		<ng-container [ngSwitch]="detail.arrival_detail_type_id">

			<!-- WORK DESC -->
			<ng-container *ngSwitchCase="1">
				<p class="prewrap">{{ detail.description }}</p>
			</ng-container>

			<!-- WORK COMM -->
			<ng-container *ngSwitchCase="2">
				<p class="prewrap">{{ detail.description }}</p>
			</ng-container>

			<!-- WORK UNFINISHED REASON -->
			<ng-container *ngSwitchCase="4">
				<p class="prewrap">{{ detail.description }}</p>
			</ng-container>

			<!-- WORK ELEMENT -->
			<ng-container *ngSwitchCase="3">
				<p>
					<b>{{ "ARRIVAL.DETAIL.ELEMENT_QUANTITY" | trans }}: </b>
					{{ detail.element_quantity }} {{ detail.element_uom }}
				</p>

				<p *ngIf="can_see_individual_prices">
					<task-price [label]="'ARRIVAL.DETAIL.ELEMENT_PRICE'"
							[price]="detail.element_price"
							[currency]="detail.element_currency"></task-price>
				</p>

				<p *ngIf="can_see_individual_prices">
					<task-price [label]="'ARRIVAL.DETAIL.ELEMENT_TOTAL'"
							[price]="detail.invoice_type_id == 2 ? 0 : detail.element_price * (detail.element_quantity || 0) * (detail.lump_sum ? 0 : 1)"
							[currency]="detail.element_currency"></task-price>
					<ng-container *ngIf="detail.lump_sum">
						({{ "ARRIVAL.DETAILS.LUMP" | trans }})
					</ng-container>
				</p>
			</ng-container>

			<!-- DOCUMENT -->
			<ng-container *ngSwitchCase = "5">
				<p class="prewrap">{{ detail.description }}</p>
				<task-file [file]="detail" [download_file]="download_file"></task-file>
			</ng-container>

		</ng-container>
	</div>

	<div class="detail"
			*ngFor="let detail of details">

		<div class="flex-row justify-space-between">
			<h4 style="margin-top: 0">
				{{ get_title | fn : detail : detail.element_name }}
			</h4>
			<button class="round" color="warn"
					*ngIf="detail.arrival_detail_type_id != 3 || detail.element_quantity != 0"
					(click)="toggle_delete_detail(detail)">
				<i [attr.icon]="detail['is_deleted'] ? 'undo' : 'delete'"></i>
			</button>
		</div>

		<ng-container [ngSwitch]="detail.arrival_detail_type_id">

			<!-- WORK DESC -->
			<ng-container *ngSwitchCase="1">
				<label class="input outlined"
						[title]="'ARRIVAL.DETAIL.WORK_DESCRIPTION' | trans">
					<input [(ngModel)]="detail.description" required/>
				</label>
			</ng-container>

			<!-- WORK COMM -->
			<ng-container *ngSwitchCase="2">
				<label class="input outlined"
						[title]="'ARRIVAL.DETAIL.WORK_COMMENTARY' | trans">
					<input [(ngModel)]="detail.description" required/>
				</label>
			</ng-container>

			<!-- WORK ELEMENT -->
			
			 <ng-container *ngSwitchCase="3">
				<task-work-element [detail] = "detail"></task-work-element>
			</ng-container> 

			<!-- WORK UNFINISHED REASON -->
			<ng-container *ngSwitchCase="4">
				<label class="input outlined"
						[title]="'ARRIVAL.DETAIL.WORK_UNFINISHED_REASON' | trans">
					<input [(ngModel)]="detail.description" required/>
				</label>
			</ng-container>

			<!-- DOCUMENT -->
			<ng-container *ngSwitchCase="5">
				<task-file [file]="detail._iFile"></task-file>
			</ng-container>

		</ng-container>
	</div>
</div>

<ng-container *ngIf="can_edit">
	<div class="add-detail flex-row">
		<label class="input outlined flex-dynamic"
				[title]="'ARRIVAL.DETAIL.TYPE' | trans">
			<select [(ngModel)]="current_detail_type">
				<option [ngValue]="null">
					{{ "ARRIVAL.DETAIL.SELECT_TYPE" | trans }}
				</option>
				<option *ngFor="let type of DETAIL_TYPES | keyvalue"
						[ngValue]="+type.key">
					{{ ("ARRIVAL.DETAIL." + type.value) | trans }}
				</option>
			</select>
		</label>
		<button class="filled pill"
				style="margin-right: 0;"
				color="primary"
				(click)="create_detail(current_detail_type)"
				[disabled]="!current_detail_type">
			<i icon="add_circle"></i>
			<span class="screen-big">{{ "ARRIVAL.DETAIL.ADD" | trans }}</span>
		</button>
	</div>
	
	<div class="buttons">
		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			<span>{{ 'CANCEL' | trans }}</span>
		</button>
	
	
		<button class="filled" 
				color="secondary" 
				(click)="save()" 
				[disabled]="workelementForm.containsErrors() || ('edit-arrival-details-save' | inProgress)">
			<i icon="check"></i>
			<span>{{ 'SAVE' | trans }}</span>
		</button>
	
	</div>
</ng-container>
</ng-container>

