import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

type SupplierType = Omit<Task.Supplier, "status_id">;

@Component({
    selector: 'task-create-edit-supplier-dialog',
    template: `
        <task-create-edit-supplier [supplier]="supplier" (result)="handle_result($event)"></task-create-edit-supplier>
    `
})
export class CreateEditSupplierDialog extends ModalBase<any, any> implements OnInit {
    preventSoftClose = true;
    supplier: SupplierType;
    can_edit_mail = false;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => {
                this.supplier = res || {
                    name: "",
                    email: "",
                    company_type_id: Task.COMPANY_TYPE.EXTERNAL_SUPPLIER,
                    cin: "",
                    identification_no: "",
                    sectors: []
                }
            });
        } else {
            this.supplier = this.injectedData || {
                name: "",
                email: "",
                company_type_id: Task.COMPANY_TYPE.EXTERNAL_SUPPLIER,
                cin: "", 
                identification_no: "",
                sectors: []
            }
        }
    }

    handle_result(supplier: SupplierType) {
        if (supplier == null) {
            this.submit(false);
            return;
        }

        
        
        let promise: Promise<any>;
        if (this.supplier["supplier_id"]) {
            let out_supplier = supplier as unknown as Parameters<typeof TaskRoutes.suppliers.api_supplier_modify>[1];
    
            out_supplier.sectors = supplier.sectors.map(s=>s.sector_id);
    
            this.supplier = supplier;

            promise = TaskRoutes.suppliers.api_supplier_modify(this.supplier.supplier_id, out_supplier);
        } else {
            let out_supplier = supplier as unknown as Parameters<typeof TaskRoutes.suppliers.api_supplier_add>[0];

            out_supplier.sectors = supplier.sectors.map(s=>s.sector_id);

            this.supplier = supplier;

            promise = TaskRoutes.suppliers.api_supplier_add(out_supplier);
        }

        promise.then(() => {
            this.submit(true);
        });
    }
}
