import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { language, ModalBase, modal, noOp, Frame, utils } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { WorkHierarchy } from '@task-modules/work-hierarchy/work-hierarchies'
import { questionnaires_translations } from './questionnaires.trans';

@Component({
    selector: 'task-edit-whier-questionnaires',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './edit-whier-questionnaires.component.html',
    styleUrls: ['./edit-whier-questionnaires.component.scss']
})
export class EditWhierQuestionnairesComponent implements OnInit {
    @Input()
    whier: Task.WorkHierarchy;

    static_questionnaires: Task.Questionnaire[];
    original_questionnaires: Task.Questionnaire[];
    questionnaires: Task.Questionnaire[];

    saving = false;

    async update_values() {
        this.static_questionnaires = [];
        this.original_questionnaires = [];
        this.questionnaires = [];
        let qs: Task.Questionnaire[];

        if (this.whier.whier_id) {
            qs = (await WorkHierarchy.Questionnaire.get_all_by_whier_recursive(this.whier.whier_id)).data;
        } else {
            qs = (await TaskRoutes.questionnaires.api_questionnaire_get_all()).data;
            qs = qs.filter(q => q.whier_id == null);
        }

        for (let questionnaire of qs) {
            if (questionnaire.whier_id === this.whier.whier_id) {
                this.original_questionnaires.push({...questionnaire});
                this.questionnaires.push(questionnaire);
            } else {
                this.static_questionnaires.push(questionnaire);
            }
        }
    }

    constructor(private ar: ActivatedRoute) { }

    ngOnInit() {
        language.load_translations(questionnaires_translations);

        if (this.whier == null) {
            const whier_id = +this.ar.snapshot.paramMap.get("id");

            if (whier_id != 0) {
                WorkHierarchy.get(whier_id).then(res => {
                    this.whier = res;
                    this.update_values();

                    Frame.set({
                        title: language.translate("NAVROUTE.WHIER_QUESTIONNAIRES"),
                        visible: true,
                        layout: "middle",
                        size: "full"
                    });
                });
            } else {
                this.whier = { whier_id: null } as Task.WorkHierarchy;
                this.update_values();
                Frame.set({
                    title: language.translate("NAVROUTE.WHIER_QUESTIONNAIRES"),
                    visible: true,
                    layout: "middle",
                    size: "full"
                });
            }
        } else {
            this.update_values();
        }
    }

    add_question() {
        this.questionnaires.push(<Task.Questionnaire>{
            whier_id: this.whier.whier_id,
            component_type_id: Task.COMPONENT_TYPE.TEXT,
            question: "",
            group: 1
        });
    }

    delete_question(questionnaire: Task.Questionnaire) {
        let index = this.questionnaires.indexOf(questionnaire);
        if (index != -1) {
            this.questionnaires.splice(index, 1);
        }
    }

    async save_changes() {
        if (this.saving) {
            return;
        }

        this.saving = true;
        let promises: Promise<any>[] = [];
        for (let q of this.questionnaires) {
            if (q.component_type_id == Task.COMPONENT_TYPE.IMAGE) {
                if (q.max_images > 0 &&
                    q.min_images > 0 &&
                    q.max_images < q.min_images
                ) {
                    [q.max_images, q.min_images] = [q.min_images, q.max_images];
                }
            }

            let prom: Promise<any>;
            if (q.questionnaire_id) {
                delete q["company_id"];
                prom = TaskRoutes.questionnaires.api_questionnaire_modify(q.questionnaire_id, q as any);
            } else {
                prom = TaskRoutes.questionnaires.api_questionnaire_add(q as any);
            }

            promises.push(prom);
        }

        let ids_to_delete = this.original_questionnaires
            .map(q=>q.questionnaire_id)
            .filter(id => !this.questionnaires.find(q => q.questionnaire_id === id));

        for (let id of ids_to_delete) {
            promises.push(
                TaskRoutes.questionnaires.api_questionnaire_delete(id)
            );
        }

        await Promise.all(promises);
        history.go(-1);
    }

    cancel() {
        if (this.saving) {
            return;
        }

        history.go(-1);
    }
}
