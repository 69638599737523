<h4 style="margin: 0.5em 0">{{questionnaire.question}}</h4>
<ng-container [ngSwitch]="questionnaire.component_type_id">

<!-- TOGGLE -->
<div class="flex-row" *ngSwitchCase="1">
    <button class="flex-dynamic"
            (click)="got_bool(true)"
            [ngClass]="{ 'filled': bool_answer === true }"
            color="secondary">
        <i icon="check"></i>
    </button>
    <button class="flex-dynamic"
            (click)="got_bool(false)"
            [ngClass]="{ 'filled': bool_answer === false }"
            color="secondary">
        <i icon="close"></i>
    </button>
</div>

<!-- TEXT -->
<ng-container *ngSwitchCase="3">
    <label class="input outlined" [title]="questionnaire.question">
        <input (change)="got_text($event.target.value)"
                [conditions]="is_required ? 'nonempty' : ''"/>
    </label>
</ng-container>

<!-- IMAGE -->
<ng-container *ngSwitchCase="4">
    <p *ngIf="questionnaire.min_images || questionnaire.max_images"
       style="margin-top: 0">
        <i *ngIf="questionnaire.min_images != 0 &&
                  questionnaire.max_images != 0 &&
                  questionnaire.min_images != questionnaire.max_images">
            {{ 'QUESTION.IMAGE.MINMAXC' | trans : questionnaire.min_images : questionnaire.max_images }}
        </i>
        <i *ngIf="questionnaire.min_images == questionnaire.max_images">
            {{ 'QUESTION.IMAGE.MINMAXEQC' | trans : questionnaire.min_images }}
        </i>
        <i *ngIf="questionnaire.min_images != 0 &&
                  questionnaire.max_images == 0">
            {{ 'QUESTION.IMAGE.MINC' | trans : questionnaire.min_images }}
        </i>
        <i *ngIf="questionnaire.min_images == 0 &&
                  questionnaire.max_images != 0">
            {{ 'QUESTION.IMAGE.MAXC' | trans : questionnaire.max_images }}
        </i>
    </p>
    <task-multi-image ngModel
        [min_images]="questionnaire.min_images"
        [max_images]="questionnaire.max_images"
        (ngModelChange)="got_images($event)">
    </task-multi-image>
</ng-container>

<!-- DOCUMENT -->
<ng-container *ngSwitchCase="6">
    <task-upload-files
        ngModel
        (ngModelChange)="got_files($event)"
        [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'">
    </task-upload-files>
</ng-container>

<!-- INVENTORY -->
<ng-container *ngSwitchCase="5">
    <task-inventory-picker ngModel
        (ngModelChange)="got_inventory($event)"
        [location_cin]="location_cin">
    </task-inventory-picker>
</ng-container>

<ng-container *ngSwitchDefault>
    <i icon="error"></i>
</ng-container>

</ng-container>
