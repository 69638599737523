import { Component, OnInit } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { orders_translations } from './orders.trans';

@Component({
    selector: 'task-order-dialog',
    template: `
        <task-order [order]="order"></task-order>
    `
})
export class OrderDialog extends ModalBase<Task.Order, any> implements OnInit {
    order: Task.Order;

    ngOnInit() {
        language.load_translations(orders_translations);
        this.order = this.injectedData;
    }
}
