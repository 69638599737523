import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { files } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { Ticket } from '../tickets';
import { WorkHierarchy } from '@task-modules/work-hierarchy/work-hierarchies';
import { Answer_Type } from '@task-modules/questionnaires/answer-questionnaire.component';
import { User } from '@task-modules/users/users';
import { tickets_translations } from '../tickets.trans';
import { orders_translations } from '@task-modules/orders';

interface ShortLocation {
	location_id: number;
	cin: string;
	name: string;
	address: string;
};

@Component({
	selector: 'task-create-ticket',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="flex-column fullheight-noscroll" style="max-width: 580px;">
		<h2 class="flex-static modal-title">
			{{ "TICKET.NEW" | trans }}
		</h2>

		<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>


		<div style="overflow: auto"
				form-collector
				#ticketForm="collector">
			<div class="field-grid flex-static">
				<label class="input outlined whole-row"
						title="{{ 'TICKET.TITLE' | trans }}">
					<i icon="receipt"></i>
					<input [(ngModel)]="ticket.title"
							conditions="nonempty" />
				</label>

				<div class="justify-flex-end flex-row whole-row">
					<!-- <h4 style="margin: 0.7em 0;">{{ ("PRIORITY") | trans }}</h4> -->
					<button class=""style="gap: 0.5em;"
							(click)="toggle_priority()">
						<i [attr.icon]="PRIORITY_ICONS[ticket.priority_id].icon"
								[style.color]="PRIORITY_ICONS[ticket.priority_id].color">
						</i>
						{{ ("PRIORITY." + PRIORITY[ticket.priority_id]) | trans }}
					</button>
				</div>

				<task-search-locations
					[(locations)]="picked_location"
					[loc-disable]="location_picker_disabled"
				></task-search-locations>

				<p *ngIf="show_warn_msg" class="whole-row flex-row justify-flex-end align-center" style="color: rgb(var(--warn));gap: .5em;"><i icon="warning" color="warn"></i>{{ "NO_LOCATION_ASSIGNED_MSG" | trans }}</p>

				<task-whier-picker [(ngModel)]="whier"
						(ngModelChange)="whier_changed()"
						required>
				</task-whier-picker>
			</div>

			<div class="flex-column flex-dynamic"
					*ngIf="questionnaires && questionnaires.length > 0">
				<ng-container *ngFor="let q of questionnaires; let i = index">
					<task-answer-questionnaire
							[questionnaire]="q"
							[location-cin]="picked_location?.cin"
							(answer)="set_answer(i, $event)"
							[is_required]="true">
					</task-answer-questionnaire>
				</ng-container>
			</div>

			<div class="flex-row align-center" style="margin-top: 1em;">

				<label class="checkbox-input" style="margin-left: auto; ">
					<input type="checkbox"
							[(ngModel)]="ticket.complaint" />
						{{"ORDER.RECLAMATION" | trans }}
				</label>
			</div>
		</div>

		<div class="flex-row flex-static modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				{{ 'CANCEL' | trans }}
			</button>
			<button class="filled"
					color="secondary"
					(click)="create_ticket()"
					[disabled]="ticketForm.containsErrors()">
				<i icon="check"></i>
				{{ 'CREATE' | trans }}
			</button>
		</div>
	</div>
	`,
	styles: [`
		task-create-ticket {
			max-width: 600px;
		}

		task-create-ticket .field-grid {
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr;
			grid-gap: 0 8px;
		}

		task-create-ticket knw-search-picker {
			display: contents;
		}

		task-create-ticket knw-search-picker label[title="Location"] {
			flex-flow: row-reverse;
		}

		task-create-ticket knw-search-picker label[title="Location"] input {
			margin-right: 0;
			margin-left: 24px;
		}

		task-create-ticket knw-search-picker label[title="Location"]::after {
			content: "place";
			font-family: Icons;
			font-size: 24px;
			line-height: 0px;
			margin-right: -24px;
		}

		task-create-ticket img {
			max-width: 10vw;
			max-height: 10vh;
		}

		@media only screen and (max-width: 600px) {
			task-create-ticket .field-grid {
				grid-template-columns: 1fr;
			}
		}
	`]
})
export class CreateTicketDialog extends ModalBase implements OnInit {
	preventSoftClose = true;
	allowSubmit = true;

	ticket= {
		title: "",
		whier_id: null,
		location_id: null,
		priority_id: Task.PRIORITY.MEDIUM,
		ticket_details: <Task.TicketDetail[]>[],
		complaint: false,
	};

	whier: Task.WorkHierarchy = null;

	location_picker_disabled = true;
	picked_location: ShortLocation = null;

	show_warn_msg = false;

	questionnaires: Task.Questionnaire[] = [];
	answers: Answer_Type[] = [];
	PRIORITY = Task.Priority;
	PRIORITY_ICONS = Task.PriorityIcons;

	ngOnInit() {
		language.load_translations(tickets_translations);
		language.load_translations(orders_translations);
		this.update_location();
	}

	// RETAIL MAN, MAIN MAN, ADMIN, SYSADMIN
	async update_location() {
		this.location_picker_disabled = User.currentUser.locations.length <= 1 &&
		!await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.COMPANY_WORKER);

		if (this.location_picker_disabled) {
			if (User.currentUser.locations.length == 1) {
				let loc_id = this.ticket.location_id || User.currentUser.locations[0].location_id;
				let loc = await TaskRoutes.locations.api_location_get_single(loc_id);
				this.picked_location = loc.data;
			} else {
				this.show_warn_msg = true;
			}
		}
	}

	async whier_changed() {
		if (this.whier) {
			let res = await WorkHierarchy.Questionnaire.get_all_by_whier_recursive(this.whier.whier_id);
			this.questionnaires = res.data.sort((q1, q2) => {
				let i1 = q1.component_type_id === Task.COMPONENT_TYPE.IMAGE;
				let i2 = q2.component_type_id === Task.COMPONENT_TYPE.IMAGE;

				if (i1 && i2) { return 0; }
				if (i1 && !i2) { return 1; }
				if (!i1 && i2) { return -1; }
				return 0;
			});
		} else {
			this.questionnaires = [];
		}
	}

	set_answer(index: number, answer: Answer_Type) {
		this.answers[index] = answer;
	}

	toggle_priority() {
		if (this.ticket.priority_id == Task.PRIORITY.HIGH) {
			this.ticket.priority_id = Task.PRIORITY.MEDIUM;
		} else {
			this.ticket.priority_id = Task.PRIORITY.HIGH;
		}
	}

	cancel() {
		this["host"].close();
	}

	create_detail(question: Task.Questionnaire, answer: string) {
		if (answer === "" || answer === null || answer === undefined) {
			return null;
		}

		let detail: Partial<Task.TicketDetail> = {
			component_type_id: question.component_type_id,
			question: question.question,
			answer: answer,
			inventory_no: ""
		};

		return detail as Task.TicketDetail;
	}

	create_inventory_detail(question: Task.Questionnaire, answer: Task.KAM_Inventory) {
		let empty = true;

		for (let key in answer) {
			let val = answer[key]
			if (val !== undefined && val !== null && val !== "") {
				empty = false;
				break;
			}
		}

		if (empty) {
			return null;
		}

		let detail: Partial<Task.TicketDetail> = {
			component_type_id: question.component_type_id,
			question: question.question,
			answer: "Inventory",
			inventory_id: answer.inventory_id,
			inventory_no: answer.inventory_no,
			inventory_serial_no: answer.inventory_serial_no,
			inventory_name: answer.inventory_name,
			inventory_data: answer
		};

		return detail as Task.TicketDetail;
	}

	create_document_detail(question: Task.Questionnaire, answer: files.IImage | files.IFile) {
		let detail: Partial<Task.TicketDetail> = {
			component_type_id: question.component_type_id,
			question: question.question,
		}

		if (files.is_iimage(answer)) {
			detail.answer = answer.src;

		} else {
			detail.answer = answer.file.name;

		}

		return detail as Task.TicketDetail;
	}


	async upload_image(img: files.IImage) {
		if (!img.is_uploaded) {
			let res = await TaskRoutes.upload.api_upload_file(img.file);
			img.src = TaskRoutes.upload.uploaded_file_get(res.data);
			img.is_uploaded = true;
		}
		return img;
	}

	async upload_file(
		ticket_id: number,
		file: files.IFile,
		q: Task.Questionnaire
	) {
		if (!file.is_uploaded) {
			try {
				await TaskRoutes.tickets.api_upload_document(
					ticket_id, q.questionnaire_id, file.file
				);
			} catch(err) {
				console.log(err)
			}

		}
	}

	async create_ticket() {
		this.ticket.whier_id = this.whier.whier_id;
		this.ticket.location_id = this.picked_location.location_id;
		this.ticket.ticket_details = [];


		if (this.allowSubmit) {
			this.allowSubmit = false;

			let i = 0;
			for (let q of this.questionnaires) {
				let answer = this.answers[i] ?? "";

				switch (typeof answer) {
					case "boolean":
						this.ticket.ticket_details.push(
							this.create_detail(q, answer ? "true" : "false"));
					break;

					case "string":
						this.ticket.ticket_details.push(
							this.create_detail(q, answer));
					break;

					case "object":
						if (files.is_iimage_array(answer)) {
							for (let img of answer) {
								await this.upload_image(img);
								this.ticket.ticket_details.push(this.create_document_detail(q, img));
							}
						} else if (files.is_ifile_array(answer)) {

						} else {
							for (let inv of answer) {
								this.ticket.ticket_details.push(
									this.create_inventory_detail(q, inv));
							}
						}
					break;
				}

				i++;
			}



			this.ticket.ticket_details = this.ticket.ticket_details.filter(td => td !== null);
			let file_upload_promises: Promise<void>[] = [];
			let ticket = await Ticket.add(this.ticket, false);

		console.log("Ticket: ", ticket)
			let j = 0;
			for (let question of this.questionnaires) {
				let answer = this.answers[j] ?? "";

				if (typeof answer == 'object') {

					for (let file of answer) {
						if (files.is_ifile(file)) file_upload_promises.push(this.upload_file(ticket.data.ticket_id, file, question))
					}

				}
				j++;
			}
			this.submit();
			this.allowSubmit = true;
		}
	}
}
