<div class="flex-column fullheight-noscroll">
	<h2 class="modal-title" *ngIf="is_edit_form">
		{{ 'ORDER.SCHEDULED.EDIT' | trans }}
	</h2>

	<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>


	<div style="overflow: auto">
		<div class="field-grid" >
			<label class="input outlined grid-el-one" title="{{ 'ORDER.SCHEDULED_TITLE' | trans }}">
				<i icon="ballot"></i>
				<input [(ngModel)]="order.title" placeholder="{{ 'ORDER.SCHEDULED_TITLE' | trans }}"
				conditions="nonempty" />
			</label>

			<label class="textarea grid-el-two-thirds" title="{{ 'DESCRIPTION' | trans }}" *ngIf="descriptions.length">
				<textarea rows="2"
					placeholder="{{ 'DESCRIPTION' | trans }}"
					[(ngModel)]="descriptions[0].description"
					(ngModelChange)="update_details()">
				</textarea>
			</label>

			<task-search-locations
				[classes-loc-group]="'outlined special-box grid-el-one'"
				[classes-loc]="'outlined special-box grid-el-two-thirds'"
				[multi]="true"
				[(locations)]="order.locations"
				(locationsChange)="update_details()"
			></task-search-locations>

			<task-whier-picker required
				[(ngModel)]="order.whier"
				(ngModelChange)="select_whier_id($event)"
				[requiredLevel]="3"
				[maxLevel]="3">
			</task-whier-picker>


			<div class="special-box flex-row">
				<!-- <h4 class="flex-row" style="margin: 0.5em 0">{{ "ORDER.SHEDULED.YEARLY_PLAN" | trans }}</h4> -->
				<label class="input outlined" title="{{ 'ORDER.SCHEDULED.YEALRY_PLAN_SELECT' | trans }}" style="width: 100%; box-sizing: border-box;">
					<select required [(ngModel)]="order.annual_frequency" (ngModelChange)="check_show_info()">
						<option *ngFor="let plan of yearly_plans | keyvalue" [ngValue]="+plan.key">
							{{ ("ORDER.SCHEDULED.YEARLY_PLAN." + plan.value) | trans }}
						</option>
					</select>
				</label>

				<button (click)="show_info_popup()" [title]="'DETAILS' | trans" *ngIf="show_info" class="round filled" color="primary"><i icon="info"></i></button>
			</div>

			<knw-date-picker-input
				[(ngModel)]="order.planned_start_date"
				(ngModelChange)="check_show_info()"
				[required]="true"
				[label]="'ORDER.SCHEDULED.PLANNED_DATE_START' | trans"
				[classes]="'flex-dynamic outlined'">
			</knw-date-picker-input>

			<knw-date-picker-input
				*ngIf="show_end_date()"
				[(ngModel)]="order.planned_end_date"
				(ngModelChange)="check_show_info()"
				[required]="true"
				[label]="'ORDER.SCHEDULED.PLANNED_DATE_END' | trans"
				[classes]="'flex-dynamic outlined'"
				[min-date]="order.planned_start_date"
				[max-date]="max_date_allowed"
				>
			</knw-date-picker-input>

			<knw-date-picker-input
				[(ngModel)]="order.cut_date"
				(ngModelChange)="check_show_info()"
				[required]="true"
				[label]="'ORDER.SCHEDULED.CUT_DATE' | trans"
				[classes]="'flex-dynamic outlined'"
				[min-date]="order.planned_end_date"
				[max-date]="max_date_allowed">
			</knw-date-picker-input>

			<!-- </div> -->

			<div class="special-box" *ngIf="!is_supplier">
				<!-- <h4 style="margin: 0.5em 0; ">{{ 'SUPPLIER' | trans }}</h4> -->
				<knw-auto-search
					[required]="true"
					[function]="search_suppliers"
					[to-label]="format_supplier_name"
					label="{{ 'SUPPLIER' | trans }}"
					[(ngModel)]="picked_supplier"
					(ngModelChange)="select_supp_id($event)"
					classes="outlined">
				</knw-auto-search>
			</div>

			<div class="special-box" *ngIf="is_internal_supplier">
				<knw-auto-search
					[required]="true"
					[function]="search_workers"
					[to-label]="format_worker_name"
					label="{{'USER.TYPE.COMPANY_WORKER' | trans }}"
					[(ngModel)]="picked_worker"
					(ngModelChange)="select_picked_worker($event)">
				</knw-auto-search>
			</div>

			<div class="special-box whole-row" *ngIf="order.locations.length==1">
				<task-inventory-picker
					[(ngModel)]="inventory"
					[location_cin]="order.locations[0].cin"
					(ngModelChange)="update_details()"
				></task-inventory-picker>
			</div>



			<!-- <label class="input outlined" title="{{ 'ORDER.COST_ESTIMATE' | trans }}">
				<input [(ngModel)]="order.cost_estimate"
						placeholder="{{ 'ORDER.COST_ESTIMATE' | trans }}"
						type="number" /><i style="padding-left: 0.2em; vertical-align: middle;">€</i>
			</label>

			<label class="input outlined" title="{{ 'ORDER.INVESTMENT_TYPE' | trans }}">
				<select [(ngModel)]="order.investment_type" required>
					<option *ngFor="let type of investment_types | keyvalue" [ngValue]="+type.key">
							{{ ("ORDER.INVESTMENT_TYPE." + type.value) | trans }}
					</option>
				</select>
			</label> -->
		</div>
	</div>


</div>
