import { network, modal, noOp } from 'curvy';
import { get_global } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';

const Headers = network.Headers;
type Paginator = network.Headers.Paginator;

export namespace Ticket {
	export async function open_ticket(ticket_id: number) {
		modal.open(get_global("TicketDialog"), noOp, await get(ticket_id));
	}

	export async function getAll(paginator?: Paginator, expand=true) {
		return await TaskRoutes.tickets.api_ticket_get_all(paginator, expand);
	}

	export async function search(
		pagination: Paginator,
		filter: TaskRoutes.Search_Params,
		expand=true
	) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;
		let has_sort = !!(filter?.sort);

		if ((search_len === 0 || attr_len === 0) && !has_sort) {
			return await getAll(pagination, expand);
		}

		return await TaskRoutes.tickets.api_ticket_search(filter, pagination, expand);
	}

	export async function get(id: number) {
		return (await TaskRoutes.tickets.api_ticket_get_single(id)).data;
	}

	export async function get_orders(id: number) {
		return (await TaskRoutes.tickets.api_ticket_orders_get_all(id, {page_no: 1, page_size: 1000}, true)).data; 
	}

	export const add = TaskRoutes.tickets.api_ticket_add;

	export async function modify(ticket: Task.Ticket, expand=false) {
		return await TaskRoutes.tickets.api_ticket_modify(ticket.ticket_id, ticket, expand);
	}

	export async function approve(ticket_id: number) {
		return await TaskRoutes.tickets.api_ticket_approve(ticket_id, false);
	}

	export async function reject(ticket_id: number, comment: string) {
		return await TaskRoutes.tickets.api_ticket_reject(ticket_id, {comment}, false);
	}

	export async function remove(id: number) {
		return await TaskRoutes.tickets.api_ticket_delete(id);
	}

	export async function get_comments(id: number) {
		return await TaskRoutes.comments.api_comments_by_entity_value_get_all(Task.ENTITY.TICKET, id);
	}

	// export const STATUS_ICONS = {
	// 	[Task.STATUS.CREATED]: Structs.icon('receipt', '#49f'),
	// 	[Task.STATUS.INACTIVE]: Structs.icon('watch_later', '#49f'),
	// 	[Task.STATUS.APPROVED]: Structs.icon('receipt', '#f70'),
	// 	[Task.STATUS.IN_EXECUTION]: Structs.icon('receipt', '#5a5'),
	// 	[Task.STATUS.EXECUTED]: Structs.icon('check_circle', '#5a5'),
	// 	[Task.STATUS.INVOICED]: Structs.icon('check_circle', '#5a5'),
	// 	[Task.STATUS.DELETED]: Structs.icon('cancel', '#f49'),
	// 	[Task.STATUS.REJECTED]: Structs.icon('cancel', '#f49'),
	// };

	// export const PRIORITY_ICONS = {
	// 	[Task.PRIORITY.HIGH]: Structs.icon('priority_high', "#f49"),
	// 	[Task.PRIORITY.MEDIUM]: Structs.icon('remove', "#49f"),
	// 	// [Task.PRIORITY.MEDIUM]: Structs.icon('fiber_manual_record', "#49f"),
	// 	// [Task.PRIORITY.LOW]: Structs.icon('arrow_downward', "#5a5"),
	// 	// narančasto #fa9
	// };
}
