import { Component, EventEmitter, Input, ViewEncapsulation, OnInit, Output, ElementRef } from '@angular/core';
import { Frame, language, TableConfig, network, GenericFilter, noOp, template, modal, GenericFilterComponent, DataTableComponent, date, utils, ConfirmDialogComponent, color } from 'curvy';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { ScheduledOrderTemplateType, ScheduledOrderType } from '../orders.types';




@Component({
	selector: 'new-scheduled-order-plan',
	template: `
		<details open>
			<summary>
				<h3 style="margin-bottom: 0.5em;">{{ count_plan + '. ' + (plan.title || '') }}</h3>

			</summary>
			<div class="flex-column">
				<task-scheduled-order-form
					[order]="plan">
				</task-scheduled-order-form>

				<div class="flex-row align-center" style="gap: 0.5em; align-self: flex-end; margin-top: 1em;">
					<button (click)="copy()" color="primary" class="filled round" [title]="'ORDER.SCHEDULED_DUPLICATE' | trans"><i icon="content_copy"></i></button>
					<button (click)="delete_order(plan)" color="warn" class="filled round" [title]="'DELETE' | trans">
						<i icon="delete"></i>
					</button>
				</div>
			</div>
		</details>

	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['../order.component.scss']
})

export class NewScheduledOrderComponent implements OnInit {
	@Input()
	plan: ScheduledOrderTemplateType;

	@Output('do-copy')
	copy_out = new EventEmitter();

	@Input()
	count_plan:number;

	@Input()
	all_plans: ScheduledOrderTemplateType[];

	copy() {
		this.copy_out.emit()
	}


	delete_order(plan:ScheduledOrderTemplateType) {

		modal.open(ConfirmDialogComponent, (res)=>{
			if (res) {
				let index = this.all_plans.indexOf(plan)
				this.all_plans.splice(index, 1);
			}
		}, {
			title: language.translate('ORDER.SCHEDULED_DELETE_TITLE'),
			icon: 'receipt',
			iconColor: color.variable(color.Variable.warn),
			message: language.translate('ORDER.SCHEDULED_DELETE_MSG'),
		})
	}

	constructor(public el:ElementRef<HTMLElement>) {}

	ngOnInit() {
		this.el.nativeElement.scrollIntoView({behavior: 'smooth'});
	}
}
