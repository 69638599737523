import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "PRICELIST.PRICELIST": "Pricelist",
        "PRICELIST.NEW": "New pricelist item",
        "PRICELIST.TITLE": "Name",

        "PRICELIST.TYPE": "Type",
        "PRICELIST.TYPE.WORKING_HOUR": "Working hour",
        "PRICELIST.TYPE.MATERIAL": "Material",
        "PRICELIST.TYPE.TRANSPORTATION": "Transportation",
        "PRICELIST.TYPE.FIELD_VISIT": "Field visit",
        "PRICELIST.TYPE.MECHANICAL_WORKS": "Mechanical works",

        "PRICELIST.UOM.CENTIMETER": "Centimeter",
        "PRICELIST.UOM.METER": "Meter",
        "PRICELIST.UOM.KILOMETER": "Kilometer",
        "PRICELIST.UOM.SQUARE_METER": "Square meter",
        "PRICELIST.UOM.CUBIC_METER": "Cubic meter",
        "PRICELIST.UOM.KILOGRAM": "Kilogram",
        "PRICELIST.UOM.LITER": "Liter",
        "PRICELIST.UOM.SERVICE": "Service",
        "PRICELIST.UOM.HOUR": "Hour",
        "PRICELIST.UOM.PIECE": "Piece",
        "PRICELIST.UOM.OTHER": "Other",

        "PRICELIST.CIN": "CIN",
        "PRICELIST.PRICE": "Price",
        "PRICELIST.CURRENCY": "Currency",
        "PRICELIST.UOM": "Unit of measure",
        "PRICELIST.LUMP_SUM": "Lump sum",
        "PRICELIST.WARRANTY_PERIOD": "Warranty period",
        "PRICELIST.WARRANTY_PERIOD.MONTHS": "months",

        "PRICELIST.QUALIFICATION": "Worker qualification",
        "PRICELIST.QUALIFICATION.NKV": "Unqualified",
        "PRICELIST.QUALIFICATION.KV": "Qualified",
        "PRICELIST.QUALIFICATION.VKV": "Highly qualified",

        "PRICELIST.IMPORT": "Import pricelist",
        "PRICELIST.DOWNLOAD": "Download pricelist",
        "PRICELIST.DIALOG_TITLE": "Import pricelist dialog",
        "PRICELIST.ERRORS.DEFAULT": "Error in xlsx table processing.\n Error on table rows: ",
        "PRICELIST.ERRORS": "Error: imported table hasn't been accepted.",
        "PRICELIST.SUCCESS": "Pricelist has been uploaded",
        "PRICELIST.IMPORT_TEXT":"Download pricelist xslx table, update with new data then import new priclelist.",
        "PRICELIST.SHOW_HISTORY": "Show hostory of edited pricelists"


    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "PRICELIST.PRICELIST": "Cjenik",
        "PRICELIST.NEW": "Novi element cjenika",

        "PRICELIST.TITLE": "Ime",

        "PRICELIST.TYPE": "Tip",
        "PRICELIST.TYPE.WORKING_HOUR": "Radni sat",
        "PRICELIST.TYPE.MATERIAL": "Materijal",
        "PRICELIST.TYPE.TRANSPORTATION": "Prijevoz",
        "PRICELIST.TYPE.FIELD_VISIT": "Putni troškovi",
        "PRICELIST.TYPE.MECHANICAL_WORKS": "Radovi",

        "PRICELIST.UOM.CENTIMETER": "Centimetar",
        "PRICELIST.UOM.METER": "Metar",
        "PRICELIST.UOM.KILOMETER": "Kilometar",
        "PRICELIST.UOM.SQUARE_METER": "Metar kvadratni",
        "PRICELIST.UOM.CUBIC_METER": "Metar kubni",
        "PRICELIST.UOM.KILOGRAM": "Kilogram",
        "PRICELIST.UOM.LITER": "Litra",
        "PRICELIST.UOM.SERVICE": "Usluga",
        "PRICELIST.UOM.HOUR": "Sat",
        "PRICELIST.UOM.PIECE": "Komad",
        "PRICELIST.UOM.OTHER": "Ostalo",

        "PRICELIST.CIN": "Šifra",
        "PRICELIST.PRICE": "Cijena",
        "PRICELIST.CURRENCY": "Valuta",
        "PRICELIST.UOM": "Mjera",
        "PRICELIST.LUMP_SUM": "Paušalno",
        "PRICELIST.WARRANTY_PERIOD": "Garantni period",
        "PRICELIST.WARRANTY_PERIOD.MONTHS": "mjeseci",

        "PRICELIST.QUALIFICATION": "Kvalifikacija radnika",
        "PRICELIST.QUALIFICATION.NKV": "Nekvalificiran",
        "PRICELIST.QUALIFICATION.KV": "Kvalificiran",
        "PRICELIST.QUALIFICATION.VKV": "Visokokvalificiran",

        "PRICELIST.IMPORT": "Uvezi cjenik",
        "PRICELIST.DOWNLOAD": "Preuzmi cjenik",
        "PRICELIST.DIALOG_TITLE": "Uvoz cjenika",
        "PRICELIST.ERRORS.DEFAULT": "Došlo je do greške kod prihvata uvezene tablice.\nGreške se nalaze u tablici na linijama: ",
        "PRICELIST.ERRORS": "Greška: uvezena tablica nije prihvaćena.",

        "PRICELIST.SUCCESS": "Tablica je uspješno uvezena",
        "PRICELIST.IMPORT_TEXT":"Preuzmite cjenik, izmjenite stavke u excel tablici te uvezite prilagođenu verziju.",
        "PRICELIST.SHOW_HISTORY": "Povijest izmjenjenih cjenika"
    }
};

export const pricelist_translations = [english, croatian];
