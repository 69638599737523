import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalBase, AutoSearchComponent, language, async } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { users_translations } from './users.trans';

type UserType = Pick<Task.User, 'first_name' | 'last_name' | 'email' | 'user_type_id' | 'sector_id'>;

@Component({
    selector: 'task-spoof-user-dialog',
    template: `
        <div>
            <h2 class="modal-title" style="margin-bottom: 0.7em;">Quick spoof user</h2>
            <div form-collector #spoofForm="collector" class="flex-column">
               
                <div class="flex-row">
                    <label class="input filled input-labelled" style="max-width: 300px; flex: 1; margin-left: 0; margin-right: 0;" [title]="'USER.TYPE' | trans ">
                        <select
                            [(ngModel)]="picked_user_type"
                            (ngModelChange)="reset_search_picker()"
                            >
                            <option value="" *ngFor="let type of userTypes | keyvalue" [ngValue]="type.key">{{ 'USER.TYPE.' + type.value | trans }}</option>
                        </select>
                    </label>
                    <button (click)="clear()" class="round"><i icon="clear"></i></button>
                </div>
                <knw-auto-search #searchPicker
                    [function]="search_users"
                    [to-label]="format_user"
                    [(ngModel)]="picked_user" 
                    classes="input filled input-labelled"
                    [popover-width]="'auto'"
                    required>
                </knw-auto-search>

                <div class="flex-row" style="margin-top: 1.5em; width: 100%; justify-content: space-between;">
                    <button color="primary" (click)="cancel()">
                        <i icon="clear"></i>
                        <span>{{ 'CANCEL' | trans }}</span>
                    </button>

                    <button class="filled" color="secondary" (click)="submitUser()" [disabled]="spoofForm.containsErrors()">
                        <i icon="check"></i>
                        <span>Spoof</span>
                    </button>
                
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styles:[`
        task-spoof-user-dialog knw-auto-search label {
            padding-top: 1.25em!important;
        }
    `]
})
export class SpoofUserDialog extends ModalBase<UserType | Promise<UserType>, UserType> implements OnInit {
    @ViewChild("searchPicker")
    searchPicker: AutoSearchComponent;

    preventSoftClose = false;
    picked_user: Task.User | null = null;
    picked_user_type: string | null = null;
    userTypes: Record<Task.USER_TYPE, string>;

    ngOnInit() {
        this.userTypes = { ...Task.User_Type };
        delete this.userTypes[Task.USER_TYPE.SUPER_USER];
        
        language.load_translations(users_translations);
    }

    clear() {
        this.reset_search_picker();
        this.picked_user_type = null;
    }

    reset_search_picker() {
        this.searchPicker.writeValue(null);
        this.picked_user = null;
    }

    async search_user_type() {
        let userType = { ...Task.User_Type }

        return userType;
    }

    format_user_type(ut: Task.USER_TYPE) {
        if (!ut) return;

        return `${ut}`
    }

    search_users = async (search: string | null) => {
        search = search || "";
        let res;
        if (this.picked_user_type) {
            res = await TaskRoutes.users.api_user_search({
                attributes: ["#!:status_id", '#=:user_type_id', "first_name+ +last_name+ +email"],
                search: ["3", this.picked_user_type || "", search]
            }, {
                page_no: 1,
                page_size: 50
            })

        } else {
            res = await TaskRoutes.users.api_user_search({
                attributes: ["#!:status_id", "first_name+ +last_name+ +email"],
                search: ["3", search]
            }, {
                page_no: 1,
                page_size: 50
            })
        }

        return res.data;
    }

    format_user = (user: Task.User) =>{
        if (!user) return;
        
        let role = "";
        if (!this.picked_user_type) role = `(${language.translate("USER.TYPE." + Task.User_Type[user.user_type_id])})`;
        else role = "";

        return `${user.first_name} ${user.last_name} ${role}`;
    }

    cancel() {
        this.submit(null);
    }

    submitUser() {
        this.submit(this.picked_user)
    }
}
