import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "SETTINGS.TITLE": "SETTINGS",
        "SETTINGS.LANG": "Language",
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "SETTINGS.TITLE": "Postavke",
        "SETTINGS.LANG": "Jezik",
    }
};

export const user_settings_translations = [english, croatian];
