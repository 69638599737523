import { Component } from '@angular/core';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import {
	Frame,
	popover,
	PopoverMenuComponent,
	noOp,
	language,
	utils, modal
} from 'curvy';

function fade_out(el: HTMLElement) {
	el.style.setProperty("transition", "opacity 0.25s");
	el.style.setProperty("opacity", "0");
	setTimeout(() => {
		el.remove();
	}, 250);
}

@Component({
	selector: 'task-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor() {
		Frame.set({
			app_name: "Task",
			base_color: "background-light",
			highlight_color: "secondary",
			user_menu_action: () => {
				this.openUserMenu()
			}
		});

		window["_frame"] = Frame;

		fade_out(document.body.querySelector("#spinner-overlay"));
	}

	openUserMenu() {
		popover.open(
			PopoverMenuComponent,
			noOp,
			User.getUserMenu(),
			null,
			"mouse", 
			"auto"
		);
	}
}
