import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { ModalBase, progress } from 'curvy';
import { language } from 'curvy';
import { arrivals_translations } from '../../arrivals.trans';



@Component({
    selector: 'task-financial-item-history',
    encapsulation: ViewEncapsulation.None,
    template: `
	<div class="flex-row justify-space-between">
		<knw-spinner
			color="primary"
			[size]="32"
			*ngIf="'history-log' | inProgress"
			style="margin-left: auto; margin-right: auto;"
		/>
		<h2 *ngIf="!has_changes && !('history-log' | inProgress)" style="margin-right: auto; margin-top: 1em; padding-right: 1em;" class="modal-title">{{ "ARRIVAL.DETAILS.HISTORY_NO_CHANGES" | trans }}</h2>

		<h2 style="margin-right: auto; margin-top: 1em; padding-right: 1em;" class="modal-title" *ngIf="has_changes && !('history-log' | inProgress)">{{ "ARRIVAL.DETAILS.HISTORY_TITLE" | trans : item.element_name }}</h2>

		<button (click)="submit(null)" class="round filled" color="primary" style="margin-left: auto; margin-bottom: 1em; "><i icon="close"></i></button>
	</div>

	<div class="financial-item-container">
		<div *ngFor="let log of changed_logs">
			<div class="flex-column changed" >
				<div class="flex-row" style="margin-bottom: .8em;">
					<strong>&nbsp;{{ log.log_created_date | datefmt }}</strong>
					<span style="margin-left: auto; padding-left: 1em; align-self: center;">
						<span>{{ log.modified_by_first_name}} {{ log.modified_by_last_name }}</span>
						<i icon="engineering" style="margin-left: 0.5em; vertical-align: bottom;"></i>
					</span>
				</div>
				<div class="flex-row changed-item">
					<i class="icon flex-static" icon="history" style="margin: 0.5em 1.5em .5em 0em;"></i>
					<div class="flex-column flex-dynamic">
						<div class="flex-row">
							<div class="flex-column flex-dynamic changed-item" *ngIf="log.element_price != log.element_old_price && log.element_old_price != null">
								<span class="flex-row align-center">
									<span class="flex-dynamic">{{ log.element_old_price.toLocaleString('hr', { style: 'currency', currency: log.element_currency }) }}</span>
									<i icon="arrow_forward"></i>
									<span class="flex-dynamic">{{ log.element_price.toLocaleString('hr', { style: 'currency', currency: log.element_currency }) }}</span>
								</span>
							</div>
						</div>

						<div class="flex-row">
							<div class="flex-column flex-dynamic changed-item" *ngIf="log.element_quantity != log.element_old_quantity && log.element_old_quantity != null">
								<span class="flex-row align-center">
									<span class="flex-dynamic">{{ log.element_old_quantity }} {{ log.element_uom }}</span>
									<i icon="arrow_forward"></i>
									<span class="flex-dynamic">{{ log.element_quantity }} {{ log.element_uom }}</span>
								</span>
							</div>
						</div>
						<div class="flex-row">
							<div class="flex-column flex-dynamic changed-item" *ngIf="log.lump_sum != log.old_lump_sum && log.old_lump_sum != null">
								<span class="flex-row align-center">
									<span class="flex-dynamic flex-row justify-center align-center"><i [attr.icon]="log.old_lump_sum ? 'check_circle' : 'cancel'" style="margin-right: 0.5em;"></i> {{ "ARRIVAL.DETAILS.LUMP" | trans }}</span>
									<i icon="arrow_forward"></i>
									<span class="flex-dynamic flex-row justify-center align-center"><i [attr.icon]="log.lump_sum ? 'check_circle' : 'cancel'" style="margin-right: 0.5em;"></i> {{ "ARRIVAL.DETAILS.LUMP" | trans }}</span>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="flex-row justify-flex-start changed-item" *ngIf="log.comment">
					<i class="icon flex-static" icon="comment" style="margin: 0.5em 1.5em .5em 0em;"></i>
					<div class="flex-column justify-flex-center">
						<i>{{ log.comment }}</i>
					</div>
				</div>
			</div>
		</div>

	</div>

	`,
    styleUrls: ['./financial-item-history.component.scss']

})
export class FinancialItemHistoryComponent extends ModalBase<Task.ArrivalDetail> implements OnInit {
	item: Task.ArrivalDetail;
	logs: TaskRoutes.Log<Task.ArrivalDetail, "arrival_detail_log_id">[] = [];
	changed_logs: {
		element_price?: number,
		element_old_price?: number,
		element_quantity?: number,
		element_old_quantity?: number,
		lump_sum?: boolean,
		old_lump_sum?: boolean,
		element_currency: string,
		log_created_date: Date,
		element_uom: string,
		comment: string
	}[] = [];

	users: Task.User[] = [];
	has_changes = false;


	single_sum() {
		let total_price = this.item.element_price * this.item.element_quantity;
		return total_price.toLocaleString('hr', { style: 'currency', currency: this.item.element_currency || 'HRK' });
	}

	detectChanges(items: TaskRoutes.Log<Task.ArrivalDetail, "arrival_detail_log_id">[]) {
		this.changed_logs = items;
		if (this.changed_logs.length > 0) {
			this.has_changes = true;
		}
	}

	ngOnInit() {
		language.load_translations(arrivals_translations);

        this.item = this.injectedData;

		progress.listen(
			"history-log",
			TaskRoutes.arrivals.api_arrival_detail_logs_get(this.item.arrival_detail_id).then(res=>{
				this.logs = res.data.sort((a,b)=>a.arrival_detail_log_id-b.arrival_detail_log_id);

				this.detectChanges(this.logs);
			})
		)

	}


}
