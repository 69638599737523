<h2 class="modal-title">{{ 'SUPPLIER.NEW' | trans : is_edit }}</h2>

<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>

<div class="field-grid" form-collector #supplierForm="collector">
	<label class="input outlined" [title]="'SUPPLIER.SUPPLIER' | trans">
		<input [(ngModel)]="supplier.name" conditions="nonempty" />
	</label>

	<label class="input outlined" [title]="'SUPPLIER.CIN' | trans">
		<input [(ngModel)]="supplier.cin" conditions="nonempty" />
	</label>

	<label class="input outlined" [title]="'SUPPLIER.OIB' | trans">
		<input [(ngModel)]="supplier.identification_no" conditions="nonempty" />
	</label>

	<label class="input outlined" [title]="'EMAIL' | trans">
		<input [(ngModel)]="supplier.email" type="email" conditions="nonempty" [disabled]="is_edit"/>
	</label>

	<label class="input outlined" [title]="'SUPPLIER.PHONE' | trans">
		<input [(ngModel)]="supplier.contact_phone" />
	</label>

	<label class="input outlined" [title]="'SUPPLIER.CONTACT_NAME' | trans">
		<input [(ngModel)]="supplier.contact_name" />
	</label>

	<knw-auto-search
		#sectorPicker
		[function]="search_sectors"
		[to-label]="format_sector_name"
		label="{{'SECTOR' | trans}}"
		[multi]="true"
		[(ngModel)]="picked_sectors"
		[no-search]="true"
		popover-width="auto"
		[classes]="'outlined supplier-search'"
		></knw-auto-search>
</div>

<div class="flex-row modal-footer">

	<button color="primary" (click)="cancel()">
		<i icon="clear"></i>
		<span>{{ 'CANCEL' | trans }}</span>
	</button>

	<!-- <div class="flex-dynamic"></div> -->

	<button class="filled" color="secondary" (click)="done()" [disabled]="supplierForm.containsErrors()">
		<i icon="check"></i>
		<span>{{ 'SAVE' | trans }}</span>
	</button>

</div>
