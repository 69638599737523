import { Pipe } from "@angular/core";

// type NAME_PARTS = "first_name" | "last_name";

// type HasName<NAME_KEY extends string> = {
// 	[
// 		NAME_PART in NAME_PARTS 
// 		as `${NAME_KEY}_${NAME_PART}`
// 	]?: string;
// };

@Pipe({
	name: "fullname",
	pure: true
})
export class FullnamePipe {
	transform(
		obj: Record<string, any>, 
		key: string, 
		...checks: boolean[]
	) {
		for (let check of checks) {
			if (!check) return undefined;
		}

		let first_name_key = `${key}_first_name`;
		let last_name_key = `${key}_last_name`;

		let first_name = obj[first_name_key];
		let last_name = obj[last_name_key];

		if (first_name == null || last_name == null) {
			return undefined;
		}

		return `${first_name} ${last_name}`;
	}
}