<ng-container *ngIf="item">
    <div [ngClass]="{ approved: approved }" class="financial-item-container">
        <div class="flex-row align-flex-start justify-flex-end wrappable" style="gap: .3em; transform: translateY(-1.5em);">
            <strong *ngIf="!item.element_id"
                class="label-pill uppercase"
                style="font-size: 80%; background-color: rgb(var(--primary));"
            >
                {{'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT' | trans}}
            </strong>

            <div *ngIf="arrival.emergency"
                class="flex-column"
            >
                <strong class="label-pill uppercase" style=" font-size: 80%; background-color: rgb(var(--orange-dark));">{{'ARRIVAL.DETAILS.EMERGENCY_TITLE' | trans}}</strong>
            </div>

            <strong *ngIf="is_deleted"
                class="label-pill uppercase"
                style="font-size: 80%; background-color: rgb(var(--warn));"
            >
                Obrisano
            </strong>
        </div>

        <div class="flex-row align-center justify-space-between mobile-wrap-reverse">
            <h3 style="margin: 0 0 .8em;">{{ item.element_name }}</h3>
        </div>

        <div class="flex-column" style="font-size: 85%; margin-bottom: .5em">
            <div class="flex-row wrappable">
                <a [goto]="'/arrivals/' + item.arrival_id" style="cursor: pointer">
                    #{{item.arrival_id}}
                    <i>&nbsp;{{ item.created_date | datefmt }}</i>
                </a>

                <div class="flex-dynamic"></div>

                <span [ngStyle]="{'color': item.created_by_type_id === 9 ? 'red' : 'null'}">
                    <span>{{ item.created_by_first_name }} {{ item.created_by_last_name }}</span> &nbsp;
                    <i icon="engineering" style="vertical-align: bottom"></i>
                </span>
            </div>
        </div>

        <div class="flex-column" style="margin-bottom: 1.5em;">
            <div class="flex-row" style="gap: .5em; font-size: 85%; margin-bottom: 0.35em;">
                <strong>{{'ARRIVAL.DETAIL.GROUP' | trans }}:</strong>
                <span>{{item.work_description}}</span>
            </div>

             <div class="flex-row" style="gap: .5em; font-size: 85%; margin-bottom: 0.35em;">
                <strong>{{ "ARRIVAL.CREATED_BY" | trans}}:</strong>
                <span>{{ arrival.created_by_first_name}} {{ arrival.created_by_last_name}}</span>
            </div>
        </div>

        <div class="flex-column changed"
                *ngIf="price_changed || quantity_changed || lump_sum_changed">
            <div class="flex-row">
                <i icon="history" style="margin: 0.5em"></i>

                <div class="flex-column flex-dynamic">
                    <span class="flex-row align-center"
                            *ngIf="price_changed">
                        <span class="flex-dynamic">{{ old_price }}</span>
                        <i icon="arrow_forward"></i>
                        <span class="flex-dynamic">{{ new_price }}</span>
                    </span>
                    <span class="flex-row align-center"
                            *ngIf="quantity_changed">
                        <span class="flex-dynamic">{{ old_quantity }}</span>
                        <i icon="arrow_forward"></i>
                        <span class="flex-dynamic">{{ new_quantity }}</span>
                    </span>
                    <span class="flex-row align-center"
                            *ngIf="lump_sum_changed">
                        <span class="flex-dynamic flex-row justify-center align-center">
                            <i [attr.icon]="old_lump_sum ? 'check_circle' : 'cancel'"></i>
                            {{ 'ARRIVAL.DETAILS.LUMP' | trans }}
                        </span>
                        <i icon="arrow_forward"></i>
                        <span class="flex-dynamic flex-row justify-center align-center">
                            <i [attr.icon]="new_lump_sum ? 'check_circle' : 'cancel'"></i>
                            {{ 'ARRIVAL.DETAILS.LUMP' | trans }}
                        </span>
                    </span>
                </div>
            </div>

            <div class="flex-row" *ngIf="change_comment">
                <i icon="comment" style="margin: 0.5em"></i>
                <i>{{ change_comment }}</i>
            </div>
        </div>

        <div class="field-grid">
            <label class="input outlined" title="{{ 'ARRIVAL.DETAILS.QUANTITY' | trans }}">
                <input type="number"
                        required
                        constrain
                        min=0
                        max=999999999
                        [(ngModel)]="item.element_quantity"
                        (input)="update()"
                        (change)="update()"
                        [disabled]="approved || !can_edit_financial_details"/>
                <span>{{ item.element_uom || "" }}</span>
            </label>

            <label class="input outlined" title="{{ 'ARRIVAL.DETAILS.PRICE' | trans }}">
                <input type="number"
                        required
                        constrain
                        min=0
                        max=999999999
                        [(ngModel)]="item.element_price"
                        (input)="update()"
                        (change)="update()"
                        [disabled]="item.element_id || (approved || !can_edit_financial_details)"/>
                <span>{{ item.element_currency || "" }}</span>
            </label>
        </div>

        <div class="flex-row justify-space-between" style="margin-top: .8em;">
            <label class="checkbox-input">
                <input type="checkbox"
                        [(ngModel)]="item.lump_sum"
                        (ngModelChange)="update()"
                        [disabled]="approved || !can_edit_financial_details"/>
                {{ 'ARRIVAL.DETAILS.LUMP' | trans }}
            </label>

            <strong style="margin-left: auto;">TOTAL: {{ single_sum() }}</strong>
        </div>

        <div class="flex-column">

            <label class="input outlined placeholder-warn"
                    title="{{ 'ARRIVAL.DETAILS.REASON' | trans }}"
                    *ngIf="show_comment"
                    style="margin-left: 0; margin-right: 0">
                <input [(ngModel)]="item.comment"
                        required
                        placeholder="{{ 'ARRIVAL.DETAIL.REASON' | trans }}"
                        (input)="update()"
                        [disabled]="approved || !can_edit_financial_details"/>
            </label>
        </div>

        <div class="flex-column">
            <button class="filled round" [title]="'ARRIVAL.DETAILS.HISTORY'| trans" style="margin-left: auto; margin-top: 1em;" color="primary" (click)="openHistory()">
                <i icon="history"></i>
            </button>
        </div>
    </div>

</ng-container>
