import { network, Omit } from 'curvy';
import { TaskRoutes } from '@task-utils/routes';
import { Task, DeepWriteable } from '@task-utils/types';

const { Post, Get, Patch, Delete, Headers } = network;
type Paginator = network.Headers.Paginator;

export namespace Sectors {

	// GET
	export function getAll(paginator?: Paginator, expand=true) {
		return TaskRoutes.sectors.api_sector_get_all(paginator, expand);
	}

	// POST
	export function add(_sector: Task.Sector, expand=true) {
		return TaskRoutes.sectors.api_sector_add(_sector, expand);
	}

	// PATCH - Modify
	export async function modify(_sector: Task.Sector, expand=true) {
		const id = _sector.sector_id;
		delete _sector.company_id;
		delete _sector.sector_id;
		return await TaskRoutes.sectors.api_sector_modify(id, _sector, expand);
	}
}
