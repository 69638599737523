import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';


type SupplierPickerInput = {
	supplier_id: number;
	company_id: number;
	arrival_worker_id?: number;
};

type SupplierPickerOutput = {
	supplier: Task.Supplier;
	arrival_worker?: Task.User;
}

@Component({
	selector: 'task-supplier-picker-dialog',
	template: `
		<h2 class="modal-title">{{ "ORDER.REASSIGN.TITLE" | trans }}</h2>
		<div class="flex-column" form-collector #iForm="collector">
			<knw-auto-search
				[function]="search_supplier"
				[to-label]="format_supplier_name"
				[(ngModel)]="picked_supplier"
				[label]="'ORDER.REASSIGN.LABEL' | trans"
				[required]="true"
				(ngModelChange)="picked_supplier_handler($event)"
				classes="outlined flex-dynamic">
			</knw-auto-search>

			<knw-auto-search
				*ngIf="is_internal_supplier"
				[required]="true"
				[function]="search_worker"
				[to-label]="format_worker_name"
				label="{{'USER.TYPE.COMPANY_WORKER' | trans }}"
				[(ngModel)]="picked_worker"
				classes="outlined flex-dynamic">
			</knw-auto-search>
		</div>

		<div class="flex-row modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				{{ 'CANCEL' | trans }}
			</button>

			<div class="flex-dynamic"></div>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="iForm.containsErrors()">
				<i icon="check"></i>
				{{ 'SAVE' | trans }}
			</button>
		</div>
	`
})
export class SupplierPickerDialog extends ModalBase<SupplierPickerInput, SupplierPickerOutput> {
	preventSoftClose = true;
	picked_supplier: Task.Supplier = null;
	picked_worker: Task.User = null;
	is_internal_supplier = false;
	order: SupplierPickerInput = null;

	search_supplier = async (search: string) => {
		let res = await TaskRoutes.suppliers.api_supplier_search({
			search: [search], attributes: ["company.name"]
		});
		return res.data;
	}

	format_supplier_name (sup: Task.Supplier) {
		if (!sup) { return "" };
		return `${sup.name}`
	}

	search_worker = async(search: string) => {
		let res = await TaskRoutes.users.api_user_search({
			search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
			attributes: ['first_name+ +last_name', '#=:user_type_id']
		}, {
			page_no: 1,
			page_size: 10
		})

		return res.data;
	}

	format_worker_name(usr: Task.User) {
		if (!usr) return "";
		return `${usr.first_name} ${usr.last_name}`
	}

	picked_supplier_handler(ev: Task.Supplier) {
	
		if (ev.company_type_id === 1) {
			this.is_internal_supplier = true;
		} else {
			this.is_internal_supplier = false;
		}
	}

	async get_supplier(){
		if (this.is_internal_supplier) {
			let res = await TaskRoutes.suppliers.api_supplier_get_single(this.order.supplier_id)

			return res.data;
		}
	}

	async get_worker() {
		if (this.is_internal_supplier) {
			let res = await TaskRoutes.users.api_user_get_single(this.order.arrival_worker_id)

			return res.data;
		}
	}

	ngOnInit() {
		this.order = this.injectedData;
		this.init();
	}

	async init() {
		this.is_internal_supplier = this.order.supplier_id == this.order.company_id ? true : false;
		
		this.picked_supplier = await this.get_supplier();
		this.picked_worker = await this.get_worker();		
	}

	done() {
		
		this.submit({supplier: this.picked_supplier, arrival_worker: this.picked_worker});
	}

	cancel() {
		this.submit({supplier: null, arrival_worker: null});
	}
}
