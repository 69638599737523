import { Component, OnInit } from '@angular/core';
import { ModalBase, notification, color, language } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';


type QuestionType = {
   checklist_question_id: number|null;
   name: string;
   description: string;
   location_ids: number[];
   location_group_ids: number[];
   whier_id: number;
   frequency: Task.ANNUAL_FREQUENCY;
}

@Component({
   selector: 'task-create-edit-question-dialog',
   template: `
      <task-create-edit-question [question]="question" (result)="handle_result($event)"></task-create-edit-question>
   `
})

export class CreateEditQuestiontDialog extends ModalBase<Omit<Task.CheckListQuestion, 'company_id'> | Promise<Omit<Task.CheckListQuestion, 'company_id'>>, boolean> implements OnInit {
   preventSoftClose = true;
   question: Omit<Task.CheckListQuestion, 'company_id'|`created_${string}`>;

   ngOnInit(): void {
      if (this.injectedData instanceof Promise) {
         this.injectedData.then(res => {

            this.question = res || {
               name: "",
               description: "",
               locations: [],
               location_groups: [],
               whier: null,
               whier_id: null,
               frequency: null,
               checklist_question_id: null
            }
         })
      } else {


         this.question = this.injectedData || {
            name: "",
            description: "",
            locations: [],
            location_groups: [],
            whier: null,
            whier_id: null,
            frequency: null,
            checklist_question_id: null
         }
      }
   }

   handle_result(question: Omit<Task.CheckListQuestion, 'company_id'>) {
      if (question === null) {
         this.submit(false);
         return;
      }

      let transformed_data = {
         ...question,
         location_ids: question.locations.map(l=>l.location_id),
         location_group_ids: question.location_groups.map(l=>l.location_group_id)
      }

      console.log({transformed_data})

      let promise: Promise<any>;

      // Edit
      if (question["checklist_question_id"]) {
         promise = TaskRoutes.checklists.api_checklist_question_modify(question.checklist_question_id, transformed_data);

         // Add
      } else {
         promise = TaskRoutes.checklists.api_checklist_question_add(transformed_data);
      }

      promise.then(()=>{
         this.submit(true);

      }).catch((err)=>{
         console.log(err)

      });

   }
}
