import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { Sectors } from './sectors';

type SectorType = Omit<Task.Sector, 'company_id' | 'sector_id'>;

@Component({
    selector: 'task-create-edit-sector-dialog',
    template: `
        <task-create-edit-sector [sector]="sector" (result)="handle_result($event)">
        </task-create-edit-sector>
    `
})
export class CreateEditSectorDialog extends ModalBase<any, any> implements OnInit {
    preventSoftClose = true;
    sector: SectorType;

    is_edit: boolean = false;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => {
                this.sector = res || {
                    name: "",
                    whiers: [],
                }
            });
        } else {
            this.sector = this.injectedData || {
                name: "",
                whiers: [],
            };
        }
    }


    handle_result(sector: SectorType) {

        if (sector == null) {
            // console.log(this.sector);
            this.submit(false);
            return;
        }

        if (this.sector !== sector) {
            this.is_edit = true;
        }

        this.sector = sector;

        let promise: Promise<any>;

        if ( this.sector["sector_id"] ) {
            promise = Sectors.modify( sector as Task.Sector );
        }

        else {
            promise = Sectors.add(sector as Task.Sector);
        }

        promise.then(( res ) => {
            this.submit(true);
        });
    }
}
