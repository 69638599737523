import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { Modal } from '@task-utils/utils';

@Component({
   selector: 'task-question-info',
   template: `
      <div class="flex-column" style="width: 100%;" >
         @if (question.locations.length>0) {
            <div class="question-info-group">
               <strong class="popover-info-title">{{'NAVROUTE.LOCATIONS' | trans }}:</strong>

               <div class="info-list-wrapper">
                  @for (loc of question.locations; track $index){
                     <span style="white-space: nowrap;">{{$index+1}}. <strong>#{{ loc.cin }}</strong> {{ loc.name }}</span>
                  }
               </div>
            </div>
         }

         @if (question.location_groups.length>0) {
            <div class="question-info-group">
               <strong class="popover-info-title">{{'NAVROUTE.LOCATION_GROUPS' | trans }}:</strong>

               <div class="info-list-wrapper">
                  @for (loc of question.location_groups; track $index){
                     <span style="white-space: nowrap;"> {{$index+1}}.  {{ loc.name }}</span>
                  }
               </div>
            </div>

         }

         @if (question.whier.subcategory_description) {
            <!-- work hierarchy -->
            <div class="question-info-group">
               <strong class="popover-info-title">{{ "NAVROUTE.WORKHIER" | trans }}: </strong>

               <div class="flex-row wrappable" style="align-items: flex-start;">
                  <i>{{ question.whier.category_description }}</i><i icon="chevron_right" style="font-size: 0.8em; vertical-align: middle"></i>
                  <i>{{ question.whier.subcategory_description }}</i>
               </div>

            </div>
         }
      </div>


   `,
   encapsulation: ViewEncapsulation.None,
   styles: [`
      .info-popover-wrapper {
         padding: 1.5em 1em;
      }

      .info-popover-wrapper task-question-info  {
         max-width: 400px;
         min-width: 300px;
         max-height: 500px;
         overflow: auto;
         display: flex;
         flex-flow: column;
         align-items: center;
      }

      .info-list-wrapper {
         display: flex;
         flex-flow: column wrap;
         max-height: 260px;
         flex: 1 0 auto;
         box-sizing: border-box;
         overflow-x: hidden;
         overflow-y: auto;
         gap: .3em;
      }

      .question-info-group {
         padding: 1rem 0;

      }

      .question-info-group:not(:last-of-type) {
         border-bottom: 1px solid rgba(var(--background-text), 0.2);

      }

      .popover-info-title {
         text-transform: uppercase;
         font-size: 90%;
         letter-spacing: 0.1em;
         display: block;
         margin-bottom: .5rem;
      }
   `]
})

export class ChecklistsQuestionInfo extends ModalBase<Task.CheckListQuestion, any> implements OnInit {
   @Input() question: Task.CheckListQuestion;

   ngOnInit(): void {
      if (this.injectedData instanceof Promise) {
         this.injectedData.then(res=> this.question = res)
      } else {
         this.question = this.injectedData;
      }
   }
}