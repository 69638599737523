import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "WORKGROUP.NEW": "New Work Group",
        "WORKGROUP.EDIT": "Edit Work Group",
        "WORKGROUP.WORKGROUP": "Work Group",
        "WORKGROUP.DESCRIPTION": "Description",
        "WORKGROUP.NAME": "Name",
        "WORKGROUP.UPPDATE_ALL_MSG": "Update all arrival details",
        "WORKGROUP.ADD_NEW": "Create work group"
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "WORKGROUP.NEW": "Nova Grupa radova",
        "WORKGROUP.EDIT": "Uređivanje Grupe radova",
        "WORKGROUP.WORKGROUP": "Grupa radova",
        "WORKGROUP.DESCRIPTION": "Opis",
        "WORKGROUP.NAME": "Naziv",
        "WORKGROUP.UPPDATE_ALL_MSG": "Ažurirati na svim detaljima dolazaka",
        "WORKGROUP.ADD_NEW": "Dodaj grupu radova"

    }
};

export const work_groups_translations = [english, croatian];
