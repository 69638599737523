import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { color, Frame, language, notification, progress } from 'curvy';
import { arrivals_translations } from '../arrivals.trans';
import { files } from '@task-utils/utils';
import { User } from '@task-modules/users';

type FileType = (files.IFile | Task.DMSFile);
function is_dms_file(file: FileType): file is Task.DMSFile {
    return file.hasOwnProperty('dms_document_id');
}


@Component({
    selector: 'task-edit-arrival-details',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './edit-arrival-details.component.html',
    styleUrls: ['./edit-arrival-details.component.scss']
})
export class EditArrivalDetailsComponent implements OnInit {
    @Input() arrival: Task.Arrival;
    @Input() details: Task.ArrivalDetail[];

    original_details: Task.ArrivalDetail[] = [];

    DETAIL_TYPES = {...Task.Arrival_Detail_Type};

    current_detail_type: Task.ARRIVAL_DETAIL_TYPE = null;

    can_edit = false;
    can_delete = false;
    can_see_individual_prices: boolean = false;

    constructor(private ar: ActivatedRoute) {
        language.load_translations(arrivals_translations);
    }

    ngOnInit() {
        this.init();
    }

    update_detail_types(comment_document_only: boolean) {
        if (!comment_document_only) {
            this.DETAIL_TYPES = {...Task.Arrival_Detail_Type};
        } else {
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT];
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION];
            delete this.DETAIL_TYPES[Task.ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON];
        }
    }

    async init() {
        if (this.arrival == null) {
            Frame.set({
                title: language.translate("ARRIVAL.DETAILS"),
                visible: true,
                layout: "middle",
                size: "full"
            });
            const arrival_id = this.ar.snapshot.paramMap.get("id");

            this.arrival = (await TaskRoutes.arrivals.api_arrival_get_single(+arrival_id)).data;
            console.log('arrival', this.arrival);
            this.details = this.arrival.arrival_details;
            console.log('details', this.details);
            this.original_details = this.details.map(d => ({ ...d, is_deleted: false }));
            console.log('original_details', this.original_details);
            this.details = [];
        }

        let is_supplier_admin = await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN);
        let is_admin = await User.currentTypeIs(
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.ADMIN
        );

        if (is_admin) {
            this.can_edit = this.arrival.order.status_id !== Task.STATUS.EXECUTED;
        } else if (is_supplier_admin) {
            this.can_edit = [
                Task.STATUS.CREATED,
                Task.STATUS.IN_EXECUTION,
                Task.STATUS.ON_SUPPLIER_REVIEW
            ].includes(this.arrival.order.status_id);
        }

        // manager 2 nije u mogucnosti vidjeti cijene
        this.can_see_individual_prices = !(await User.currentTypeIs(Task.USER_TYPE.MANAGER_2))
        this.can_delete = false;

        if (is_supplier_admin) {
            if (this.arrival.status_id == Task.STATUS.CREATED) {
                this.update_detail_types(true);
            } else if (this.can_edit) {
                this.update_detail_types(false);
            }
            this.can_delete = [
                Task.STATUS.IN_EXECUTION,
                Task.STATUS.ON_SUPPLIER_REVIEW
            ].includes(this.arrival.order.status_id);

        } else if (is_admin) {
            this.update_detail_types(false);
            this.can_delete = this.can_edit;
        }
    }


    get_title(detail: Task.ArrivalDetail) {
        if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
            if (detail.element_name && detail.element_name.length > 0) {
                if (detail.element_cin && detail.element_cin.length > 0) {
                    return detail.element_name + "(" + detail.element_cin + ")";
                }

                return detail.element_name;
            }
        }

        return language.translate("ARRIVAL.DETAIL." + Task.Arrival_Detail_Type[detail.arrival_detail_type_id]);
    }

    async get_files_for_upload() {
        return await files.get_files({
            multiple: true,
            accept:'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook',
            max_file_size: 40000000, //40000000
            on_files_too_large: (files) => {
                let names = files.map(f => f.name).join(", ");

                notification.show({
                    icon: "error",
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: language.translate('DOCUMENT.UPLOADED_TOO_BIG_TEXT', names)
                })
            }
        })
    }

    async uplaod_file(file: files.IFile, arrival_id: number, arrival_detail_type_id: number) {
        if (!is_dms_file(file)) {
            try {
                await TaskRoutes.arrivals.api_upload_document(arrival_id, file.file, false, arrival_detail_type_id);

                notification.show({
                    icon: "check",
                    color: color.Variable.secondary,
                    title: language.translate('SUCCESS'),
                    message: language.translate('DOCUMENT.UPLOADED_TEXT')
                })
            } catch(error) {
                let msg;
                if (error.internal_error_code == 4107) {
                    msg = language.translate('DOCUMENT.UNSAFE_FAIL_TEXT');
                } else {
                    msg = language.translate('DOCUMENT.UPLOADED_FAIL_TEXT');
                }

                notification.show({
                    icon: 'error',
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: msg
                })
            }
        }
    }

    async download_file(file:Task.DMSFile) {
        await TaskRoutes.arrivals.api_get_document_by_arrival_detail_id(file.arrival_detail_id, true);
    }

    async create_detail(detail_type: Task.ARRIVAL_DETAIL_TYPE) {
        if (detail_type == Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT) {

            let files = await this.get_files_for_upload();
            files = [...files];

            for (let file of files) {
                this.details.push({
                    arrival_detail_type_id: detail_type,
                    _iFile: file
                } as unknown as any)
            }

        } else {
            this.details.push(<Task.ArrivalDetail>{
                arrival_detail_type_id: detail_type,
                lump_sum: false,
                description: ""
            });
        }
    }

    undelete_detail(detail: Task.ArrivalDetail) {
        if (this.original_details.map(d => d.arrival_detail_id).includes(detail.arrival_detail_id)) {
            detail["is_deleted"] = false;
        }
    }

    delete_detail(detail: Task.ArrivalDetail) {
        if (this.original_details.map(d => d.arrival_detail_id).includes(detail.arrival_detail_id)) {
            detail["is_deleted"] = true;
        } else {
            let index = this.details.indexOf(detail);
            this.details.splice(index, 1);
        }
    }

    toggle_delete_detail(detail: Task.ArrivalDetail) {
        if (detail["is_deleted"]) {
            this.undelete_detail(detail);
        } else {
            this.delete_detail(detail);
        }
    }



    async save() {
        let promises: Promise<any>[] = [];

        for (let detail of this.details) {
            if(detail.arrival_detail_type_id != Task.ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT && detail.arrival_detail_type_id != Task.ARRIVAL_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT) {
                promises.push(TaskRoutes.arrivals.api_arrival_detail_add(
                    this.arrival.arrival_id, detail as any, true
                ));
            }
        }

        let to_delete = this.original_details.filter(d => d["is_deleted"]);

        for (let detail of to_delete) {
            if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
                detail.element_quantity = 0;
                promises.push(TaskRoutes.arrivals.api_arrival_detail_modify(
                    detail.arrival_detail_id,
                    detail
                ));
            } else {
                promises.push(TaskRoutes.arrivals.api_arrival_detail_delete(detail.arrival_detail_id));
            }
        }

        for (let detail of this.details.filter(d=>"_iFile" in d)) {
            promises.push(this.uplaod_file(detail["_iFile"] as files.IFile, this.arrival.arrival_id, detail.arrival_detail_type_id));
        }

        await progress.listen("edit-arrival-details-save", Promise.all(promises));
        // await Promise.all(promises);

        let p = TaskRoutes.arrivals.api_arrival_get_single(this.arrival.arrival_id, true);
        progress.listen("edit-arrival-details-save", p);

        this.arrival = (await p).data;
        window.history.back();
    }

    cancel() {
        window.history.back();
    }
}
