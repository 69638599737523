import { Component, OnInit } from "@angular/core";
import { Task } from "@task-utils/types";
import { ModalBase } from "curvy";

interface InputType {
    inventory: Task.OrderDetail[],
    arrival_details: Task.ArrivalDetail[],
    inventory_detail_map: Map<Task.OrderDetail, Set<Task.ArrivalDetail>>
};


@Component({
    selector: 'task-assign-arrival',
    template: `
        <h2 style="margin: 1.5em 0;">{{ "ORDER.FINANCIAL_ITEMS_ASSING_TITLE" | trans }}</h2>
        <label *ngIf="inventory_detail_map.size > 8" class="input-search outlined input" color="complementary" [title]="'SEARCH.INVENTORY' | trans">
            <input [(ngModel)]="searchStr" />
        </label>
        <div class="details-container">
            <ng-container *ngFor="let item of inventory_detail_map | keyvalue" >
                <div *ngIf="item.key.inventory_name.toLowerCase().includes(searchStr.toLowerCase());" style="margin-bottom: 1.2rem;">
                    <h3 style="margin: 0.5em 0 0.2em; font-size: 1em;" color="primary">{{ item.key.inventory_name }}: </h3>
                    <label
                        class="input outlined"
                        style="flex: 1 1 auto;">
                        <select ngModel (ngModelChange)="selectDetail(item.key, $event)">
                            <option class="flex-row justify-space-between arrival-detail" value="" hidden style="display: none; color: gray;">{{ "ARRIVAL_DETAIL_PICK" | trans }}...</option>
                            <option *ngFor="let detail of details" class="flex-row justify-space-between arrival-detail"
                            [ngValue]="detail">
                                <b>{{ detail.element_name }} </b>
                                (<span style="white-space: nowrap;">
                                    <task-price [label]="null" [price]="detail.element_price * detail.element_quantity" [currency]="detail.element_currency"></task-price>
                                </span>)
                            </option>
                        </select>
                    </label>
                    <div class="flex-row wrappable detail-items justify-center">
                        <button class="filled flex-row detail-item"
                                color="secondary"
                                *ngFor="let det of item.value">
                            <div class="flex-column">
                                <i *ngIf="det.arriva_detail_id">#{{ det.arriva_detail_id }}</i>
                                <b class="truncate" [title]="det.element_name">{{ det.element_name }}</b>
                                <task-price [label]="null" [price]="det.element_price * det.element_quantity" [currency]="det.element_currency"></task-price>
                            </div>
                            <button class="round"
                                    color="secondary-text"
                                    style="pointer-events: all"
                                    (click)="delete_detail(item.value, det)">
                                <i icon="clear"></i>
                            </button>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex-row modal-footer">
            <button color="primary"
                    (click)="cancel()">
                <i icon="clear"></i>
                <span>{{ 'CANCEL' | trans }}</span>
            </button>

            <button class="filled"
                    color="secondary"
                    (click)="save()">
                <i icon="check"></i>
                <span>{{ 'SAVE' | trans }}</span>
            </button>

        </div>


    `,
    styleUrls: ['./order.component.scss']
})

export class AssignArrivalDialog extends ModalBase<InputType, any> implements OnInit {
    preventSoftClose = true;

    inventory: Task.OrderDetail[];
    details: Task.ArrivalDetail[];

    inventory_detail_map: Map<Task.OrderDetail, Set<Task.ArrivalDetail>>;
    searchStr = "";


    ngOnInit(): void {
        this.inventory = this.injectedData.inventory;
        this.details = this.injectedData.arrival_details;
        this.inventory_detail_map = this.injectedData.inventory_detail_map;
    }

    delete_detail(_set: Set<Task.ArrivalDetail>, det: Task.ArrivalDetail) {
        _set.delete(det);
    }

    selectDetail(inventory: Task.OrderDetail, detail: Task.ArrivalDetail) {
        let detail_set = this.inventory_detail_map.get(inventory);
        detail_set.add(detail);
    }

    cancel() {
        this.submit(null);
    }

    save() {
        this.submit(this.inventory_detail_map);
    }
}
