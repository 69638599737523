import { Component, ViewChild } from '@angular/core';
import {
    Frame, language, TableConfig, network, GenericFilter, noOp,
    template, modal, GenericFilterComponent, DataTableComponent, date, utils, ConfirmDialogComponent, color
} from 'curvy';
import { Task } from '@task-utils/types';;
import { PlanArrivalDialog } from '@task-modules/arrivals/plan-arrival.dialog';
import { Arrival } from './arrivals';
import { arrivals_translations } from './arrivals.trans';
import { TaskRoutes } from '@task-utils/routes';
import { User } from '../users';



type TableType = DataTableComponent<Task.Arrival, DisplayArrival, 'arrivals', GenericFilterComponent>;

@Component({
    selector: 'task-arrivals',
    template: `<knw-data-table #arrivalstable [config]="tableConfig"></knw-data-table>`
})
export class ArrivalsComponent {
    @ViewChild('arrivalstable') table: TableType;

    statuses = [
        { label: "STATUS.CREATED", value: Task.STATUS.CREATED },
        { label: "STATUS.IN_EXECUTION", value: Task.STATUS.IN_EXECUTION },
        { label: "STATUS.EXECUTED", value: Task.STATUS.EXECUTED },
        { label: "STATUS.UNDONE", value: Task.STATUS.UNDONE },
        { label: "STATUS.DELETED", value: Task.STATUS.DELETED }
    ];

    constructor() {
        Frame.set({
            title: "NAVROUTE.ARRIVALS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(arrivals_translations);
    }

    async search_companies(search: string) {
        let res = await TaskRoutes.companies.api_company_search(
            { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
            { page_no: 1, page_size: 10 }
        )

        return res.data;
    }

    format_company_name(comp: Task.Company) {
        if (!comp) return "";
        return comp.name;
    }

    tableConfig: TableType["tableConfig"] = {
        tableName: 'arrivals',
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = { search: [], attributes: [], sort: null };
            for (let key in filter) {
                if (key == "arrival") {
                    let re_num = /^[0-9]+$/;
                    let is_id = filter[key].match(re_num);
                    let attr = is_id ? "#=:arrival_id" : "arrival_id+ +order.title+ +order.order_id+ +suppliers_company.name+ +arrival_worker.first_name+ +arrival_worker.last_name";
                    parsed_filter.attributes.push(attr);
                    parsed_filter.search.push(filter[key]);

                } else if(key === 'arrival_worker_id' && filter['arrival_worker_id'] !== null){
                    parsed_filter.attributes.push("#=:order.arrival_worker_id")
                    parsed_filter.search.push(filter.arrival_worker_id.user_id)

                } else if (key === 'company' && filter['company'] !== null) {
                    parsed_filter.attributes.push('#=:order.company_id')
                    parsed_filter.search.push(filter[key].company_id)

                } else if (key == "created_date_from") {
                    let start = date.start_of_day(filter[key]);
                    parsed_filter.attributes.push("-d:created_date");
                    parsed_filter.search.push(start.toISOString());

                } else if (key == "created_date_to") {
                    let end = date.end_of_day(filter[key]);
                    parsed_filter.attributes.push("+d:created_date");
                    parsed_filter.search.push(end.toISOString());

                } else if (key == "status_id") {
                    if (!filter[key] || !filter[key].length) continue;

                    let target_statuses = filter[key] as typeof this.statuses;
                    let exclude_statuses = Object.keys(Task.Status).filter(s=> !target_statuses.some(t=>t.value == +s));

                    for (let status of exclude_statuses) {
                        parsed_filter.attributes.push("#!:status_id");
                        parsed_filter.search.push(status);
                    }
                } else if (key == "location" && filter["location"] !== null) {
                    parsed_filter.attributes.push("#=:order.location_id");
                    parsed_filter.search.push(filter.location.location_id);

                } else if (key == "loc_groups") {
                    parsed_filter.attributes.push("#=:loc_groups.location_group_id");
                    parsed_filter.search.push(filter.loc_groups.location_group_id);

                } else if (key == "supplier" && filter["supplier"] !== null) {
                    parsed_filter.attributes.push("suppliers_company.name");
                    parsed_filter.search.push(filter.supplier.name);

                } else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key]);
                }
            }

            if (sort_header) {
                switch (sort_header.label) {
                    case 'arrival':
                        sort_header.label = '#:arrival_id';
                        break;

                    case 'time':
                        sort_header.label = 'd:planned_start_time';
                        break;

                    case 'workers':
                        sort_header.label = '#:planned_no_of_workers';
                        break;

                }

            }
            parsed_filter.sort = sort_header;

            return await TaskRoutes.arrivals.api_arrival_search(parsed_filter, paginator, true)
            // return Arrival.search(paginator, parsed_filter);
        },
        getHeader: (header) => {
            switch (header) {
                case 'status':
                    return { icon: "offline_bolt", label: "", sortable: false };

                case 'arrival':
                    return { icon: "today", label: "ARRIVAL.TITLE", sortable: true };

                case 'time':
                    return { icon: "schedule", label: "ARRIVAL.TIME", sortable: true };

                case 'details':
                    return { icon: "details", label: "DETAILS", sortable: false }; // numbers in strings don't sort correctly so we are skipping it for now

                case 'workers':
                    return { icon: "people", label: "ARRIVAL.WORKERS", sortable: true };
            }
        },
        unwrapData: (arrival) => {
            let emergency_label = language.translate('ARRIVAL.DETAILS.EMERGENCY_TITLE');
            let ended_without_Qr = language.translate('ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE');
            let emergency = ``;

            if (arrival.emergency) {
                emergency = `<i class="tooltip-icon" icon="emergency" style="padding: 0 0px 0 0; color: rgba(var(--orange)); font-size: 55%;" tooltip="${emergency_label}"></i>
                 <i class="tooltip-icon" icon="emergency" style="padding: 0 0px 0 0; color: rgba(var(--orange)); font-size: 55%;" tooltip="${emergency_label}"></i>`
            } else if (arrival.ended_without_qr) {
                emergency = `<i class="tooltip-icon" icon="emergency" style="padding: 0 8px 0 0; color: rgba(var(--orange)); font-size: 55%;" tooltip="${ended_without_Qr}"></i>`
            }

            return {
                status: template.transform(`
                    <div class="flex-column justify-center" style="padding: .25em 0;">
                        <div class="flex-row align-center justify-center">
                            <i style="color: {{icon_color}}">{{icon_name|icon}}</i> ${emergency}</div>
                        <div style="font-size: 0.8em">
                            {{ status_name | trans }}
                        </div>
                    </div>
                    `, {
                    icon_name: Task.StatusIcons[arrival.status_id].icon,
                    icon_color: Task.StatusIcons[arrival.status_id].color,
                    status_name: language.translate("STATUS." + Task.Status[arrival.status_id]),
                }),
                arrival: template.transform(`
                    <div class="flex-column" style="width: 100%; text-align: left">
                        <span style="color: rgb(var(--primary))"><b>#{{ arrival_id }}</b>&nbsp;{{ supplier }}</span>
                        <i class="flex-row flex-align-center">
                            <i icon="ballot"></i>
                            <span><b>#{{ order_id }}</b>&nbsp;<span class="truncate-text" style="padding-right:0.3em;">{{ order }}</span></span>
                        </i>
                    </div>
                `, {
                    arrival_id: arrival.arrival_id,
                    supplier: arrival.order.supplier_name || '',
                    order_id: arrival.order_id,
                    order: arrival.order.title
                }),
                time: template.transform(`
                    <div class="flex-column">
                        <span>{{ time | date << R }}</span>
                    </div>
                `, { time: arrival.status_id === Task.STATUS.EXECUTED ? arrival.end_time || (arrival.start_time || arrival.planned_start_time) : arrival.start_time || arrival.planned_start_time }),
                details: template.transform(`
                    <div class="flex-column">
                        <b>{{ location }}</b>
                    </div>
                `, {
                    location: arrival.order.location_name,
                }),
                workers: template.transform(`{{ no }}`, { no: arrival.planned_no_of_workers })
            }
        },
        showHeaderWhenEmpty: true,
        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (arrival) => {
                utils.router.navigateByUrl("/arrivals/" + arrival.arrival_id);
            },
            priority: 1,
            isVisible: () => true
        }, {
            label: language.translate("ARRIVAL_WORKERS"),
            icon: "people",
            onClick: (arrival) => {
                modal.open(PlanArrivalDialog, noOp, {
                    arrival,
                    start_time_picker: arrival.status_id === Task.STATUS.CREATED
                }, () => {
                    this.table.getData();
                });
            },
            priority: 2,
            isVisible: (arrival) => arrival.status_id === Task.STATUS.CREATED
        },
        {
            label: language.translate("DELETE"),
            icon: "delete",
            color: "warn",
            onClick: (arrival) => {
                modal.open(ConfirmDialogComponent, async (succ) => {
                    if (succ) {
                        await Arrival.remove(arrival.arrival_id)
                        this.table.getData();
                    }
                },
                    {
                        title: language.translate("ARRIVAL.DELETE.TITLE"),
                        message: language.translate("ARRIVAL.DELETE.MSG", arrival.arrival_id),
                        icon: "today",
                        iconColor: color.variable(color.Variable.warn)
                    });
            },
            priority: 3,
            isVisible: (arrival) => {
                let allowCreated = [
                    Task.USER_TYPE.ADMIN,
                    Task.USER_TYPE.SYSTEM_ADMIN,
                    Task.USER_TYPE.SUPPLIER_ADMIN,
                    Task.USER_TYPE.COMPANY_WORKER
                ].includes(User.currentUser.user_type_id) && arrival.status_id === Task.STATUS.CREATED;

                let allowInexecution = [
                    Task.USER_TYPE.ADMIN,
                    Task.USER_TYPE.SYSTEM_ADMIN
                ].includes(User.currentUser.user_type_id) && arrival.status_id === Task.STATUS.IN_EXECUTION

                if (User.currentUser.user_type_id == Task.USER_TYPE.COMPANY_WORKER) {
                    return allowCreated && arrival.order.arrival_worker_id == User.currentUser.user_id;
                }

                return allowCreated || allowInexecution;
        }
        }],
        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("ARRIVAL","arrival"),
                GenericFilter.search("STATUS", "status_id", this.search_statuses, this.format_status_name, undefined, undefined, undefined, true, true),
                GenericFilter.date_filter("START_CREATED_DATE", "created_date_from"),
                GenericFilter.date_filter("END_CREATED_DATE", "created_date_to"),
                GenericFilter.search("SUPPLIER", "supplier", this.search_suppliers, this.format_supplier_name),
                GenericFilter.toggle("ARRIVAL.DETAILS.ENDED_WITHOUT_QR_TITLE", "b:ended_without_qr"),
            ];

            filters.splice(1,0,GenericFilter.search("NAVROUTE.LOCATION_GROUPS", "loc_groups", this.search_location_groups, this.format_location_group_name));

            if (await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)) {
                filters.splice(1, 0, GenericFilter.text("LOCATION", "location.name"))
            } else {
                filters.splice(1, 0,
                    GenericFilter.search("LOCATION", "location", this.search_locations, this.format_location_name, true),
                )
                filters.push(GenericFilter.search('USER.TYPE.COMPANY_WORKER', 'arrival_worker_id', this.search_company_workers, this.format_company_workers))
            }

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
                filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }


            return filters;

        }
    }


    search_statuses = async (search: string) => {
        return this.statuses;
    }

    format_status_name(status) {
        if (!status) return;
        return language.translate(status.label);
    }

    async search_suppliers(search: string) {
        let res = await TaskRoutes.suppliers.api_supplier_search({
            search: [search],
            attributes: ["company.name"]
        }, {page_no: 1, page_size: 10});

        return res.data;
    }

    format_supplier_name(supp: Task.Supplier) {
        if (!supp) return;

        return `${supp.name}`
    }

    async search_locations(search: string) {
        let res = await TaskRoutes.locations.api_location_search_visible(
            { search: [search], attributes: ["cin+ +name"] },
            { page_no: 1, page_size: 50 }
        );
        return res.data;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
    }

    async search_location_groups(search: string) {
        let res = await TaskRoutes.location_groups.api_location_groups_search_visible({
            search: [search],
            attributes: ["name"]
        }, {
            page_no: 1,
            page_size: 10
        }, false)

        return res.data;
    }

    format_location_group_name(loc: Task.LocationGroup) {
        if (!loc) return "";
        return `${loc.name}`
    }

    search_company_workers = async (search: string) => {
		let res = await TaskRoutes.users.api_user_search({
			search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
			attributes: ['first_name+ +last_name', "#=:user_type_id"]
		}, {
			page_no: 1,
			page_size: 10
		})
		return res.data;
	}

    format_company_workers = (user: Task.User) => {
        if(!user) {
            return ""
        }
        return `${user.first_name} ${user.last_name}`
    }
}

interface DisplayArrival {
    status: template.EscapedHTML;
    arrival: template.EscapedHTML;
    details: template.EscapedHTML;
    workers: template.EscapedHTML;
    time: template.EscapedHTML;
}
