import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { Frame, language, date, progress, utils, popover, noOp, modal } from 'curvy';
import { EventInfoPopover } from './event-info-popover';


type eventType = string;

/** Backend does not give us the day of the month for displaying the event, so we have to calculate it ourselves. */
type CalendarEvent = Task.CalendarEvent & {
	/**
		The index of the day in the month view of the calendar.

		For April 17. day will be equal to 17.
	*/
	day: number
};

type CalendarFilters = {
	selectedLocation: Task.Location;
	selectedYear: number;
	selectedMonth: number;
	allStatuses: legend[];
	selectedSupplier: Task.Supplier;
	selectedType: eventType;
	assignedToEvent: 'me' | null | 'others';
	selectedCompany: Task.Company
}

interface legend_extra_options {
	admin_only: boolean,
	except: eventType[]
}

interface legend extends Partial<legend_extra_options> {
	icon: string,
	name: string,
	color: string,
	status_id: Task.STATUS,
	visible: boolean,
	selected: boolean
}

function make_legend(status_id: Task.STATUS, options: Partial<legend_extra_options>={}): legend {
	return {
		icon: Task.get_status_icon(status_id).icon,
		name: language.translate("STATUS." + Task.Status[status_id]),
		color: Task.get_status_icon(status_id).color,
		visible: true,
		selected: false,
		status_id,
		...options
	};
}


function is_in_past(d:Date | string) {
	let today = date.start_of_day(date.today(), true);
	return date.start_of_day(new Date(d), true) < today;
}

function getArrivalStatus(arrival: Task.CalendarArrival) {

	if (arrival.status_id == Task.STATUS.DELETED) {
		return Task.STATUS.DELETED;
	} else if (is_in_past(arrival.planned_start_time) && arrival.end_time == null) {
		return Task.STATUS.UNDONE
	} else {
		return arrival.status_id
	}
}

function transformArrival(arrival: Task.CalendarArrival): CalendarEvent {
	let date = arrival.start_time || arrival.planned_start_time;

	return {
		status_id: getArrivalStatus(arrival),
		event_id: arrival.arrival_id,
		event_title: arrival.title,
		supplier_name: arrival.supplier_name,
		supplier_id: arrival.supplier_id,
		location_name: arrival.location_name,
		location_id: arrival.location_id,
		location_cin: arrival.location_cin,
		date: new Date(date),
		has_own_url: true,
		url: `/arrivals/${arrival.arrival_id}`,
		day: new Date(date).getUTCDate(),
		type: "ARRIVAL"
	}
}

function getOrderStatus(order: Task.CalendarOrder) {
	if (order.status_id == Task.STATUS.DELETED) {
		return Task.STATUS.DELETED;
	} else if (order.status_id == Task.STATUS.CREATED) {
		return Task.STATUS.CREATED;
	} else if (order.status_id == Task.STATUS.IN_EXECUTION) {
		return Task.STATUS.IN_EXECUTION;
	} else if (
		order.status_id == Task.STATUS.ON_COMPANY_REVIEW ||
		order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
		order.status_id == Task.STATUS.EXECUTED ||
		order.status_id == Task.STATUS.INVOICED) {
		return Task.STATUS.EXECUTED;
	} else if(order.status_id == Task.STATUS.REJECTED) {
		return Task.STATUS.REJECTED;
	} else {
		return Task.STATUS.UNDONE;
	}
}

function transformOrder(order: Task.CalendarOrder): CalendarEvent {
	return {
		status_id: getOrderStatus(order),
		event_id: order.order_id,
		event_title: order.title,
		supplier_name: order.supplier_name,
		supplier_id: order.supplier_id,
		location_name: order.location_name,
		location_id: order.location_id,
		location_cin: order.location_cin,
		date: new Date(order.created_date),
		has_own_url: true,
		url: `/orders/${order.order_id}`,
		day: new Date(order.created_date).getUTCDate(),
		type: "ORDER"
	}
}

function getSCHOrderStatus(sch_order: Task.CalendarScheduledOrder) {

	if (sch_order.status_id == Task.STATUS.DELETED || sch_order.order_status_id == Task.STATUS.DELETED) {
		return Task.STATUS.DELETED;
	} else if ((sch_order.order_status_id == Task.STATUS.CREATED || sch_order.order_status_id == null) &&
		!is_in_past(sch_order.planned_end_date)) {
			return Task.STATUS.CREATED;
	} else if (sch_order.order_status_id == Task.STATUS.IN_EXECUTION) {
		return Task.STATUS.IN_EXECUTION;
	} else if (
		sch_order.order_status_id == Task.STATUS.ON_COMPANY_REVIEW ||
		sch_order.order_status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
		sch_order.order_status_id == Task.STATUS.EXECUTED ||
		sch_order.order_status_id == Task.STATUS.INVOICED) {
		return Task.STATUS.EXECUTED;
	} else if(sch_order.order_status_id == Task.STATUS.REJECTED) {
		return Task.STATUS.REJECTED;
	} else {
		return Task.STATUS.UNDONE;
	}
}

function transformScheduledOrder(sch_order: Task.ScheduledOrder): CalendarEvent {
	return {
		status_id: getSCHOrderStatus(sch_order),
		event_id: sch_order.scheduled_order_id,
		event_title: sch_order.title,
		supplier_name: sch_order.supplier_name,
		supplier_id: sch_order.supplier_id,
		location_name: sch_order.location_name,
		location_id: sch_order.location_id,
		location_cin: sch_order.location_cin,
		has_own_url: sch_order.order_id ? true : false,
		url: sch_order.order_id ? `/orders/${sch_order.order_id}`: null,
		date: new Date(sch_order.planned_end_date),
		day: new Date(sch_order.planned_end_date).getUTCDate(),
		type: "SCHEDULED_ORDER"
	}
}

@Component({
	selector: "task-orders-calendar",
	templateUrl: "./orders-calendar.component.html",
	styleUrls: ['./orders-calendar.component.scss'],
	encapsulation: ViewEncapsulation.None

})
export class OrdersCalendarComponent implements OnInit {
	allEvents: CalendarEvent[] = [];
	lastOrdersPromise: any = null;
	selectedLocation: Task.Location = null;
	selectedYear: number = new Date().getUTCFullYear();
	selectedMonth: number = new Date().getUTCMonth();
	startDay: number = 0;
	monthDays: number = 30;
	months = language.get_months();
	days = (() => {
		let wkds = [...language.get_weekdays()];
		let first = wkds.shift();
		wkds.push(first);
		return wkds;
	})();
	/** Are both year and month selected? */
	isMontAndYear = false;

	allStatuses: legend[] = [
		make_legend(Task.STATUS.CREATED),
		make_legend(Task.STATUS.IN_EXECUTION),
		make_legend(Task.STATUS.EXECUTED),
		make_legend(Task.STATUS.UNDONE, { except: ["ORDER"] }),
		make_legend(Task.STATUS.REJECTED, {except: ["ARRIVAL"]}),
		make_legend(Task.STATUS.DELETED, { admin_only: true })
	];

	selectedSupplier: Task.Supplier = null;
	eventTypes: eventType[];
	selectedType: eventType;
	assignedToEvent: 'me' | null | 'others' = null;
	canSeeReassign = false;
	refreshCount = 0;
	refresh() { this.refreshCount++; }
	is_admin: boolean = false;
	selectedCompany: Task.Company;

	create_filters_obj():CalendarFilters {
		return {
			assignedToEvent: this.assignedToEvent,
			selectedLocation: this.selectedLocation,
			selectedSupplier: this.selectedSupplier,
			selectedMonth: this.selectedMonth,
			selectedYear: this.selectedYear,
			selectedType: this.selectedType,
			allStatuses: this.allStatuses,
			selectedCompany: this.selectedCompany
		}
	}

	set_filters_to_LS (obj: CalendarFilters) {
		localStorage.setItem('calendar-filters', JSON.stringify(obj));
	}

	get_filters_from_LS(): CalendarFilters {
		let o = JSON.parse(localStorage.getItem('calendar-filters'));
			o.statusSelected = new Set(o.statusSelected);

		return o;
	}

	set_filters_from_LS_to_props(obj:CalendarFilters) {
		this.assignedToEvent = obj.assignedToEvent;
		this.selectedLocation = obj.selectedLocation;
		this.selectedSupplier = obj.selectedSupplier;
		this.selectedMonth = obj.selectedMonth;
		this.selectedYear = obj.selectedYear;
		this.selectedType = obj.selectedType;
		this.allStatuses = obj.allStatuses;
		this.selectedCompany = obj.selectedCompany

	}

	ngOnInit() {
		Frame.set({
			title: language.translate("NAVROUTE.CALENDAR"),
			visible: true,
			layout: "top-middle",
			size: "full"
		});


		this.init();
	}

	async init() {

		this.select_default_statuses();

		if (await User.is_feature_flag_enabled('scheduled_orders')) {
			this.eventTypes = ['ORDER', 'ARRIVAL', 'SCHEDULED_ORDER'];

			if (await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)) {
				this.selectedType = this.eventTypes[2];

			} else {
				this.selectedType = this.eventTypes[1];
			}

		} else {
			this.eventTypes = ['ORDER', 'ARRIVAL'];
			this.selectedType = this.eventTypes[1];
		}

		this.select_default_statuses();

		if (await User.currentTypeIs(
			Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
			Task.USER_TYPE.SAFETY_MANAGER,
			Task.USER_TYPE.ADMIN
		)) {
			this.canSeeReassign = true;

			if (await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER)) {
				this.assignedToEvent = 'me';
			}
		}

		if (localStorage.getItem('calendar-filters')) {
			this.set_filters_from_LS_to_props(this.get_filters_from_LS());
		}

		this.is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN);

		this.updateLegend();
		this.onChangeDate();
	}

	select_default_statuses() {
		/** orders have default status IN_EXECUTION, others UNDONE */
		if (!this.allStatuses.some(s => s.selected)) {
			if (this.selectedType == 'ORDER') {
				this.allStatuses.find(s=>s.status_id === Task.STATUS.IN_EXECUTION).selected = true;

			} else {
				this.allStatuses.find(s=>s.status_id === Task.STATUS.IN_EXECUTION).selected = false;
				this.allStatuses.find(s=>s.status_id === Task.STATUS.UNDONE).selected = true;
			}
		}

	}

	async updateLegend() {
		for (let legend of this.allStatuses) {
			legend.visible = true;

			if (legend.admin_only) {
				legend.visible = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN);
			}

			if (legend.except) {
				for (let type of legend.except) {
					if (this.selectedType === type) {
						legend.visible = false;
					}
				}
			}

			if (!legend.visible) {
				legend.selected = false;
			}
		}

		this.select_default_statuses();
		this.refresh();
	}

	ngOnDestroy() {
		this.set_filters_to_LS(this.create_filters_obj());

	}

	clear_filter() {
		this.selectedLocation = this.selectedSupplier = null;
		this.selectedCompany = null;
		this.getData();
	}

	async getData() {
		let d = date.create({
			year: this.selectedYear,
			month: this.selectedMonth,
			date: 3 // does not matter
		} as any);

		let range = date.month_range(d, true);
		range.end = date.end_of_day(range.end);

		let searchDate = ["-d:created_date", "+d:created_date"];

		switch (this.selectedType) {
			case 'ARRIVAL' :
				searchDate = ["-d:planned_start_time", "+d:planned_start_time"];
				break;
			case 'SCHEDULED_ORDER':
				searchDate = ["-d:planned_end_date", "+d:planned_end_date"];
				break;
			case 'ORDER':
				searchDate = ["-d:created_date", "+d:created_date"];
				break;
			default :
			 	searchDate = ["-d:created_date", "+d:created_date"];
				break;
		}

		let searchAttr = searchDate;
		let searchSrch = [range.start.toISOString(), range.end.toISOString()];

		let user = await User.currentUserPromise;
		let is_maintenance_manager = await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER);
		let is_safety_manager = await User.currentTypeIs(Task.USER_TYPE.SAFETY_MANAGER);

		if (is_maintenance_manager || is_safety_manager) {
			if (this.assignedToEvent == 'me') {
				searchAttr.push("assigned_to");
				searchSrch.push('me')

			} else if (this.assignedToEvent == 'others') {
				searchAttr.push("assigned_to");
				searchSrch.push("others");
			}

		} else  {
			if (this.assignedToEvent == 'me') {
				searchAttr.push("#=:created_by_id");
				searchSrch.push(user.user_id+"");

			} else if (this.assignedToEvent == 'others') {
				searchAttr.push("#!:created_by_id");
				searchSrch.push(user.user_id+"");

			}
		}

		if (this.selectedLocation) {
			if (this.selectedType === 'ARRIVAL') {
				searchAttr.push("#=:order.location_id");

			} else {
				searchAttr.push("#=:location_id");
			}

			searchSrch.push(this.selectedLocation.location_id.toString());
		}

		if (this.selectedSupplier) {
			if (this.selectedType === 'ARRIVAL') {
				searchAttr.push("#=:order.supplier_id");

			} else {
				searchAttr.push("#=:supplier_id");
			}

			searchSrch.push(this.selectedSupplier.supplier_id.toString());
		}

		if (this.selectedCompany) {
			if(this.selectedType === 'ARRIVAL') {
				searchAttr.push("#=:order.company_id");
			} else {
				searchAttr.push("#=:company_id");
			}
			searchSrch.push(this.selectedCompany.company_id.toString())
		}

		let prom: Promise<CalendarEvent[]>;

		if (this.selectedType == 'ORDER') {
			prom = TaskRoutes.orders.api_order_calendar_search({
				attributes: searchAttr,
				search: searchSrch
			}).then(res=>res.data.map(transformOrder));

		} else if (this.selectedType == 'ARRIVAL') {
			prom = TaskRoutes.arrivals.api_arrival_calendar_search({
				attributes: searchAttr,
				search: searchSrch
			}).then(res=>res.data.map(transformArrival))

		} else {
			prom = TaskRoutes.scheduled_orders.api_scheduled_order_calendar_search({
				attributes: searchAttr,
				search: searchSrch
			}).then(res=>res.data.map(transformScheduledOrder));
		}

		progress.listen("load-calendar", prom);
		this.lastOrdersPromise = prom;
		let res = await prom;
		if (this.lastOrdersPromise != prom) { return; }

		this.allEvents = res;
		this.refresh();
	}

	async search_locations(search: string) {
		let res = await TaskRoutes.locations.api_location_search_visible({ search: [search], attributes: ["cin+ +name"] }, {
			page_no: 1,
			page_size: 50
		});
		return res.data;
	}

	async search_suppliers(search: string) {
		let res = await TaskRoutes.suppliers.api_supplier_search({ search: [search], attributes: ["company.name"] });
		return res.data;
	}

	format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

	format_supplier_name(sup: Task.Supplier) {
		if (!sup) { return ""; }
		return `${sup.name}`;
	}

	async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

	onChangeDate() {
		const d = date.set(new Date(0), {
			year: this.selectedYear,
			month: this.selectedMonth,
			date: 15
		});

		this.startDay = date.first_day_of_month(d) - 1;

		if (this.startDay <= 0) {
			this.startDay += 7;
		}

		this.monthDays = date.days_in_month(d, true);

		// check if selected month and year are current ones
		let today = new Date();
		let isMonth = d.getMonth() == today.getMonth();
		let isYear = d.getFullYear() == today.getFullYear();
		this.isMontAndYear = isMonth && isYear;

		this.allEvents = [];
		this.getData();
	}

	async openEventsInfo(events: CalendarEvent[]) {
		modal.open(EventInfoPopover, noOp, events, noOp);

	}

	async selectDay(event: CalendarEvent) {

	}

	printDocument() {
		window.print();
	}

	switchMonth(dir) {
		if (dir > 0) {
			this.selectedMonth += 1;

			if (this.selectedMonth > 11) {
				this.selectedYear += 1;
				this.selectedMonth = 0;
			}
		} else {
			this.selectedMonth -= 1;

			if (this.selectedMonth < 0) {
				this.selectedYear -= 1;
				this.selectedMonth = 11;
			}
		}

		this.onChangeDate();
	}

	dayEvents = (day: number) => {

		let dayEvents = this.allEvents;
		dayEvents = dayEvents.filter(ev => ev.day == day);
		dayEvents = dayEvents.filter(ev => this.allStatuses.some(s => s.selected && s.status_id == ev.status_id));

		if (!this.allStatuses.some(s => s.selected)) {
			throw new Error("No statuses selected");
		}

		return dayEvents;
	}

	filterStatus(legend: legend) {
		this.refresh();
		legend.selected = !legend.selected;
		if(this.allStatuses.some(s=>s.selected)) { return };
		this.allStatuses.forEach(s=>s.selected = true);
	}

	setStatus(id: number) {
		return Task.get_status_icon(id);
	}

	isToday(relative_day: number) {
		if (!this.isThisMonth(relative_day)) { return false; }
		if (!this.isMontAndYear) { return false; }

		let dateLabel = date.set(new Date(0), {
			year: this.selectedYear,
			month: this.selectedMonth,
			date: relative_day
		}, true).getUTCDate();

		let today = new Date();
		return dateLabel == today.getUTCDate();
	}

	isThisMonth(relative_day: number): boolean {
		return relative_day > 0 && relative_day <= this.monthDays+1; // quick fix maybe valid
	}

	getDayLabel(relative_day: number) {
		return date.set(new Date(0), {
			year: this.selectedYear,
			month: this.selectedMonth,
			date: relative_day
		}, true).getUTCDate();
	}

	openLink(event:Task.CalendarEvent) {
		if(event.has_own_url) utils.router.navigateByUrl(event.url);
	}
}
