<div class="comments-container flex-column">
	<h4 style="margin-bottom: 0.5em;" class="flex-static">{{ 'COMMENTS' | trans }}</h4>

	<div #commentscontainer class="comments flex-column flex-dynamic">
		<div class="comment" *ngFor="let comment of comments" [attr.title]="comment.created_date | datefmt">
			<h4>
				{{comment.created_by_first_name}} {{comment.created_by_last_name}}
				<span style="font-size: 0.8em">{{comment.created_date | datefmt : 'short_date_time' : false}}</span>
			</h4>
			<p>{{comment.comment}}</p>
		</div>
	</div>

	<div class="flex-row flex-static" style="margin-top: 0.25em">
		<label class="textarea filled flex-dynamic" (keyup.enter)="add_comment()">
			<textarea placeholder="{{ 'COMMENT' | trans }}" [(ngModel)]="comment_text"></textarea>
		</label>
		<button class="filled" color="secondary" (click)="add_comment()" style="margin-left: 0; align-self: stretch;">
			<i icon="send"></i>
		</button>
	</div>
</div>
