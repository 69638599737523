import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "SETPASS.TITLE": "Set your password",
        "SETPASS.ERROR": "Error",
        "SETPASS.ERROR.NOUSER": "Invalid link! Please ask your administrator to send you a new password link.",
        "SETPASS.ERROR.INVALID": "Invalid link! Please ask your administrator to send you a new password link.",
        "SETPASS.ERROR.EXPIRED": "Expired link! Please ask your administrator to send you a new password link.",
        "SETPASS.CONFIRM": "Confirm password",
        "SETPASS.SAVE": "Save", 
        "SETPASS.LENGTH": "Password must be at least 8 characters long.",
        "SETPASS.UPPERCASE": "Password must have at least one uppercase character.",
        "SETPASS.LOWERCASE": "Password must have at least one lowercase character.",
        "SETPASS.NUMBER": "Password must have at leaset one number.",
        "SETPASS.SPEC": (char)=>{
            if (char) return `Password must have at least one special character. Special characters include: ${char}`;
            else return 'Password must have at least one special character.'
            
        },
        "SETPASS.AGAIN_ERROR": "Passwords don't match",

    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "SETPASS.TITLE": "Postavite svoju lozinku",
        "SETPASS.ERROR": "Greška",
        "SETPASS.ERROR.NOUSER": "Poveznica koju ste pratili ovdje je nevažeća. Zatražite svojeg administratora novu poveznicu za izmjenu lozinke.",
        "SETPASS.ERROR.INVALID": "Poveznica koju ste pratili ovdje je nevažeća. Zatražite svojeg administratora novu poveznicu za izmjenu lozinke.",
        "SETPASS.ERROR.EXPIRED": "Poveznica koju ste pratili ovdje je istekla. Zatražite svojeg administratora novu poveznicu za izmjenu lozinke.",
        "SETPASS.CONFIRM": "Potvrdite lozinku",
        "SETPASS.SAVE": "Spremi",
        "SETPASS.LENGTH": "Lozinka mora sadržavati minimalno 8 znakova.",
        "SETPASS.UPPERCASE": "Lozinka mora sadržavati najmanje jedno veliko slovo.",
        "SETPASS.LOWERCASE": "Lozinka mora sadržavati najmanje jedno malo slovo.",
        "SETPASS.NUMBER": "Lozinka mora sadržavati najmanje jedan broj.",
        "SETPASS.SPEC": (char)=>{
            if (char) return `Lozinka mora uključivati specijalni znak. Podržani specijalni znakovi su: ${char}`;
            else return 'Lozinka mora uključivati specijalni znak.'
            
        },
        "SETPASS.AGAIN_ERROR": "Lozinke se ne podudaraju",

    }
};

export const set_pass_translations = [english, croatian];
