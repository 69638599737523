import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "SECTOR.NEW": "New Sector",
        "SECTOR.EDIT": "Edit Sector",
        "SECTOR.DESCRIPTION": "Description",
        "SECTOR.WHIER": "Work Hierarchy",
        "SECTOR.TYPE": "Type",
        "SECTOR.TYPE.ADMIN": "Administrators",
        "SECTOR.TYPE.SUPER_GROUP": "Superusers",
        "SECTOR.TYPE.AUTHORIZED_GROUP": "Authorized personnel",
        "SECTOR.TYPE.REGIONAL_MAINTENANCE_GROUP": "Maintenance",
        "SECTOR.TYPE.REGIONAL_RETAIL_GROUP": "Retail",

        "SECTOR.ERROR.DELETE.EXISTING_USERS": "Failed to delete sector because there are users assigned to it.",
        "SECTOR.DELETE.TITLE": "Sector deletion",
		"SECTOR.DELETE.MSG": (name) => `Are you sure you want to delete the sector ${name}?`,
        "SECTOR.COMPANY": 'Company',
        "SECTOR.ADD_NEW": "Create sector"
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "SECTOR.NEW": "Novi Sektor",
        "SECTOR.EDIT": "Uređivanje Sektora",
        "SECTOR.DESCRIPTION": "Opis",
        "SECTOR.WHIER": "Radna Hijerarhija",
        "SECTOR.TYPE": "Tip",
        "SECTOR.TYPE.ADMIN": "Administratori",
        "SECTOR.TYPE.SUPER_GROUP": "Superkorisnici",
        "SECTOR.TYPE.AUTHORIZED_GROUP": "Ovlaštene osobe",
        "SECTOR.TYPE.REGIONAL_MAINTENANCE_GROUP": "Održavanje",
        "SECTOR.TYPE.REGIONAL_RETAIL_GROUP": "Maloprodaja",

        "SECTOR.ERROR.DELETE.EXISTING_USERS": "Nije moguće obrisati sektor jer postoje korisnici koji mu pripadaju.",
        "SECTOR.DELETE.TITLE": "Brisanje sektora",
		"SECTOR.DELETE.MSG": (name) => `Da li ste sigurni da želite obrisati sektor ${name}?`,
        "SECTOR.COMPANY": 'Tvrtka',
        "SECTOR.ADD_NEW": "Dodaj sektor"

    }
};

export const sectors_translations = [english, croatian];
