import { network, Omit, cookies, noOp, modal } from 'curvy';
import { Structs, get_global } from '@task-utils/utils';
import { TaskRoutes } from '@task-utils/routes';
import { Task, DeepWriteable } from '@task-utils/types';
import { User } from '@task-modules/users';

const { Headers } = network;
type Paginator = network.Headers.Paginator;

export namespace Arrival {
	export async function open_arrival(arrival_id: number) {
		modal.open(get_global('ArrivalDialog'), noOp, (await get(arrival_id)).data);
	}

	export async function getAll(paginator?: Paginator, expand=true) {
		return await TaskRoutes.arrivals.api_arrival_get_all(paginator, expand);
	}

	export async function search(
		pagination: Paginator,
		filter: { search: string[], attributes: string[] },
		expand=true
	) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;

		if (search_len === 0 || attr_len === 0) {
			return getAll(pagination, expand);
		}

		return await TaskRoutes.arrivals.api_arrival_search(filter, pagination, expand);
	}

	export async function get(id: number) {
		return await TaskRoutes.arrivals.api_arrival_get_single(id, true);
	}

	export async function add(data: {
		order_id: Task.Order["order_id"];
		planned_start_time: Date;
		arrival_workers?: any[];
	}) {
		return await TaskRoutes.arrivals.api_arrival_add(data, false);
	}

	export async function modify(arrival: Task.Arrival) {
		return await TaskRoutes.arrivals.api_arrival_modify(arrival.arrival_id, arrival, false);
	}

	export async function remove(arrival_id: number) {
		return await TaskRoutes.arrivals.api_arrival_delete(arrival_id);
	}

	export async function qr_code(arrival_id: number) {
		return await (await fetch(
			TaskRoutes.arrivals.qr_code_create(arrival_id),
			{ headers: { "Auth-Token": User.currentToken } }
		)).blob();
	}
}
