import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Frame, language, DataTableComponent, GenericFilterComponent, template, modal, noOp } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Templates } from '@task-utils/utils';
import { User } from '@task-modules/users';
import { CreateEditPricelistDialog } from './create-edit-pricelist.dialog';
import { pricelist_translations } from './pricelist.trans';
import { ImportPricelistDialog } from './import-pricelist.dialog';

type TableType = DataTableComponent<Task.Pricelist, DisplayPricelist, 'pricelist', GenericFilterComponent>;

interface DisplayPricelist {
    [key: string]: any;
}

/**
    // TEMPORARILY REMOVED BUTTON FROM TOP OF COMPONENT
    <div class="temp-add-button">
        <button (click)="open_add()"
                class="filled pill"
                color="secondary">
            <i icon="add_circle"></i>
            <span>{{ 'PRICELIST.NEW' | trans }}</span>
        </button>
    </div>
**/

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'task-pricelists',
    template: `
        <knw-data-table #pricetable [config]="tableConfig">
        </knw-data-table>
    `,
    styles: [
        `task-pricelists {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }
        html [tooltip-right]:after {

            white-space: nowrap;
        }`
    ]
})
export class PricelistsComponent {
    @ViewChild('pricetable')
    table: TableType;
    company_id: number = null;

    constructor(private ar: ActivatedRoute) {
        Frame.set({
            title: "NAVROUTE.PRICELIST",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(pricelist_translations);
    }

    async get_company_id() {
        let id = this.ar.snapshot.paramMap.get("id");
        if (id != undefined) {
            this.company_id = +this.ar.snapshot.paramMap.get("id");
        } else {
            this.company_id = (await User.currentUserPromise).company_id;
        }
    }

    tableConfig: TableType["tableConfig"] = {
        tableName: "pricelist",
        getData: async (_filter, paginator) => {
            if (this.company_id == null) {
                await this.get_company_id();
            }

            return TaskRoutes.pricelists.api_pricelist_search({
                attributes: ["#=:company_id"],
                search: [`${this.company_id}`]
            }, paginator);
        },

        getHeader: (header) => {
            switch (header) {
                case 'el_type':
                    return { icon: "local_offer", label: "", sortable: false };

                case 'name':
                    return { icon: "request_quote", label: "PRICELIST.TITLE", sortable: false };

                case 'price':
                    return { icon: "paid", label: "PRICELIST.PRICE", sortable: false };
            };
        },

        unwrapData: (price) => {
            let name_template = "<b>{{name}}</b>";

            if (price.cin) {
                name_template += "<i>#{{cin}}</i>";
            }

            let price_template = "";

            if (price.price != undefined && price.currency != undefined) {
                price_template = "<b>{{ price }}{{ currency }}</b>"
            }

            if (price_template && price.unit_of_measure) {
                price_template += "<pre> / </pre>";
            }

            if (price.unit_of_measure) {
                price_template += "{{ unit_of_measure }}";
            }

            return {
                el_type: Templates.icon(Task.get_element_icon(price.element_type_id)),
                name: template.transform(`<div class="flex-column" style="text-align: left; width: 100%">${name_template}</div>`, price),
                price: template.transform(price_template, price),

            }
        },
        showHeaderWhenEmpty: true,
        tableActions: [{
            label:'Import',
            icon: 'publish',
            onClick: ()=>{
                modal.open(ImportPricelistDialog, (res)=>{
                    if(res) {
                        this.table.getData();
                    }
                }, this.company_id)
            },
            priority: 1,
            isVisible: () => User.is(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN)
        }],
        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (price) => {
                modal.open(
                    CreateEditPricelistDialog,
                    noOp, price, () => {
                        this.table.getData();
                    }
                );
            },
            priority: 1,
            isVisible: () => User.is(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN)
        }],
    };

    open_add() {
        modal.open(CreateEditPricelistDialog,
            () => {
                this.table.getData();
            }
        );
    }
}
