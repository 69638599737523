import {
   Component, Input, Output, EventEmitter,
   OnInit, OnChanges, ViewEncapsulation,
   ChangeDetectorRef, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, modal, ConfirmDialogComponent, noOp, progress, FormCollectorDirective } from 'curvy';
import { Order } from '@task-modules/orders/orders';
import { arrivals_translations } from '../../arrivals/arrivals.trans';
import { pricelist_translations } from '../../pricelists/pricelist.trans';
import { User } from '@task-modules/users/users';
import { ChangeOrderDialog } from '@task-modules/orders/change-order.dialog';
import { FinancialItemExtended } from '../order-single.component';
import { FinancialItemHistoryComponent } from '@task-modules/arrivals';
import { suppliers_translations } from '@task-modules/suppliers';
import { orders_translations } from '@task-modules/orders';

function is_responsible(user: Task.User, order: Task.Order) {
   return order.authorized_maintenance_managers.some(u => u.user_id === user.user_id)
};

function can_confirm(user: Task.User, order: Task.Order): boolean {

   switch (order.status_id) {
      case Task.STATUS.ON_SUPPLIER_REVIEW:
         if (user.company_id === order.supplier_id || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
         break;
      case Task.STATUS.ON_COMPANY_REVIEW:
         if (user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
         else if ((user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER || user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) && is_responsible(user, order)) return true;
         break;
      case Task.STATUS.EXECUTED:
      case Task.STATUS.INVOICED:
      default:
         return false;
   }
}

function can_edit(user: Task.User, order: Task.Order): boolean {

   switch (order.status_id) {
      case Task.STATUS.ON_SUPPLIER_REVIEW:
         if (
               user.company_id === order.supplier_id ||
               user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN
         ) return true;
         break;
      case Task.STATUS.ON_COMPANY_REVIEW:
         if
            (user.user_type_id === Task.USER_TYPE.ADMIN ||
            user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN
         ) return true;

         else if (
            (user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER ||
            user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) &&
            is_responsible(user, order)
         ) return true;
         break;
      case Task.STATUS.EXECUTED:
      case Task.STATUS.INVOICED:
      default:
         return false;
   }
}

@Component({
   selector: 'task-ping-pong',// api_get_all_arrivals_of_single_order
   encapsulation: ViewEncapsulation.None,
   templateUrl: './ping-pong.component.html',
   styleUrls: ['../order-single.component.scss']
})

export class FinancialDetailsComponent implements OnInit, OnChanges {
   @ViewChild('financeForm', { static: false }) financeForm: FormCollectorDirective;

   @Input() order: Task.Order;
   @Input() disable: boolean;

   original_fin_items_str: string = "";
   financial_items: FinancialItemExtended[] = [];

   arrivals: Record<number, Task.Arrival> = {};
   has_errors = false;

   allow_confirmation = false;
   can_edit_financial_details = false;
   has_completed_arrivals = false;

   _edited = false;


   @Output("editedChange")
   editedChanged = new EventEmitter<boolean>();

   @Input("edited")
   get edited() {
      return this._edited;
   }

   @Output("submitted")
   submitted = new EventEmitter();

   set edited(val: boolean) {
      if (this._edited == val) return;
      this._edited = val;
      this.editedChanged.emit(val);
   }

   data_before_edit: Task.Order

   items_to_approve_count = 0;

   /** ping pong props */
   headers: { id: number, name: string, icon: string, key: string, centered?: boolean, is_right?: boolean }[] = [
      { id: 0, name: '#', icon: '', key: 'row-number', centered: true },
      { id: 1, name: language.translate('ARRIVAL'), icon: '', key: 'arrival' },
      { id: 2, name: language.translate('ARRIVAL.DETAIL.GROUP'), icon: '', key: 'work_description' },
      { id: 3, name: language.translate("ARRIVAL.DETAIL.ELEMENT_NAME"), icon: '', key: 'element_name' },
      { id: 4, name: language.translate("ARRIVAL.DETAIL.ELEMENT_QUANTITY"), icon: '', key: 'element_quantity', is_right: true },
      { id: 5, name: language.translate("ARRIVAL.DETAIL.ELEMENT_PRICE"), icon: '', key: 'element_price', is_right: true },
      { id: 6, name: language.translate("ARRIVAL.DETAIL.ELEMENT_TOTAL"), icon: '', key: 'element_total_price', is_right: true },
      { id: 7, name: language.translate("ARRIVAL.DETAILS.LUMP"), icon: '', key: 'lump_sum', centered: true },
      { id: 8, name: '', icon: '', key: 'row-action-button' }
   ]


   constructor(private ar: ActivatedRoute, private cdr: ChangeDetectorRef) {
      console.log("Ping pong constructor");
      language.load_translations(arrivals_translations);
      language.load_translations(pricelist_translations);
      language.load_translations(suppliers_translations);
      language.load_translations(orders_translations);
   }

   check_if_item_is_new(item: FinancialItemExtended) {
      if (item.is_approved) return false;
      if (this.order.status_id !== Task.STATUS.ON_COMPANY_REVIEW && this.order.status_id !== Task.STATUS.ON_SUPPLIER_REVIEW) return false;

      let arrival = this.arrivals[item.arrival_id];
      let arrival_end = new Date(arrival.end_time);
      let detail_created = new Date(item.created_date);
      let item_changed = item.price_changed || item.quantity_changed || item.lump_sum_changed;

      return !item_changed && detail_created > arrival_end;
   }

   update(item:FinancialItemExtended) {
      let changed_price = (item.element_price + " ") + (item.element_currency || "");
      let changed_quantity = (item.element_quantity + " ") + (item.element_uom || "");

      let old_show_comment = item.show_comment;

      item.show_comment = changed_price != item.el_new_price ||
         changed_quantity != item.el_new_quantity ||
         item.lump_sum != item.el_new_lump_sum;

      // Ako se item.show_comment promijeni iz false -> true
      if (item.show_comment && !old_show_comment) {
         item.show_details = true;
      }

      if (!item.show_comment) {
         item.comment = "";
      }

      if (item.element_quantity === 0 ) {
         item.is_deleted = true;
      } else {
         item.is_deleted = false;
      }

      item.approved = !item.show_comment;

      item.is_item_new = this.check_if_item_is_new(item);

      if (!this.original_fin_items_str) this.original_fin_items_str = this.get_fin_items_str();
      this.edited = this.original_fin_items_str != this.get_fin_items_str();

      // this.itemChange.emit(this.item);
      this.cdr.detectChanges();
   }

   delayed_update(item:FinancialItemExtended) {
      setTimeout(() => {
         this.update(item);
      }, 250);
   }

   open_history(item: FinancialItemExtended) {
      modal.open(FinancialItemHistoryComponent, noOp, item);
   }

   single_sum(quantity: number, price: number, currency: string) {
      let total_price = price * quantity;
      return total_price.toLocaleString('hr', { style: 'currency', currency: currency || 'EUR' });
   }

   row_click(item: FinancialItemExtended) {
      item.show_details = !item.show_details;
   }

   ngOnInit() {
      if (this.order == null) {
         const order_id = this.ar.snapshot.paramMap.get("id");

         Order.get(+order_id).then(res => {
            this.order = res;

            Frame.set({
               title: language.translate("Financial details", order_id),
               visible: true,
               layout: "middle",
               size: "full"
            });

            this.update_values();
         });
      }

   }

   work_el_is_deleted(detail: FinancialItemExtended):'soft' | 'hard' {
      if (detail['is_deleted']) {
         return 'soft';
      } else if (detail.element_quantity == 0) return 'hard';

   }

   get_fin_items_str() {
      return JSON.stringify(
         this.financial_items.map(i => ({
            element_quantity: i.element_quantity,
            element_price: i.element_price,
            lump_sum: i.lump_sum,
            arrival_detail_id: i.arrival_detail_id
         })).sort((a,b)=>{
            return a.arrival_detail_id - b.arrival_detail_id;
         })
      );
   }

   revert_item(item:FinancialItemExtended) {
      item.element_price = item.element_old_price;
      item.element_quantity = item.element_old_quantity;
      item.lump_sum = item.old_lump_sum;

      this.update(item)
   }

   async update_values() {
      this.data_before_edit = JSON.parse(JSON.stringify(this.order));

      let user = await User.currentUserPromise;

      if (this.order) {
         let res = await TaskRoutes.orders.api_order_arrivals_get_all(this.order.order_id, { page_no: 1, page_size: 1000 }, true);
         this.arrivals = {};
         let arrival_details = [];

         this.allow_confirmation = can_confirm(user, this.order);

         for (let a of res.data) {
            this.arrivals[a.arrival_id] = a;

            a['icon_name'] =  Task.StatusIcons[a.status_id].icon;
            a['icon_color'] =  Task.StatusIcons[a.status_id].color;
            a['status_name'] = language.translate("STATUS."+ Task.Status[a.status_id]);

            arrival_details.push(...a.arrival_details);
         }

         this.financial_items = arrival_details.filter(ad => {
            return ad.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT &&
               ad.arrival_status_id !== Task.STATUS.DELETED;
         }).map((d: FinancialItemExtended)=>({
            ...d,
            is_edited: false,
            is_deleted: false,
            is_approved: false,
            el_new_price: "",
            el_old_price: "",
            price_changed: false,
            el_new_quantity: "",
            el_old_quantity: "",
            quantity_changed: false,
            el_new_lump_sum: false,
            el_old_lump_sum: false,
            lump_sum_changed: false,
            show_comment: false,
            change_comment: "",
            show_details: false
         } satisfies FinancialItemExtended));

         this.items_to_approve_count = this.financial_items.filter(i => !i.approved).length;

         this.has_completed_arrivals = res.data.some(a => a.status_id == Task.STATUS.EXECUTED);

         for (let item of this.financial_items) {
            let old_price = item.element_old_price;
            let new_price = item.element_price;
            let price_changed = old_price != null && old_price != new_price;

            item.el_old_price = String(old_price) + " " + (item.element_currency || "");
            item.el_new_price = String(new_price) + " " + (item.element_currency || "");
            item.price_changed = price_changed;

            let old_quantity = item.element_old_quantity;
            let new_quantity = item.element_quantity;
            let quantity_changed = old_quantity != null && old_quantity != new_quantity;

            item.el_old_quantity = String(old_quantity) + " " + (item.element_uom || "");
            item.el_new_quantity = String(new_quantity) + " " + (item.element_uom || "");
            item.quantity_changed = quantity_changed;

            let old_lump_sum = item.old_lump_sum;
            let new_lump_sum = item.lump_sum;
            let lump_sum_changed = old_lump_sum != null && old_lump_sum != new_lump_sum;

            item.el_old_lump_sum = item.old_lump_sum;
            item.el_new_lump_sum = item.lump_sum;
            item.lump_sum_changed = lump_sum_changed;

            if (price_changed || quantity_changed || lump_sum_changed) {
               item.change_comment = item.comment;
            }

            item.old_lump_sum = item.lump_sum;
            item.element_old_price = item.element_price;
            item.element_old_quantity = item.element_quantity;

            item.comment = "";
            item.is_approved = item.approved;
            this.update(item);
            this.delayed_update(item);
         }

         this.financial_items.sort((a,b)=>{
            const A_BEFORE_B = -1;
            const B_BEFORE_A = 1;

            let a_deleted = a.element_quantity == 0;
            let b_deleted = b.element_quantity == 0;

            if (a_deleted && !b_deleted) return B_BEFORE_A;
            if (!a_deleted && b_deleted) return A_BEFORE_B;

            let a_changed = a.price_changed || a.quantity_changed || a.lump_sum_changed;
            let b_changed = b.price_changed || b.quantity_changed || b.lump_sum_changed;

            if (a_changed && !b_changed) return A_BEFORE_B;
            if (!a_changed && b_changed) return B_BEFORE_A;

            return a.element_name.localeCompare(b.element_name);
         });

         this.original_fin_items_str = this.get_fin_items_str();

         this.can_edit_financial_details = can_edit(user, this.order);

      } else {
         this.financial_items = [];
      }
   }

   sum() {
      let total = 0;

      for (let item of this.financial_items) {
         if (item.lump_sum) continue;
         if (item.arrival_status_id == Task.STATUS.DELETED) continue;
         if (item.arrival_detail_type_id !== Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) continue;

         total += Math.round((item.element_price * item.element_quantity) * 100) / 100;
      }

      return total;
   }

   show_work_element_type = (detail_type: Task.ELEMENT_TYPE) => {
      switch(detail_type) {
         case Task.ELEMENT_TYPE.WORKING_HOUR:
            return language.translate(`PRICELIST.TYPE.${Task.Element_Type[1]}`);
         case Task.ELEMENT_TYPE.MATERIAL:
            return language.translate(`PRICELIST.TYPE.${Task.Element_Type[2]}`);
         case Task.ELEMENT_TYPE.TRANSPORTATION:
            return language.translate(`PRICELIST.TYPE.${Task.Element_Type[3]}`);
         case Task.ELEMENT_TYPE.FIELD_VISIT:
            return language.translate(`PRICELIST.TYPE.${Task.Element_Type[4]}`);
         case Task.ELEMENT_TYPE.MECHANICAL_WORKS:
            return language.translate(`PRICELIST.TYPE.${Task.Element_Type[5]}`);
         default:
            return '-';
      }
   }


   // PING_PONG
   async send_confirmation() {
      let user = await User.currentUserPromise;
      let updates = [];
      let changed = 0;

      for (let item of this.financial_items) {

         let old_price = item.element_old_price;
         let new_price = item.element_price;
         let price_changed = old_price !== null && old_price != new_price;
         let old_quantity = item.element_old_quantity;
         let new_quantity = item.element_quantity;
         let quantity_changed = old_quantity !== null && old_quantity != new_quantity;
         let old_lump_sum = item.old_lump_sum;
         let new_lump_sum = item.lump_sum;
         let lump_sum_changed = old_lump_sum !== null && old_lump_sum != new_lump_sum;

         if (price_changed || quantity_changed || lump_sum_changed) changed++;

         updates.push(item);
      }

      let canApprove = is_responsible(user, this.order);
      let isCompanyUser = this.order.status_id === Task.STATUS.ON_COMPANY_REVIEW && user.company_id === this.order.company_id && canApprove;
      let isAdmin = user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN;

      let allChanged = changed == this.items_to_approve_count;

      let promise: Promise<any>;
      if ((isCompanyUser || isAdmin) && !changed) {
         // console.log("modal")
         modal.open(ChangeOrderDialog, async (order) => {
            if (order) {
               promise = TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });

                await progress.listen('edit-financial-detilas-save', promise);
               this.update_values();
               this.submitted.emit();


            }
         }, this.order);
      } else {
         // console.log("ne modal")
         if (allChanged || this.items_to_approve_count == 1) {
            promise = TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });

            await progress.listen('edit-financial-detilas-save', promise);
            this.update_values();
            this.submitted.emit();


         } else {
            modal.open(ConfirmDialogComponent, async (succ) => {
               if (succ) {
                  promise = TaskRoutes.orders.api_order_send_on_review(this.order.order_id, { arrival_details: updates });

                  await progress.listen('edit-financial-detilas-save', promise);
                  this.update_values();
                  this.submitted.emit();

               }
            }, {
               title: language.translate("FINANCIAL.CONFIRM_TITLE", this.items_to_approve_count - changed),
               message: language.translate("FINANCIAL.CONFIRM_TEXT")
            })
         }
      }


   }


    cancel_order() {

      if (this.edited && this.data_before_edit) {
            this.order = this.data_before_edit;
            this.edited = false;
            this.update_values();
        }

    }

     ngOnChanges() {
         this.update_values()
    }




}
