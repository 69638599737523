import { Component, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Frame, language, TableConfig, network, GenericFilter, noOp, template, modal, GenericFilterComponent, DataTableComponent, date, utils, ConfirmDialogComponent, color } from 'curvy';
import { Task } from '@task-utils/types';
import { Structs } from '@task-utils/utils';
import { CreateTicketDialog } from '@task-modules/tickets/create-ticket/create-ticket.dialog';
import { Ticket } from './tickets';
import { tickets_translations } from './tickets.trans';
import { orders_translations } from '@task-modules/orders/orders.trans';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';


type TableType = DataTableComponent<Task.Ticket, DisplayTicket, 'tickets', GenericFilterComponent>;

@Component({
    selector: 'task-tickets',
    encapsulation: ViewEncapsulation.None,
    template: `
        <div class="temp-add-button">
            <button (click)="openAdd()"
                    class="filled pill"
                    color="primary">
                <i icon="add_circle"></i>
                <span>{{ 'TICKET.NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #tickettable [config]="tableConfig">
        </knw-data-table>`,
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent {
    @ViewChild('tickettable') table: TableType;

    currUser: Task.User = null;
    statuses = [
        { label: "STATUS.CREATED", value: Task.STATUS.CREATED },
        { label: "STATUS.APPROVED", value: Task.STATUS.APPROVED },
        { label: "STATUS.REJECTED", value: Task.STATUS.REJECTED },
        { label: "STATUS.IN_EXECUTION", value: Task.STATUS.IN_EXECUTION },
        { label: "STATUS.EXECUTED", value: Task.STATUS.EXECUTED },
        { label: "STATUS.ON_HOLD", value: Task.STATUS.ON_HOLD }
    ];

    constructor(private el_ref: ElementRef) {
        Frame.set({
            title: "NAVROUTE.TICKETS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(tickets_translations);
        language.load_translations(orders_translations);
    }

    openAdd() {
        modal.open(CreateTicketDialog, () => {
            this.table.getData();
        });
    }

    async search_companies(search: string) {
        let res = await TaskRoutes.companies.api_company_search(
            { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
            { page_no: 1, page_size: 10 }
        )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
        if (!comp) return "";
        return comp.name;
   }

    get_filters = async () => {
        let filters: GenericFilterComponent["filterInput"] = [
            GenericFilter.text("TICKET.TITLE", "ticket"),
            GenericFilter.search("LOCATION", "location", this.search_locations, this.format_location_name, true),
            GenericFilter.search("NAVROUTE.LOCATION_GROUPS", "loc_groups", this.search_location_groups, this.format_location_group_name),
            GenericFilter.search("STATUS", "status_id", this.serach_statuses, this.format_status_name, undefined, undefined, undefined, true, true),
            GenericFilter.toggle("TICKET.REFINEMENT_REASON", "on_refinement"),
            GenericFilter.date_filter("START_CREATED_DATE", "created_date_from"),
            GenericFilter.date_filter("END_CREATED_DATE", "created_date_to"),
            GenericFilter.dropdown("PRIORITY", "priority_id", [
                { label: "PRIORITY.HIGH", value: Task.PRIORITY.HIGH },
                { label: "PRIORITY.MEDIUM", value: Task.PRIORITY.MEDIUM },
            ]),
            // GenericFilter.text("USER.RESPONSIBLE", "responsible_users.first_name+ +responsible_users.last_name"),
            GenericFilter.toggle("ORDER.RECLAMATION", "b=:complaint"),
        ];

        if (await User.currentTypeIs(
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER,
                Task.USER_TYPE.AUTHORIZED_PERSONEL,
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
                Task.USER_TYPE.STORE_MANAGER)
        ) {
            let default_value = "me";

            if (await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.MANAGER_1, Task.USER_TYPE.MANAGER_2)) {
                default_value = null;
            }

            if (await User.currentTypeIs(Task.USER_TYPE.SAFETY_MANAGER, Task.USER_TYPE.AUTHORIZED_PERSONEL, Task.USER_TYPE.STORE_MANAGER)) {
                let default_value_for_created = "me";

                if (await User.currentTypeIs(Task.USER_TYPE.STORE_MANAGER)) {
                    default_value_for_created = null;
                }
                filters.push(
                    GenericFilter.dropdown("CREATED_BY", "created", [
                        { label: "CREATED_BY_ME", value: "me" },
                        { label: "CREATED_BY_OTHERS", value: "others" }
                    ], default_value_for_created)
                );

            } else {
                filters.push(
                    GenericFilter.search("ASSIGNED", "assigned", this.search_users, this.format_users, true, default_value)
                );

                filters.push(
                    GenericFilter.dropdown("CREATED_BY", "created", [
                        { label: "CREATED_BY_ME", value: "me" },
                        { label: "CREATED_BY_OTHERS", value: "others" }
                    ], null)
                );
            }
        }

        if(await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)){
            filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

        }

        return filters;
    }

    ticket_icon(ticket: Task.Ticket): Structs.Icon[] {
        let icons: Structs.Icon[] = [Task.StatusIcons[ticket.status_id]];
        let priority_id = ticket.priority_id;
        // if (ticket.on_refinement) {
        //     return Structs.icon("restore_page", "#f55");
        // }

        if (ticket.on_refinement) {
            icons.push(Structs.icon("restore_page", "#f55"))
        }

        if (priority_id == Task.PRIORITY.HIGH) {
            icons.push(Task.PriorityIcons[priority_id])
        }
        return icons;
        // return Task.StatusIcons[ticket.status_id];
    }

    ticket_status_name(ticket: Task.Ticket):string[] {
        let status_names = ["STATUS." + Task.Status[ticket.status_id]];

        if (ticket.on_refinement) {
            status_names.push("TICKET.REFINEMENT_REASON");
        }

        return status_names;
    }

    async search_users(search: string) {
        let wanted_types = [
                Task.USER_TYPE.SAFETY_MANAGER + "",
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER + "",
                Task.USER_TYPE.REGIONAL_RETAIL_MANAGER + ""
        ];
        let unwanted_types = Object.keys(Task.User_Type).filter(u => !wanted_types.includes(u));

        let res = await TaskRoutes.users.api_user_search({
            search: [...unwanted_types, search],
            attributes: [...unwanted_types.map(() => "!#:user_type_id"), "first_name+ +last_name"]
        }, {
            page_no: 1,
            page_size: 25
        })

        return ["me", "others", ...res.data]

    }

    format_users(user: Task.User | "others" | "me") {
        if (!user) return;

        let name = "";

        if (typeof(user) == "string") {
            name = user === "me" ? language.translate("BY_ME") : language.translate("BY_OTHERS");
        } else {
            name = `${user.first_name} ${user.last_name}`;
        }

        return name;
    }

    serach_statuses = async (search)=> {
        return this.statuses;
    }

    format_status_name(status) {
        if (!status) return;

        return language.translate(status.label);
    }

    tableConfig: TableType["tableConfig"] = {
        tableName: 'tickets',
        getData: (filter, paginator, sort_header) => {
            return User.getCurrent().then(async(res: Task.User) => {
                let parsed_filter = { search: [], attributes: [], sort: null };
                this.currUser = res;

                for (let key in filter) {
                    if(key== "ticket") {
                        let re_num = /^[0-9]+$/;
                        let is_id = filter[key].match(re_num);
                        let attr = is_id ? '#=:ticket_id' : 'ticket_id+ +title';
                        parsed_filter.attributes.push(attr);
                        parsed_filter.search.push(filter[key]);

                    } else if (key === 'company' && filter['company'] !== null) {
                        parsed_filter.attributes.push('#=:company_id')
                        parsed_filter.search.push(filter[key].company_id)

                    } else if (key == "created_date_from") {
                        let start = date.start_of_day(filter[key]);
                        parsed_filter.attributes.push("-d:created_date");
                        parsed_filter.search.push(start.toISOString());

                    } else if (key == "created_date_to") {
                        let end = date.end_of_day(filter[key]);
                        parsed_filter.attributes.push("+d:created_date");
                        parsed_filter.search.push(end.toISOString());

                    } else if (key == "location" && filter["location"] !== null) {
                        parsed_filter.attributes.push("#=:location_id");
                        parsed_filter.search.push(filter.location.location_id);

                    } else if (key == "loc_groups") {
                        parsed_filter.attributes.push("#=:loc_groups.location_group_id");
                        parsed_filter.search.push(filter.loc_groups.location_group_id);

                    } else if (key == "assigned" && filter["assigned"] !== null) {
                        parsed_filter.attributes.push("=:assigned_to");
                        parsed_filter.search.push(filter[key].user_id ?? filter[key]);

                    } else if (key === "created" && filter["created"] !== null) {
                        if(filter["created"]=== "me") {
                            parsed_filter.attributes.push("#=:created_by_id");
                            parsed_filter.search.push(this.currUser.user_id);

                        } else {
                            parsed_filter.attributes.push("!#:created_by_id");
                            parsed_filter.search.push(this.currUser.user_id);

                        }
                    } else if (key == "status_id") {
                        if (!filter[key] || !filter[key].length) continue;

                        let target_statuses = filter[key] as typeof this.statuses;
                        let exclude_statuses = Object.keys(Task.Status).filter(s=>!target_statuses.some(t=>t.value == +s));

                        for (let status of exclude_statuses) {
                            parsed_filter.attributes.push("#!:status_id");
                            parsed_filter.search.push(status);
                        }
                    } else if (key == "on_refinement") {
                        parsed_filter.attributes.push("on_refinement");
                        parsed_filter.search.push(filter[key]);

                    } else if ( key == "status_id") {
                        parsed_filter.attributes.push("#=:status_id");
                        parsed_filter.search.push(filter[key]);

                    }
                    else {
                        parsed_filter.attributes.push(key);
                        parsed_filter.search.push(filter[key]);
                    }
                }

                if (sort_header) {
                    switch (sort_header.label) {
                        case 'title':
                            sort_header.label = '#:ticket_id';
                            break;

                        case 'whier':
                            sort_header.label = 'work_hierarchy.category_description';
                            break;
                   }
                    parsed_filter.sort = sort_header;
                }

                parsed_filter.sort = sort_header;
                return await Ticket.search(paginator, parsed_filter);
            });
        },
        getHeader: (header) => {
            switch (header) {
                case 'status':
                    return { icon: "offline_bolt", label: "", sortable: false };

                case 'title':
                    return { icon: "receipt", label: "TICKET.TITLE", sortable: true };

                case 'details':
                    // numbers in strings don't sort correctly so we are skipping it for now
                    return { icon: "details", label: "DETAILS", sortable: false };

                case 'whier':
                    return { icon: "account_tree", label: "NAVROUTE.WORKHIER", sortable: true };

                case 'responsible':
                    return { icon: "person_pin", label: "TICKET.RESPONSIBLE", sortable: false };

                case 'assigned':
                    return { icon: "assignment", label: "TICKET.ASSIGNED", sortable: false };
            }
        },
        unwrapData: (ticket) => {

            let status_names = this.ticket_status_name(ticket).map(language.translate).map(t=>`<span style="display: block;">${t}</span>`).join("");
            let icons = this.ticket_icon(ticket);

            let status_icon = "";
            for (let icon of icons) {
                status_icon += `<i style="color: ${icon.color}" icon="${icon.icon}"></i>`
            }

            return {
                status: template.transform(`
                    <div class="flex-row align-center justify-center">
                       ${status_icon}
                    </div>
                    <div style="font-size: 0.8em">
                        ${status_names}
                    </div>
                    <div style="font-size: 0.8em">
                        {{ relative | date << R }}
                    </div>
                `, {
                    relative: ticket.status_date || ticket.created_date
                }),
                title: template.transform(`
                    <span style="font-size: 0.8em">
                        {{ created_date | date }}
                    </span>
                    <span style="color: rgb(var(--primary))">
                        <b>#{{id}}</b> <span class="truncate-text">{{ title }}</span>
                    </span>
                    <span style="font-size: 0.8em">
                        ${language.translate('NAVROUTE.ORDERS')}: {{ order_count }}
                    </span>
                `, {
                    id: ticket.ticket_id,
                    created_date: ticket.created_date,
                    title: ticket.title,
                    order_count: ticket.associated_orders_count || "0"
                }),
                whier: template.transform(`
                    <i style="max-width: 30ch; text-transform: uppercase;">{{ desc }}</i>
                `, { desc: ticket["whier"]["category_description"] }),
                responsible: template.transform(`
                        <div>{{ persons }}</div>
                `, {
                    persons: ticket.responsible_users.length ? ticket.responsible_users.map(u => `${u.first_name} ${u.last_name}`).join(', ') : ''
                }),
                assigned: ticket.authorized_maintenance_managers.length ? ticket.authorized_maintenance_managers.map((auth) => `${auth.first_name} ${auth.last_name}`).join(', ') : ''
                ,
                details: template.transform(`
                    <div class="flex-column">
                        <b>{{ location }}</b>
                        <span>{{ created_by }}</span>
                    </div>
                `, {
                    location: ticket.location_name,
                    created_by: `${ticket.created_by_first_name} ${ticket.created_by_last_name}`
                })
            };
        },
        showHeaderWhenEmpty: true,
        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [
            {
                label: language.translate('EDIT'),
                icon: "edit",
                onClick: (ticket) => {
                    utils.router.navigateByUrl("/tickets/" + ticket.ticket_id);
                },
                priority: 1,
                isVisible: () => true
            },
            {
                label: language.translate("DELETE"),
                icon: "delete",
                color: "warn",
                onClick: (ticket) => {
                    modal.open(ConfirmDialogComponent,async (succ) =>{
                        if(succ) {
                            await Ticket.remove(ticket.ticket_id)
                            this.table.getData();
                        }
                    },
                    {
                        title: language.translate("TICKET.DELETE.TITLE"),
                        message: language.translate("TICKET.DELETE.CONTENT", ticket.ticket_id, ticket.title),
                        icon: "receipt",
                        iconColor: color.variable(color.Variable.warn)
                    });
                },
                priority: 2,
                isVisible: async (ticket)=> {
                    return (ticket.created_by_id === this.currUser.user_id || await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)) && (ticket.active_orders_count == 0)
                }
            }
        ],
        filterComponent: GenericFilterComponent,
        filterInput: this.get_filters
    }

    async search_locations(search: string) {
        let res = await TaskRoutes.locations.api_location_search_visible(
            { search: [search], attributes: ["cin+ +name"] },
            { page_no: 1, page_size: 50 }
        );
        return res.data;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
    }

    async search_location_groups(search: string) {
        let res = await TaskRoutes.location_groups.api_location_groups_search_visible({
            search: [search],
            attributes: ["name"]
        }, {
            page_no: 1,
            page_size: 10
        }, false)

        return res.data;
    }

    format_location_group_name(loc: Task.LocationGroup) {
        if (!loc) return "";
        return `${loc.name}`
    }

}

interface DisplayTicket {
    status: template.EscapedHTML;
    title: template.EscapedHTML;
    whier: template.EscapedHTML;
    details: template.EscapedHTML;
    responsible: template.EscapedHTML;
    assigned: string;
}
