import {
	Component, OnInit, OnChanges, SimpleChanges,
	Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Task } from '@task-utils/types';
import { language, ModalBase } from 'curvy';
import { WorkHierarchy } from '@task-modules/work-hierarchy';
import { work_groups_translations } from './work-descriptions.trans';

type WorkDescriptionType = Task.WorkDescription & {automatically_update_arrival_details? : boolean};
@Component({
	selector: 'task-create-edit-work-description',
	templateUrl: './create-edit-work-description.component.html',	styleUrls: ['./create-edit-work-description.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class CreateEditWorkDescriptionComponent {
	constructor() {
		language.load_translations(work_groups_translations);
	}

	@Input()
	workgroup: WorkDescriptionType = {
		work_description: "",
		work_description_id: null,
		automatically_update_arrival_details: false
   };

   is_edit_form = false;

	@Output('result')
	result: EventEmitter<WorkDescriptionType> = new EventEmitter();



	ngOnInit() {
		language.load_translations(work_groups_translations);
		this.is_edit_form = this.workgroup.work_description_id !== null;
		console.log(this.workgroup)
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["workgroup"]) {
			this.workgroup = {...changes["workgroup"].currentValue};
		}

	}

	done() {
		this.result.emit(this.workgroup);
	}

	cancel() {
		this.result.emit(null);
	}
}
