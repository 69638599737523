import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';

@Component({
    selector: 'task-arrival-dialog',
    template: `
        <task-arrival [arrival]="arrival"></task-arrival>
    `
})
export class ArrivalDialog extends ModalBase<Task.Arrival, any> implements OnInit {
    arrival: Task.Arrival;

    ngOnInit() {
        this.arrival = this.injectedData;
    }
}
