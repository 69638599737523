<ng-container *ngIf="order">

<div class="flex-row align-center wrappable" style="margin-bottom: 0.25em; gap: .7em;">
    <task-back-button></task-back-button>
    <h2 style="margin: 0 0 0 0.5ch">{{ 'FINANCIAL.TITLE' | trans }}</h2>

    <div class="flex-row wrappable align-center" style="margin-left: auto; gap: .7em;">
        <button *ngIf="has_completed_arrivals" class="pill filled"
            color="secondary"
            (click)="download_by_store_approval()">
            <i icon="picture_as_pdf"></i>
            <span>{{ 'FINANCIAL.PDF_BY_LOCATION' | trans }}</span>
        </button>
        <button *ngIf="has_completed_arrivals" class="pill filled"
            color="secondary"
            (click)="download_all()">
            <i icon="picture_as_pdf"></i>
            <span>{{ 'FINANCIAL.PDF_ALL' | trans }}</span>
        </button>
        <button class="pill filled"
            color="secondary"
            [title]="'PRICELIST.DOWNLOAD' | trans "
            (click)="download_pricelist()">
            <i icon="file_download"></i>
            <span>{{ 'PRICELIST.DOWNLOAD' | trans }}</span>
        </button>
    </div>
</div>

<div class="flex-dynamic" style="overflow: auto; padding-top: 2em;" form-collector #financeForm="collector">
    <task-edit-financial-item *ngFor="let financial_item of financial_items"
        [arrival]="arrivals[financial_item.arrival_id]"
        [item]="financial_item"
        (itemChange)="detectErrors(financeForm)"
        [can_edit_financial_details]="can_edit_financial_details">
    </task-edit-financial-item>
</div>

<div class="flex-static flex-row justify-space-between" style="margin: 0.5em; font-size: 150%">
    <div>
        <b>TOTAL: {{ sum() }}</b>
        <p style="font-size: 78%; margin: 0.35em 0;">{{ 'FINANCIAL.TAX_DISCLAIMER' | trans}}.</p>
    </div>
    <button class="filled"
            color="secondary"
            (click)="send_confirmation()"
            [disabled]="has_errors || !allow_confirmation || !can_edit_financial_details">
        {{ 'CONFIRMATION' | trans }}
    </button>
</div>

</ng-container>
