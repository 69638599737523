import { Component } from '@angular/core';
import { ModalBase } from 'curvy';

type InType = {
	title: string;
	content: string;
};

@Component({
    selector: 'confirm-textbox-dialog',
    template: `
		  <h2 class="flex-static modal-title">
				{{ injectedData.title | trans }}
				</h2>

			<div style="margin-bottom: 2em">
				{{ injectedData.content | trans }}
				</div>

			<div class="flex-row justify-flex-end modal-footer">
				<button class="" color="warn" (click)="done()">
					{{ 'YES' | trans }}
				</button>

				<button class="" color="primary" (click)="cancel()">
					<span>{{ 'NO' | trans }}</span>
				</button>

			</div>
    `
})

export class ConfirmDialog extends ModalBase<InType, any> {
    preventSoftClose = true;

	done() {
		this.submit(true);
	}

	cancel() {
		this.submit(false);
	}
}
