import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalBase, language, network } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { files } from '@task-utils/utils';
import { AccordionListComponent } from '@task-components/task-accordion-list.comopnent';

interface InternalError {
    error_argument: string;
    internal_error_code: number;
    status: string;
};
function is_internal_error(obj: any): obj is InternalError {
    return "error_argument" in obj && "internal_error_code" in obj && "status" in obj;
}

@Component({
    selector: 'task-import-pricelist-dialog',
    template: `
        <h2 class="modal-title" style="margin-bottom: 1.2em;">{{ "PRICELIST.DIALOG_TITLE" | trans }}</h2>
        
        <details style="box-shadow: none; grid-column: 1 / -1; padding-left: 0; padding-right: 0; border-bottom: 1px solid rgba(var(--background-dark));  margin-top: 0px; ">
            <summary stlye="">
                <h3 style="margin-bottom: 0.3em; font-size: .89em;" class="uppercase flex-row align-center">
                    <i icon="info_outline" style="font-size: .7em;5 display: inline-block; margin-right: .5em;"></i>
                    <span>Pravila za uređivanje troškovnika</span>
                </h3>
            </summary>
             <ul>
                <li>zabranjeno brisanje kolona</li>
                <li>zabranjeno dodavanje novih kolona</li>
                <li>zabranjeno skrivanje kolona</li>
                <li>kolone označene sa * su obavezne za popuniti (uključujući i Grupu radova). Nećemo moći učitati cjenike u slučaju izmjena dok Grupa radova nije popunjena)</li>
                <li>određene kolone imaju dropdown vrijednost (Tip, Jedinica mjere, Paušalni obračun, Kvalifikacija radnika) - moraju se odabrati s popisa, ne dodavati ništa dodano niti uklanjati predefinirane vrijednosti</li>
                <li>kolonu kvalifikacija radnika popuniti samo za stavke koje se odnose na radnika</li>
                <li>Nove stavke slobodno dodajete na kraj ili umetnite red gdje vam odgovara (dodati novi red, zabranjeno kopirati postojeći)</li>
                <li>Postojeće možete ažurirati po potrebi</li>
             </ul>
        </details>   

        <task-accordion-list [fn]="get_edited_pricelist" style=" top: -8px; position: relative;" >
            <h3 summary style="margin-bottom: 0.3em; font-size: .89em;" class="uppercase flex-row align-center">
                <i icon="history" style="font-size: .75em; display: inline-block; margin-right: .5em;"></i>
                <span>{{'PRICELIST.SHOW_HISTORY' | trans }}</span>
            </h3>

            <ng-template let-pr>
                <div li_element class="list-el flex-row justify-space-between">
                    <span>{{pr.user.first_name}} {{pr.user.last_name}}</span>
                    <span>{{pr.created_date | datefmt: 'short_date' }}</span>
                    <button class="round" title="{{ 'PRICELIST.DOWNLOAD' | trans }}" (click)="get_edited_file(pr.table)"><i icon="download"></i></button>
                </div>
            </ng-template>
        </task-accordion-list>
        
        <div form-collector #pricelistImportForms="collector" style="">
            <div *ngIf="!msgs.showMsg" class="flex-row justify-space-around" style="margin-top: 1.5em;">
                <button class="filled"
                    color="secondary"
                    (click)="download()"
                    [disabled]="pricelistImportForms.containsErrors()">
                    <i icon="file_download"></i>
                    <span>{{ 'PRICELIST.DOWNLOAD' | trans }}</span>
                </button>
                <button class="filled"
                    color="primary"
                    (click)="getFile()"
                    [disabled]="pricelistImportForms.containsErrors()">
                    <i icon="file_upload"></i>
                    <span>{{ 'PRICELIST.IMPORT' | trans }}</span>
                </button>
            </div>
        </div>

        <div *ngIf="msgs.showMsg" class="info-msg" style="">
            <p style="color:rgb(var(--{{msgs.msg_color}})); white-space: pre-wrap;">{{ msgs.msg }}</p>
        </div>
        
        <div class="flex-row flex-static justify-end modal-footer">
            <button color="primary"
                    (click)="cancel()">
                <i icon="clear"></i>
                <span>{{ 'CLOSE' | trans }}</span>
            </button>
        </div>
       
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./pricelists.scss']
})
export class ImportPricelistDialog extends ModalBase<number, any> {
    @ViewChild(AccordionListComponent)
    accordion: AccordionListComponent;

    preventSoftClose = true;
    file: files.IFile [];
    // showImportBtn = false;
    msgs = {
        msg: language.translate("PRICELIST.SUCCESS"),
        showMsg: false,
        msg_color: 'secondary-dark'
    };
    

    supplier_id:number;

    ngOnInit() {
        this.supplier_id = this.injectedData;
    }

    get_edited_file(filename: string) {
        return TaskRoutes.upload.api_uploaded_file_get(filename, true);
    }
    
    get_edited_pricelist = async (paginator:network.Headers.Paginator) => {
        return await TaskRoutes.pricelists.api_list_pricelists_edits({
            attributes: ["#=:supplier_id"],
            search: [`${this.supplier_id}`],
            sort: [
                {
                    attribute: "d:created_date",
                    direction: "desc"
                }
            ]
        }, paginator);
    }

    async download() {
        await TaskRoutes.pricelists.api_pricelist_xlsx_generate(this.supplier_id, true);
        this.cancel();
    }

    async getFile() {
        this.file = await files.get_files({
            multiple: false,
            accept: ".xlsx"
        })

        if (this.file) {
            try {
                await TaskRoutes.pricelists.api_upload_edited_pricelist(this.supplier_id, this.file[0].file);
                this.msgs.msg_color = 'secondary-dark';
                this.msgs.showMsg = true;
                this.accordion.get_data();

            } catch(error) { 
                // console.log(error)
                
                this.msgs.showMsg = true;
                this.msgs.msg_color = 'warn';
                if (is_internal_error(error)) {
                    this.msgs.msg = language.translate("PRICELIST.ERRORS");
                } else {
                    this.msgs.msg = language.translate("PRICELIST.ERRORS.DEFAULT") + error.data.join(', ');
                }

            }
        }
    }

    cancel() {
		this.submit(null);
	}

    
}
