import { Component } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

@Component({
    selector: 'task-create-edit-pricelist-dialog',
    template: `
        <task-create-edit-pricelist [pricelist]="injectedData"
                (result)="handle_result($event)">
        </task-create-edit-pricelist>
    `
})
export class CreateEditPricelistDialog extends ModalBase<any, any> {
    preventSoftClose = true;

    handle_result(pricelist: Task.Pricelist) {
        if (pricelist) {
            let prom;

            if (pricelist.cin === "") {
                pricelist.cin = null;
            }

            if (pricelist.element_id == undefined) {
                prom = TaskRoutes.pricelists.api_pricelist_element_add(pricelist);
            } else {
                prom = TaskRoutes.pricelists.api_pricelist_element_modify(pricelist.element_id, pricelist);
            }

            prom.then(res => {
                this.submit(pricelist);
            });
        } else {
            this.submit(pricelist);
        }
    }
}
