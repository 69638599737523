import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { language, popover, noOp, date } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { ScheduledOrderTemplateType } from '../orders.types';
import { Order } from '../orders';
import { ScheduledDates } from '@task-components/scheduled-dates';
import { User } from '@task-modules/users/users';


@Component({
	selector: 'task-scheduled-order-form',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'scheduled-order-form.component.html',
	styles: [`
		new-scheduled-order-plan task-scheduled-order-form {
			max-width: none;
		}

		new-scheduled-order-plan task-scheduled-order-form .textarea {
			grid-column: auto;
		}

		new-scheduled-order-plan task-scheduled-order-form .field-grid {
			align-items: end;
		}

		@media only screen and (min-width: 1400px) {
			new-scheduled-order-plan task-scheduled-order-form .field-grid {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 8px 10px;
			}

			new-scheduled-order-plan task-scheduled-order-form .field-grid .grid-el-one {
				grid-column: 1/3;
			}
			new-scheduled-order-plan task-scheduled-order-form .field-grid .grid-el-two-thirds {
				grid-column: -1/3;
			}

		}

		task-edit-scheduled-order-dialog task-scheduled-order-form .field-grid {
			grid-template-columns: 1fr;
		}


		.special-box label,
		label.special-box {
			width: 100%;
			box-sizing: border-box;
			margin-left: 0!important;
			margin-right: 0!important;
		}

		task-scheduled-order-form {
			max-width: 650px;
		}

		@media only screen and (min-width: 600px) {
			task-scheduled-order-form .field-grid {
				min-width: 400px;
				grid-gap: 8px 10px;

			}
		}

		task-scheduled-order-form .field-grid {
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr;
			grid-gap: 4px 10px;
		}

		task-scheduled-order-form knw-search-picker {
			display: contents;
		}

		@media only screen and (max-width: 600px) {
			task-scheduled-order-form .field-grid {
				grid-template-columns: 1fr;
			}
		}
	`]
})
export class scheduledOrderFormComponent implements OnInit {

	@Input()
	order: ScheduledOrderTemplateType = Order.scheduled_order_template_empty();

	inventory: Task.KAM_Inventory[] = [];
	descriptions: ScheduledOrderTemplateType["scheduled_orders_template_details"] = [{
		order_detail_type_id: Task.ORDER_DETAIL_TYPE.DESCRIPTION,
		description: ""
	}];


	investment_types = Task.Investment_Type;
	picked_supplier: Task.Supplier = null;
	is_internal_supplier = false;
	picked_worker: Task.User = null;
	yearly_plans = {...Task.Annual_Frequency};
	is_supplier = false;
	is_edit_form = false;
	show_info = false;
	max_date_allowed: Date = null;


	update_details() {
		if (this.order.locations.length!==1) {
			this.inventory = [];
			this.order.scheduled_orders_template_details = [...this.descriptions];

		} else {
			this.order.scheduled_orders_template_details = [...this.descriptions, ...this.inventory.map(inv=>({
				order_detail_type_id: Task.ORDER_DETAIL_TYPE.INVENTORY,
				description: "-", // Description is mandatory with order details
				inventory_id: inv.inventory_id,
				inventory_name: inv.inventory_name,
				inventory_no: inv.inventory_no,
				inventory_serial_no: inv.inventory_serial_no,
				inventory_data: inv
			}))];
		}
	}


	ngOnInit() {
		language.load_translations(orders_translations);
		this.init();
	}

	async init() {
		this.is_supplier = await Order.is_supplier_admin();
		this.is_edit_form = this.order["scheduled_orders_template_id"] ? true : false;

		// @TODO(borna): ovdje smo dohvatili podatke o supplieru
		// sa backenda iako bi si ih sami mogli proslijediti iz neke
		// parent componente (create-scheduled-order) ili nešto
		if (this.order.supplier_id) {
			let res = await TaskRoutes.suppliers.api_supplier_get_single(this.order.supplier_id);
			this.picked_supplier = res.data;

			if (this.picked_supplier.supplier_id === (await User.currentUserPromise).company_id) {
				this.is_internal_supplier = true;
			}

			if (this.order.arrival_worker_id) {
				this.picked_worker = this.order.arrival_worker;
				this.is_internal_supplier = true;
			}
		}

		this.inventory = this.order.scheduled_orders_template_details.filter(d=>d.order_detail_type_id === Task.ORDER_DETAIL_TYPE.INVENTORY).map(d=>d.inventory_data);

	}

	show_end_date() {
		return this.order.annual_frequency !== Task.ANNUAL_FREQUENCY.DAILY;
	}

	check_show_info() {
		let is_daily = this.order.annual_frequency === Task.ANNUAL_FREQUENCY.DAILY;
		let has_dates = false;
		let has_freq = !!this.order.annual_frequency;
		if (is_daily) {
			has_dates = !!this.order.planned_start_date;
		} else {
			has_dates = !!this.order.planned_start_date && !!this.order.planned_end_date;
		}

		this.show_info = has_dates && has_freq;

		if (this.order.planned_start_date) {
			if (is_daily) {
				this.order.planned_end_date = this.order.planned_start_date;
			}
			let start_date_parts = date.parts(new Date(this.order.planned_start_date));

			if (this.order.cut_date == null) {
				this.order.cut_date = date.create({
					year: start_date_parts.year,
					month: 11,
					date: 31,
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				}, false);
			}

			this.max_date_allowed = date.subtract(
				date.create({
					year: start_date_parts.year+1,
					month: start_date_parts.month,
					date: start_date_parts.date,
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				}, false),
				date.duration({ date: 2 })
			);
		} else this.max_date_allowed = null;
	}

	log(...args: any[]) {
		console.log(...args);
	}

	show_info_popup() {
		popover.open(
			ScheduledDates, noOp, {
				annual_frequency: this.order.annual_frequency,
				start_date: new Date(this.order.planned_start_date),
				end_date: new Date(this.order.planned_end_date),
				cut_date: new Date(this.order.cut_date)
			}, noOp, "mouse", "auto", "info-popover-wrapper"
		);
	}

	select_supp_id(event: Task.Supplier) {
		if (!event) this.order.supplier_id = null;
		else this.order.supplier_id = event.supplier_id;

		if (event.company_type_id === Task.COMPANY_TYPE.MAIN) {
			this.is_internal_supplier = true;
		} else {
			this.is_internal_supplier = false;
			this.order.arrival_worker = null;
			this.order.arrival_worker_id = NaN;
		}
	}

	select_whier_id(event: Task.WorkHierarchy) {
		if (!event) this.order.whier_id = null;
		else this.order.whier_id = event.whier_id;
	}


	search_suppliers = async (search: string) => {
		let res = await TaskRoutes.suppliers.api_supplier_search(
			{ attributes: ["company.name"], search: [search]},
			{page_no: 1, page_size: 15}
		);

		return res.data;
	}

	format_supplier_name(supp: Task.Supplier) {
		if (!supp) { return ""; }
		return supp.name;
	}

	search_workers = async (search: string) => {
		let res = await TaskRoutes.users.api_user_search({
			search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
			attributes: ['first_name+ +last_name', "#=:user_type_id"]
		}, {
			page_no: 1,
			page_size: 10
		})


		return res.data;
	}

	format_worker_name(usr: Task.User) {
		if (!usr) return "";
		return `${usr.first_name} ${usr.last_name}`
	}

	select_picked_worker(worker: Task.User) {
		if (worker) {
			this.order.arrival_worker = worker;
			this.order.arrival_worker_id = worker.user_id;
		} else {
			this.order.arrival_worker = null;
			this.order.arrival_worker_id = NaN;
		}
	}
}

