import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Frame, language, modal, utils, noOp, template, DataTableComponent, GenericFilterComponent, ConfirmDialogComponent, color, notification } from 'curvy';
import { Task } from '@task-utils/types';


interface EntityInfoType {
	title: string,
	status: { icon: string, color: string, status_name: string },
	properties: Record<string, any>,
	tiles?: {title: string, color: string}[],
	priority?: Task.PRIORITY,
	link?: string,
}

@Component({
	selector: 'task-entity-info',
	template: `
		<div class="heading flex-row" style="gap: .5em;">
			<i [attr.icon]="status.icon" [style.color]="status.color" [title]="status.status_name"></i>
			<h3 (click)="navigate_to_link()"
				[ngStyle]="{'cursor': title.url ? 'pointer' : 'inherit'}">{{ title.text }}</h3>
			<button *ngIf="link" title="Idi na nalog" class="round filled" color="secondary" [goto]="link" style="margin-left: auto;"><i icon="east"></i></button>
		</div>

		<ng-container *ngFor="let prop of properties | keyvalue">
			<span class="flex-row wrappable align-baseline" *ngIf="prop.value !== undefined && prop.value !== null">
				<strong>{{ prop.key | trans }}: </strong> &nbsp;

				<span class="flex-row wrappable" *ngIf="is_object(prop.value); else justString;">
					<i>{{ prop.value.category_description }}</i>
					<ng-container *ngIf="prop.value.subcategory_description">
						<i icon="chevron_right"></i>
						<i>{{ prop.value.subcategory_description }}</i>
						<ng-container *ngIf="prop.value.group_description">
							<i icon="chevron_right"></i>
							<i>{{ prop.value.group_description }}</i>
						</ng-container>
					</ng-container>
				</span>

				<ng-template #justString>
					<span>{{ prop.value }}</span>
				</ng-template>

			</span>
		</ng-container>

		<div class="label-pills-container" *ngIf="tiles">
			<ng-container *ngFor="let tile of tiles" >
				<strong class="label-pill uppercase"
				[style.backgroundColor]="'rgb('+tile.color+')'">{{ tile.title | trans}}</strong>
			</ng-container>
		</div>
	`,
	styles: [`
		task-entity-info {
			display: contents;
		}

		task-entity-info .heading h3 {
			word-break: break-word;
		}

	`],
	encapsulation: ViewEncapsulation.None

})

export class EntityInfoComponent {
	item: EntityInfoType | null = null;

	@Input()
	title: {
		text: string,
		url?: string
	};

	@Input()
	status = {
		icon: "",
		color: "",
		status_name: ""
	};

	@Input()
	properties: Record<string, any> = {};

	@Input()
	tiles: {title: string, color: string} [] | null = null;

	@Input()
	priority: Task.PRIORITY | null = null;

	@Input()
	link: string | null = null;

	is_object(val):boolean {
		return typeof(val) == 'object';
	}

	navigate_to_link() {
		if (!this.title.url) return;
		utils.router.navigateByUrl(this.title.url);
	}

}