import { Component } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';

@Component({
    selector: 'task-confirm-arrival-dialog',
    template: `
        <task-confirm-arrival [arrival]="injectedData"></task-confirm-arrival>
        <button (click)="submit(null)">{{ "CLOSE" | trans }}</button>
    `
})
export class ConfirmArrivalDialog extends ModalBase<Task.Arrival, any> {
    preventSoftClose = true;
}
