import { Component, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { language, notification, color } from 'curvy';
import { reports_translations } from './reports.trans';

@Component({
    selector: 'task-report-pricelist',
    template: `
        <h2 class="">{{ 'REPORTS.PRICELIST_ELEMENT_TITLE' | trans }}</h2>
        <div class="flex-row wrappable">
            <knw-auto-search
                [function]="search_pricelist"
                [to-label]="format_pricelist_name"
                [(ngModel)]="report.picked_pricelist"
                label="{{ 'REPORTS.PRICELIST_EL' | trans }}"
                [allow-null]="true"
                [popover-width]="'auto'"
                [classes]="'filled flex-dynamic'">
            </knw-auto-search>

            <knw-date-picker-input
                [(ngModel)]="report.dateFrom"
                [label]="'STARTDATE' | trans"
                [classes]="'filled flex-dynamic'">
            </knw-date-picker-input>

            <knw-date-picker-input
                [(ngModel)]="report.dateTo"
                [label]="'ENDDATE' | trans"
                [classes]="'filled flex-dynamic'">
            </knw-date-picker-input>
            <knw-auto-search
                    [function]="search_locations"
                    [to-label]="format_location_name"
                    [(ngModel)]="report.picked_location"
                    label="{{ 'LOCATION' | trans }}"
                    [allow-null]="true"
                    [popover-width]="'auto'"
                    [classes]="'filled flex-dynamic'">
            </knw-auto-search>
            <knw-auto-search
                    [function]="search_suppliers"
                    [to-label]="format_supplier_name"
                    [(ngModel)]="report.picked_supplier"
                    label="{{ 'REPORTS.SUPPLIER' | trans }}"
                    [allow-null]="true"
                    [popover-width]="'auto'"
                    [classes]="'filled flex-dynamic'">
            </knw-auto-search>
        </div>
        <div class="report-btn-wrapper">
            <button class="filled pill report-btn"
                (click)="get_pricelist_expense_report()"
                *ngIf="!loading; else isLoading">
                <i icon="download"></i>
                <span>{{ 'REPORTS.DOWNLOAD' | trans }}</span>
            </button>

            <ng-template #isLoading>
                <knw-spinner color="primary" size="50"></knw-spinner>
            </ng-template>
        </div>
        `,
    styleUrls: ['./reports.component.scss'],
    encapsulation: ViewEncapsulation.None,

})

export class ReportPricelistComponent implements OnInit {
    report = {
        dateFrom: null as Date,
        dateTo: null as Date,
        picked_location: null as Task.Location,
        picked_supplier: null as Task.Supplier,
        picked_pricelist: null as Task.Pricelist,
    };

    loading = false;

    ngOnInit() {
        language.load_translations(reports_translations);

    }

    async search_locations(search: string) {
        let res = await TaskRoutes.locations.api_location_search_visible({ search: [search], attributes: ["cin+ +name"] });
        return res.data;
    }

    async search_suppliers(search: string) {
        let res = await TaskRoutes.suppliers.api_supplier_search({ search: [search], attributes: ["company.name"] }, {page_no: 1, page_size: 15});
        return res.data;
    }

    async search_pricelist(search: string) {
        let res = await TaskRoutes.pricelists.api_pricelist_search({ search: [search], attributes: ["cin+ +name"] });
        return res.data;
    }

    format_pricelist_name(price: Task.Pricelist) {
        if (!price) { return ""; }
        return `${price.cin} | ${price.name}`;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
        return `${loc.cin ?? ''} ${loc.name}`;
    }

    format_supplier_name(sup: Task.Supplier) {
        if (!sup) { return ""; }
        return `${sup.name}`;
    }


    getData() {
        let parsed_filter = { search: [], attributes: [] };

        if (this.report.picked_location) {
            parsed_filter.search.push(this.report.picked_location.location_id)
            parsed_filter.attributes.push("#=:location_id")
        }

        if (this.report.picked_supplier) {
            parsed_filter.search.push(this.report.picked_supplier.supplier_id)
            parsed_filter.attributes.push("#=:supplier_id")
        }

        if (this.report.picked_pricelist) {
            parsed_filter.search.push(this.report.picked_pricelist.element_id);
            parsed_filter.attributes.push("#=:element_id")
        }

        if (this.report.dateFrom) {
            parsed_filter.search.push(this.report.dateFrom.toISOString())
            parsed_filter.attributes.push("-d:created_date");
        }

        if (this.report.dateTo) {
            parsed_filter.search.push(this.report.dateTo.toISOString())
            parsed_filter.attributes.push("+d:created_date");
        }

        return parsed_filter;
    }

    async get_pricelist_expense_report() {
        try {
            this.loading = true;
            await TaskRoutes.reports.api_pricelist_elements_report_get(this.getData());
        } catch {
            notification.show({
                icon: "report_problem",
                color: color.Variable.warn,
                title: language.translate("REPORTS.NO_DATA_TITLE"),
                message: language.translate("REPORTS.NO_DATA_DESCR")
            });
        }
        this.loading = false;
    }

}
