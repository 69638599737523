import {
	Component, OnInit, OnChanges, SimpleChanges, ViewChild,
	Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { language, ModalBase, notification, FormCollectorDirective } from 'curvy';
import { Task } from '@task-utils/types';
import { User } from '@task-modules/users/users';
import { Sectors } from '@task-modules/sectors/sectors';
import { users_translations } from './users.trans';

type UserType = Pick<Task.User, 'first_name' | 'last_name' | 'email' | 'user_type_id' | 'sector_id' | 'contact_phone' | 'work_hour_type_id'> & {locations?:Task.Location[]};
import UT = Task.USER_TYPE;

@Component({
	selector: 'task-create-edit-user',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="flex-column fullheight-noscroll">
		<h2 class="modal-title">
			{{ (is_edit_form ? 'USER.EDIT' : 'USER.NEW') | trans }}
		</h2>

		<div style="overflow: auto;">
			<div class="field-grid" form-collector #userForm="collector">
				<label class="input outlined" [title]="'NAME' | trans">
					<input [(ngModel)]="user.first_name"
							conditions="nonempty"
							[disabled]="!can_edit_details" />
				</label>
				<label class="input outlined" [title]="'SURNAME' | trans">
					<input [(ngModel)]="user.last_name"
							conditions="nonempty"
							[disabled]="!can_edit_details" />
				</label>
				<label class="input outlined" [title]="'USER.TYPE' | trans" style="grid-column: 1 / -1">
					<select [(ngModel)]="user.user_type_id"
							[disabled]="!can_edit_sectors"
							required>
						<option *ngFor="let type of disabled_user_types"
								disabled
								style="color: #fff9;"
								[ngValue]="type.key">
							{{ ("USER.TYPE." + type.value) | trans }}
						</option>
						<option *ngFor="let type of allowed_user_types"
								[ngValue]="type.key">
							{{ ("USER.TYPE." + type.value) | trans }}
						</option>
					</select>
				</label>
				<!-- SUPPLIER WORKER QUALIFICATION -->
				<label class="input outlined"
						*ngIf="user.user_type_id == 10"
						[title]="'USER.QUALIFICATION' | trans"
						style="grid-column: 1 / -1">
					<select [(ngModel)]="user.work_hour_type_id"
							required
							[disabled]="!can_edit_qualification">
						<option *ngFor="let type of worker_qualifications"
								[ngValue]="type.key">
							{{ ("USER.QUALIFICATION." + type.value) | trans }}
						</option>
					</select>
				</label>
				<ng-container *ngIf="sectors.length > 0">
					<label class="input outlined" *ngIf="has_sector(user.user_type_id)"
							[title]="'SECTOR' | trans"
							style="grid-column: 1 / -1">
						<select [(ngModel)]="user.sector_id"
								[disabled]="!can_edit_sectors"
								[required]="sector_required(user.user_type_id)">
							<option *ngFor="let sector of sectors"
									[ngValue]="sector.sector_id">
								{{ sector.name }}
							</option>
						</select>
					</label>
				</ng-container>
				<div *ngIf="sectors.length === 0 && user['sector_name']">
					<b>{{ 'SECTOR' | trans }}:&nbsp;</b>{{ user["sector_name"] }}
				</div>
				<label class="input outlined"
						[title]="'EMAIL' | trans"
						style="grid-column: 1 / -1" >
					<i icon="email"></i>
					<input [(ngModel)]="user.email"
							autocomplete="off"
							conditions="nonempty,pattern=.+@.+"
							[disabled]="!can_edit_mail"/>
				</label>
				<label class="input outlined"
						[title]="'PHONE' | trans"
						style="grid-column: 1 / -1" >
					<i icon="phone"></i>
					<input [(ngModel)]="user.contact_phone"
							autocomplete="off"
							[disabled]="!can_edit_details"/>
				</label>

				<details *ngIf="user.locations?.length" class="flex-column" style="box-shadow: none; list-style: none; margin-bottom: 1.5em; margin-left: 0; padding-left: 0; grid-column: 1 / -1">
					<summary>
						<h3 style="margin-bottom:0;" >Zadužen za prodavaonice:</h3>
					</summary>
					<span *ngFor="let loc of user.locations" style="font-size: .9em; margin-bottom: .15em;">
						<span>#{{loc.location_cin}}</span> &nbsp; {{loc.location_name}}
					</span>
				</details>

				<div *ngIf="show_password_email_button && is_edit_form"
						class="flex-row justify-flex-end"
						style="grid-column: 1 / -1; margin-bottom: 9px">
					<button class="pill"
							(click)="send_password_email()">
						<i icon="email"></i>
						<span>{{ 'USER.SEND_PASSWORD_EMAIL' | trans }}</span>
					</button>
				</div>
			</div>
		</div>

		<div class="flex-row modal-footer">
			<button color="primary"
					(click)="cancel()">
				<i icon="clear"></i>
				<span>{{ 'CANCEL' | trans }}</span>
			</button>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="userForm.containsErrors()">
				<i icon="check"></i>
				<span>{{ 'SAVE' | trans }}</span>
			</button>

		</div>
	</div>
	`,
	styles: [`
		task-create-user {
			max-width: 600px;
		}

		task-create-user knw-search-picker {
			display: contents;
		}

		task-create-user knw-search-picker label[title="Location"] {
			flex-flow: row-reverse;
		}

		task-create-user knw-search-picker label[title="Location"] input {
			margin-right: 0;
			margin-left: 24px;
		}

		task-create-user knw-search-picker label[title="Location"]::after {
			content: "place";
			font-family: Icons;
			font-size: 24px;
			line-height: 0px;
			margin-right: -24px;
		}

		.error-message {
			color: red;
			padding: 1em;
		}
	`]
})
export class CreateEditUserComponent implements OnInit, OnChanges {
	@ViewChild('userForm')
	userForm: FormCollectorDirective;

	@Input()
	user: UserType = {
		first_name: "",
		last_name: "",
		email: "",
		user_type_id: null,
		sector_id: null,
		contact_phone: "",
		work_hour_type_id: null
	};

	@Output('result')
	result: EventEmitter<Task.User | UserType> = new EventEmitter();

	show_password_email_button = true;
	is_edit_form = false;

	can_edit_details = false;
	can_edit_sectors = false;
	can_edit_mail = false;
	can_edit_qualification = false;

	allowed_user_types: Task.EnumStruct<UT>[] = [];
	disabled_user_types: Task.EnumStruct<UT>[] = Task.enum_array(Task.User_Type);
	worker_qualifications: Task.EnumStruct<Task.WORK_HOUR_TYPE>[] = Task.enum_array(Task.Work_Hour_Type);

	sectors: Task.Sector[] = [];

	ngOnInit() {
		this.init();
	}

	async init() {
		language.load_translations(users_translations);
		this.can_edit_sectors = await User.currentTypeIs(
			UT.ADMIN,
			UT.SYSTEM_ADMIN,
			UT.SUPER_USER,
			UT.SUPPLIER_ADMIN
		);

		if (this.can_edit_sectors) {
			this.sectors = (await Sectors.getAll()).data;
		}
		this.update_details();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["user"]) {
			this.user = {...changes["user"].currentValue};
		}

		this.update_details();
	}

	has_sector(user_type: UT) {
		return user_type !== UT.SUPPLIER_ADMIN && user_type !== UT.SUPPLIER_WORKER;
	}

	sector_required(user_type: UT) {
		return user_type === UT.REGIONAL_MAINTENANCE_MANAGER || user_type === UT.SAFETY_MANAGER;
	}

	async update_details() {
		const user_id = this.user["user_id"];
		this.is_edit_form = user_id !== null && user_id !== undefined;

		if (this.is_edit_form) {
			let cur_user_id = (await User.currentUserPromise).user_id;

			this.can_edit_details = user_id === cur_user_id;
			this.can_edit_mail = user_id === cur_user_id;

			if (await User.currentTypeIs(
					UT.SYSTEM_ADMIN,
					UT.ADMIN,
				)
			) {
				this.can_edit_details = true;
				this.can_edit_mail = true;
				this.can_edit_qualification = true;

			}

			if (await User.currentTypeIs(UT.SUPPLIER_ADMIN)) {
				this.can_edit_details = true;
				this.can_edit_mail = true;
				this.can_edit_qualification = true;

			}

			if (await User.currentTypeIs(UT.SUPPLIER_WORKER)) {
				this.can_edit_mail = false;
			}

		} else {
			this.can_edit_details = true;
			this.can_edit_mail = true;
			this.can_edit_qualification = true;
		}

		switch (await User.currentType()) {
			case UT.SYSTEM_ADMIN: {
				this.allowed_user_types = Task.enum_array(Task.User_Type);
				this.disabled_user_types = [];
			} break;

			case UT.ADMIN: {
				let type_bisection = Task.array_bisect(
					Task.enum_array(Task.User_Type),
					t => t.key !== UT.SYSTEM_ADMIN);
				this.allowed_user_types = type_bisection.included;
				this.disabled_user_types = type_bisection.excluded;
			} break;

			case UT.SUPER_USER: {
				let type_bisection = Task.array_bisect(
					Task.enum_array(Task.User_Type),

					t => t.key !== UT.SYSTEM_ADMIN && t.key !== UT.ADMIN);
				this.allowed_user_types = type_bisection.included;
				this.disabled_user_types = type_bisection.excluded;
			} break;

			case UT.SUPPLIER_ADMIN: {
				let type_bisection = Task.array_bisect(
					Task.enum_array(Task.User_Type),
					t => t.key == UT.SUPPLIER_ADMIN || t.key == UT.SUPPLIER_WORKER);
				this.allowed_user_types = type_bisection.included;
				this.disabled_user_types = type_bisection.excluded;
			} break;

		}

	}

	done() {
		this.result.emit(this.user);
	}

	cancel() {
		this.result.emit(null);
	}

	send_password_email() {
		if (this.is_edit_form) {
			this.show_password_email_button = false;
			User.send_password_email(this.user["user_id"]).then(() => {
				notification.show({
					icon: "email",
					title: language.translate("SUCCESS"),
					message: language.translate("USER.SEND_PASSWORD_EMAIL_SENT")
				});
			}).catch((_err) => {
				let err;

				try {
					err = JSON.parse(_err.response);
				} catch {
					err = null;
				}

				if (err && err.internal_error_code == 4106) {
					notification.show({
						icon: "warning",
						title: language.translate("FAILURE"),
						message: language.translate("USER.SEND_PASSWORD_EMAIL_NOT_SENT")
					});
				} else {
					notification.show({
						icon: "warning",
						title: language.translate("FAILURE"),
						message: language.translate("USER.SEND_PASSWORD_EMAIL_ERROR")
					});
				}
			});
		}
	}
}
