import {
	Component, OnInit, OnChanges, SimpleChanges,
	Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Task } from '@task-utils/types';
import { Frame, language } from 'curvy';
import { suppliers_translations } from './suppliers.trans';
import { TaskRoutes } from '@task-utils/routes';

type SupplierType = Pick<Task.Supplier, "name" | "email" | "company_type_id" | "cin" | "contact_phone" | "contact_name" | "identification_no" | "sectors">;

@Component({
	selector: 'task-create-edit-supplier',
	templateUrl: './create-edit-supplier.component.html',
	styleUrls: ['./create-edit-supplier.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CreateEditSupplierComponent {
	@Input()
	supplier: SupplierType = {
		name: "",
		email: "",
		company_type_id: Task.COMPANY_TYPE.EXTERNAL_SUPPLIER,
		cin: "",
		contact_phone: "",
		contact_name: "",
		identification_no: "",
		sectors: []
	};

	is_edit = false;
	sectors:Task.Sector[] = [];
	picked_sectors: Task.Sector [] = [];

	@Output('result')
	result: EventEmitter<Task.Supplier | SupplierType> = new EventEmitter();

	constructor() {
		language.load_translations(suppliers_translations);

	}

	async init() {
		if(this.supplier.name == "") {
			this.is_edit = false;
		} else {
			this.is_edit = true;
		}

		if (this.supplier.sectors.length) {
			this.picked_sectors = this.supplier.sectors;
		}

	}
	
	async search_sectors() {
		this.sectors = (await TaskRoutes.sectors.api_sector_get_all(null, false)).data;
		return this.sectors
	}

	format_sector_name(sec: Task.Sector) {
		if (!sec) return;

		return `${sec.name}`;
	}

	
	ngOnInit() {
		
		this.init();
		
	}

	done() {
		if (this.supplier.company_type_id == null) {
			this.supplier.company_type_id = Task.COMPANY_TYPE.EXTERNAL_SUPPLIER;
		}

		this.supplier.sectors = this.picked_sectors

		this.result.emit(this.supplier);
	}

	cancel() {
		this.result.emit(null);
	}
}
