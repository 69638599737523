import { Component, ViewEncapsulation } from '@angular/core';
import { ModalBase, date } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

type ReturnDateType = {
    start_date?: string,
    end_date: string
}

@Component({
    selector: 'task-complete-arrival-dialog',
    template: `
    <div class="flex-column plan-arrival-modal" form-collector #completeArrivalForm="collector">
        <h2 class="modal-title">
            {{ 'ARRIVAL.COMPLETE' | trans }}
        </h2>

        <div class="flex-row" *ngIf="has_start">
            <knw-date-picker-input class="flex-dynamic input outlined" 
                [classes] ="'flex-dynamic input outlined'"
                [required]="true" 
                [label]="'ARRIVAL.PLAN.DATE2' | trans" 
                [(ngModel)]="start_time"
                [max-date]="today">
            </knw-date-picker-input>

            <knw-time-picker-input class="flex-dynamic input outlined" 
                [classes] ="'flex-dynamic input outlined'"
                [required]="true" 
                [label]="'ARRIVAL.PLAN.TIME' | trans" 
                [(ngModel)]="start_time">
            </knw-time-picker-input>
        </div>

        <div class="flex-row">
            <knw-date-picker-input class="flex-dynamic input outlined" 
                [classes] ="'flex-dynamic input outlined'"
                [required]="true" 
                [label]="'ARRIVAL.PLAN.END_DATE2' | trans" 
                [(ngModel)]="end_time"
                [max-date]="today">
            </knw-date-picker-input>

            <knw-time-picker-input class="flex-dynamic input outlined" 
                [classes] ="'flex-dynamic input outlined'"
                [required]="true" 
                [label]="'ARRIVAL.PLAN.END_TIME' | trans" 
                [(ngModel)]="end_time">
            </knw-time-picker-input>
		</div>
    </div>
    <div class="flex-row modal-footer">
        <button color="primary" (click)="cancel()">
            <i icon="clear"></i>
            {{ 'CANCEL' | trans }}
        </button>

        <button class="filled" color="secondary" [disabled]="completeArrivalForm.containsErrors()" (click)="done()">
            <i icon="check"></i>
            <span style="white-space: pre-line">
                {{ 'ARRIVAL.COMPLETE' | trans }}
            </span>
        </button>
    
    </div>
    `,
    styleUrls: ['./plan-arrival.component.scss'],
    encapsulation: ViewEncapsulation.None,

})


export class CompleteArrivalDialog extends ModalBase<any, ReturnDateType> {
    preventSoftClose = true;
    // end_date: Date = null;
    end_time: Date = null;
    // start_date: Date = null;
    start_time: Date = null;
    date_end: string = '';
    date_start: string = '';
    arrival:Task.Arrival = null;

    has_start = false;
   
    today = new Date();


    cancel() {
        this.submit(null);
    }

    ngOnInit() {
        if (this.injectedData) {
            this.arrival = this.injectedData;
            this.has_start = this.arrival.status_id === Task.STATUS.CREATED;
            // this.start_date = new Date(this.arrival.planned_start_time);
        }   
    }

    async done() {
        // let tzoff = new Date().getTimezoneOffset();

        // let end_time = date.add(this.end_date, this.end_time);
        // end_time = date.add(end_time, date.duration({ minute: tzoff }));
        this.date_end = this.end_time.toISOString();

        if (this.has_start) {
            // let start_time = date.add(this.start_date, this.start_time);
            // start_time = date.add(start_time, date.duration({ minute: tzoff }));
            this.date_start = this.start_time.toISOString();
            this.submit({start_date: this.date_start, end_date: this.date_end})
            
        } else {
            this.submit({end_date: this.date_end})
        }

        
    }
}
