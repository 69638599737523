
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { language, ModalBase, color, notification } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { files } from '@task-utils/utils';
import { User } from '@task-modules/users';
import { Order } from '../orders';
import { orders_translations } from '../orders.trans';
import { OrderType } from '../orders.types';


@Component({
  selector: 'task-create-order-dialog',
  encapsulation: ViewEncapsulation.None,
  template: `
    <task-create-order [tickets]="tickets"
                       (result)="handle_result($event)">
    </task-create-order>
    `,
    styles: [`

    @media only screen and (min-width: 601px) {
        .modal task-create-order-dialog {
            max-width: 620px;
            box-sizing: border-box;
        }
    }
    `]
})
export class CreateOrderDialog extends ModalBase<Task.Ticket[] | Promise<Task.Ticket[]>, Task.Order> implements OnInit {
    tickets: Task.Ticket[] = [];
    preventSoftClose = true;
    allowSubmit = true;



    ngOnInit() {
        language.load_translations(orders_translations);

        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => this.tickets = res);
        } else {
            this.tickets = this.injectedData || [];
        }
    }

    async upload_image(img: files.IImage) {
        if (!img.is_uploaded) {
            let res = await TaskRoutes.upload.api_upload_file(img.file);
            img.src = TaskRoutes.upload.uploaded_file_get(res.data);
            img.is_uploaded = true;
        }
        return img;
    }

    async upload_file(order_id: number, file: files.IFile, order_detail_type_id: number) {
        if (!file.is_uploaded) {
            try {
                await TaskRoutes.orders.api_upload_document(order_id, file.file, false, order_detail_type_id);
            } catch(error)  {
                let msg;
                if (error.internal_error_code == 4107) {
                    msg = language.translate('DOCUMENT.UNSAFE_FAIL_TEXT');
                } else {
                    msg = language.translate('DOCUMENT.UPLOADED_FAIL_TEXT');
                }

                notification.show({
                  icon: 'error',
                  color: color.Variable.warn,
                  title: language.translate('ERROR'),
                  message: msg
                })
            }


        }
    }

    async handle_result(order: OrderType) {


        if (this.allowSubmit) {
			this.allowSubmit = false;

            if (order) {
                let out_order: Parameters<typeof TaskRoutes.orders.api_order_add>[0] = {
                    ...order,
                    ticket_ids: this.tickets.map(t => t.ticket_id),
                    cost_estimate: order.cost_estimate as unknown as number,
                    order_details: order.order_details as Task.OrderDetail[]
                };

                let image_upload_promises: Promise<files.IImage>[] = [];

                for (let img of order.iimages) {
                    if (!out_order.order_details.some(od => od.answer == img.src)) {
                        image_upload_promises.push(this.upload_image(img));
                    }
                }

                let uploaded_images = await Promise.all(image_upload_promises)

                for (let img of uploaded_images) {
                    order.order_details.push({
                        picture_url: img.src,
                        description: img.file.name,
                        order_detail_type_id: Task.ORDER_DETAIL_TYPE.IMAGE
                    } as Task.OrderDetail);
                }

                let res = await TaskRoutes.orders.api_order_add(out_order, false);

                let file_upload_promises: Promise<void>[] = [];
                for (let file of order.ifinancial_files) {
                    if (!("dms_document_id" in file)) {
                        file_upload_promises.push(this.upload_file(res.data.order_id, file, Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT));
                    } else {
                        order.order_details.push({
                            order_detail_type_id: Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT,
                            dms_document_file_name: file.dms_document_file_name,
                            dms_document_id: file.dms_document_id
                        });
                    }
                }
                for (let file of order.iservice_files) {
                    if (!("dms_document_id" in file)) {
                        file_upload_promises.push(this.upload_file(res.data.order_id, file, Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT));
                    } else {
                        order.order_details.push({
                            order_detail_type_id: Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT,
                            dms_document_file_name: file.dms_document_file_name,
                            dms_document_id: file.dms_document_id
                        });
                    }
                }

                await Promise.all(file_upload_promises);

                this.submit(res.data);
            } else {
                this.submit(null);
            }

            this.allowSubmit = true;
        }
    }
}
