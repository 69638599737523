import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Frame, language, modal, utils, ConfirmDialogComponent, color } from 'curvy';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { ScheduledOrderTemplateType } from '../orders.types';
import { orders_translations } from '../orders.trans';
import { Order } from '../orders';
import { Task } from '@task-utils/types';
import { get_sch_order_to_copy, unset_sch_order_to_copy } from './scheduled-orders';


// @TODO(borna) vidi dal se može ljepše napravit ovo:
declare global {
	function structuredClone<T> (obj: T): T;
}

@Component({
	selector: 'task-create-scheduled-order',
	template: `
		<div class="flex-column" style="padding: 2em; overflow: auto; height: calc(100dvh - 9.5em); box-sizing: border-box;" form-collector #scheduledOrderTemplateForm="collector">
			<div class="flex-row align-center" style="margin-bottom: 2em;">
				<task-back-button [confirm_data]="scheduled_plans.length > 0 ?confirm_leave_msg : null"></task-back-button>
				<h1 style="margin: 0 0 0 0.5ch;">{{ 'ORDER.SCHEDULED.NEW' | trans }}</h1>
			</div>
			<ng-container *ngFor="let plan of scheduled_plans; let i = index;">

				<new-scheduled-order-plan (do-copy)="copy_scheduled_order(i)" [plan]="plan" [count_plan]="i+1" [all_plans]="scheduled_plans"></new-scheduled-order-plan>
			</ng-container>
			<!-- new order button -->
			<div class="flex-row justify-center align-center" style="margin-top: 2em; position: sticky; bottom: 0px; pointer-events: none;">
				<button (click)="create_new_order()"
					class="filled pill"
					color="primary"
					style="pointer-events: auto;">
					<i icon="add_circle"></i>
					<span>{{ 'ORDER.SCHEDULED.ADD_NEW' | trans }}</span>
				</button>
			</div>
		</div>

		<div class="flex-row justify-space-between align-center" style="padding: 1.5em 2.5em; ">
			<button (click)="cancel()" class="" color="primary" style="transform: scale(1.1);">
				<i icon="clear"></i>
				<span>{{ "CANCEL" | trans }}</span>
			</button>
			<button (click)="save()" [disabled]="scheduledOrderTemplateForm.containsErrors()" class="filled" color="secondary" style="transform: scale(1.1);">
				<i icon="check"></i>
				<span>{{ "SAVE" | trans }}</span>
			</button>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['../order.component.scss']
})

export class CreateScheduledOrderComponent implements OnInit {
	scheduled_plans: ScheduledOrderTemplateType[] = [get_sch_order_to_copy() ?? Order.scheduled_order_template_empty()];

	confirm_leave_msg: any;

	constructor() {
		language.load_translations(orders_translations);

		Frame.set({
			title: "NAVROUTE.SCHEDULED_ORDERS",
			visible: true,
			layout: "middle",
			size: "full"
		});

		this.confirm_leave_msg = {
			message: language.translate('ORDER.SCHEDULED.LEAVE_MSG'),
			title: language.translate("ORDER.SCHEDULED.LEAVE_TITLE"),
			icon: "warning",
			iconColor: color.variable(color.Variable.warn)
		}
	}

	copy_scheduled_order(i) {
		let new_plan:ScheduledOrderTemplateType;

		new_plan = structuredClone(this.scheduled_plans[i]);
		this.scheduled_plans.push(new_plan);
	}

	create_new_order() {
		this.scheduled_plans.push(Order.scheduled_order_template_empty());
	}

	async save() {

		if (await Order.is_supplier_admin()) {
			let user_company = (await User.currentUserPromise).company_id
			for(let plan of this.scheduled_plans) {
				plan.supplier_id = user_company;

				if (plan.annual_frequency == Task.ANNUAL_FREQUENCY.DAILY) {
					plan.planned_end_date = plan.planned_start_date;
				}
			}
		} else {
			for(let plan of this.scheduled_plans) {
				if (plan.annual_frequency == Task.ANNUAL_FREQUENCY.DAILY) {
					plan.planned_end_date = plan.planned_start_date;
				}
			}
		}

		if(this.scheduled_plans.length > 0) {
			let res = await TaskRoutes.scheduled_orders_templates.api_scheduled_order_add({scheduled_orders_templates: this.scheduled_plans as any}, false)

			utils.router.navigateByUrl("/scheduled-orders");
		}
    }


    cancel() {

		if (this.scheduled_plans.length>0) {
			modal.open(ConfirmDialogComponent, (succ)=>{
				if(succ) {
					utils.router.navigateByUrl("/scheduled-orders");
				}
			}, this.confirm_leave_msg)
		} else {
			utils.router.navigateByUrl("/scheduled-orders");
		}
    }

	ngOnInit(): void {
		unset_sch_order_to_copy();
	}
}
