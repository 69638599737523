
import { language } from 'curvy';

export const english: language.TranslationDefinition =  {
   locale: 'en-us',
   translations: {
      "CHECKLISTS.Q_NAME": "Question",
      "CHECKLISTS.Q_DESCRIPTION": "Question description",
      "CHECKLISTS.Q_LOCATIONS_NUBMER": "Number of locations",
      "CHECKLISTS.Q_LOCGROUP_NUMBER": "Number of location groups",
      "CHECKLISTS.Q_FREQUENCY": "Annual frequency",
      "CHECKLISTS.DELETE.TITLE": "Delete a question",
      "CHECKLISTS.DELETE.MESSAGE": (q)=> `Are you sure you want to delete following question: ${q}?`,
      "CHECKLISTS.EDIT_Q_TITLE": "Edit question",
      "CHECKLISTS.NEW_Q_TITLE": "New question",
      "CHECKLISTS.ADD_NEW_Q": "Add new question",
      "CHECKLISTS.DISCLAMER": 'Locations are added either through "Locations" or "Location groups".'
   }
};

export const croatian: language.TranslationDefinition = {
   locale: 'hr-hr',
   translations: {
      "CHECKLISTS.Q_NAME": "Pitanje",
      "CHECKLISTS.Q_DESCRIPTION": "Opis pitanja",
      "CHECKLISTS.Q_LOCATIONS_NUBMER": "Broj lokacija",
      "CHECKLISTS.Q_LOCGROUP_NUMBER": "Broj grupa lokacija",
      "CHECKLISTS.Q_FREQUENCY": "Godišnje ponavljanje",
      "CHECKLISTS.DELETE.TITLE": "Brisanje pitanja",
      "CHECKLISTS.DELETE.MESSAGE": (q)=> `Jeste li sigurni da želite obrisati sljedeće pitanje: ${q}?`,
      "CHECKLISTS.EDIT_Q_TITLE": "Uređivanje pitanja",
      "CHECKLISTS.NEW_Q_TITLE": "Kreiranje novog pitanja",
      "CHECKLISTS.ADD_NEW_Q": "Dodaj novo pitanje",
      "CHECKLISTS.DISCLAMER": 'Lokacije je dovoljno popuniti ili preko "Lokacija" ili preko "Grupe lokacija".'

   }
}

export const checklists_translations = [english, croatian];