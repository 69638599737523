import { Component, Input, OnInit, ViewEncapsulation, ViewChild, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { language, SearchPickerComponent, async } from 'curvy';
import { components_trans } from './components.trans';
import { arrivals_translations } from '@task-modules/arrivals/arrivals.trans';
import { users_translations } from '@task-modules/users/users.trans';
import { User } from '@task-modules/users';



const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WorkersComponent),
	multi: true
};

@Component({
	selector: 'task-workers',
	encapsulation: ViewEncapsulation.None,
	template: `
	<div class="flex-column" style="overflow: auto">
		<div class="worker" *ngFor="let worker of workers; let i = index">
			<div class="flex-row justify-space-between tabs-nav" style="margin-bottom: 0.5em;">
				<button [class.filled]="!worker['is_user']" color="primary" class="tab-button outlined" (click)="worker_switch(worker)">Unos radnika</button>
				<button [class.filled]="worker['is_user']" color="primary" class="tab-button outlined" (click)="worker_switch(worker)">Pretraga radnika</button>
			</div>

			<div class="flex-row justify-space-between">
				<h4 style="margin: 0 0 0.5em 0">
					{{ 'ARRIVAL.PLAN.WORKER_NO' | trans : i + 1 }}
				</h4>
				<button class="round"
						color="warn"
						[title]="'DELETE' | trans"
						(click)="delete_worker(worker)"
						[disabled]="disabled">
					<i icon="delete"></i>
				</button>
			</div>
			<ng-container *ngIf="!worker['is_user']">
				<div class="flex-column">
					<label class="input outlined" title="{{ 'ARRIVAL.PLAN.WORKER_FIRST_NAME' | trans }}">
						<input [(ngModel)]="worker.first_name"
								[required]="required"
								[disabled]="disabled" 
								conditions="pattern=^\\s*(?:\\S+\\s*)+$"/>
					</label>
					<label class="input outlined" title="{{ 'ARRIVAL.PLAN.WORKER_LAST_NAME' | trans }}">
						<input [(ngModel)]="worker.last_name"
								[required]="required"
								[disabled]="disabled"
								conditions="pattern=^\\s*(?:\\S+\\s*)+$" />
					</label>
					<label class="input outlined" title="{{ 'ARRIVAL.PLAN.WORKER_ID' | trans }}">
						<input [(ngModel)]="worker.description"
								[disabled]="disabled"/>
					</label>

					<label class="input outlined" [title]="'USER.QUALIFICATION' | trans">
						<select [(ngModel)]="worker.work_hour_type_id"
								required>
							<option *ngFor="let type of worker_qualifications"
									[ngValue]="type.key">
								{{ ("USER.QUALIFICATION." + type.value) | trans }}
							</option>
						</select>
					</label>
				</div>
			</ng-container>

			<ng-container *ngIf="worker['is_user']">
				<knw-auto-search
					[function]="search_users"
					[to-label]="format_user_name"
					[ngModel]="worker"
					(ngModelChange)="assign_user_id(worker, $event)"
					label="{{ 'ARRIVAL.PLAN.WORKER_SEARCH' | trans }}"
					[allow-null]="true"
					classes="filled">
				</knw-auto-search>
			</ng-container>

		</div>
	</div>
	<button class="filled pill"
			style="align-self: center; flex: 0 0 auto; margin-top: 1em"
			color="primary"
			(click)="add_worker()"
			[disabled]="disabled">
		<i icon="add_circle"></i>
		<span>{{ 'ARRIVAL.PLAN.WORKER.NEW' | trans }}</span>
	</button>
	`,
	styleUrls: ['./components.scss'],
	providers: [ CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR ]
})
export class WorkersComponent implements OnInit, ControlValueAccessor {
	@Input()
	arrival_id: number = null;


	@Input()
	workers: ({is_user?: boolean} & Pick<
		Task.ArrivalWorker,
		"arrival_id" | "description" | "first_name" | "last_name" | "work_hour_type_id" | "user_id"
	>)[] = [];

	@Input()
	classes = 'outlined';

	private on_changed;
	private on_touched;
	worker_qualifications: Task.EnumStruct<Task.WORK_HOUR_TYPE>[] = Task.enum_array(Task.Work_Hour_Type);

	@Input()
	disabled = false;

	@Input('required')
	set _required(val: boolean) {
		this.required = val !== false;
	}

	required = false;
	user_id = null;
	user_type = null;

	constructor(public cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.init();
	}
	
	async init() {
		language.load_translations(components_trans);
		language.load_translations(arrivals_translations);
		language.load_translations(users_translations);
		
		let user = await User.currentUserPromise;

		this.user_id = user.user_id;
		this.user_type = user.user_type_id;
	
		for (let worker of this.workers) {
			if (worker.user_id) {
				worker["is_user"] = true;
			} else worker["is_user"] = false;
		}

	}


	worker_switch(worker) {
		worker.is_user = !worker.is_user;
		
		worker.user_id = null;
		worker.first_name = "";
		worker.last_name = "";
		worker.description = "";
	}
	
	assign_user_id(worker, user) {
		// console.log(worker, user)
		if (worker.is_user) {
			worker.user_id = user.user_id;
			worker.arrival_id = this.arrival_id,
			worker.first_name = user.first_name;
			worker.last_name = user.last_name;
			worker.description = user.description;
		}
		else worker.user_id = null;

	}

	add_worker(do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }
		
		this.workers.push({
			arrival_id: this.arrival_id,
			description: "",
			first_name: "",
			last_name: "",
			work_hour_type_id: null,
			user_id: null, 
			is_user: true
		});
		if (do_events && this.on_changed) { this.on_changed(this.workers); }
		this.cdr.detectChanges();
	}

	delete_worker(worker: Task.ArrivalWorker, do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }

		let index = this.workers.indexOf(worker);
		if (index != -1) {
			this.workers.splice(index, 1);
			if (do_events && this.on_changed) { this.on_changed(this.workers); }
			this.cdr.detectChanges();
		}
	}

	search_users = async (search: string) => { 
		let res: Task.Payload<Task.User[]>;
		let ut: string; 

		switch (this.user_type) {
			case Task.USER_TYPE.SUPPLIER_ADMIN:
				ut = Task.USER_TYPE.SUPPLIER_WORKER.toString();

				res = await TaskRoutes.users.api_user_search({
					search: [search, ut],
					attributes: ["first_name+ +last_name", "#=:user_type_id"]
				})	
				break;
		
			default:
				if (this.user_type == Task.USER_TYPE.SYSTEM_ADMIN) {
					res = await TaskRoutes.users.api_user_search({
						search: [search, this.user_id],
						attributes: ["first_name+ +last_name", "#!:user_id"]
					})
				} else {
					ut = Task.USER_TYPE.COMPANY_WORKER.toString();
					res = await TaskRoutes.users.api_user_search({
						search: [search, ut],
						attributes: ["first_name+ +last_name", "#=:user_type_id"]
					})
				}
				break;
		}

		return res.data.filter(u => !this.workers.some(w => w.user_id === u.user_id));
	}

	format_user_name(usr: Task.ArrivalWorker) {
		if (!usr) { return ""; }
		return `${usr.first_name} ${usr.last_name}` ;
	}

	writeValue(val: Task.ArrivalWorker[]) {
		val = val || [];

		for (let worker of val) {
			this.workers.push(worker);
		}
	}

	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

	setDisabledState(state: boolean) {
		this.disabled = state;
	}
}
