import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { files } from '@task-utils/utils';


type FileType = (files.IFile | Task.DMSFile)
@Component({
  selector: 'task-file-new',
  template: `


    <div class="file-wrapper" (click)="download_file(file)">
      <div class="file-wrapper-inner">
        <i icon="download" *ngIf="show_icon"></i>
        <span>{{ name() }}</span>
      </div>
      <div class="show_on_hover" *ngIf="show_icon">
            <i icon="download"></i>
        </div>
    </div>

      <div class="file-wrapper" *ngIf="!download_file">
        <div class="file-wrapper-inner">
          <i icon="download" *ngIf="show_icon"></i>
          <span>{{ name() }}</span>
        </div>
      </div>
    `,
  styles: [`

      .file-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .file-wrapper-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: .3em;
          line-break: anywhere;
          padding: .6rem;
      }
  `],
  styleUrls: ['./components.scss'],
  encapsulation: ViewEncapsulation.None

})

export class FileNewComponent implements OnInit {
  @Input()
  file: FileType;
  show_icon:boolean;

  @Input ()
  download_file:(file:FileType)=>Promise<Blob> | null;

  ngOnInit() {
    if ("file" in this.file) {
      this.show_icon = false;
    } else {
      this.show_icon = true;
    }
 }


  name() {
    if ("file" in this.file) {
        return this.file.file.name;
    } else {
      return this.file.dms_document_file_name;
    }
  }

}


