import { Component, ElementRef, Input, ViewEncapsulation, ViewChild, ContentChild, TemplateRef, AfterViewInit } from '@angular/core';
import { Task } from '@task-utils/types';

@Component({
	selector: 'task-click-toggle',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div #container style="display: contents;">
			<ng-container *ngIf="toggle">
				<ng-content select="[on]">
				</ng-content>
			</ng-container>
			<ng-container *ngIf="!toggle">
				<ng-content select="[off]">
				</ng-content>
			</ng-container>
		</div>
	`,
	styles: [`task-responsive-container { display: contents; }`]
})
export class ClickToggle implements AfterViewInit {
	@ViewChild("container")
	container: ElementRef<HTMLDivElement>;
	toggle = false;

	@Input("can-toggle")
	canToggle = true;

	ngAfterViewInit() {
		this.container.nativeElement.addEventListener("click", (ev) => {
			if (this.canToggle && !this.toggle) {
				this.toggle = true;
			}
			ev.stopPropagation();
		});

		document.addEventListener("click", (ev) => {
			if (this.canToggle && this.toggle && !this.container.nativeElement.contains((ev.target) as HTMLElement)) {
				this.toggle = false;
			}
		});
	}
}
