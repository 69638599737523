
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "SUPPLIER.NEW": (is_edit)=> !is_edit ? "New supplier" : "Edit supplier",
        "SUPPLIER.SUPPLIER": "Supplier",
        "SUPPLIER.CIN": "CIN",
        "SUPPLIER.OIB": "ID number",
        "SUPPLIER.PHONE": "Contact phone",
        "SUPPLIER.CONTACT_NAME": "Contact person",
        "SUPPLIER.DELETE.TITLE": "Supplier deletion",
        "SUPPLIER.DELETE.MSG": (supp) => `Are you sure you want to delete the supplier ${supp}?`,
        "SUPPLIER.COMPANY": 'Company',
        "SUPPLIER.ADD_NEW": "Create supplier"

    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "SUPPLIER.NEW": (is_edit) => !is_edit ? "Novi dobavljač" : "Izmjena dobavljača",
        "SUPPLIER.SUPPLIER": "Dobavljač",
        "SUPPLIER.CIN": "Šifra",
        "SUPPLIER.OIB": "OIB",
        "SUPPLIER.PHONE": "Kontakt broj",
        "SUPPLIER.CONTACT_NAME": "Kontakt osoba",
        "SUPPLIER.DELETE.TITLE": "Brisanje dobavljača",
        "SUPPLIER.DELETE.MSG": (supp) => `Jeste li sigurni da želite obrisati dobavljača ${supp}?`,
        "SUPPLIER.COMPANY": 'Tvrtka',
        "SUPPLIER.ADD_NEW": "Dodaj dobavljača"

    }
};

export const suppliers_translations = [english, croatian];
