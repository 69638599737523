import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Curvy } from 'curvy';
import { ImageDirective } from './image.directive';

let directives = [
	ImageDirective,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		Curvy
	],
	declarations: directives,
	exports: directives
})
export class TaskDirectivesModule {}
