import { Component, ViewEncapsulation } from '@angular/core';
import { Frame, language, progress, utils, notification, color, navigation } from 'curvy';
import { login_translations } from './login.trans';
import { User } from '../users/users';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';

@Component({
	selector: 'task-login',
	template: `
		<div class="flex-column" style="margin-bottom: 6rem;">
			<img src="assets/icons/mstile-310x310.png" alt="TASK Management" style="height: 160px; align-self: center; margin-bottom: 3rem;">
			<div class="flex-column form-container" (keyup.enter)="submit()" *ngIf="!showForgotPass" >
				<h2>{{'LOGIN.LOG_IN' | trans }}</h2>
				<label class="input filled" color="primary-dark">
					<i icon="email"></i>
					<input style="margin-left: 0.5em;" [placeholder]="'EMAIL' | trans" type="email" [(ngModel)]="email"/>
				</label>
				<div class="flex-row">
					<label class="input filled flex-dynamic" color="primary-dark">
						<i icon="lock"></i>
						<input style="margin-left: 0.5em;" [placeholder]="'PASS' | trans" [type]="show_pass ? 'text' : 'password'" [(ngModel)]="password"/>
					</label>
					<button class="small round" (click)="toggleShowPass()" color="primary-dark">
						<i [attr.icon]="show_pass ? 'visibility_off' : 'visibility'"></i>
					</button>
				</div>
				<div class="flex-column" style="margin-top: 1.5em;">
					<a href="disable" class="plain-link align-self-flex-end" style="color: var(--primary-light);" (click)="show_pass_reset($event)">
						{{'LOGIN.FORGOTTEN_PASS' | trans}}
					</a>
				</div>
				<button (click)="submit()" color="primary-dark" [disabled]="email.length == 0 || password.length == 0 || ('login' | inProgress)" class="login-btn">{{ "LOGIN.LOGIN" | trans }}</button>
			</div>
			<div class="flex-column form-container" *ngIf="showForgotPass">
			<h2 style="margin-bottom: 0em;">{{ 'LOGIN.PASS_RESET' | trans }}</h2>

			<p style="margin-bottom: 2em;">{{ 'LOGIN.PASS_RESET_DESCR' | trans }}</p>
				<label class="input filled" color="primary-dark">
					<i icon="email"></i>
					<input style="margin-left: 0.5em;" [placeholder]="'EMAIL' | trans" type="email" [(ngModel)]="new_pass_email">
				</label>
				<a href="disable" class="plain-link align-self-flex-end" (click)="show_pass_reset($event)" style="margin-top: 1.5em; color: var(--primary-light);">{{ 'LOGIN.BACK_TO_LOGIN' | trans }}</a>
				<button (click)="submit_new_password()" color="primary-dark" [disabled]="new_pass_email.length == 0" class="login-btn">{{ "LOGIN.SEND_NEW_PASS" | trans }}</button>
			</div>
		</div>

		<!-- <img src="../assets/icons/knowit_logo.svg" alt="KnowIT Informacijske Tehnologije d.o.o."> -->

	`,
	styleUrls: ["./login.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
	email="";
	password="";
	show_pass = false;
	showForgotPass = false;
	new_pass_email="";

	constructor() {
		Frame.set({
			title: "NAVROUTE.LOGIN",
			visible: false,
			layout: "middle",
			size: "full"
		});
		language.load_translations(login_translations);

		let params = new URLSearchParams(window.location.search);
		if (params.has("forgotpass")) {
			this.showForgotPass = true;
		}
	}

	toggleShowPass() {
		this.show_pass = !this.show_pass;
	}

	show_pass_reset(e) {
		e.preventDefault();
		this.showForgotPass = !this.showForgotPass;

		if (this.showForgotPass) {
			this.new_pass_email = "";
		}
	}

	async submit_new_password() {
		if (!this.new_pass_email) return;
		notification.set_default_timeout(30000);

		try {
			await TaskRoutes.auth.api_request_recovery_email({email: this.new_pass_email});
			notification.show({
				icon: "check",
				color: color.Variable.secondary,
				title: language.translate('LOGIN.PASS_RESET.SUCC_TITLE'),
				message: language.translate('LOGIN.PASS_RESET.SUCC_TEXT')
			});

			this.showForgotPass = false;

		} catch(err) {

			// possible multiple errors handling...

		}
	}

	async submit() {
		if (!this.email || !this.password) {
			return;
		}

		let prom = User.logIn({ email: this.email, password: this.password });
		progress.listen('login', prom);

		try {
			await prom;
			let route = await navigation.get_first_elligible_route(navigation.routes);
			utils.router.navigateByUrl(route);
		} catch (err) {
			switch (err.status) {
				case 401:
				case 403:
					notification.show({
						icon: "lock",
						color: color.Variable.warn,
						title: language.translate("LOGIN.CREDENTIALS_TITLE"),
						message: language.translate("LOGIN.CREDENTIALS_TEXT")
					});
					break;

				case 0:
					notification.show({
						icon: "wifi_off",
						color: color.Variable.warn,
						title: language.translate("LOGIN.CONNECTION_TITLE"),
						message: language.translate("LOGIN.CONNECTION_TEXT")
					});
					break;

				default:
					notification.show({
						icon: "error",
						color: color.Variable.warn,
						title: language.translate("LOGIN.ERROR_TITLE"),
						message: language.translate("LOGIN.ERROR_TEXT")
					});
					break;
			}
		}
	}
}
