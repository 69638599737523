import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Frame, language, template } from 'curvy';
import { arrivals_translations } from './arrivals.trans';
import { Arrival } from './arrivals';

@Component({
    selector: 'task-confirm-arrival',
    templateUrl: './confirm-arrival.component.html',
    styleUrls: ['./confirm-arrival.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmArrivalComponent implements OnInit {
    @Input()
    arrival: Task.Arrival;
    src: string = null;
    img: template.EscapedHTML = null;

    constructor() {
        /*Frame.set({
            title: "NAVROUTE.CONFIRM_ARRIVAL",
            isVisible: true,
            layout: "middle",
            size: "full"
        });*/
        language.load_translations(arrivals_translations);
    }

    ngOnInit() {
        Arrival.qr_code(this.arrival.arrival_id).then((res: Blob)=>{
            this.src = URL.createObjectURL(res);
            this.img = template.transform("<img src='{{src}}'/>", { src: this.src });
        });
    }
}
