import { Component, Input } from '@angular/core';
import { Task } from '@task-utils/types';
import { Frame, language } from 'curvy';
import { questionnaires_translations } from './questionnaires.trans';

@Component({
    selector: 'task-edit-questionnaire',
    templateUrl: './edit-questionnaire.component.html',
    styleUrls: ['./edit-questionnaire.component.scss']
})
export class EditQuestionnaireComponent {
    @Input()
    questionnaire: Task.Questionnaire;

    @Input()
    disabled: boolean = false;

    QUESTION_TYPES: { id: number, qtype: string }[] = [];
    component_type: Record<Task.COMPONENT_TYPE, string>;

    constructor() {
        Frame.set({
            title: "NAVROUTE.EDIT_QUESTIONNAIRES",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(questionnaires_translations);

        this.component_type = {...Task.Component_Type};
        // delete this.component_type[Task.COMPONENT_TYPE.DOCUMENT];

        for (let ctype in this.component_type) {
            if (+ctype == +Task.COMPONENT_TYPE.OPTION) continue;
            this.QUESTION_TYPES.push({
                id: +ctype, qtype: Task.Component_Type[ctype]
            });
        }
    }
}
