import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';


// @TODO: Typing.
type InType<T> = {
	title: string;
	label: string;
	search_fn: (a:string)=> Promise<T[]>;
	label_fn?: (a:T)=> string;
	nullable?: boolean;
	required?: boolean;
};

@Component({
	selector: 'task-picker-dialog',
	template: `
		<h2 class="modal-title">{{ injectedData.title | trans }}</h2>
		<div class="flex-column" form-collector #iForm="collector">
			<knw-auto-search
				[function]="injectedData.search_fn"
				[to-label]="injectedData.label_fn"
				[(ngModel)]="search_result"
				[label]="injectedData.label | trans"
				[allow-null]="injectedData.nullable"
				[required]="injectedData.required"
				classes="outlined flex-dynamic">
			</knw-auto-search>
		</div>

		<div class="flex-row modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				{{ 'CANCEL' | trans }}
			</button>

			<div class="flex-dynamic"></div>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="iForm.containsErrors()">
				<i icon="check"></i>
				{{ 'SAVE' | trans }}
			</button>
		</div>
	`
})
export class PickerDialog<T> extends ModalBase<InType<T>, T> {
	preventSoftClose = true;
	search_result: T = null;
	picked_worker: Task.User = null;
	is_internal_supplier = false;


	
	done() {
		this.submit(this.search_result);
	}

	cancel() {
		this.submit(null);
	}
}
