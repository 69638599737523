import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, OnChanges } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { language, notification, color, date } from 'curvy';
import { reports_translations } from './reports.trans';


@Component({
    selector: 'task-report-supplier_admin',
    template: `
        <h2 class="">{{ 'REPORTS.SUPPLIER_ADMIN' | trans }}</h2>
        <div class="flex-row wrappable">
            <knw-date-picker-input
                [(ngModel)]="report.dateFrom"
                [classes]="'filled flex-dynamic'"
                [label]="'STARTDATE'| trans">
            </knw-date-picker-input>

            <knw-date-picker-input
                [(ngModel)]="report.dateTo"
                [classes]="'filled flex-dynamic'"
                [label]="'ENDDATE'| trans">
            </knw-date-picker-input>
        </div>
        <div class="report-btn-wrapper">
            <button class="filled pill report-btn"
                (click)="get_report_supplier_admin()"
                *ngIf="!loading; else isLoading">
                <i icon="download"></i>
                <span>{{ 'REPORTS.DOWNLOAD' | trans }}</span>
            </button>

            <ng-template #isLoading>
                <knw-spinner color="primary" size="50"></knw-spinner>
            </ng-template>
        </div>
        `,
    styleUrls: ['./reports.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class ReportSupplierAdmin implements OnInit {
    report = {
        dateFrom: null as Date,
        dateTo: null as Date,
    };

    loading: boolean = false;

    ngOnInit() {
       language.load_translations(reports_translations);
    }

    getData = () => {
        let parsed_filter = { search: [], attributes: [] };

        if(this.report.dateFrom) {
            this.report.dateFrom.setUTCHours(0, 0, 0, 0);
            parsed_filter.search.push(this.report.dateFrom.toISOString());
            parsed_filter.attributes.push("-d:order.created_date");
        }

        if(this.report.dateTo) {
            this.report.dateTo.setUTCHours(0, 0, 0, 0)
            parsed_filter.search.push(this.report.dateTo.toISOString());
            parsed_filter.attributes.push("+d:order.created_date");
        }

        return parsed_filter;
    }

    get_report_supplier_admin = async () => {
        try{
            this.loading = true;
            await TaskRoutes.orders.api_supplier_order_report_get(this.getData(), true);
        } catch(error) {
            console.error(error.message)
            notification.show({
                icon: "report_problem",
                color: color.Variable.warn,
                title: language.translate("REPORTS.NO_DATA_TITLE"),
                message: language.translate("REPORTS.NO_DATA_DESCR")
            });
        }
        this.loading = false
    }
}
