import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { ScheduledOrderTemplateType } from '../orders.types';



@Component({
  selector: 'task-edit-scheduled-order-dialog',
  encapsulation: ViewEncapsulation.None,
  template: `
    <task-scheduled-order-form [order]="sch_order" form-collector #scheduledOrderForm="collector">
    </task-scheduled-order-form>
    <div class="flex-row modal-footer">
		<button color="primary" (click)="cancel()">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<button class="filled" color="secondary" (click)="done()" [disabled]="scheduledOrderForm.containsErrors()">
			<i icon="check"></i>
			{{ 'SAVE' | trans }}
		</button>

	</div>
    `,
    styles: [`

    .modal task-edit-scheduled-order-dialog {
        max-width: 620px;
        box-sizing: border-box;
        padding-bottom: 70px;
        position: relative;
    }
    @media only screen and (min-width: 601px) {

        /* .modal task-edit-scheduled-order-dialog .modal-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        } */
    }
    `]
})
export class EditScheduledOrderDialog extends ModalBase<ScheduledOrderTemplateType | Promise<ScheduledOrderTemplateType>, Task.ScheduledOrderTemplate> implements OnInit {
    sch_order: ScheduledOrderTemplateType;
    preventSoftClose = true;
    allowSubmit = true;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => this.sch_order = res)
        } else {
            this.sch_order = this.injectedData;
        }
        language.load_translations(orders_translations);
    }

    async done() {
        if(this.sch_order) {

            let res = await TaskRoutes.scheduled_orders_templates.api_scheduled_order_template_modify(
                this.sch_order.scheduled_orders_template_id,
                this.sch_order
            );
            this.submit(res.data);

        } else {
            this.submit(null);
        }
    }

    cancel() {
        this.submit(null);
    }
}
