
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Task } from '@task-utils/types';
import { DataTableComponent, Frame, GenericFilter, GenericFilterComponent, language, TableConfig, template, modal, ConfirmDialogComponent, color } from 'curvy';
import { locations_translations } from './locations.trans';
import { User } from '@task-modules/users';
import { CreateEditLocationDialog } from './create-edit-location.dialog';
import { TaskRoutes } from '@task-utils/routes';

@Component({
    selector: 'task-locations',
    template: `
        <div class="temp-add-button">
            <button (click)="add_location()"
                class="filled pill"
                color="primary">
                <i icon="add_circle"></i>
                <span>{{ 'LOCATION.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #locationstable [config]="tableConfig"></knw-data-table>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-locations {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }
    `]
})
export class LocationsComponent {
    @ViewChild('locationstable') table: DataTableComponent<Task.Location, DisplayLocation, 'locations', GenericFilterComponent>;

    constructor() {
        Frame.set({
            title: "NAVROUTE.LOCATIONS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(locations_translations);
    }

    add_location() {
        modal.open(CreateEditLocationDialog, () => {
            this.table.getData();
        }, {
            cin: "",
            name: "",
            area: "",
            format: "",
            description: "",
            location_type_id: null,
            country: "",
            region: "",
            city: "",
            address: "",
            district: "",
            postal_code: "",
            contact_phone: "",
            email: "",
            location_users: [],
            gross_area: null,
            net_area: null,
            nfc: false
        } as Task.Location)
    };

    async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }


    tableConfig: TableConfig<Task.Location, DisplayLocation, 'locations', GenericFilterComponent> = {
        tableName: 'locations',
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = { search: [], attributes: [], sort: null };
            for (let key in filter) {
                if(key === 'company' && filter['company'] !== null) {
                    parsed_filter.attributes.push('#=:company_id')
                    parsed_filter.search.push(filter[key].company_id)
                }
                else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key]);
                }
            }


            if (
                filter.length === 0 &&
                await User.currentTypeIs(
                    Task.USER_TYPE.SAFETY_MANAGER,
                    Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER)
            ) {
                parsed_filter.attributes.push("location_user.user_id");
                parsed_filter.search.push((await User.currentUserPromise).user_id);
            }

            if (sort_header) {
                switch (sort_header.label) {
                    case "location":
                        sort_header.label = "cin"; break;
                }
                parsed_filter.sort = sort_header;
            }

            return await TaskRoutes.locations.api_location_search_visible(parsed_filter, paginator);
        },
        getHeader: (header) => {
            switch (header) {
                case 'location':
                    return { icon: "place", label: "LOCATION.NAME", sortable: true };

                case 'address':
                    return { icon: "location_city", label: "LOCATION.ADDRESS", sortable: true };

                case 'contact':
                    return { icon: "contacts", label: "LOCATION.CONTACT", sortable: false };

                case 'details':
                    return { icon: "details", label: "DETAILS", sortable: false };

                case 'nfc':
                    return { icon: "contactless", label: "LOCGROUP.LOCATION_NCF", sortable: false };

            }
        },
        unwrapData: (location) => {
            let icon = LocationTypeIcon[location.location_type_id];
            let col = LocationTypeColor[location.location_type_id];
            let tooltip = language.translate(
                "LOCATION.TYPE." + Task.Location_Type[location.location_type_id]
            );

            let status_deleted = language.translate("STATUS.DELETED");
            let deleted = location.status_id === Task.STATUS.DELETED ? `<i icon="block" style="padding: 0 8px 0 0; color: rgba(var(--warn));" tooltip="${status_deleted}"></i>` : '';

            let cin_string = '';
            if (location.cin) {
                cin_string = '<span>#{{cin}}</span>';
            }

            let address: template.EscapedHTML;

            if (location.address) {
                address = template.transform (`<span>{{address}}</span>`, location);
            } else {
                address = template.prebuilt.icon("remove");
            }

            let contact: template.EscapedHTML;

            if (location.email && location.contact_phone) {
                contact = template.transform(`
                    <div class="flex-column align-center justify-center">
                        <a href="mailto:{{email}}">{{email}}</a>
                        <div class="flex-row align-center justify-center">
                            {{phone|icon}}
                            <div style="white-space: nowrap">{{contact_phone}}</div>
                        </div>
                    </div>
                `, location)
            } else if (location.email) {
                contact = template.transform(`
                    <div class="flex-column align-center justify-center">
                        <a href="mailto:{{email}}">{{email}}</a>
                    </div>
                `, location)
            } else if (location.contact_phone) {
                contact = template.transform(`
                    <div class="flex-column align-center justify-center">
                        <div class="flex-row align-center justify-center">
                            {{phone|icon}}
                            <div style="white-space: nowrap">{{contact_phone}}</div>
                        </div>
                    </div>
                `, location)
            } else {
                contact = template.prebuilt.icon("remove");
            }

            let nfc: template.EscapedHTML;
            if (location.nfc) {
                nfc = template.prebuilt.icon("check")
            } else {
                nfc = template.prebuilt.icon("");
            }

            return {
                location: template.transform(`
                    <div class="flex-row align-center" style="width: 100%">
                        <i icon="${icon}"
                            tooltip-right="${tooltip}"
                            style="padding: 0 8px; color: ${col}"></i>
                            ${deleted}
                        <div class="flex-column flex-dynamic align-flex-start">
                            <b style="text-align: left">{{name}}</b>
                            ${cin_string}
                        </div>
                    </div>`, location),
                address: address,
                contact: contact,
                details: template.transform( `
                    <div class="flex-column" style="width: 100%; justify-content: flex-start">
                    <span>
                        ${(location.gross_area) ? 'bruto P: ' + location.gross_area +'m<sup>2</sup>' : ''}
                        </span>
                    <span>
                        ${(location.net_area) ? 'neto P: ' + location.net_area +'m<sup>2</sup>' : ''}
                        </span>
                    </div>
                ` , {
                    gross_area: (location.gross_area) ? location.gross_area : '?',
                    net_area: (location.net_area) ? location.net_area : '?'
                    // net_area: this.testinjo()
                }),
                nfc: nfc
            }
        },
        showHeaderWhenEmpty: true,
        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (location) => {
                modal.open(CreateEditLocationDialog, () => {
                    this.table.getData();
                }, location);
            },
            priority: 1,
            isVisible: ()=>true
        }, {
            label: language.translate('DELETE'),
            icon: "delete",
            color: "warn",
            onClick: async (loc) => {
                modal.open(ConfirmDialogComponent, async(succ)=>{
                    if (succ) {
                        await TaskRoutes.locations.api_location_delete(loc.location_id);
                        this.table.getData();
                    }
                }, {
                    title: language.translate("LOCATION.DELETE.TITLE"),
                    message: language.translate("LOCATION.DELETE.MSG", loc.name),
                    icon: "place",
                    iconColor: color.variable(color.Variable.warn)
                })
            },
            priority: 2,
            isVisible: () => [
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.SUPER_USER].includes(User.currentUser.user_type_id)
        }],
        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("SEARCH", "*I:address+ +city+ +country+ +district+ +email+ +region+ +postal_code+ +name+ +cin"),
                GenericFilter.dropdown("LOCATION.TYPE", "location_type_id", [
                    { label: "LOCATION.TYPE.WAREHOUSE", value: Task.LOCATION_TYPE.WAREHOUSE },
                    { label: "LOCATION.TYPE.STORE", value: Task.LOCATION_TYPE.STORE },
                    { label: "LOCATION.TYPE.BUSINESS_FACILITY", value: Task.LOCATION_TYPE.BUSINESS_FACILITY },
                    { label: "LOCATION.TYPE.LEASED_LOCATION", value: Task.LOCATION_TYPE.LEASED_LOCATION },
                    { label: "LOCATION.TYPE.OFFICE", value: Task.LOCATION_TYPE.OFFICE}

                ]),
                GenericFilter.toggle("LOCGROUP.LOCATION_NCF", "nfc"),
            ]

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
               filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }

            return filters
        }
    }
}

const LocationTypeIcon = {
    [Task.LOCATION_TYPE.STORE]: "store",
    [Task.LOCATION_TYPE.WAREHOUSE]: "home_work",
    [Task.LOCATION_TYPE.OFFICE]: "domain",
    [Task.LOCATION_TYPE.SUPPLIER_LOCATION]: "service_toolbox",
    [Task.LOCATION_TYPE.OTHER]: "other_houses",
    [Task.LOCATION_TYPE.BUSINESS_FACILITY]: "business_center",
    [Task.LOCATION_TYPE.LEASED_LOCATION]: "local_convenience_store"
};

const LocationTypeColor = {
    [Task.LOCATION_TYPE.STORE]: "#55a",
    [Task.LOCATION_TYPE.WAREHOUSE]: "#49f",
    [Task.LOCATION_TYPE.OFFICE]: "#a55",
    [Task.LOCATION_TYPE.SUPPLIER_LOCATION]: Task.STATUS_COLORS.YELLOW,
    [Task.LOCATION_TYPE.OTHER]: Task.STATUS_COLORS.ORANGE,
    [Task.LOCATION_TYPE.BUSINESS_FACILITY]: "#d7c661",
    [Task.LOCATION_TYPE.LEASED_LOCATION]: "#47bf8e"
};

type DisplayLocation = Record<string, any>;
