<!-- <pre style="overflow: scroll; font-size: 9px;">{{ arrival | json }}</pre> -->
<h2 style="margin-bottom: 0">QR -
  <b *ngIf="arrival.status_id == 1">
    {{ 'ARRIVAL.START' | trans}}
  </b>
  <b *ngIf="arrival.status_id == 6">
    {{ 'ARRIVAL.END' | trans}}
  </b>
</h2>
<h4>#{{ arrival.arrival_id }}&nbsp;{{ arrival.order.supplier_name }}</h4>
<div *ngIf="src" [table-display]="img"></div>