<div class="field-grid">
    <label class="input outlined"
           title="{{ 'QUESTION' | trans }}"
           required>
        <i icon="live_help"></i> &nbsp;
        <input placeholder="{{ 'QUESTION' | trans }}"
               required
               [disabled]="disabled"
               [(ngModel)]="questionnaire.question" />
    </label>

    <label class="input outlined"
           title="{{ 'QUESTION.TYPE' | trans }}">
        <i icon="filter_none"></i> &nbsp;
        <select [disabled]="disabled"
                [(ngModel)]="questionnaire.component_type_id">
                <option *ngFor="let type of QUESTION_TYPES"
                        [ngValue]="type.id">
                        {{ ('QUESTION.TYPE.' + type.qtype) | trans }}
                </option>
        </select>
    </label>

    <ng-container *ngIf="questionnaire.component_type_id == 4">
        <label class="input outlined"
                title="{{ 'QUESTION.IMAGE.MIN' | trans }}">
        
            <input placeholder="{{ 'QUESTION.IMAGE.MIN' | trans }}"
                   type="number"
                   integer
                   min="0"
                   max="999"
                   constrain
                   required
                   [disabled]="disabled"
                   [(ngModel)]="questionnaire.min_images" />
        </label>

        <label class="input outlined"
                *ngIf="questionnaire.component_type_id == 4"
                title="{{ 'QUESTION.IMAGE.MAX' | trans }}">
        
            <input placeholder="{{ 'QUESTION.IMAGE.MAX' | trans }}"
                   type="number"
                   integer
                   min="0"
                   max="999"
                   constrain
                   required
                   [disabled]="disabled"
                   [(ngModel)]="questionnaire.max_images" />
        </label>
    </ng-container>

</div>
