import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Frame, language } from 'curvy';
import { Task } from '@task-utils/types';
import { pricelist_translations } from './pricelist.trans';

@Component({
    selector: 'task-create-edit-pricelist',
    template: `
        <h4>{{ 'PRICELIST.PRICELIST' | trans }}</h4>

        <div class="field-grid" form-collector #priceForm="collector">

            <label class="input outlined"
                    title="{{ 'PRICELIST.TITLE' | trans }}">
                <input [(ngModel)]="pricelist.name"
                        required />
            </label>

            <label class="input outlined"
                    title="{{ 'PRICELIST.CIN' | trans }}">
                <input [(ngModel)]="pricelist.cin" />
            </label>

            <label class="input outlined"
                    title="{{ 'PRICELIST.TYPE' | trans }}">
                <select [(ngModel)]="pricelist.element_type_id" required>
                    <option *ngFor="let type of element_types | keyvalue"
                            [ngValue]="+type.key">
                        {{ ("PRICELIST.TYPE." + type.value) | trans }}
                    </option>
                </select>
            </label>

            <!--Work hour type-->
            <label class="input outlined"
                    *ngIf="pricelist.element_type_id === 1"
                    title="{{ 'PRICELIST.QUALIFICATION' | trans }}">
                <select [(ngModel)]="pricelist.work_hour_type_id" required>
                    <option *ngFor="let type of qualification_types | keyvalue"
                            [ngValue]="+type.key">
                        {{ ("PRICELIST.QUALIFICATION." + type.value) | trans }}
                    </option>
                </select>
            </label>

            <label class="input outlined"
                    title="{{ 'PRICELIST.PRICE' | trans }}">
                <input [(ngModel)]="pricelist.price"
                        type="number"
                        conditions="range=0-9999999999"
                        required />
            </label>

            <label class="input outlined"
                    title="{{ 'PRICELIST.CURRENCY' | trans }}">
                <input [(ngModel)]="pricelist.currency" required />
            </label>

            <label class="input outlined"
                    title="{{ 'PRICELIST.UOM' | trans }}">
                <input [(ngModel)]="pricelist.unit_of_measure" />
            </label>

            <label class="input outlined" title="{{ 'PRICELIST.WARRANTY_PERIOD' | trans }}">
                <input [(ngModel)]="pricelist.warranty_period"
                        type="number"
                        min="0"
                        max="9999999999" />
                &nbsp;{{ 'PRICELIST.WARRANTY_PERIOD.MONTHS' | trans }}
            </label>

            <label class="checkbox-input">
                <input type="checkbox"
                        [(ngModel)]="pricelist.lump_sum" />
                {{ 'PRICELIST.LUMP_SUM' | trans }}
            </label>

        </div>

        <div class="flex-row flex-static modal-footer">
            <button color="primary"
                    (click)="cancel()">
                <i icon="clear"></i>
                <span>{{ 'CANCEL' | trans }}</span>
            </button>
            <button class="filled"
                    color="secondary"
                    (click)="done()"
                    [disabled]="priceForm.containsErrors()">
                <i icon="check"></i>
                <span>{{ ( is_edit_form ? 'CREATE' : 'SAVE' ) | trans }}</span>
            </button>
        </div>
    `
})
export class CreateEditPricelistComponent implements OnChanges {
    @Input()
    pricelist: Omit<Task.Pricelist, 'company_id'|'invoice_type_id'|'work_description_id'|'to_company_id'> = {
        cin: "",
        currency: "",
        element_id: null,
        element_type_id: null,
        lump_sum: false,
        name: "",
        price: 0,
        unit_of_measure: "",
        warranty_period: 0,
        work_hour_type_id: null,
        description: ""
    };

    @Output()
    result = new EventEmitter<Task.Pricelist>();

    element_types = Task.Element_Type;
    qualification_types = Task.Work_Hour_Type;
    is_edit_form = false;

    constructor() {
        language.load_translations(pricelist_translations);
    }

    ngOnChanges(changes) {
        if (changes["pricelist"]) {
            this.pricelist = {...changes["pricelist"].currentValue};
        }

        this.is_edit_form = this.pricelist.element_id == undefined;
    }

    done() {
        this.result.emit(this.pricelist as Task.Pricelist);
    }

    cancel() {
        this.result.emit(null);
    }
}
