import { Structs } from "./utils";

type RequiredKeys<T> = { [K in keyof T]-?: ({} extends { [P in K]: T[K] } ? never : K) }[keyof T];
type OptionalKeys<T> = { [K in keyof T]-?: ({} extends { [P in K]: T[K] } ? K : never) }[keyof T];
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends (<T>() => T extends Y ? 1 : 2) ? A : B;
type WritableKeys<T> = { [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P> }[keyof T];
type ReadonlyKeys<T> = { [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, never, P> }[keyof T];
export type Readonlies<T> = Pick<T, ReadonlyKeys<T>>;
export type Writables<T> = Pick<T, WritableKeys<T>>;
export type Optionals<T> = Pick<T, OptionalKeys<T>>;
export type Requireds<T> = Pick<T, RequiredKeys<T>>;
export type Full<T> = { [P in keyof T]-?: P };
export type EnumDict<T extends string | symbol | number, U> = { [K in T]: U };
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export namespace Task {
	export interface CalendarEvent {
		status_id: Task.STATUS,
		event_id: number,
		event_title: string,
		supplier_name: string,
		supplier_id: number,
		location_name: string,
		location_id: number,
		location_cin: string,
		// day: number,
		date: Date,
		url?: string,
		has_own_url: boolean,
		type: "ORDER" | "ARRIVAL" | "SCHEDULED_ORDER"
	}

	export interface ScheduledEvent {
		approved_or_rejected_by_first_name?: string,
		approved_or_rejected_by_id?: number,
		approved_or_rejected_by_last_name?: string,
		approved_or_rejected_date?: TIMESTAMP,
		comment?: string,
		event_id: number,
		event_details: ({
			scheduled_orders_template_detail_id?: number,
			scheduled_orders_template_id?: number,
			order_detail_type_id: Task.ORDER_DETAIL_TYPE,
			picture_url?: string,
			inventory_id?: number,
			inventory_no?: string,
			inventory_name?: string,
			inventory_serial_no?: string,
			inventory_data?: Task.KAM_Inventory,
			description?: string
		} | {
			scheduled_order_detail_id?: number,
			scheduled_order_id?: number,
			order_detail_type_id: Task.ORDER_DETAIL_TYPE,
			picture_url?: string,
			inventory_id?: number,
			inventory_no?: string,
			inventory_name?: string,
			inventory_serial_no?: string,
			inventory_data?: Task.KAM_Inventory,
			description?: string,
		})[];
		status_id: Task.STATUS,
		planned_end_date: TIMESTAMP
		planned_start_date: TIMESTAMP,
		cut_date: TIMESTAMP,
		annual_frequency: number,
		locations: Location[],
		investment_type: number,
		created_by_first_name?: string,
		created_by_last_name?: string,
		created_by_id?: number,
		created_date?: TIMESTAMP,
		supplier_id: number,
		supplier_name: string,
		title: string,
		type: "SCHEDULED_ORDER" | "TEMPLATE",
		whier: WorkHierarchy,
		responsible_users: string[] | null,
		arrival_worker?: User;
		order_id?: number;
	}

	export interface CalendarArrival {
		arrival_id: number,
		created_date: TIMESTAMP,
		end_time: TIMESTAMP,
		location_cin: string,
		location_id: number,
		location_name: string,
		planned_start_time: TIMESTAMP,
		start_time: TIMESTAMP,
		status_id: number,
		supplier_id: number,
		supplier_name: string,
		title: string
	}

	export interface CalendarOrder {
		order_id: number,
		created_date: TIMESTAMP,
		location_cin: string,
		location_id: number,
		location_name: string,
		status_id: number,
		supplier_id: number,
		supplier_name: string,
		title: string
	}

	export interface CalendarScheduledOrder {
		scheduled_order_id: number,
		created_date: TIMESTAMP,
		location_cin: string,
		location_id: number,
		location_name: string,
		order_status_id: number,
		planned_end_date: TIMESTAMP,
		status_id: number,
		supplier_id: number,
		supplier_name: string,
		title: string
	}

	export interface DMSFile {
		dms_document_file_name: string,
		dms_document_id: number,
		order_detail_id?: number,
		arrival_detail_id?: number,
		ticket_detail_id?: number
	}

	export interface Created {
		readonly created_date: TIMESTAMP;
		readonly created_by_id: number;
		readonly created_by_first_name?: string;
		readonly created_by_last_name?: string;
		readonly created_by_pic_url?: string;
	}

	export interface Modified {
		readonly modified_date?: TIMESTAMP;
		readonly modified_by_id?: number;
		readonly modified_by_first_name?: string;
		readonly modified_by_last_name?: string;
		readonly modified_by_pic_url?: string;
	}

	export interface Payload<T> {
		status: string;
		message: string;
		count: number;
		data: T;
	};

	export type Response<T> = Promise<Payload<T>>;

	export type TIMESTAMP = Date | string;
	export type TIME = `${number}:${number}:${number}`;

	export interface KAM_Inventory {
		inventory_brand_name: string;
		inventory_category_name: string;
		inventory_group_name: string;
		inventory_id: number;
		inventory_name: string;
		inventory_no: string;
		inventory_serial_no: string;
		inventory_subcategory_name: string;
		inventory_supplier_name: string;
		inventory_warranty_period: string;
		inventory_invoice_date?: string;
		inventory_invoice_no?: string;
		udas: {
			uda_name: string;
			uda_value: string;
		}[];
	};

	// Colors
	export const STATUS_COLORS = {
		BLUE: "#49f",
		ORANGE: "#f70",
		GREEN: "#5a5",
		RED: "#f66",
		GRAY: "#999",
		YELLOW: "#fbb014",
		BRIGHT_YELLOW: "#f5d020"
	} as const;

	// Icons
	export const StatusIcons = {
		// [STATUS.DEFAULT]: Structs.icon("radio_button_unchecked", STATUS_COLORS.BLUE),
		// [STATUS.ACTIVE]: Structs.icon("radio_button_unchecked", STATUS_COLORS.BLUE),
		[STATUS.CREATED]: Structs.icon("radio_button_unchecked", STATUS_COLORS.BLUE),
		[STATUS.INACTIVE]: Structs.icon("pause_circle", STATUS_COLORS.GRAY),
		[STATUS.DELETED]: Structs.icon("cancel", STATUS_COLORS.RED),
		[STATUS.APPROVED]: Structs.icon("recommend", STATUS_COLORS.ORANGE),
		[STATUS.REJECTED]: Structs.icon("motion_photos_off", STATUS_COLORS.RED),
		[STATUS.IN_EXECUTION]: Structs.icon("build_circle", STATUS_COLORS.YELLOW),
		[STATUS.EXECUTED]: Structs.icon("check_circle", STATUS_COLORS.GREEN),
		[STATUS.INVOICED]: Structs.icon("paid", STATUS_COLORS.GREEN),
		[STATUS.UNDONE]: Structs.icon("do_not_disturb", STATUS_COLORS.RED),
		[STATUS.ON_COMPANY_REVIEW]: Structs.icon("play_circle_filled", STATUS_COLORS.BLUE),
		[STATUS.ON_SUPPLIER_REVIEW]: Structs.icon("play_circle_outline", STATUS_COLORS.BLUE),
		[STATUS.ON_HOLD]: Structs.icon("schedule", STATUS_COLORS.BLUE),
		[STATUS.WORKS_FINISHED]: Structs.icon("fact_check", STATUS_COLORS.GREEN),
		// [STATUS.HAS_ARRIVAL]: Structs.icon( "radio_button_checked", STATUS_COLORS.BRIGHT_YELLOW ),

	};

	export const UNKNOWN_ICON = Structs.icon("question", "#ff0");
	export const HAS_ARRIVAL_ICON = Structs.icon("radio_button_checked", Task.STATUS_COLORS.BRIGHT_YELLOW);

	export const PriorityIcons = {
		[Task.PRIORITY.HIGH]: Structs.icon('priority_high', "#f49"),
		[Task.PRIORITY.MEDIUM]: Structs.icon('circle', "#49f")
	};

	export const ElementIcons = {
		[Task.ELEMENT_TYPE.FIELD_VISIT]: Structs.icon('home_repair_service', STATUS_COLORS.ORANGE),
		[Task.ELEMENT_TYPE.MATERIAL]: Structs.icon('construction', STATUS_COLORS.BLUE),
		[Task.ELEMENT_TYPE.TRANSPORTATION]: Structs.icon('drive_eta', STATUS_COLORS.GREEN),
		[Task.ELEMENT_TYPE.WORKING_HOUR]: Structs.icon('schedule', STATUS_COLORS.RED),
		[Task.ELEMENT_TYPE.MECHANICAL_WORKS]: Structs.icon('build', STATUS_COLORS.YELLOW),
	};

	export function get_element_icon(element_id: ELEMENT_TYPE) {
		let icon = ElementIcons[element_id];
		if (icon) { return icon; }
		console.log(`Unknown icon for element ${element_id}(${Element_Type[element_id]})!`);
		return UNKNOWN_ICON;
	}

	export function get_status_icon(status_id: STATUS) {
		let icon = StatusIcons[status_id];

		if (icon) { return icon; }

		console.log(`Unknown icon for status ${status_id}(${Status[status_id]})!`);
		return UNKNOWN_ICON;
	}

	export function get_priority_icon(priority_id: PRIORITY) {
		let icon = PriorityIcons[priority_id];

		if (icon) { return icon; }

		console.log(`Unknown icon for priority ${priority_id}(${Priority[priority_id]})!`);
		return UNKNOWN_ICON;
	}

	export function enum_array<T extends number>(enum_dict: EnumDict<T, any>): EnumStruct<T>[] {
		let arr: EnumStruct<T>[] = [];

		for (let key in enum_dict) {
			arr.push({
				key: (+key) as T,
				value: enum_dict[key]
			});
		}

		return arr;
	}

	export type EnumStruct<T extends number> = {
		key: T,
		value: string
	};


	export type Bisection<T> = { included: T[], excluded: T[] };
	export function array_bisect<T>(arr: T[], filter: (arg: T) => boolean): Bisection<T> {
		let included: T[] = [];
		let excluded: T[] = [];

		for (let obj of arr) {
			if (filter(obj)) {
				included.push(obj);
			} else {
				excluded.push(obj);
			}
		}

		return { included, excluded };
	}
}

export namespace Task {
	export const enum ANNUAL_FREQUENCY {
		DAILY = 6,
		WEEKLY = 5,
		MONTHLY = 4,
		QUARTERLY = 3,
		HALF_YEARLY = 2,
		YEARLY = 1,
	}

	export const Annual_Frequency: EnumDict<ANNUAL_FREQUENCY, string> = {
		[ANNUAL_FREQUENCY.DAILY]: "DAILY",
		[ANNUAL_FREQUENCY.WEEKLY]: "WEEKLY",
		[ANNUAL_FREQUENCY.MONTHLY]: "MONTHLY",
		[ANNUAL_FREQUENCY.QUARTERLY]: "QUARTERLY",
		[ANNUAL_FREQUENCY.HALF_YEARLY]: "HALF_YEARLY",
		[ANNUAL_FREQUENCY.YEARLY]: "YEARLY",
	}

	export const enum ARRIVAL_DETAIL_TYPE {
		WORK_DESCRIPTION = 1,
		WORK_COMMENTARY = 2,
		WORK_ELEMENT = 3,
		WORK_UNFINISHED_REASON = 4,
		FINANCIAL_DOCUMENT = 5,
		SERVICE_AND_SUPPORT_DOCUMENT = 6,
	}

	export const Arrival_Detail_Type: EnumDict<ARRIVAL_DETAIL_TYPE, string> = {
		[ARRIVAL_DETAIL_TYPE.WORK_DESCRIPTION]: "WORK_DESCRIPTION",
		[ARRIVAL_DETAIL_TYPE.WORK_COMMENTARY]: "WORK_COMMENTARY",
		[ARRIVAL_DETAIL_TYPE.WORK_ELEMENT]: "WORK_ELEMENT",
		[ARRIVAL_DETAIL_TYPE.WORK_UNFINISHED_REASON]: "WORK_UNFINISHED_REASON",
		[ARRIVAL_DETAIL_TYPE.FINANCIAL_DOCUMENT]: "FINANCIAL_DOCUMENT",
		[ARRIVAL_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT]: "SERVICE_AND_SUPPORT_DOCUMENT",
	}

	export const enum COMPANY_TYPE {
		MAIN = 1,
		EXTERNAL_SUPPLIER = 2,
		INTERNAL_SUPPLIER = 3,
	}

	export const Company_Type: EnumDict<COMPANY_TYPE, string> = {
		[COMPANY_TYPE.MAIN]: "MAIN",
		[COMPANY_TYPE.EXTERNAL_SUPPLIER]: "EXTERNAL_SUPPLIER",
		[COMPANY_TYPE.INTERNAL_SUPPLIER]: "INTERNAL_SUPPLIER",
	}

	export const enum COMPONENT_TYPE {
		TOGGLE = 1,
		OPTION = 2,
		TEXT = 3,
		IMAGE = 4,
		INVENTORY = 5,
		FINANCIAL_DOCUMENT = 6,
		SERVICE_AND_SUPPORT_DOCUMENT = 7,
	}

	export const Component_Type: EnumDict<COMPONENT_TYPE, string> = {
		[COMPONENT_TYPE.TOGGLE]: "TOGGLE",
		[COMPONENT_TYPE.OPTION]: "OPTION",
		[COMPONENT_TYPE.TEXT]: "TEXT",
		[COMPONENT_TYPE.IMAGE]: "IMAGE",
		[COMPONENT_TYPE.INVENTORY]: "INVENTORY",
		[COMPONENT_TYPE.FINANCIAL_DOCUMENT]: "FINANCIAL_DOCUMENT",
		[COMPONENT_TYPE.SERVICE_AND_SUPPORT_DOCUMENT]: "SERVICE_AND_SUPPORT_DOCUMENT",
	}

	export const enum ELEMENT_TYPE {
		WORKING_HOUR = 1,
		MATERIAL = 2,
		TRANSPORTATION = 3,
		FIELD_VISIT = 4,
		MECHANICAL_WORKS = 5,
	}

	export const Element_Type: EnumDict<ELEMENT_TYPE, string> = {
		[ELEMENT_TYPE.WORKING_HOUR]: "WORKING_HOUR",
		[ELEMENT_TYPE.MATERIAL]: "MATERIAL",
		[ELEMENT_TYPE.TRANSPORTATION]: "TRANSPORTATION",
		[ELEMENT_TYPE.FIELD_VISIT]: "FIELD_VISIT",
		[ELEMENT_TYPE.MECHANICAL_WORKS]: "MECHANICAL_WORKS",
	}

	export const enum ENTITY {
		USER = 1,
		SUPPLIER = 2,
		LOCATION = 3,
		TICKET = 4,
		TICKET_DETAIL = 5,
		ORDER = 6,
		ORDER_DETAIL = 7,
		ARRIVAL = 8,
		ARRIVAL_DETAIL = 9,
	}

	export const Entity: EnumDict<ENTITY, string> = {
		[ENTITY.USER]: "USER",
		[ENTITY.SUPPLIER]: "SUPPLIER",
		[ENTITY.LOCATION]: "LOCATION",
		[ENTITY.TICKET]: "TICKET",
		[ENTITY.TICKET_DETAIL]: "TICKET_DETAIL",
		[ENTITY.ORDER]: "ORDER",
		[ENTITY.ORDER_DETAIL]: "ORDER_DETAIL",
		[ENTITY.ARRIVAL]: "ARRIVAL",
		[ENTITY.ARRIVAL_DETAIL]: "ARRIVAL_DETAIL",
	}

	export const enum INVESTMENT_TYPE {
		EA = 1,
		DA = 2,
	}

	export const Investment_Type: EnumDict<INVESTMENT_TYPE, string> = {
		[INVESTMENT_TYPE.EA]: "EA",
		[INVESTMENT_TYPE.DA]: "DA",
	}

	export const enum INVOICE_TYPE {
		PER_ARRIVAL = 1,
		MONTHLY = 2,
	}

	export const Invoice_Type: EnumDict<INVOICE_TYPE, string> = {
		[INVOICE_TYPE.PER_ARRIVAL]: "PER_ARRIVAL",
		[INVOICE_TYPE.MONTHLY]: "MONTHLY",
	}

	export const enum LOCATION_TYPE {
		STORE = 1,
		WAREHOUSE = 2,
		OFFICE = 3,
		SUPPLIER_LOCATION = 4,
		OTHER = 5,
		BUSINESS_FACILITY = 6,
		LEASED_LOCATION = 7,
	}

	export const Location_Type: EnumDict<LOCATION_TYPE, string> = {
		[LOCATION_TYPE.STORE]: "STORE",
		[LOCATION_TYPE.WAREHOUSE]: "WAREHOUSE",
		[LOCATION_TYPE.OFFICE]: "OFFICE",
		[LOCATION_TYPE.SUPPLIER_LOCATION]: "SUPPLIER_LOCATION",
		[LOCATION_TYPE.OTHER]: "OTHER",
		[LOCATION_TYPE.BUSINESS_FACILITY]: "BUSINESS_FACILITY",
		[LOCATION_TYPE.LEASED_LOCATION]: "LEASED_LOCATION",
	}

	export const enum ORDER_DETAIL_TYPE {
		DESCRIPTION = 1,
		IMAGE = 2,
		INVENTORY = 3,
		FINANCIAL_DOCUMENT = 4,
		SERVICE_AND_SUPPORT_DOCUMENT = 5,
	}

	export const Order_Detail_Type: EnumDict<ORDER_DETAIL_TYPE, string> = {
		[ORDER_DETAIL_TYPE.DESCRIPTION]: "DESCRIPTION",
		[ORDER_DETAIL_TYPE.IMAGE]: "IMAGE",
		[ORDER_DETAIL_TYPE.INVENTORY]: "INVENTORY",
		[ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT]: "FINANCIAL_DOCUMENT",
		[ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT]: "SERVICE_AND_SUPPORT_DOCUMENT",
	}

	export const enum PERMISSIONS {
		VISIBILITY_ALL = 1,
		VISIBILITY_OWN = 2,
		RESPONSIBILITY_ALL = 3,
		RESPONSIBILITY_OWN = 4,
	}

	export const Permissions: EnumDict<PERMISSIONS, string> = {
		[PERMISSIONS.VISIBILITY_ALL]: "VISIBILITY_ALL",
		[PERMISSIONS.VISIBILITY_OWN]: "VISIBILITY_OWN",
		[PERMISSIONS.RESPONSIBILITY_ALL]: "RESPONSIBILITY_ALL",
		[PERMISSIONS.RESPONSIBILITY_OWN]: "RESPONSIBILITY_OWN",
	}

	export const enum PRIORITY {
		HIGH = 1,
		MEDIUM = 2,
		LOW = 3,
	}

	export const Priority: EnumDict<PRIORITY, string> = {
		[PRIORITY.HIGH]: "HIGH",
		[PRIORITY.MEDIUM]: "MEDIUM",
		[PRIORITY.LOW]: "LOW",
	}

	export const enum QR_CODE_TYPE {
		ARRIVAL_START = 1,
		ARRIVAL_END = 2,
	}

	export const Qr_Code_Type: EnumDict<QR_CODE_TYPE, string> = {
		[QR_CODE_TYPE.ARRIVAL_START]: "ARRIVAL_START",
		[QR_CODE_TYPE.ARRIVAL_END]: "ARRIVAL_END",
	}

	export const enum STATUS {
		CREATED = 1,
		INACTIVE = 2,
		DELETED = 3,
		APPROVED = 4,
		REJECTED = 5,
		IN_EXECUTION = 6,
		EXECUTED = 7,
		INVOICED = 8,
		ON_HOLD = 9,
		UNDONE = 10,
		ON_COMPANY_REVIEW = 11,
		ON_SUPPLIER_REVIEW = 12,
		WORKS_FINISHED = 13,
	}

	export const Status: EnumDict<STATUS, string> = {
		[STATUS.CREATED]: "CREATED",
		[STATUS.INACTIVE]: "INACTIVE",
		[STATUS.DELETED]: "DELETED",
		[STATUS.APPROVED]: "APPROVED",
		[STATUS.REJECTED]: "REJECTED",
		[STATUS.IN_EXECUTION]: "IN_EXECUTION",
		[STATUS.EXECUTED]: "EXECUTED",
		[STATUS.INVOICED]: "INVOICED",
		[STATUS.ON_HOLD]: "ON_HOLD",
		[STATUS.UNDONE]: "UNDONE",
		[STATUS.ON_COMPANY_REVIEW]: "ON_COMPANY_REVIEW",
		[STATUS.ON_SUPPLIER_REVIEW]: "ON_SUPPLIER_REVIEW",
		[STATUS.WORKS_FINISHED]: "WORKS_FINISHED",
	}

	export const enum UNIT_OF_MEASURE {
		CENTIMETER = `cm`,
		METER = `m`,
		KILOMETER = `km`,
		SQUARE_METER = `m2`,
		CUBIC_METER = `m3`,
		KILOGRAM = `kg`,
		LITER = `l`,
		SERVICE = `usluga`,
		HOUR = `h`,
		PIECE = `kom`,
		OTHER = ``,
	}

	export const Unit_Of_Measure: EnumDict<UNIT_OF_MEASURE, string> = {
		[UNIT_OF_MEASURE.CENTIMETER]: "CENTIMETER",
		[UNIT_OF_MEASURE.METER]: "METER",
		[UNIT_OF_MEASURE.KILOMETER]: "KILOMETER",
		[UNIT_OF_MEASURE.SQUARE_METER]: "SQUARE_METER",
		[UNIT_OF_MEASURE.CUBIC_METER]: "CUBIC_METER",
		[UNIT_OF_MEASURE.KILOGRAM]: "KILOGRAM",
		[UNIT_OF_MEASURE.LITER]: "LITER",
		[UNIT_OF_MEASURE.SERVICE]: "SERVICE",
		[UNIT_OF_MEASURE.HOUR]: "HOUR",
		[UNIT_OF_MEASURE.PIECE]: "PIECE",
		[UNIT_OF_MEASURE.OTHER]: "OTHER",
	}

	export const enum USER_TYPE {
		SYSTEM_ADMIN = 1,
		ADMIN = 2,
		SUPER_USER = 3,
		AUTHORIZED_PERSONEL = 4,
		REGIONAL_MAINTENANCE_MANAGER = 5,
		REGIONAL_RETAIL_MANAGER = 6,
		STORE_MANAGER = 7,
		SUPPLIER_ADMIN = 9,
		SUPPLIER_WORKER = 10,
		COMPANY_WORKER = 12,
		SAFETY_MANAGER = 13,
		MANAGER_1 = 14,
		MANAGER_2 = 15,
	}

	export const User_Type: EnumDict<USER_TYPE, string> = {
		[USER_TYPE.SYSTEM_ADMIN]: "SYSTEM_ADMIN",
		[USER_TYPE.ADMIN]: "ADMIN",
		[USER_TYPE.SUPER_USER]: "SUPER_USER",
		[USER_TYPE.AUTHORIZED_PERSONEL]: "AUTHORIZED_PERSONEL",
		[USER_TYPE.REGIONAL_MAINTENANCE_MANAGER]: "REGIONAL_MAINTENANCE_MANAGER",
		[USER_TYPE.REGIONAL_RETAIL_MANAGER]: "REGIONAL_RETAIL_MANAGER",
		[USER_TYPE.STORE_MANAGER]: "STORE_MANAGER",
		[USER_TYPE.SUPPLIER_ADMIN]: "SUPPLIER_ADMIN",
		[USER_TYPE.SUPPLIER_WORKER]: "SUPPLIER_WORKER",
		[USER_TYPE.COMPANY_WORKER]: "COMPANY_WORKER",
		[USER_TYPE.SAFETY_MANAGER]: "SAFETY_MANAGER",
		[USER_TYPE.MANAGER_1]: "MANAGER_1",
		[USER_TYPE.MANAGER_2]: "MANAGER_2",
	}

	export const enum WHIER_LEVEL {
		CATEGORY = 1,
		SUBCATEGORY = 2,
		GROUP = 3,
	}

	export const Whier_Level: EnumDict<WHIER_LEVEL, string> = {
		[WHIER_LEVEL.CATEGORY]: "CATEGORY",
		[WHIER_LEVEL.SUBCATEGORY]: "SUBCATEGORY",
		[WHIER_LEVEL.GROUP]: "GROUP",
	}

	export const enum WORK_GROUP_TYPE {
		ADMIN = 1,
		SUPER_GROUP = 2,
		AUTHORIZED_GROUP = 3,
		REGIONAL_MAINTENANCE_GROUP = 4,
		REGIONAL_RETAIL_GROUP = 5,
	}

	export const Work_Group_Type: EnumDict<WORK_GROUP_TYPE, string> = {
		[WORK_GROUP_TYPE.ADMIN]: "ADMIN",
		[WORK_GROUP_TYPE.SUPER_GROUP]: "SUPER_GROUP",
		[WORK_GROUP_TYPE.AUTHORIZED_GROUP]: "AUTHORIZED_GROUP",
		[WORK_GROUP_TYPE.REGIONAL_MAINTENANCE_GROUP]: "REGIONAL_MAINTENANCE_GROUP",
		[WORK_GROUP_TYPE.REGIONAL_RETAIL_GROUP]: "REGIONAL_RETAIL_GROUP",
	}

	export const enum WORK_HOUR_TYPE {
		NKV = 1,
		KV = 2,
		VKV = 3,
	}

	export const Work_Hour_Type: EnumDict<WORK_HOUR_TYPE, string> = {
		[WORK_HOUR_TYPE.NKV]: "NKV",
		[WORK_HOUR_TYPE.KV]: "KV",
		[WORK_HOUR_TYPE.VKV]: "VKV",
	}

	export interface Arrival {
		arrival_id: number;
		status_id: Task.STATUS;
		order_id: number;
		order: {
			order_id: number;
			title: string;
			status_id: Task.STATUS;
			supplier_name: string|`Interno`;
			location_name: string;
			arrival_worker_id: number;
			company_id: number;
			company_name: string;
			supplier_id: number;
			location_id: number;
			location_address: string;
			location_cin: string;
			mail_sent_to_supplier: boolean;
			total_minutes: number;
			total_arrivals: number;
			active_arrivals: number;
			arrival_in_execution: boolean;
			next_arrival_date: null|TIMESTAMP;
			status_date: TIMESTAMP;
			comment: string;
			invoice_no: string;
			in_warranty_period: boolean;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_last_name: string;
			created_by_pic_url: null;
			insurance: boolean;
			lease: boolean;
			preinvoice: boolean;
			inspection: boolean;
			complaint: boolean;
			scheduled_order_id: number;
			is_generated_from_scheduled_order: boolean;
			authorized_maintenance_managers: Array<{
				user_id: number;
				first_name: string;
				last_name: string;
			}>;
			approved_by_id: number;
			approved_by_first_name: null|string;
			approved_by_last_name: null|string;
			arrival_worker_first_name: null|string;
			arrival_worker_last_name: null|string;
			internal_approval_mail_sent: null|boolean;
			ping_pong_count: number;
			cost_estimate: number;
			cost_estimate_currency: string;
			investment_type: number;
			whier: {
				category_description: string;
				whier_id: number;
				name: string;
				description: string;
				subcategory_description: string;
			};
			order_details: Array<{
				order_detail_id: number;
				order_id: number;
				order_detail_type_id: number;
				description: string;
				dms_document_id: number;
				dms_document_file_name: string;
				picture_url: string;
				inventory_id: number;
				inventory_name: string;
				inventory_no: string;
				inventory_serial_no: string;
				inventory_data: any;
				created_date: TIMESTAMP;
				created_by_id: number;
				created_by_first_name: string;
				created_by_last_name: string;
			}>;
		};
		company_id: number;
		company_name: string;
		supplier_name: string;
		planned_start_time: TIMESTAMP;
		start_time: TIMESTAMP;
		end_time: TIMESTAMP;
		no_of_workers: number;
		planned_no_of_workers: number;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: null;
		started_by_id: number;
		started_by_first_name: null|string;
		started_by_last_name: null|string;
		started_by_pic_url: null;
		ended_by_id: number;
		ended_without_qr: boolean;
		ended_by_first_name: null|string;
		ended_by_last_name: null|string;
		ended_by_pic_url: null;
		emergency: boolean;
		nfc: boolean;
		arrival_details: Array<{
			arrival_detail_id: number;
			arrival_id: number;
			arrival_status_id: Task.STATUS;
			arrival_detail_type_id: Task.ARRIVAL_DETAIL_TYPE;
			description: string;
			dms_document_id: number;
			dms_document_file_name: string;
			approved: boolean;
			comment: string;
			picture_url: string;
			element_id: number;
			element_type_id: number;
			element_name: string;
			element_cin: string;
			element_uom: string;
			element_quantity: number;
			element_old_quantity: number;
			inventory_id: number;
			inventory_name: string;
			inventory_no: string;
			inventory_serial_no: string;
			inventory_data: any;
			lump_sum: boolean;
			old_lump_sum: boolean;
			element_associated_with_inventory: boolean;
			in_warranty_period: boolean;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_type_id: Task.USER_TYPE;
			created_by_last_name: string;
			created_by_pic_url: null;
			work_description_id: number;
			work_description: string;
			is_approved_at_location: boolean;
			element_price: number;
			element_old_price: number;
			element_currency: `?`|string;
		}>;
		arrival_workers: Array<{
			arrival_worker_id: number;
			arrival_id: number;
			first_name: string;
			user_id: number;
			last_name: string;
			description: string;
			work_hour_type_id: number;
			created_date: TIMESTAMP;
			created_by_first_name: string;
			created_by_last_name: string;
		}>;
	}

	export interface ArrivalDetail {
		arrival_detail_id: number;
		arrival_id: number;
		arrival_status_id: Task.STATUS;
		arrival_detail_type_id: Task.ARRIVAL_DETAIL_TYPE;
		description: string;
		dms_document_id: number;
		dms_document_file_name: string;
		approved: boolean;
		comment: string;
		picture_url: string;
		element_id: number;
		element_type_id: number;
		element_name: string;
		element_cin: string;
		element_uom: string;
		element_quantity: number;
		element_old_quantity: number;
		inventory_id: number;
		inventory_name: string;
		inventory_no: string;
		inventory_serial_no: string;
		inventory_data: KAM_Inventory;
		lump_sum: boolean;
		old_lump_sum: boolean;
		element_associated_with_inventory: boolean;
		in_warranty_period: boolean;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_type_id: Task.USER_TYPE;
		created_by_last_name: string;
		created_by_pic_url: null;
		work_description_id: number;
		work_description: string;
		is_approved_at_location: boolean;
		element_price: number;
		element_old_price: number;
		element_currency: `?`|string;
	}

	export interface ArrivalWorker {
		arrival_worker_id: number;
		arrival_id: number;
		first_name: string;
		user_id: number;
		last_name: string;
		description: string;
		work_hour_type_id: number;
		created_date: TIMESTAMP;
		created_by_first_name: string;
		created_by_last_name: string;
	}

	export interface CheckListAnswer {
		checklist_question_id: number;
		whier_id: number;
		name: string;
		description: string;
		created_date: TIMESTAMP;
		checklist_answer_id: number;
		location_id: number;
		location_name: string;
		location_address: string;
		location_cin: string;
		due_date: TIMESTAMP;
		checklist_ok: boolean;
		user_id: number;
		completed_at: TIMESTAMP;
		ticket_id: number;
		checklist_answer_details: Array<{
			checklist_answer_detail_id: number;
			checklist_answer_detail_type_id: number;
			picture_url: string;
		}>;
	}



	export interface CheckListQuestion {
		checklist_question_id: number;
		whier_id: number;
		whier: null|{
			category_description: string;
			whier_id: number;
			name: string;
			description: string;
			subcategory_description: string;
		};
		name: string;
		description: string;
		created_date: TIMESTAMP;
		frequency: number;
		locations: Array<{
			location_id: number;
			name: string;
			cin: string;
			address: string;
		}>;
		location_groups: Array<{
			location_group_id: number;
			name: string;
		}>;
	}

	export interface Comment {
		comment_id: number;
		status_id: Task.STATUS;
		entity_id: Task.ENTITY;
		entity_value: number;
		comment: string;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
	}

	export interface Company {
		company_id: number;
		company_type_id: number;
		status_id: number;
		name: string;
		email: string;
		description: string;
		tax_number: string;
		address: string;
		city: string;
		postal_code: string;
		country: string;
		contact_phone: string;
		contact_name: string;
		identification_no: string;
		smtp_url: string;
		smtp_port: number;
		smtp_username: string;
		smtp_password: string;
		smtp_email: string;
		smtp_use_default: boolean;
		order_from_schedule_days_ahead: number;
		main_company_nfc: boolean;
		jobs: {
			job_ticket_warnings_last: TIMESTAMP;
			job_late_scheduled_orders_time: TIME;
			job_late_scheduled_orders_last: TIMESTAMP;
			job_gen_scheduled_orders_time: TIME;
			job_gen_scheduled_orders_last: TIMESTAMP;
			job_cancel_takeovers_last: TIMESTAMP;
			job_ticket_warnings_time: TIME;
		};
		flags: {
			flag_checklists: boolean;
			flag_nfcs: boolean;
			flag_scheduled_orders: boolean;
		};
	}

	export interface Location {
		location_id: number;
		status_id: number;
		company_id: number;
		company_name: string;
		location_type_id: number;
		parent_id: number;
		cin: string;
		name: string;
		short_name: string;
		description: string;
		address: string;
		format: string;
		city: string;
		district: string;
		region: string;
		area: string;
		country: string;
		latitude: number;
		longitude: number;
		postal_code: string;
		contact_phone: string;
		fax: string;
		email: string;
		timezone: string;
		net_area: number;
		gross_area: number;
		automatically_approve_tickets: boolean;
		nfc: boolean;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: null;
		location_users: Array<{
			user_id: number;
			first_name: string;
			last_name: string;
			user_type_id: Task.USER_TYPE;
			pic_url: null;
		}>;
		location_groups_users: Array<{
			user_id: number;
			first_name: string;
			last_name: string;
			user_type_id: Task.USER_TYPE;
			pic_url: null;
		}>;
	}

	export interface LocationGroup {
		location_group_id: number;
		company_id: number;
		company_name: string;
		name: string;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
		nfc: boolean;
		locations_count: number;
		locations: Array<{
			location_id: number;
			cin: string;
			name: string;
			nfc: boolean;
		}>;
		location_group_users: Array<{
			user_id: number;
			first_name: string;
			last_name: string;
			user_type_id: Task.USER_TYPE;
			pic_url: string;
		}>;
	}

	export interface LocationGroupAssociation {
		location_group_association_id: number;
		location_group_id: number;
		location_id: number;
	}

	export interface LocationUser {
		location_user_id: number;
		location_id: number;
		location_group_id: number;
		user_id: number;
		work_group_id: number;
		expiration_date: TIMESTAMP;
		original_user_id: number;
	}

	export interface Order {
		order_id: number;
		company_id: number;
		company_name: string;
		status_id: Task.STATUS;
		supplier_id: number;
		location_id: number;
		location_name: string;
		location_address: string;
		location_cin: string;
		title: string;
		mail_sent_to_supplier: boolean;
		total_minutes: number;
		total_arrivals: number;
		active_arrivals: number;
		arrival_in_execution: boolean;
		next_arrival_date: null|TIMESTAMP;
		status_date: TIMESTAMP;
		comment: string;
		invoice_no: string;
		in_warranty_period: boolean;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: null;
		insurance: boolean;
		lease: boolean;
		preinvoice: boolean;
		inspection: boolean;
		complaint: boolean;
		scheduled_order_id: number;
		is_generated_from_scheduled_order: boolean;
		authorized_maintenance_managers: Array<{
			user_id: number;
			first_name: string;
			last_name: string;
		}>;
		approved_by_id: number;
		approved_by_first_name: null|string;
		approved_by_last_name: null|string;
		arrival_worker_id: number;
		arrival_worker_first_name: null|string;
		arrival_worker_last_name: null|string;
		internal_approval_mail_sent: null|boolean;
		ping_pong_count: number;
		cost_estimate: number;
		cost_estimate_currency: string;
		investment_type: number;
		supplier_name: string|`Interno`;
		whier: {
			category_description: string;
			whier_id: number;
			name: string;
			description: string;
			subcategory_description: string;
		};
		order_details: Array<{
			order_detail_id: number;
			order_id: number;
			order_detail_type_id: number;
			description: string;
			dms_document_id: number;
			dms_document_file_name: string;
			picture_url: string;
			inventory_id: number;
			inventory_name: string;
			inventory_no: string;
			inventory_serial_no: string;
			inventory_data: any;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_last_name: string;
		}>;
	}


	export interface OrderDetail {
		order_detail_id: number;
		order_id: number;
		order_detail_type_id: number;
		description: string;
		dms_document_id: number;
		dms_document_file_name: string;
		picture_url: string;
		inventory_id: number;
		inventory_name: string;
		inventory_no: string;
		inventory_serial_no: string;
		inventory_data: KAM_Inventory;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
	}

	export interface Pricelist {
		element_id: number;
		company_id: number;
		to_company_id: number;
		element_type_id: Task.ELEMENT_TYPE;
		name: string;
		cin: string;
		unit_of_measure: string;
		lump_sum: boolean;
		invoice_type_id: number;
		work_hour_type_id: number;
		description: string;
		work_description_id: number;
		price: number;
		currency: string;
		warranty_period: number;
	}

	export interface PricelistEdit {
		pricelist_edit_id: number;
		user_id: number;
		user: {
			user_id: number;
			status_id: Task.STATUS;
			company_id: number;
			company_name: string;
			sector_id: number;
			sector_name: null|string;
			user_type_id: Task.USER_TYPE;
			first_name: string;
			last_name: string;
			email: string;
			password_date: TIMESTAMP;
			last_password_recovery_email_time: TIMESTAMP;
			pass_key: string;
			contact_phone: string;
			pic_url: string;
			work_hour_type_id: number;
			communication_type: number;
			locations: number|Array<{
				location_id: number;
				location_name: string;
				location_cin: string;
				location_address: string;
			}>;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: null|string;
			created_by_last_name: null|string;
			created_by_pic_url: null;
			feature_flags: {
				nfcs: boolean;
				checklists: boolean;
				scheduled_orders: boolean;
			};
		};
		supplier: {
			company_id: number;
			company_type_id: number;
			status_id: number;
			name: string;
			email: string;
			description: string;
			tax_number: string;
			address: string;
			city: string;
			postal_code: string;
			country: string;
			contact_phone: string;
			contact_name: string;
			identification_no: string;
			smtp_url: string;
			smtp_port: number;
			smtp_username: string;
			smtp_password: string;
			smtp_email: string;
			smtp_use_default: boolean;
			order_from_schedule_days_ahead: number;
			main_company_nfc: boolean;
			jobs: {
				job_ticket_warnings_last: TIMESTAMP;
				job_late_scheduled_orders_time: TIME;
				job_late_scheduled_orders_last: TIMESTAMP;
				job_gen_scheduled_orders_time: TIME;
				job_gen_scheduled_orders_last: TIMESTAMP;
				job_cancel_takeovers_last: TIMESTAMP;
				job_ticket_warnings_time: TIME;
			};
			flags: {
				flag_checklists: boolean;
				flag_nfcs: boolean;
				flag_scheduled_orders: boolean;
			};
		};
		created_date: TIMESTAMP;
		table: string;
		supplier_id: number;
	}

	export interface QR_Code {
		qr_code_id: number;
		qr_code_type_id: number;
		arrival_id: number;
		order_id: number;
		hash: string;
		scanned: boolean;
	}

	export interface Questionnaire {
		questionnaire_id: number;
		company_id: number;
		whier_id: number;
		question: string;
		component_type_id: number;
		option: string;
		group: number;
		min_images: number;
		max_images: number;
	}

	export interface ScheduledOrder {
		scheduled_order_id: number;
		company_id: number;
		whier_id: number;
		status_id: Task.STATUS;
		supplier_id: number;
		location_id: number;
		location_name: string;
		location_address: string;
		location_cin: string;
		title: string;
		planned_start_date: TIMESTAMP;
		planned_end_date: TIMESTAMP;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
		approved_date: TIMESTAMP;
		approved_by_id: number;
		approved_by_first_name: null|string;
		approved_by_last_name: null|string;
		approved_by_pic_url: null|string;
		order_from_schedule_days_ahead: number;
		order_id: number;
		arrival_worker_id: number;
		arrival_worker_first_name: null|string;
		arrival_worker_last_name: null|string;
		cost_estimate: number;
		investment_type: number;
		supplier_name: string|`Interno`;
		whier: null|{
			category_description: string;
			whier_id: number;
			name: string;
			description: string;
			subcategory_description: string;
		};
		scheduled_order_details: Array<{
			scheduled_order_detail_id: number;
			scheduled_order_id: number;
			order_detail_type_id: number;
			description: string;
			picture_url: string;
			inventory_id: number;
			inventory_name: string;
			inventory_no: string;
			inventory_serial_no: string;
			inventory_data: any;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_last_name: string;
			created_by_pic_url: string;
		}>;
		order_status_id: null|Task.STATUS;
	}

	export interface ScheduledOrderDetail {
		scheduled_order_detail_id: number;
		scheduled_order_id: number;
		order_detail_type_id: number;
		description: string;
		picture_url: string;
		inventory_id: number;
		inventory_name: string;
		inventory_no: string;
		inventory_serial_no: string;
		inventory_data: KAM_Inventory;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
	}

	export interface ScheduledOrderTemplate {
		scheduled_orders_template_id: number;
		company_id: number;
		whier_id: number;
		status_id: Task.STATUS;
		supplier_id: number;
		title: string;
		planned_start_date: TIMESTAMP;
		planned_end_date: TIMESTAMP;
		cut_date: TIMESTAMP;
		cost_estimate: number;
		investment_type: number;
		created_date: TIMESTAMP;
		created_by_id: number;
		rejected_date: TIMESTAMP;
		rejected_by_id: number;
		rejected_by_first_name: null|string;
		rejected_by_last_name: null|string;
		modified_by_first_name: null|string;
		modified_by_last_name: null|string;
		modified_date: TIMESTAMP;
		annual_frequency: Task.ANNUAL_FREQUENCY;
		comment: string;
		arrival_worker_id: number;
		arrival_worker_first_name: null|string;
		arrival_worker_last_name: null|string;
		supplier_name: string|`Interno`;
		whier: null|{
			whier_id: number;
			company_id: number;
			parent_whier_id: number;
			name: string;
			description: string;
			long_description: string;
			level: Task.WHIER_LEVEL;
			automatically_approve_tickets: boolean;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_last_name: string;
			created_by_pic_url: string;
			group_name: string;
			group_description: string;
			subcategory_name: string;
			subcategory_description: string;
			category_name: string;
			category_description: string;
			send_email_on_ticket_approval: boolean;
		};
		locations: Array<{
			location_id: any;
			name: any;
			cin: any;
			address: string;
		}>;
		scheduled_orders_template_details: Array<{
			scheduled_orders_template_detail_id: number;
			scheduled_orders_template_id: number;
			order_detail_type_id: number;
			description: string;
			picture_url: string;
			inventory_id: number;
			inventory_name: string;
			inventory_no: string;
			inventory_serial_no: string;
			inventory_data: any;
			created_date: TIMESTAMP;
			created_by_id: number;
			created_by_first_name: string;
			created_by_last_name: string;
			created_by_pic_url: string;
		}>;
	}

	export interface ScheduledOrderTemplateDetail {
		scheduled_orders_template_detail_id: number;
		scheduled_orders_template_id: number;
		order_detail_type_id: number;
		description: string;
		picture_url: string;
		inventory_id: number;
		inventory_name: string;
		inventory_no: string;
		inventory_serial_no: string;
		inventory_data: KAM_Inventory;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
	}

	export interface ScheduledOrdersEdits {
		scheduled_orders_edit_id: number;
		user_id: number;
		created_date: TIMESTAMP;
		file_name: string;
		supplier_id: number;
	}

	export interface Sector {
		sector_id: number;
		company_id: number;
		name: string;
		whiers: Array<{
			category_description: string;
			whier_id: number;
			name: string;
			description: string;
			subcategory_description: string;
		}>;
	}

	export interface Supplier {
		company_id: number;
		company_type_id: number;
		status_id: number;
		name: string;
		email: string;
		description: string;
		tax_number: string;
		address: string;
		city: string;
		postal_code: string;
		country: string;
		contact_phone: string;
		contact_name: string;
		identification_no: string;
		smtp_url: string;
		smtp_port: number;
		smtp_username: string;
		smtp_password: string;
		smtp_email: string;
		smtp_use_default: boolean;
		order_from_schedule_days_ahead: number;
		main_company_nfc: boolean;
		jobs: {
			job_ticket_warnings_last: TIMESTAMP;
			job_late_scheduled_orders_time: TIME;
			job_late_scheduled_orders_last: TIMESTAMP;
			job_gen_scheduled_orders_time: TIME;
			job_gen_scheduled_orders_last: TIMESTAMP;
			job_cancel_takeovers_last: TIMESTAMP;
			job_ticket_warnings_time: TIME;
		};
		flags: {
			flag_checklists: boolean;
			flag_nfcs: boolean;
			flag_scheduled_orders: boolean;
		};
		supplier_id: number;
		cin: string;
		comany_name: string;
		main_company_id: number;
		main_company_name: string;
		sectors: Array<{
			sector_id: number;
			company_id: number;
			name: string;
			whiers: Array<{
				category_description: string;
				whier_id: number;
				name: string;
				description: string;
				subcategory_description: string;
			}>;
		}>;
	}


	export interface Ticket {
		ticket_id: number;
		status_id: Task.STATUS;
		company_id: number;
		company_name: string;
		whier: {
			category_description: string;
			whier_id: number;
			name: string;
			description: string;
			subcategory_description: string;
		};
		location_id: number;
		location_name: string;
		location_address: string;
		location_city: string;
		location_cin: string;
		priority_id: Task.PRIORITY;
		title: string;
		comment: string;
		reference_ticket_id: number;
		associated_orders_count: number;
		active_orders_count: number;
		responsible_users: Array<{
			first_name: string;
			last_name: string;
		}>;
		authorized_maintenance_managers: Array<{
			user_id: number;
			first_name: string;
			last_name: string;
		}>;
		approved_or_rejected_by_id: number;
		approved_or_rejected_by_first_name: null|string;
		approved_or_rejected_by_last_name: null|string;
		approved_or_rejected_date: TIMESTAMP;
		assigned_user_id: number;
		assigned_user_first_name: null|string;
		assigned_user_last_name: null|string;
		taken_over_date: TIMESTAMP;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		status_date: TIMESTAMP;
		on_refinement: boolean;
		complaint: boolean;
		ticket_details: Array<{
			ticket_detail_id: number;
			component_type_id: number;
			question: string;
			answer: string;
			inventory_id: number;
			inventory_name: string;
			inventory_no: string;
			inventory_serial_no: string;
			inventory_data: any;
			dms_document_id: number;
			dms_document_file_name: string;
		}>;
	}

	export interface TicketDetail {
		ticket_detail_id: number;
		component_type_id: number;
		question: string;
		answer: string;
		inventory_id: null|number;
		inventory_name: null|string;
		inventory_no: null|string;
		inventory_serial_no: null|string;
		inventory_data: KAM_Inventory;
		dms_document_id: null|number;
		dms_document_file_name: null|string;
	}


	export interface User {
		user_id: number;
		status_id: Task.STATUS;
		company_id: number;
		company_name: string;
		sector_id: number;
		sector_name: null|string;
		user_type_id: Task.USER_TYPE;
		first_name: string;
		last_name: string;
		email: string;
		password_date: TIMESTAMP;
		last_password_recovery_email_time: TIMESTAMP;
		pass_key: string;
		contact_phone: string;
		pic_url: string;
		work_hour_type_id: number;
		communication_type: number;
		locations: number|Array<{
			location_id: number;
			location_name: string;
			location_cin: string;
			location_address: string;
		}>;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: null|string;
		created_by_last_name: null|string;
		created_by_pic_url: null;
		feature_flags: {
			nfcs: boolean;
			checklists: boolean;
			scheduled_orders: boolean;
		};
	}

	export interface UserTakeoverHistory {
		user_takeover_history_id: number;
		start_date: TIMESTAMP;
		end_date: TIMESTAMP;
		user_id: number;
		user_first_name: string;
		user_last_name: string;
		replaced_user_id: number;
		replaced_user_first_name: string;
		replaced_user_last_name: string;
		status_id: number;
	}

	export interface UserWorkGroupAssociation {
		user_work_group_association_id: number;
		work_group_id: number;
		user_id: number;
	}

	export interface Version {
		mobile_version: string;
		mobile_version_android: string;
		mobile_version_ios: string;
		version_date: TIMESTAMP;
	}

	export interface WorkDescription {
		work_description_id: number;
		work_description: string;
	}

	export interface WorkGroup {
		work_group_id: number;
		company_id: number;
		whier_id: number;
		whier_name: null|string;
		whier_description: null|string;
		work_group_type_id: number;
		name: string;
		description: string;
		email: string;
	}

	export interface WorkHierarchy {
		whier_id: number;
		company_id: number;
		parent_whier_id: number;
		name: string;
		description: string;
		long_description: string;
		level: Task.WHIER_LEVEL;
		automatically_approve_tickets: boolean;
		created_date: TIMESTAMP;
		created_by_id: number;
		created_by_first_name: string;
		created_by_last_name: string;
		created_by_pic_url: string;
		group_name: string;
		group_description: string;
		subcategory_name: string;
		subcategory_description: string;
		category_name: string;
		category_description: string;
		send_email_on_ticket_approval: boolean;
	}
}

